import { Box, Container, Flex, Image, Link, Text } from '@chakra-ui/react';
import { useEffect } from 'react';

import { CreditFrozen, EquifaxLogo, ExperianLogo, TransUnionLogo } from '../../assets/Images';
import Subtitle from '../../components/Subtitle';
import Title from '../../components/Title';
import TitleContainer from '../../components/TitleContainer';
import { useSearchPhoneNumbers } from '../../hooks';
import { resetLocalStorage } from '../../services/localStorage';

interface Props {
  customerFrozenOrLockedCredit: boolean;
  cobuyerFrozenOrLockedCredit: boolean;
}

const FrozenOrLockedCredit = ({
  customerFrozenOrLockedCredit,
  cobuyerFrozenOrLockedCredit,
}: Props) => {
  const phoneNumber = useSearchPhoneNumbers();

  useEffect(() => {
    resetLocalStorage({ removeGuid: true, removeAccessToken: true });
  }, []);

  const justCobuyerFrozenOrLocked = !customerFrozenOrLockedCredit && cobuyerFrozenOrLockedCredit;

  return (
    <Container textAlign="left">
      <TitleContainer>
        <Title textAlign="center">
          Your {justCobuyerFrozenOrLocked ? "Co-Buyer's" : ''} Credit is Frozen or Locked
        </Title>
      </TitleContainer>
      <Subtitle mt={0} mb={5} textAlign="center">
        We will need {justCobuyerFrozenOrLocked ? 'them' : 'you'} to unfreeze or unlock it to
        proceed.
      </Subtitle>
      <Box zIndex={20} position="relative" mt={20}>
        <Image
          zIndex={-1}
          w="160px"
          position="absolute"
          top="-85px"
          left="-100px"
          transform="rotate(-20deg)"
          src={CreditFrozen}
          display={{ base: 'none', md: 'block' }}
        />
        <Box bgColor="blue.100" p={5}>
          <Text fontWeight="bold" fontSize={{ base: '14px', lg: '18px' }} mb={2}>
            {justCobuyerFrozenOrLocked
              ? 'HOW YOUR CO-BUYER CAN UNFREEZE OR UNLOCK THEIR CREDIT'
              : 'HOW TO UNFREEZE OR UNLOCK YOUR CREDIT'}
          </Text>
          <Text fontSize={{ base: '14px', lg: '16px' }}>
            {justCobuyerFrozenOrLocked
              ? 'Your co-buyer has a credit freeze or lock with one or more of the credit ' +
                'bureaus. For us to continue the loan approval process, they’ll need to ' +
                'unfreeze or unlock their credit.'
              : "You'll need to unfreeze or unlock your credit with the credit bureaus where " +
                "you've placed the restriction. "}
          </Text>
          <Text mt={2} fontSize={{ base: '14px', lg: '16px' }}>
            {justCobuyerFrozenOrLocked
              ? 'The process is simple and can be done by contacting the credit bureaus ' +
                'directly. Below are links to help them get started:'
              : 'Each bureau has a slightly different process, ' +
                "but don't worry, it's simple and quick. You can unfreeze or unlock your " +
                'credit by either calling them or visiting their websites below:'}
          </Text>
          <Flex
            fontSize={{ base: '14px', lg: '16px' }}
            mt={6}
            gap={2}
            justifyContent="space-around"
          >
            <Link href="https://www.equifax.com" isExternal>
              <Image w={{ base: '80px', md: '120px' }} mt={3} src={EquifaxLogo} />
            </Link>
            <Link href="https://www.experian.com/" isExternal>
              <Image w={{ base: '80px', md: '120px' }} src={ExperianLogo} />
            </Link>
            <Link href="https://www.transunion.com/" isExternal>
              <Image w={{ base: '80px', md: '120px' }} src={TransUnionLogo} />
            </Link>
          </Flex>
        </Box>
      </Box>
      <Text textAlign="center" fontSize="14px" color="gray" mt={8}>
        Once {justCobuyerFrozenOrLocked ? 'their' : 'your'} credit is unfrozen or unlocked, we’ll be
        able to continue the loan approval process. If you need assistance beforehand, give us a
        call at <Link href={`tel:+1-${phoneNumber}`}>{phoneNumber}</Link>.
      </Text>
    </Container>
  );
};

export default FrozenOrLockedCredit;
