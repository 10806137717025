import { Text, TextProps } from '@chakra-ui/react';
import React from 'react';

const Title: React.FC<TextProps> = ({ children, ...props }) => (
  <Text
    color="leaseEndBlue"
    fontWeight="semibold"
    fontSize={{ base: '24px', lg: '36px' }}
    textAlign="center"
    mb="20px"
    {...props}
  >
    {children}
  </Text>
);

export default Title;
