import { Card, CardBody, CardProps, Flex, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons';

type Props = {
  title: string;
  icon?: IconType;
  headerColor?: string;
  hasBorder?: boolean;
  children: React.ReactNode;
  _cardBodyProps?: CardProps;
} & CardProps;

const DashboardCard = ({
  title,
  icon,
  headerColor = 'gainsboro',
  hasBorder,
  children,
  color,
  _cardBodyProps,
  ...rest
}: Props) => (
  <Card
    {...(hasBorder && { border: '1px solid', borderColor: headerColor })}
    overflow="hidden"
    color={color}
    {...rest}
  >
    <Flex flexDirection="row" align="center" gap="5px" bgColor={headerColor} padding="15px">
      {icon && <Icon boxSize="25px" color={color || 'gray'} as={icon} />}
      <Text fontSize="20px" fontWeight="600">
        {title}
      </Text>
    </Flex>
    <CardBody {..._cardBodyProps}>{children}</CardBody>
  </Card>
);

export default DashboardCard;
