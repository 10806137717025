import { Button, Flex, Text } from '@chakra-ui/react';
import React from 'react';

import Modal from '../../../../components/Modal';
import PrimaryButton from '../../../../components/ui/buttons/PrimaryButton';
import { Deal } from '../../../../gql/generated/graphql';
import { capitalizeFirstLetter } from '../../../../utils/text/text';

interface Props {
  deal: Deal;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  handleSwitch: (cobuyer: boolean) => void;
}

const WhoIsSigningModal = ({ isOpen, onClose, deal, onConfirm, handleSwitch }: Props) => {
  const isCobuyer = deal?.isCobuyer ?? false;
  const person = isCobuyer ? deal?.cobuyer : deal?.customer;
  const personRole = isCobuyer ? 'co-buyer' : 'buyer';
  const buyerFinishedSigning = deal?.customer?.finished_signing;
  const cobuyerFinishedSigning = deal?.cobuyer?.finished_signing;
  const showSwitchButton = isCobuyer ? !buyerFinishedSigning : !cobuyerFinishedSigning;

  return (
    <Modal
      title="Who’s Signing?"
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={false}
      isCentered
      closeOnOverlayClick={false}
    >
      <Flex flexDir="column">
        <Text mb={6} textAlign="center" fontSize="16px">
          You’re about to sign as the {personRole},{' '}
          <Text as="span" fontWeight="bold">
            {capitalizeFirstLetter(person?.first_name)} {capitalizeFirstLetter(person?.last_name)}.
          </Text>{' '}
          Ensure that your signature accurately reflects your role as the {personRole}.
        </Text>
        <PrimaryButton
          w="fit-content"
          alignSelf="center"
          onClick={onConfirm}
          textTransform="uppercase"
        >
          Sign as the {personRole}
        </PrimaryButton>
        {showSwitchButton && (
          <Button
            fontWeight="semibold"
            mt={4}
            w="fit-content"
            alignSelf="center"
            variant="link"
            onClick={() => {
              onClose();
              handleSwitch(isCobuyer);
            }}
          >
            Sign as the{isCobuyer ? ' ' : ' Co-'}Buyer
          </Button>
        )}
      </Flex>
    </Modal>
  );
};

export default WhoIsSigningModal;
