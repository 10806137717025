import { useEffect, useRef, useState } from 'react';

const loadImage = (url: string) =>
  new Promise<void>((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve();
    img.onerror = () => reject();
    img.src = url;
  });

const useImageLoader = (urls: string[]) => {
  // using a useRef to ensure that the loaded state is for the current urls
  const loadedUrls = useRef<string[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    const loadImages = async () => {
      const promises = urls.map((url) => loadImage(url));
      await Promise.allSettled(promises);

      loadedUrls.current = urls;
      setLoading(false);
    };

    loadImages();
  }, [urls]);

  return loading || loadedUrls.current !== urls;
};

export default useImageLoader;
