import { Box, Button, Link, Spinner, Stack, Text, VStack } from '@chakra-ui/react';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { ChangeEvent, Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import * as Yup from 'yup';

import makes from '../constants/makes';
import { statesArray } from '../constants/states';
import { DASHBOARD } from '../constants/urls';
import { Maybe, TemporaryData, useKbbVinLazyQuery } from '../gql/generated/graphql';
import { useSearchPhoneNumbers } from '../hooks';
import { fuelTypeOptions, vehicleTypeOptions } from '../utils/cars';
import validateVin from '../utils/validation/vinValidator';
import Modal from './Modal';
import Input from './formComponents/Input';
import Select, { Option } from './formComponents/Select';
import PrimaryButton from './ui/buttons/PrimaryButton';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  initialValues: Partial<TemporaryData>;
  handleSubmit: (values: Partial<TemporaryData>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema: Yup.ObjectSchema<any>;
  resetWarnings?: () => void;
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  flowStartError?: string;
  dealExists?: boolean;
  isFlowStart?: boolean;
  isManualEntry?: Maybe<boolean>;
}

const CarDetailsModal = ({
  isOpen,
  setIsOpen,
  initialValues,
  handleSubmit,
  validationSchema,
  resetWarnings,
  title,
  subtitle,
  flowStartError,
  dealExists,
  isFlowStart,
  isManualEntry,
}: Props) => {
  const onModalClose = () => {
    setIsOpen(!isOpen);
    resetWarnings?.();
  };

  const [kbbLoading, setKbbLoading] = useState(false);
  const [getCarModels] = useKbbVinLazyQuery({ onCompleted: () => setKbbLoading(false) });
  const [trimOptions, setTrimOptions] = useState<Option[]>([]);

  const phoneNumber = useSearchPhoneNumbers();

  const handleVinChange = async (
    e: ChangeEvent<HTMLInputElement>,
    setFieldValue: FormikHelpers<Partial<TemporaryData>>['setFieldValue'],
    setFieldTouched: FormikHelpers<Partial<TemporaryData>>['setFieldTouched'],
  ) => {
    const vin = e.target.value;
    if (validateVin(vin)) {
      setKbbLoading(true);
      const { data } = await getCarModels({ variables: { vin } });

      const vinResults = data?.kbbVin?.vinResults ?? [];

      if (vinResults.length === 0) {
        return;
      }

      if (vinResults?.length === 1) {
        setFieldValue('kbb_trim_name', vinResults?.[0]?.trimName ?? '');
        setTrimOptions([]);
      } else {
        setTrimOptions(
          vinResults.map((result) => ({
            value: result?.trimName ?? '',
            label: result?.trimName ?? '',
          })),
        );
      }

      const makeName = vinResults?.[0]?.makeName;
      const modelName = vinResults?.[0]?.modelName;

      if (makeName) {
        setFieldValue('make', makeName?.toUpperCase());
        setFieldTouched('make', true);
      }
      if (modelName) {
        setFieldValue('model', modelName);
        setFieldTouched('model', true);
      }
    } else {
      setFieldValue('make', '');
      setFieldValue('model', '');
      setFieldValue('kbb_trim_name', '');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onModalClose} size="2xl" title={title} subtitle={subtitle}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, setFieldValue, setFieldTouched }) => {
          return (
            <Form style={{ width: '100%' }}>
              <Stack direction={{ base: 'column', md: 'row' }} mt={5}>
                <Input
                  name="license_plate_number"
                  label="License Plate"
                  _container={{ w: { base: 'full', md: '30%' } }}
                />
                <Select
                  name="license_plate_state"
                  options={statesArray}
                  label="State"
                  _container={{ w: { base: 'full', md: '20%' } }}
                />
                <Input
                  name="vin"
                  label="VIN"
                  _container={{ w: { base: 'full', md: '50%' } }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleVinChange(e, setFieldValue, setFieldTouched)
                  }
                />
              </Stack>
              <Stack direction={{ base: 'column', md: 'row' }} mt={5}>
                <Input
                  name="year"
                  label="Year"
                  type="number"
                  _container={{ w: { base: 'full', md: '20%' } }}
                  maxLength={4}
                />
                <Select
                  name="make"
                  options={makes}
                  label="Make"
                  _container={{ w: { base: 'full', md: '40%' } }}
                  icon={kbbLoading ? <Spinner size="xs" /> : null}
                />
                <Input
                  name="model"
                  label="Model"
                  _container={{ w: { base: 'full', md: '40%' } }}
                  icon={kbbLoading ? <Spinner size="xs" /> : null}
                />
              </Stack>
              {trimOptions.length > 1 && (
                <Select
                  name="kbb_trim_name"
                  options={trimOptions}
                  label="Trim"
                  _container={{ w: 'full', mt: 5 }}
                />
              )}
              {(isFlowStart || isManualEntry) && (
                <Stack direction={{ base: 'column', md: 'row' }} mt={5}>
                  <Select
                    name="fuel_type"
                    options={fuelTypeOptions}
                    label="Fuel Type"
                    _container={{ w: { base: 'full', md: '50%' } }}
                  />
                  <Select
                    name="vehicle_type"
                    options={vehicleTypeOptions}
                    label="Vehicle Type"
                    _container={{ w: { base: 'full', md: '50%' } }}
                  />
                </Stack>
              )}
              {isFlowStart && flowStartError && (
                <Text my={4} color="red.500" fontWeight="semibold" textAlign="center">
                  {flowStartError}
                </Text>
              )}
              {isFlowStart && dealExists && (
                <Box textAlign="center" mt={2}>
                  <Text color="red.500" fontWeight="semibold" wordBreak="break-word">
                    Looks like you already have a lease buyout in progress with us. Please give us a
                    call at{' '}
                    <Link display="inline-block" color="red.500" href={`tel:+1-${phoneNumber}`}>
                      {phoneNumber}
                    </Link>
                    .
                  </Text>
                  <Text fontWeight="semibold">
                    You can view it's status in{' '}
                    <Link as={ReactRouterLink} to={DASHBOARD}>
                      your dashboard.
                    </Link>
                  </Text>
                </Box>
              )}
              <VStack spacing={4} w="full" justifyContent="center" mt={5}>
                <PrimaryButton type="submit" isLoading={isSubmitting}>
                  {isFlowStart ? 'GET STARTED' : 'NEXT'}
                </PrimaryButton>
                {!isFlowStart && (
                  <Button variant="link" onClick={onModalClose}>
                    Nevermind, go back
                  </Button>
                )}
              </VStack>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CarDetailsModal;
