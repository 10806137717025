import { Flex, FlexProps, Icon, Link, Text } from '@chakra-ui/react';
import { BiShieldQuarter } from 'react-icons/bi';

import { LDFlags } from '../constants/flags';
import { PRIVACY_POLICY } from '../constants/urls';
import useFlag from '../hooks/useFlag';

const SafetyMessage = (props: FlexProps) => {
  const showSafetyMessage = useFlag(LDFlags.SHOW_SAFETY_MESSAGE);

  if (!showSafetyMessage) {
    return null;
  }

  return (
    <Flex alignItems="center" margin="0 auto 10px" maxW={{ lg: '650px' }} {...props}>
      <Icon as={BiShieldQuarter} w={10} h={10} color="lightgray" />
      <Text color="gray" textAlign="left" ml="2">
        We will never-ever share, sell, divulge, tweet or post any of your information. Everything
        is processed using SHA-256 with RSA-Encryption to keep the bad guys out!{' '}
        <Link href={PRIVACY_POLICY} color="royalBlue" textDecoration="underline" isExternal>
          Learn more
        </Link>
      </Text>
    </Flex>
  );
};

export default SafetyMessage;
