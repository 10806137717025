import { string } from 'yup';

export const emailValidationNoAlias = string()
  .nullable()
  .required('Field is required')
  .email('Please enter a valid email')
  .test('no-alias', 'You may not use email aliases', (value) => {
    if (!value) return false;
    if (value.includes('+')) {
      return false;
    }
    return true;
  });

export const emailValidationOptional = string()
  .nullable()
  .required('Email is required')
  .email('Please enter a valid email address');

export const emailValidationRequired = string()
  .nullable()
  .required('Field is required')
  .email('Please enter a valid email address');

export const validateEmail = (email: string) => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};
