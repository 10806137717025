import { ListItem, UnorderedList } from '@chakra-ui/react';

export const StrongPasswordTooltipExplanation = (
  <UnorderedList>
    <ListItem>Between 8-30 characters</ListItem>
    <ListItem>One uppercase letter</ListItem>
    <ListItem>One lowercase letter</ListItem>
    <ListItem>One number</ListItem>
    <ListItem>Not the previous 2 passwords</ListItem>
  </UnorderedList>
);
