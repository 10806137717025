import { Button, Center, Flex, Link, Text, VStack } from '@chakra-ui/react';
import { Form, Formik, FormikHelpers } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useJwt } from 'react-jwt';
import { Link as ReactRouterLink } from 'react-router-dom';
import * as Yup from 'yup';

import { useLEAccordionItemContext } from '../../../components/LEAccordion/LEAccordionItem/useLEAccordionItem';
import LogoutConfirmModal from '../../../components/LogoutConfirmModal';
import Input from '../../../components/formComponents/Input';
import Label from '../../../components/formComponents/Label';
import PrimaryButton from '../../../components/ui/buttons/PrimaryButton';
import { PHONE_NUMBER_PLACEMENT_VARIATIONS } from '../../../constants/customers';
import { LDFlags } from '../../../constants/flags';
import { ACCOUNT_LOGIN } from '../../../constants/urls';
import {
  useCheckExistingAccountByEmailLazyQuery,
  useTemporaryInfoUpsertMutation,
} from '../../../gql/generated/graphql';
import { useFlag, useStep, useTempInfo } from '../../../hooks';
import { CookieKeys, useCookie } from '../../../hooks/useCookie';
import { ResetWsContext } from '../../../providers/AuthorizedApolloProvider';
import { logout, parseTokenFromHash } from '../../../services/auth0';
import { resetLocalStorage } from '../../../services/localStorage';
import { logger } from '../../../services/sentry';
import { RudderEvent, rudderanalytics } from '../../../utils/rudderstack';
import { customerNameValidation } from '../../../utils/validation/customerInformation';
import { emailValidationRequired } from '../../../utils/validation/email';
import { middleNameValidation } from '../../../utils/validation/names';
import {
  phoneValidationOptional,
  phoneValidationRequired,
} from '../../../utils/validation/phoneNumber';

interface FormValues {
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  check_email: string;
}

export const EMAIL_KEY = 'https://api.leaseend.com/email';

const PersonalInfo = () => {
  const [accessToken, setAccessToken] = useCookie<string>(CookieKeys.ACCESS_TOKEN);
  const { decodedToken } = useJwt<{ [EMAIL_KEY]: string }>(accessToken || '');
  const accessTokenEmail = decodedToken?.[EMAIL_KEY];
  const step = useStep();
  const hideName = useFlag(LDFlags.HIDE_NAME_ON_GETTING_STARTED);
  const phoneNumberPlacementVariation = useFlag(LDFlags.PHONE_NUMBER_PLACEMENT_VARIATION);
  const { updateState, isExpanded } = useLEAccordionItemContext();
  const { info } = useTempInfo('network-only', isExpanded);
  const [upsertTemporaryInfo] = useTemporaryInfoUpsertMutation();
  const [checkExistingAccountByEmail] = useCheckExistingAccountByEmailLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [logoutConfirmModalOpen, setLogoutConfirmModalOpen] = useState(false);

  const { lastPanelGettingStartedRequired, lastPanelGettingStartedOptional } =
    PHONE_NUMBER_PLACEMENT_VARIATIONS;

  const phoneIsRequired = phoneNumberPlacementVariation === lastPanelGettingStartedRequired;
  const showPhone =
    phoneIsRequired || phoneNumberPlacementVariation === lastPanelGettingStartedOptional;

  const validationSchema = Yup.object({
    ...(!hideName && {
      ...customerNameValidation,
      middle_name: middleNameValidation,
    }),
    email: emailValidationRequired,
    phone_number: Yup.string().when(['check_email', 'email'], {
      is: (check_email: string, email: string) =>
        (check_email && check_email === email) || (showPhone && phoneIsRequired),
      then: phoneValidationRequired,
      otherwise: phoneValidationOptional,
    }),
    password: Yup.string().when(['check_email', 'email'], {
      is: (check_email: string, email: string) => check_email && check_email === email,
      then: Yup.string().required('Please enter your password'),
    }),
  });

  const onSubmitForm = async (values: FormValues, { setFieldValue }: FormikHelpers<FormValues>) => {
    const data = {
      first_name: values.first_name.trim(),
      middle_name: values.middle_name.trim(),
      last_name: values.last_name.trim(),
      email: values.email,
      phone_number: values.phone_number,
    };

    await upsertTemporaryInfo({
      variables: {
        info: {
          id: info?.id,
          data,
        },
      },
    });

    TagManager.dataLayer({
      dataLayer: {
        event: 'lead',
        enhanced_conversion_data: {
          email: values?.email ?? '',
          phone_number: values?.phone_number ?? '',
        },
      },
    });

    rudderanalytics.identify({
      firstName: values.first_name,
      lastName: values.last_name,
      email: values.email,
      phone: values.phone_number,
      middleName: values.middle_name,
    });

    if (!accessToken) {
      const { data: accountExists } = await checkExistingAccountByEmail({
        variables: {
          email: values.email,
        },
      });
      if (accountExists?.checkExistingAccountByEmail) {
        setFieldValue('check_email', values.email);
        rudderanalytics.track(RudderEvent.AccountExists, { email: values.email });
        return;
      }
    }

    updateState({ status: 'complete' });
    step.moveNext();
  };

  const { resetWebSocket } = useContext(ResetWsContext);

  const alreadyRan = useRef(false);
  useEffect(() => {
    if (!window.location.hash || alreadyRan.current) {
      return;
    }

    alreadyRan.current = true;

    parseTokenFromHash({
      hash: window.location.hash,
      callback: (err, authResult) => {
        if (err) {
          logger.error('AlternatePayoffInstructions.tsx', '', err, null);
          logout();
          return;
        }

        if (authResult?.accessToken) {
          resetWebSocket();
          setAccessToken(authResult.accessToken);
          step.moveNext();
        }
      },
    });
  }, [window.location.hash]);

  return (
    <>
      <Formik<FormValues>
        enableReinitialize
        initialValues={{
          first_name: info?.data?.first_name ?? '',
          middle_name: info?.data?.middle_name ?? '',
          last_name: info?.data?.last_name ?? '',
          email: info?.data?.email ?? accessTokenEmail ?? '',
          phone_number: info?.data?.phone_number ?? '',
          check_email: '',
        }}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSubmitForm}
      >
        {({ isSubmitting, values }) => {
          return (
            <Form>
              <VStack textAlign="center">
                <Input
                  _container={{ w: '100%', mx: { base: 0, md: 1 }, mb: 2, px: 1 }}
                  name="email"
                  placeholder="Email"
                  label="EMAIL ADDRESS"
                  isDisabled={!!accessToken}
                  labelProps={{ opacity: '1 !important' }}
                  helperText={
                    accessToken && (
                      <>
                        Not you?{' '}
                        <Button
                          fontSize="sm"
                          variant="link"
                          onClick={() => setLogoutConfirmModalOpen(true)}
                        >
                          Log out here
                        </Button>
                      </>
                    )
                  }
                />
                {showPhone && (
                  <Input
                    label="Phone Number"
                    name="phone_number"
                    placeholder={`Phone Number ${phoneIsRequired ? '' : '(Optional)'}`}
                    mask="(999) 999-9999"
                    type="tel"
                    _container={{ w: '100%', mx: { base: 0, md: 1 }, px: 1, mb: '3 !important' }}
                  />
                )}
                {!hideName && (
                  <>
                    <Flex px={1} alignSelf="flex-start" flexDir={{ base: 'column', md: 'row' }}>
                      <Label>WHAT'S YOUR NAME?</Label>
                      <Text>(We like the personal touch!)</Text>
                    </Flex>
                    <Flex
                      w="100%"
                      flexDir={{ base: 'column', md: 'row' }}
                      mt={{ base: 2, md: '0 !important' }}
                      justifyContent="space-between"
                    >
                      <Input
                        _container={{
                          w: { base: '100%', md: '33%' },
                          px: 1,
                          mb: 2,
                        }}
                        name="first_name"
                        placeholder="First"
                      />
                      <Input
                        _container={{
                          w: { base: '100%', md: '33%' },
                          px: 1,
                          mb: 2,
                        }}
                        name="middle_name"
                        placeholder="Middle"
                        tabIndex={-1}
                      />
                      <Input
                        name="last_name"
                        placeholder="Last"
                        _container={{ w: { base: '100%', md: '33%' }, px: 1 }}
                      />
                    </Flex>
                  </>
                )}
                {values.check_email && values.check_email === values.email && (
                  <VStack mb={2}>
                    <Text color="leaseEndRed" fontWeight="bold" fontSize="16px" textAlign="center">
                      Based on the email provided, it appears you already have an account with us.
                    </Text>
                    <Link
                      as={ReactRouterLink}
                      to={ACCOUNT_LOGIN}
                      onClick={() => resetLocalStorage({ removeGuid: true })}
                    >
                      Login now
                    </Link>
                  </VStack>
                )}
              </VStack>
              {(!values.email || values.check_email !== values.email) && (
                <Center mt="30px">
                  <PrimaryButton loading={isSubmitting} type="submit">
                    NEXT: YOUR LEASE!
                  </PrimaryButton>
                </Center>
              )}
            </Form>
          );
        }}
      </Formik>
      <LogoutConfirmModal
        isOpen={logoutConfirmModalOpen}
        handleClose={() => setLogoutConfirmModalOpen(false)}
      />
    </>
  );
};

export default PersonalInfo;
