import { Container, Flex, Icon, IconButton } from '@chakra-ui/react';
import { FaEye } from '@react-icons/all-files/fa/FaEye';
import { FaEyeSlash } from '@react-icons/all-files/fa/FaEyeSlash';
import { Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { decodeToken } from 'react-jwt';
import * as Yup from 'yup';

import Loader from '../../components/Loader';
import PasswordStrength from '../../components/PasswordStrength';
import Subtitle from '../../components/Subtitle';
import TextWithTooltip from '../../components/TextWithTooltip';
import Title from '../../components/Title';
import TitleContainer from '../../components/TitleContainer';
import Input from '../../components/formComponents/Input';
import Card from '../../components/ui/views/Card';
import { INVALID_SIGNUP_MSG } from '../../constants/auth';
import { LDFlags } from '../../constants/flags';
import { useTemporaryInfoUpsertMutation } from '../../gql/generated/graphql';
import { CookieKeys, useCookie } from '../../hooks/useCookie';
import useFlag from '../../hooks/useFlag';
import { useTempInfo } from '../../hooks/useTempInfo';
import { signupAndAuthorize } from '../../services/auth0';
import { logger } from '../../services/sentry';
import { rudderanalytics } from '../../utils/rudderstack';
import { passwordConfirmValidation, passwordValidation } from '../../utils/validation/auth';
import { phoneValidationRequired } from '../../utils/validation/phoneNumber';
import { StrongPasswordTooltipExplanation } from '../auth/utils';
import NextStepsTerms from './NextStepsTerms';
import PartnersTitle from './PartnersTitle';
import useNextSteps from './useNextSteps';

interface Values {
  password: string;
  password_confirm: string;
  username: string;
  phone_number?: string | undefined;
}

const FlowPassword = () => {
  // Hooks
  const creditPrequalificationEnabled = useFlag(LDFlags.CREDIT_PREQUALIFICATION);
  const { info, infoLoading } = useTempInfo('network-only');
  const [, setAccessToken] = useCookie<string>(CookieKeys.ACCESS_TOKEN);

  const passedPrequal = !!info?.data?.prequalification_success;

  // useStates
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  // useMutations
  const [upsertTemporaryInfo] = useTemporaryInfoUpsertMutation();

  const { importInfo } = useNextSteps();

  const tempInfoData = info?.data;

  const initialValues: Values = {
    username: tempInfoData?.email ?? '',
    password: '',
    password_confirm: '',
    phone_number: tempInfoData?.phone_number ?? '',
  };

  const validationSchema = Yup.object({
    password: passwordValidation,
    password_confirm: passwordConfirmValidation(),
    ...(tempInfoData?.phone_number
      ? null
      : {
          phone_number: phoneValidationRequired,
        }),
  });

  const handleSubmit = async (values: Values, helpers: FormikHelpers<Values>) => {
    setIsLoading(true);

    if (values.phone_number) {
      rudderanalytics.identify({ phone: values.phone_number });

      await upsertTemporaryInfo({
        variables: {
          info: {
            id: info?.id,
            data: {
              phone_number: values.phone_number,
            },
          },
        },
      });
    }

    signupAndAuthorize({
      email: tempInfoData?.email as string,
      password: values.password,
      callback: async (err, result) => {
        if (err) {
          setIsLoading(false);
          logger.error('FlowPassword', '', err, null);

          let description = err?.description ?? err?.toString() ?? 'Something went wrong';

          if (err?.code === 'invalid_signup') {
            description = INVALID_SIGNUP_MSG;
          }

          helpers.setFieldError('username', description);

          return;
        }

        setAccessToken(result?.accessToken);

        const decodedToken = decodeToken<{ sub: string }>(result?.accessToken);
        if (decodedToken) {
          rudderanalytics.identify({ auth0_id: decodedToken.sub });
        }

        await importInfo();
      },
    });
  };

  if (infoLoading) {
    return <Loader />;
  }

  return (
    <Container textAlign="center">
      {creditPrequalificationEnabled && passedPrequal ? (
        <PartnersTitle />
      ) : (
        <TitleContainer>
          <Title>Safe, Secure, & Streamlined.</Title>
        </TitleContainer>
      )}
      <Subtitle>
        Finish creating your secure account and we’ll get to work finding you your best loan! You’ll
        use this account to sign documents and track the progress of your loan buyout.
      </Subtitle>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur
        validateOnChange={false}
      >
        {({ values, submitForm }) => {
          return (
            <Form>
              <Card mt="20px">
                <Flex flexDirection="column" alignItems="center" justifyContent="center" pt={10}>
                  <Input
                    label="Username"
                    type="text"
                    name="username"
                    mb={6}
                    _input={{ autoComplete: 'username' }}
                    readOnly
                    _container={{ textAlign: 'left' }}
                  />
                  {!tempInfoData?.phone_number && (
                    <Input
                      label="Phone Number"
                      name="phone_number"
                      placeholder="Phone Number"
                      mask="(999) 999-9999"
                      type="tel"
                      _container={{ mb: 6, h: 'auto' }}
                      autoFocus
                    />
                  )}
                  <Flex flexDir={{ base: 'column', lg: 'row' }} w="100%" mb={6}>
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      label="Your Secret Password"
                      name="password"
                      placeholder="Password"
                      _container={{ w: { base: '100%', lg: '50%' } }}
                      _input={{ autoComplete: 'new-password' }}
                      icon={
                        <IconButton
                          variant="unstyled"
                          onClick={() => setShowPassword(!showPassword)}
                          aria-label="Hide/Show password"
                          icon={<Icon as={showPassword ? FaEye : FaEyeSlash} mt={1} />}
                        />
                      }
                    />
                    <Flex
                      textAlign="left"
                      flexDirection="column"
                      pl={{ lg: 4 }}
                      w={{ base: '100%', lg: '50%' }}
                    >
                      <PasswordStrength
                        label="Strength"
                        labelProps={{ display: { base: 'none', lg: 'block' } }}
                        h="auto"
                        password={values.password}
                        mt={{ base: 4, lg: 0 }}
                      />
                      <TextWithTooltip
                        label={StrongPasswordTooltipExplanation}
                        textProps={{ fontSize: '12px' }}
                      >
                        Strong password tips
                      </TextWithTooltip>
                    </Flex>
                  </Flex>
                  <Input
                    type={showPasswordConfirm ? 'text' : 'password'}
                    label="Confirm Password"
                    name="password_confirm"
                    placeholder="Confirm password"
                    _container={{
                      mb: 8,
                      h: 'auto',
                      w: { base: '100%', lg: '50%' },
                      alignSelf: 'flex-start',
                    }}
                    icon={
                      <IconButton
                        variant="unstyled"
                        onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                        aria-label="Hide/Show password confirm"
                        icon={<Icon as={showPasswordConfirm ? FaEye : FaEyeSlash} mt={1} />}
                      />
                    }
                  />
                </Flex>
              </Card>
              <NextStepsTerms
                m={{ base: '10px 0px', lg: '10px 45px' }}
                onSubmit={submitForm}
                isLoading={isLoading}
              />
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default FlowPassword;
