import { StackProps, VStack } from '@chakra-ui/react';
import React from 'react';

interface Props extends StackProps {
  children: React.ReactNode;
}

const YellowContainer = ({ children, ...rest }: Props) => {
  return (
    <VStack
      w="full"
      mb="6 !important"
      alignItems="start"
      p={4}
      bgColor="beige"
      borderRadius="xl"
      spacing={6}
      color="blackAlpha.800"
      {...rest}
    >
      {children}
    </VStack>
  );
};

export default YellowContainer;
