import { Box, Container, Flex, Hide, Image, Link, Text } from '@chakra-ui/react';

import { AngryGuy } from '../../../assets/Images';
import { DealType } from '../../../gql/generated/graphql';
import { useSearchPhoneNumbers } from '../../../hooks';
import YourTeam from '../components/YourTeam';
import { DealWithFarthestState } from '../utils';

const BootedDashboard = ({ deal }: { deal?: DealWithFarthestState }) => {
  const phoneNumber = useSearchPhoneNumbers();
  const car = deal?.car;
  const isRefi = deal?.type === DealType.Refi;
  return (
    <Container mt="20px">
      <Flex>
        <Hide below="lg">
          <Image src={AngryGuy} alt="Angry Guy" maxWidth="150px" />
        </Hide>
        <Box mx="15px">
          <Text fontSize="36px" fontWeight="bold">
            Darn it... We ran into a roadblock with your deal.
          </Text>
          <Text mt="10px">
            At this time, we're unable to proceed with{' '}
            {isRefi ? 'refinancing' : 'ending the lease on'} your{' '}
            <b>
              {car?.year} {car?.make} {car?.model}
            </b>
            . There are a few reasons this could happen, so we'll let one of our team members
            explain the situation to you personally. When you're free, please contact our Lease End
            Success Team at <Link href={`tel:+1-${phoneNumber}`}>{phoneNumber}</Link>
            {!isRefi &&
              'for more information and assistance so we can keep trying to get you your leased car'}
            .
          </Text>
          <YourTeam textAlign={{ base: 'center', lg: 'left' }} isBooted />
        </Box>
      </Flex>
    </Container>
  );
};

export default BootedDashboard;
