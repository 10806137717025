import { Box, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';

import Modal from '../../../../components/Modal';
import Input from '../../../../components/formComponents/Input';
import Select from '../../../../components/formComponents/Select';
import PrimaryButton from '../../../../components/ui/buttons/PrimaryButton';
import { OtherLienholder } from '../../../../constants/lienholders';
import {
  UNABLE_TO_ASSIST_ERROR_MSG,
  UNAVAILABLE_LIEHOLDER_ERROR_MSG,
} from '../../../../constants/tempInfos';
import { TemporaryInfo } from '../../../../gql/generated/graphql';
import { Lienholder } from '../../../../gql/prs/types';
import { RudderEvent, rudderanalytics } from '../../../../utils/rudderstack';
import { lienholderNotAvailableInState } from './utils';

interface Props {
  info?: TemporaryInfo;
  isOpen: boolean;
  closeModal: () => void;
  lienholders: Lienholder[];
  handleSubmit: () => void;
}

const LienholderModal: React.FC<Props> = ({
  info,
  isOpen,
  closeModal,
  lienholders,
  handleSubmit,
}) => {
  const { values, setFieldValue } = useFormikContext<{
    lienholder: string;
    other_lienholder: string;
  }>();

  const [error, setError] = useState<string | null>(null);

  const lienholdersDisplayNames = [
    ...lienholders.map((lienholder: Lienholder) => lienholder.display_name).sort(),
    OtherLienholder,
  ];

  const onSubmit = () => {
    const selectedLh = lienholders.find((lh) => lh.display_name === values.lienholder);

    if (!selectedLh && values.lienholder !== OtherLienholder) {
      setError('Please select a lienholder');
      return;
    }

    if (lienholderNotAvailableInState(info?.data?.state, selectedLh)) {
      setError(UNABLE_TO_ASSIST_ERROR_MSG);
      return;
    }

    if (values.lienholder !== OtherLienholder && !selectedLh?.enabled) {
      setError(UNAVAILABLE_LIEHOLDER_ERROR_MSG);
      setFieldValue('lienholder', '');
      return;
    }

    if (values.lienholder === OtherLienholder) {
      rudderanalytics.track(RudderEvent.OtherLienholderSelected, {
        lienholder: values.other_lienholder,
      });
    }
    setError(null);
    handleSubmit();
  };

  const onClose = () => {
    if (error) {
      setFieldValue('lienholder', '');
      setError(null);
    }
    closeModal();
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      title="Okay, no problem."
      subtitle="See if they’re on this list (if not, select ‘Other’)"
      size={{ base: 'md', md: '3xl' }}
    >
      <Box textAlign="center">
        <Select
          name="lienholder"
          placeholder="Select your lienholder"
          options={lienholdersDisplayNames?.map((lh) => ({
            label: lh ?? '',
            key: lh ?? '',
            value: lh ?? '',
          }))}
        />
        {values.lienholder === OtherLienholder && (
          <Input mt={4} name="other_lienholder" placeholder="Who is your lease through?" />
        )}
        {error && (
          <Text color="leaseEndRed" mt={4}>
            {error}
          </Text>
        )}
        <PrimaryButton mt={4} onClick={onSubmit} />
      </Box>
    </Modal>
  );
};

export default LienholderModal;
