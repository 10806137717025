import { useHistory } from 'react-router-dom';

import { GETTING_STARTED } from '../constants/urls';
import { useCheckFlowStatusQuery, useUnlinkTemporaryInfoMutation } from '../gql/generated/graphql';
import { rudderanalytics } from '../utils/rudderstack';
import { CookieKeys, useCookie } from './useCookie';

const useCheckFlowStatus = () => {
  const history = useHistory();
  const [guid, setGuid] = useCookie<string>(CookieKeys.GUID_KEY);
  const [accessToken] = useCookie<string>(CookieKeys.ACCESS_TOKEN);

  const [unlinkTemporaryInfo] = useUnlinkTemporaryInfoMutation();
  const {
    data: flowStatus,
    refetch,
    loading,
  } = useCheckFlowStatusQuery({
    fetchPolicy: 'cache-and-network',
    variables: { guid },
    skip: !guid,
  });

  const flowGuid = flowStatus?.checkFlowStatus?.guid;
  const enterFlow = () => {
    if (flowGuid) {
      setGuid(flowGuid);
      rudderanalytics.identify({ guid: flowGuid });
    }
    if (flowStatus?.checkFlowStatus?.dealId && flowStatus?.checkFlowStatus?.customerId) {
      rudderanalytics.identify({
        deal_id: flowStatus?.checkFlowStatus?.dealId,
        customer_id: flowStatus?.checkFlowStatus?.customerId,
      });
    }
    history.push(flowStatus?.checkFlowStatus?.url || GETTING_STARTED);
  };

  const startOver = async () => {
    history.replace({
      search: '',
    });
    if (flowGuid) {
      await unlinkTemporaryInfo({ variables: { id: flowGuid } });
    }
    setGuid('');
    refetch();
  };

  const inFlow = accessToken || guid ? flowStatus?.checkFlowStatus?.inFlow ?? false : false;
  const canStartOver = !!flowStatus?.checkFlowStatus?.guid;
  const car = flowStatus?.checkFlowStatus?.car;

  return { inFlow, enterFlow, canStartOver, startOver, car, loading };
};

export default useCheckFlowStatus;
