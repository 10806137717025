import { Image, Text, VStack } from '@chakra-ui/react';

import { AutoCheckLogo } from '../../../../../assets/Images';
import { formatMileage } from '../../../../../utils/helpers';

const AutoCheckMessage = ({
  lastOdometer,
  lastOdometerDate,
}: {
  lastOdometer: number;
  lastOdometerDate: string;
}) => {
  const date = new Date(lastOdometerDate);
  const dateString = `${date.toLocaleString('en-US', { month: 'long' })} ${date.toLocaleString(
    'en-US',
    { day: '2-digit' },
  )}, ${date.toLocaleString('en-US', { year: 'numeric' })}`;

  return (
    <VStack>
      <Text color="leaseEndRed" fontWeight="bold" fontSize="16px" textAlign="center">
        Uh oh! This mileage is lower than our last record!
      </Text>
      <Text maxW="container.sm" textAlign="center">
        Our records show <b>{formatMileage(lastOdometer)} miles</b> last reported on{' '}
        <b>{dateString}</b>. If your odometer shows a lower mileage than this please contact{' '}
        <Image
          src={AutoCheckLogo}
          alt="Auto Check Logo"
          display="inline-block"
          verticalAlign="middle"
          w="60px"
        />{' '}
        to resolve the issue.
      </Text>
    </VStack>
  );
};
export default AutoCheckMessage;
