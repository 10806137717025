import { HStack } from '@chakra-ui/react';

import useCheckFlowStatus from '../../../hooks/useCheckFlowStatus';
import LicensePlateOrVinInput from '../../licensePlateOrVinInput/LicensePlateOrVinInput';
import ContinueContainer from './ContinueContainer';

interface Props {
  autoFocus?: boolean;
}

const GetStarted = ({ autoFocus = false }: Props) => {
  const { inFlow, enterFlow, canStartOver, startOver } = useCheckFlowStatus();

  return (
    <>
      {inFlow ? (
        <ContinueContainer
          enterFlow={enterFlow}
          canStartOver={canStartOver}
          startOver={startOver}
        />
      ) : (
        <HStack align="flex-start">
          <LicensePlateOrVinInput autoFocus={autoFocus} />
        </HStack>
      )}
    </>
  );
};

export default GetStarted;
