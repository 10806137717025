import { Flex, Stack, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { Maybe, TemporaryData } from '../../../gql/generated/graphql';
import { LabelWithShield, ShowHideIcon } from './BaseSsnComponents';
import SSNInput from './SSNInput';

interface Props {
  setMustEnterSsn: (value: boolean) => void;
  tempInfo?: Maybe<TemporaryData>;
}

const LoanAppSsnInput = ({ setMustEnterSsn, tempInfo }: Props) => {
  const [showInput, setShowInput] = useState(true);
  const container = { ml: { base: 0, md: 2 }, mb: { base: 4, md: 0 } };

  useEffect(() => {
    setMustEnterSsn(!tempInfo?.has_full_ssn);
    setShowInput(!tempInfo?.has_full_ssn);
  }, [tempInfo]);

  useEffect(() => {
    if (showInput) {
      setMustEnterSsn(true);
    }
  }, [showInput]);

  if (showInput) {
    return <SSNInput _container={container} digits="9" name="ssn" />;
  }

  return (
    <Stack w="100%" {...container}>
      <LabelWithShield defaultLabel="SOCIAL SECURITY NUMBER" />
      <Flex
        mt={-0.5}
        borderWidth="1px"
        borderColor="grayDarkBackground"
        borderRadius="3px"
        h="36px"
        justify="space-between"
        align="center"
        px="10px"
      >
        <Text fontSize={16} textColor="charcoal">
          •••••••••
        </Text>
        <ShowHideIcon show={showInput} setShow={setShowInput} />
      </Flex>
    </Stack>
  );
};

export default LoanAppSsnInput;
