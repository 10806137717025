import { Box, ListItem, Text, UnorderedList } from '@chakra-ui/react';

import { useTempInfo } from '../../../hooks/useTempInfo';

const OtherPayoff = () => {
  const { info } = useTempInfo();

  return (
    <Box w="95%" h="auto" textAlign="left" m={{ md: '0 auto' }} p={{ md: '10px 15px' }}>
      <Text fontWeight="bold" fontSize={{ md: '18px' }}>
        You'll need to contact {info?.data?.other_lienholder || 'your lease finance company'} to
        find your payoff amount and good-through date.
      </Text>
      <UnorderedList fontSize={{ md: '16px' }} mt={4}>
        <ListItem>You could give them a call or visit them at their website.</ListItem>
        <ListItem>Once you've found your payoff info, enter it below.</ListItem>
        <ListItem>
          Lastly, <a href="mailto: hello@leaseend.com">contact us</a> to let us know what steps you
          took to find your payoff info and we will add them to our system.
        </ListItem>
      </UnorderedList>
    </Box>
  );
};

export default OtherPayoff;
