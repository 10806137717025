/* eslint-disable react/no-array-index-key */
import { Box } from '@chakra-ui/react';

import { InstructionSet } from '../../../gql/prs/types';
import Description from '../../Description';
import Label from '../../formComponents/Label';
import Instructions from './Instructions';
import { InstructionsDescription, WebsiteLabel } from './PayoffStylings';

const WebsiteInstructions = ({
  website,
  webInstructions,
}: {
  website: string;
  webInstructions: InstructionSet[];
}) => (
  <>
    <WebsiteLabel website={website} />
    {webInstructions.map((webInstruction, i) => (
      <Box key={`web-${i}`}>
        {webInstruction.intro && (
          <InstructionsDescription my={4}>{webInstruction.intro}</InstructionsDescription>
        )}
        <Instructions instructions={webInstruction.instructions} />
        {webInstruction.note && (
          <Description fontSize={{ md: '16px' }}>
            <Label mb={0} mr={1} fontSize={{ md: '16px' }} display="inline-block">
              Note:
            </Label>
            {webInstruction.note}
          </Description>
        )}
      </Box>
    ))}
  </>
);

export default WebsiteInstructions;
