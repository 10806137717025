import { Container } from '@chakra-ui/react';

import Subtitle from '../../components/Subtitle';
import Title from '../../components/Title';
import TitleContainer from '../../components/TitleContainer';
import NextStepsTerms from './NextStepsTerms';
import useNextSteps from './useNextSteps';

const Terms = () => {
  const { importInfo } = useNextSteps();

  return (
    <Container textAlign="center" maxW="container.lg">
      <TitleContainer>
        <Title>Great job!</Title>
      </TitleContainer>
      <Subtitle mb={8}>
        Finalize your application so our lease-end experts will get to work finding the best loan
        for you!
      </Subtitle>
      <NextStepsTerms color="leaseEndBlue" onSubmit={importInfo} variation="page" />
    </Container>
  );
};

export default Terms;
