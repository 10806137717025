import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { OtherLienholderSlug } from '../constants/lienholders';
import { lienholdersQuery } from '../gql/prs/lienholderGql';
import { Lienholder } from '../gql/prs/types';

interface useLienholdersHookType {
  lienholders: Lienholder[];
  probableLienholders: Lienholder[];
  loading: boolean;
}

export const useLienholders = ({
  state,
  make,
}: {
  state?: string | null;
  make?: string | null;
}) => {
  const [lienholdersState, setLienholdersState] = useState<useLienholdersHookType>({
    lienholders: [],
    probableLienholders: [],
    loading: true,
  });

  const [getLienholders] = useLazyQuery(lienholdersQuery, {
    onCompleted: ({ lienholders }) => {
      const sortedLienholders = lienholders
        .slice()
        .filter((a: Lienholder) => a.slug !== OtherLienholderSlug)
        .sort((a: Lienholder, b: Lienholder) => a.slug.localeCompare(b.slug));

      setLienholdersState({
        ...lienholdersState,
        lienholders: sortedLienholders,
        probableLienholders: sortedLienholders.filter(
          (lienholder: Lienholder) => lienholder.probableLienholder,
        ),
        loading: false,
      });
    },
  });

  useEffect(() => {
    if (!state) {
      return;
    }

    setLienholdersState({
      ...lienholdersState,
      loading: true,
    });

    getLienholders({ variables: { state, make: make || '' } });
  }, [state, make]);

  return lienholdersState;
};

export default useLienholders;
