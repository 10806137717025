import { Box, Collapse, Flex, Spinner, Text, useToast } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';

import { statesArray } from '../../constants/states';
import { ttJurisdictionDefaultConditions } from '../../constants/taterTitle';
import {
  Deal,
  StateAbbreviation,
  TitleRegistrationOption,
  TtDocumentName,
  TtProductType,
  useGetTtJurisdictionLazyQuery,
  useUserDealQuery,
} from '../../gql/generated/graphql';
import { handleErrorWithSentry } from '../../services/sentry';
import { getAddressValue } from '../../utils/address';
import { createErrorToast } from '../../utils/toast';
import { documents } from '../documents/components/ThingsWeNeed/utils';
import { getRequiredDocTypes } from '../documents/utils';
import UploadList from './UploadList';

const EndFlowDocUpload = () => {
  const [open, setIsOpen] = useState<boolean>(true);

  const { data: userDealData } = useUserDealQuery({
    fetchPolicy: 'network-only',
  });
  const [registrationDocumentsNeeded, setRegistrationDocumentsNeeded] = useState<TtDocumentName[]>(
    [],
  );
  const [titleDocumentsNeeded, setTitleDocumentsNeeded] = useState<TtDocumentName[]>([]);
  const [ttJurisdictionQuery] = useGetTtJurisdictionLazyQuery();

  const toast = useToast();

  const userDeal = userDealData?.userDeal as Deal;
  const customer = userDeal?.customer;
  const stateAbbr = customer?.address?.state as StateAbbreviation;
  const customerState = statesArray.find((states) => states.value === stateAbbr)?.name ?? '';
  const driversLicenseStateAbbr = customer?.drivers_license_address?.state as StateAbbreviation;

  const driversLicenseAddress = getAddressValue({
    address_line: customer?.drivers_license_address?.address_line,
    address_line_2: customer?.drivers_license_address?.address_line_2,
    city: customer?.drivers_license_address?.city,
    state: customer?.drivers_license_address?.state,
    zip: customer?.drivers_license_address?.zip,
  });
  const currentAddress = getAddressValue({
    address_line: customer?.address?.address_line,
    address_line_2: customer?.address?.address_line_2,
    city: customer?.address?.city,
    state: customer?.address?.state,
    zip: customer?.address?.zip,
  });

  useEffect(() => {
    if (!stateAbbr) {
      return;
    }
    const fetchData = async () => {
      const { data } = await ttJurisdictionQuery({
        fetchPolicy: 'network-only',
        variables: {
          state: stateAbbr,
          types: [TtProductType.Registration, TtProductType.Title],
          conditions: {
            ...ttJurisdictionDefaultConditions(stateAbbr),
            lienholder: userDeal?.car?.payoff?.lienholder_slug,
            hasCobuyer: !!userDeal?.cobuyer?.first_name,
            driversLicenseAddress,
            currentAddress,
            vehicleAge: new Date().getFullYear() - parseInt(userDeal.car?.year || '0', 10),
            transferringPlates: !!userDeal?.financial_info?.plate_transfer,
            driversLicenseState: driversLicenseStateAbbr || stateAbbr,
            movedStateDuringLease: !!customer?.address?.moved_states,
            jurisdictionState: stateAbbr,
          },
        },
      });
      if (data?.jurisdiction == null) {
        handleErrorWithSentry('Failed to get data from Tater Title', data);
        toast(createErrorToast({ errorMessage: 'Failed to get data from Tater Title' }));
        throw Error('Failed to get data from Tater Title');
      }

      const registrationDocuments =
        data.jurisdiction.products?.items?.find((item) => item?.type === TtProductType.Registration)
          ?.documents?.items ?? [];
      const titleDocuments =
        data.jurisdiction.products?.items?.find((item) => item?.type === TtProductType.Title)
          ?.documents?.items ?? [];

      setRegistrationDocumentsNeeded(
        (registrationDocuments?.map((item) => item?.type?.name) || []) as TtDocumentName[],
      );
      setTitleDocumentsNeeded(
        (titleDocuments?.map((item) => item?.type?.name) || []) as TtDocumentName[],
      );
    };
    fetchData();
  }, [
    currentAddress,
    driversLicenseAddress,
    stateAbbr,
    driversLicenseStateAbbr,
    customer?.address?.moved_states,
    userDeal?.financial_info?.plate_transfer,
  ]);

  const neededDocsTypes = useMemo(
    () =>
      getRequiredDocTypes(
        userDeal?.financial_info?.title_registration_option === TitleRegistrationOption.TitleOnly,
        titleDocumentsNeeded,
        registrationDocumentsNeeded,
      ),
    [
      userDeal?.financial_info?.title_registration_option,
      titleDocumentsNeeded,
      registrationDocumentsNeeded,
    ],
  );

  const backOfDLRequired = neededDocsTypes.includes(TtDocumentName.BackOfDriversLicense);
  const movedStates = customer?.address?.moved_states ?? false;

  const requiredDocs = useMemo(
    () =>
      documents({
        backOfDLRequired,
        state: customerState,
        movedStates,
        hasCobuyer: false,
      }).filter((doc) => neededDocsTypes.includes(doc.ttDocumentNameEnumType)),
    [backOfDLRequired, customerState, movedStates, neededDocsTypes],
  );

  return (
    <Flex
      bgColor="leaseEndBlue"
      p="15px 30px"
      borderBottomRadius="10px"
      flexDir="column"
      alignItems="center"
      w="100%"
    >
      <Text color="white" mb="5px">
        UPLOAD MY DOCUMENTS
      </Text>
      <Collapse in={open} animateOpacity>
        {!requiredDocs ? (
          <Spinner color="white" />
        ) : (
          <UploadList requiredDocs={requiredDocs} deal={userDeal} />
        )}
      </Collapse>
      <Box
        w="30px"
        h="15px"
        mt={open ? '10px' : '0px'}
        borderX="15px solid"
        borderColor="leaseEndBlue"
        borderBottom={open ? '15px solid' : '0px'}
        borderTop={open ? '0px' : '15px solid'}
        borderBottomColor="mustardYellow"
        borderTopColor="mustardYellow"
        onClick={() => setIsOpen(!open)}
        cursor="pointer"
      />
    </Flex>
  );
};

export default EndFlowDocUpload;
