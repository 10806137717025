import { number } from 'yup';

import { formatMoney } from '../helpers';
import { RudderEvent, rudderanalytics } from '../rudderstack';

export const MAX_PAYOFF_AMOUNT = 999999;
const MIN_PAYOFF_AMOUNT = 6000;

export const ERROR_TEXT = 'Must be less than 1 million';
const LOW_PAYOFF_ERROR_MESSAGE = `We are typically unable to secure financing for less than ${formatMoney(
  MIN_PAYOFF_AMOUNT,
  {
    noCents: true,
  },
)}. Thank you.`;

export const payoffAmountValidation = (payoffRequestCompleted: boolean) =>
  number()
    .nullable(true)
    .when([], {
      is: () => payoffRequestCompleted,
      then: number().required('Payoff amount is required'),
      otherwise: number()
        .required('Payoff amount is required')
        .max(MAX_PAYOFF_AMOUNT, ERROR_TEXT)
        .test('min', LOW_PAYOFF_ERROR_MESSAGE, (value) => {
          if (!value || value < MIN_PAYOFF_AMOUNT) {
            rudderanalytics.track(RudderEvent.Error, {
              error_message: `Lower than $${MIN_PAYOFF_AMOUNT} payoff`,
              payoff_amount: value,
            });
            return false;
          }

          return true;
        }),
    });
