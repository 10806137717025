import {
  MaritalStatusEnum,
  RelationToBuyerEnum,
  StateAbbreviation,
  TemporaryData,
} from '../gql/generated/graphql';

const showWisconsinCobuyerCheckbox = (tempInfoData?: TemporaryData, hasCobuyer?: boolean) =>
  hasCobuyer &&
  tempInfoData &&
  tempInfoData.cobuyer_state === StateAbbreviation.Wi &&
  tempInfoData.cobuyer_relation_to_buyer !== RelationToBuyerEnum.Spouse &&
  (tempInfoData.cobuyer_marital_status === MaritalStatusEnum.Married ||
    tempInfoData.cobuyer_marital_status === MaritalStatusEnum.Separated);

const showWisconsinBuyerCheckbox = (tempInfoData?: TemporaryData) =>
  tempInfoData &&
  tempInfoData.state === StateAbbreviation.Wi &&
  (tempInfoData.marital_status === MaritalStatusEnum.Married ||
    tempInfoData.marital_status === MaritalStatusEnum.Separated);

export { showWisconsinCobuyerCheckbox, showWisconsinBuyerCheckbox };
