export enum DealStateEnum {
  // Setters/Closers
  Estimate = 'estimate',
  Floor = 'floor',
  SoftClose = 'soft_close',
  StructuringInProgress = 'structuring_in_progress',
  Structuring = 'structuring',
  Closing = 'closing',
  Closed = 'closed',

  // Funding Clerks
  SentForSignatures = 'signatures',
  Signed = 'signed',
  Funded = 'funded',

  // Title Clerk
  SendPayoff = 'send_payoff',
  WaitingForTitle = 'waiting_for_title', // Now called Create Packet
  TitleReceived = 'title_received', // Now called Ready for Clerk
  SentToProcessor = 'sent_to_processor',

  // Logistics Coordinator
  ReadyForPickup = 'ready_for_pickup',
  PaidOff = 'paid_off',
  AtAuction = 'at_auction',
  Sold = 'sold',
  TitleSent = 'title_sent',

  // Dead Deals
  Booted = 'booted',
  Finalized = 'finalized',
}
