import { Box } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ACCORDION_NAME, ACCORDION_NAME_TO_INDEX } from '../../components/LEAccordion/utils';
import Loader from '../../components/Loader';
import {
  useTemporaryInfoLazyQuery,
  useTemporaryInfoUpsertMutation,
} from '../../gql/generated/graphql';
import { CookieKeys, useCookie } from '../../hooks/useCookie';
import { useStep } from '../../hooks/useStep';
import { RudderEvent, rudderanalytics } from '../../utils/rudderstack';

const PayoffReenter = () => {
  const [, setGuid] = useCookie<string>(CookieKeys.GUID_KEY);

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const id = params.get('id');

  const [getTempInfo] = useTemporaryInfoLazyQuery({ context: { isErrorHandled: true } });
  const [upsertTempInfo] = useTemporaryInfoUpsertMutation();
  const step = useStep();

  const returnHome = () => {
    step.movePrevious({ redirect: true });
  };

  const reenterFlow = async (tempInfoId: string, currentAccordion: number) => {
    setGuid(tempInfoId);
    rudderanalytics.identify({ guid: tempInfoId });
    step.moveNext({}, true);

    // Let temp infos know that the user has completed the payoff accordion
    if (currentAccordion < ACCORDION_NAME_TO_INDEX[ACCORDION_NAME.LOAN_ACCORDION]) {
      upsertTempInfo({
        variables: {
          info: {
            id,
            data: {
              current_accordion_panel: `${
                ACCORDION_NAME_TO_INDEX[ACCORDION_NAME.LOAN_ACCORDION]
              }:0`,
            },
          },
        },
      });
    }
  };

  const checkTempInfo = async () => {
    if (!id) {
      returnHome();
      return;
    }

    const tempInfo = await getTempInfo({ variables: { id } });
    if (!tempInfo?.data?.temporaryInfo?.data) {
      returnHome();
      return;
    }

    // Track users clicking link in Payoff Success email
    rudderanalytics.track(RudderEvent.ReenteredFromPayoffSuccessEmail, {
      id,
    });

    const [currentAccordion] =
      tempInfo?.data?.temporaryInfo?.data?.current_accordion_panel?.split(':') ?? [];
    reenterFlow(id, Number(currentAccordion));
  };

  useEffect(() => {
    checkTempInfo();
  }, [id]);

  return (
    <Box h="100vh">
      <Loader />
    </Box>
  );
};

export default PayoffReenter;
