import { useFormik } from 'formik';
import { useEffect, useMemo } from 'react';
import * as Yup from 'yup';

import { LDFlags } from '../../constants/flags';
import {
  DEFAULT_DOC_FEE,
  DEFAULT_INITIAL_SCORE,
  DEFAULT_LOAN_TERM,
  DEFAULT_TAXES_FEES_MULTIPLIER,
  GOOD_RATE,
} from '../../constants/paymentCalculator';
import {
  TemporaryDataInput,
  TtGetFeesSourceType,
  usePaymentEstimateLazyQuery,
  useTemporaryInfoUpsertMutation,
} from '../../gql/generated/graphql';
import { useFlag } from '../../hooks';
import { useTempInfo } from '../../hooks/useTempInfo';
import { getCreditScore } from '../../utils/creditScore';
import { getEstimateLowAndHigh, getFormattedEstimate } from '../../utils/estimatedPayment';
import { DEFAULT_RATE, creditScores, termLengths } from '../../utils/paymentCalculator';
import { numberOptionalValidation, numberRequiredValidation } from '../../utils/validation/numbers';

export const PAYMENT_ESTIMATE_DISCLAIMER =
  "Please note that this is an estimate based on the information provided. Soon, we'll verify all your information so we can give you an exact amount – down to the penny.";

const validationSchema = Yup.object({
  credit_score: numberRequiredValidation,
  term: numberRequiredValidation.min(termLengths[0].value, 'This value is required'),
  down_payment: numberOptionalValidation,
  vehicle_payoff: numberRequiredValidation,
});

export type EstimatorFormFields = {
  credit_score: number;
  term: number;
  rate: number;
  down_payment: number;
  vehicle_payoff: number;
  taxes_fees: number;
  estimated_payment: string;
  vin: string;
  fuel_type: string;
  year: string;
  msrp: number;
  vehicle_type: string;
  doc_fee: number;
  taxes: number;
  vehicle_registration_fee: number;
  atcFlag: boolean;
  title_only: boolean;
};

/**
 * @param refresh When changed, temp info will be refetched
 */
const useEstimatorFormik = (refresh?: boolean) => {
  const { info, infoLoading } = useTempInfo('network-only', refresh);
  const [paymentEstimateQuery, { loading: paymentEstimateLoading }] = usePaymentEstimateLazyQuery();
  const [upsertTemporaryInfo] = useTemporaryInfoUpsertMutation();
  const ATCFlag = useFlag(LDFlags.ATC_API);

  const initialValues: EstimatorFormFields = useMemo(() => {
    const estimated_payment = getFormattedEstimate(
      info?.data?.shopping_cart?.payment_estimate_low ?? 0,
      info?.data?.shopping_cart?.payment_estimate_high ?? 0,
    );
    const shoppingCartRate = creditScores.find(
      (score) => score.max > DEFAULT_INITIAL_SCORE && score.min <= DEFAULT_INITIAL_SCORE,
    )?.rate;

    return {
      credit_score:
        info?.data?.credit_score ??
        info?.data?.shopping_cart?.credit_score ??
        DEFAULT_INITIAL_SCORE,
      term: info?.data?.term ?? info?.data?.shopping_cart?.loan_term ?? DEFAULT_LOAN_TERM,
      rate: info?.data?.rate ?? shoppingCartRate ?? DEFAULT_RATE,
      down_payment: info?.data?.down_payment ?? info?.data?.shopping_cart?.down_payment ?? 0,
      vehicle_payoff: info?.data?.vehicle_payoff ?? 0,
      taxes_fees: (info?.data?.vehicle_payoff ?? 0) * DEFAULT_TAXES_FEES_MULTIPLIER,
      estimated_payment,
      vin: info?.data?.vin ?? '',
      fuel_type: info?.data?.fuel_type ?? '',
      year: info?.data?.year ?? '',
      msrp: info?.data?.retail_book_value ?? 0,
      vehicle_type: info?.data?.vehicle_type ?? '',
      taxes: info?.data?.taxes ?? 0,
      vehicle_registration_fee: info?.data?.vehicle_registration_fee ?? 0,
      doc_fee: info?.data?.doc_fee ?? DEFAULT_DOC_FEE,
      atcFlag: ATCFlag,
      title_only: true,
    };
  }, [info, ATCFlag]);

  const updateTempInfo = async (values: EstimatorFormFields) => {
    const { payment_estimate_low, payment_estimate_high } = getEstimateLowAndHigh(
      values.estimated_payment,
    );

    const data: TemporaryDataInput = {
      credit_score: values.credit_score,
      term: values.term,
      rate: values.rate,
      down_payment: values.down_payment,
      taxes_fees: values.taxes_fees,
      payment_estimate_low,
      payment_estimate_high,
      taxes: values.taxes,
      doc_fee: values.doc_fee,
      vehicle_registration_fee: values.vehicle_registration_fee,
      // We keep the boolean here and then transform to the enum when importing the data
      // because the customer currently doesn't have the option to choose.
      title_only: values.title_only,
    };

    upsertTemporaryInfo({
      variables: {
        info: {
          id: info?.id,
          data,
        },
      },
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: updateTempInfo,
  });

  const { values, setFieldValue } = formik;

  const calculatePayment = async () => {
    const { term, vehicle_payoff, down_payment, credit_score } = values;
    if (!info?.data) {
      return values;
    }

    const data = await paymentEstimateQuery({
      variables: {
        data: {
          term,
          payoff: vehicle_payoff,
          moneyDown: down_payment,
          creditScore: getCreditScore(credit_score),
          // This default value is when the shopping cart is in the first accordion because the zip may not be defined yet.
          zipCode: info.data.zip ? String(info.data.zip) : '84043',
          vin: info.data.vin,
          year: info.data.year,
          make: info.data.make,
          model: info.data.model,
          vehicleType: info.data.vehicle_type,
          fuelType: info.data.fuel_type,
          ttGetFeesSource: TtGetFeesSourceType.ComPaymentCalculator,
        },
      },
    });

    const estimated_payment = getFormattedEstimate(
      data?.data?.paymentEstimate?.paymentEstimateLow ?? 0,
      data?.data?.paymentEstimate?.paymentEstimateHigh ?? 0,
    );
    const rate = data?.data?.paymentEstimate?.estimatedAnnualPercentageRate ?? GOOD_RATE;
    const taxes_fees =
      data?.data?.paymentEstimate?.estimatedTaxesAndFees ??
      values.vehicle_payoff * DEFAULT_TAXES_FEES_MULTIPLIER;

    const title_only = !data.data?.paymentEstimate?.feeDetails.registrationFee;

    setFieldValue('estimated_payment', estimated_payment);
    setFieldValue('rate', rate);
    setFieldValue('taxes_fees', taxes_fees);
    setFieldValue('title_only', title_only);

    return {
      ...values,
      estimated_payment,
      rate,
      taxes_fees,
      title_only,
    };
  };

  useEffect(() => {
    if (values?.vehicle_payoff) {
      calculatePayment();
    }
  }, [
    values.term,
    values.vehicle_payoff,
    values.down_payment,
    values.credit_score,
    info?.data?.zip,
    info?.data?.city,
    info?.data?.county,
    info?.data?.state,
  ]);

  const calculatePaymentAndSave = async () => {
    const valuesToSave = await calculatePayment();

    updateTempInfo(valuesToSave);
  };

  useEffect(() => {
    if (
      info?.data?.zip &&
      info?.data?.city &&
      info?.data?.county &&
      info?.data?.state &&
      values?.vehicle_payoff
    ) {
      calculatePaymentAndSave();
    }
  }, [
    info?.data?.zip,
    info?.data?.city,
    info?.data?.county,
    info?.data?.state,
    values?.vehicle_payoff,
  ]);

  return { formik, loading: infoLoading || paymentEstimateLoading };
};

export default useEstimatorFormik;
