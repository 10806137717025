import { Button, Flex, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Modal from '../../../components/Modal';
import PrimaryButton from '../../../components/ui/buttons/PrimaryButton';
import { COBUYER_LOAN_APPLICATION, LOAN_APPLICATION } from '../../../constants/urls';
import { useTemporaryInfoUpsertMutation } from '../../../gql/generated/graphql';
import { useTempInfo } from '../../../hooks';
import { deleteCobuyerData } from '../../../utils/tempInfo';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const RemoveCobuyerModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { pathname } = useLocation();
  const [refresh, setRefresh] = useState(false);
  const { info } = useTempInfo('network-only', refresh);
  const history = useHistory();
  const tempInfoData = info?.data;

  const [upsertTemporaryInfo] = useTemporaryInfoUpsertMutation();

  const handleRemoveCobuyer = async () => {
    const newData = deleteCobuyerData(tempInfoData);

    await upsertTemporaryInfo({
      variables: {
        info: {
          id: info?.id,
          data: newData,
        },
      },
    });

    if (pathname === COBUYER_LOAN_APPLICATION) {
      history.push(LOAN_APPLICATION);
    } else {
      history.go(0);
    }

    setRefresh(!refresh);

    onClose();
  };

  return (
    <Modal
      title="Are you sure?"
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={false}
      isCentered
      closeOnOverlayClick={false}
    >
      <Flex flexDir="column" alignItems="center">
        <Text color="leaseEndBlue" mb={6} textAlign="center" fontSize="lg">
          Your co-buyer's information will be lost. This action cannot be undone.
        </Text>
        <PrimaryButton w="fit-content" alignSelf="center" onClick={handleRemoveCobuyer}>
          REMOVE CO-BUYER
        </PrimaryButton>
        <Button variant="link" mt={4} onClick={onClose}>
          Don't remove
        </Button>
      </Flex>
    </Modal>
  );
};

export default RemoveCobuyerModal;
