import {
  Box,
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabelProps,
  InputProps,
  ResponsiveValue,
  StackProps,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import {
  Select as ChakraSelect,
  ControlProps,
  GroupBase,
  MenuPlacement,
  OptionBase,
  Size,
} from 'chakra-react-select';
import { useField } from 'formik';
import React, { ReactNode } from 'react';

import FormErrorMessage from './FormErrorMessage';
import Label from './Label';

export interface Option extends OptionBase {
  value: string | number | boolean | null;
  label: string;
}

interface Props<T extends Option> extends FormControlProps {
  name: string;
  placeholder?: string;
  options: T[];
  size?: Size | ResponsiveValue<string>;
  label?: string;
  menuPlacement?: MenuPlacement | undefined;
  labelProps?: FormLabelProps;
  menuListHeight?: string;
  helperText?: string;
  _container?: StackProps;
  _control?: ControlProps;
  _input?: InputProps;
  invalid?: boolean;
  icon?: ReactNode;
}

const Select = <T extends Option>({
  name,
  options,
  placeholder,
  size = 'md',
  label,
  menuPlacement = 'bottom',
  labelProps,
  menuListHeight,
  helperText,
  _container,
  invalid = false,
  _input,
  _control,
  icon,
  ...rest
}: Props<T>) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [field, meta, { setValue, setTouched }] = useField(name);

  const isInvalid = (meta.touched && meta.error != null && !field.value) || invalid;

  return (
    <VStack w="full" spacing={0} {..._container}>
      <FormControl isInvalid={isInvalid} {...rest}>
        {label && <Label {...labelProps}>{label}</Label>}
        <ChakraSelect<T, false, GroupBase<T>>
          {...field}
          aria-label={`${name} select`}
          size={isMobile ? 'md' : (size as Size)}
          placeholder={placeholder}
          onBlur={() => setTouched(true)}
          options={options}
          value={options.find((option) => option.value === field.value)}
          onChange={(option) => setValue(option?.value)}
          isInvalid={isInvalid}
          menuPlacement={menuPlacement}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          chakraStyles={{
            control: (provided) => ({
              ...provided,
              ..._control,
              textAlign: 'left',
              paddingLeft: '10px',
            }),
            dropdownIndicator: (provided, { selectProps }) => ({
              ...provided,
              w: '30px',
              color: 'grayLight',
              background: selectProps.isInvalid
                ? 'leaseEndRedBG'
                : selectProps.isDisabled || selectProps.isReadOnly
                ? 'grayDisabledBackground'
                : 'white',
              _hover: {
                ...(!selectProps.isReadOnly
                  ? {
                      filter: 'brightness(95%)',
                      color: selectProps.isReadOnly ? 'grayLight' : 'grayDark',
                    }
                  : {}),
              },
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              height: 'calc(100% - 15px)',
              borderColor: 'grayDarkBackground',
            }),
            valueContainer: (provided) => ({
              ...provided,
              p: 0,
              textAlign: 'left',
            }),
            input: (provided) => ({
              ...provided,
              ..._input,
              minW: '70px',
              textAlign: 'left',
            }),
            placeholder: (provided, { selectProps }) => ({
              ...provided,
              color: selectProps.isInvalid
                ? 'leaseEndRed'
                : selectProps.isDisabled || selectProps.isReadOnly
                ? 'taupeGray'
                : 'charcoal',
              opacity: '50%',
              p: 0,
            }),
            menuList: (provided) => ({
              ...provided,
              maxHeight: '165px',
              minW: '70px',
              h: menuListHeight ?? '',
            }),
            option: (provided, state) => ({
              ...provided,
              textAlign: 'left',
              fontSize: '16px',
              bg: 'white',
              color: state.isSelected && 'oceanBoatBlue',
              _before: {
                content: state.isSelected ? '"\\2713"' : '" "',
                mr: state.isSelected ? '5px' : '20px',
              },
              _hover: {
                bg: state.isSelected ? 'oceanBoatBlue' : 'grayDisabledBackground',
              },
            }),
          }}
        />
        <Box position="absolute" right="3rem" top="2.4rem">
          {icon}
        </Box>
        {(isInvalid || helperText) && (
          <Box mt="5px" ml="5px">
            {isInvalid ? (
              <FormErrorMessage color="leaseEndRed">{meta.error}</FormErrorMessage>
            ) : (
              <FormHelperText color="taupeGray">{helperText}</FormHelperText>
            )}
          </Box>
        )}
      </FormControl>
    </VStack>
  );
};

export default Select;
