import { Box, HStack, Heading, Text } from '@chakra-ui/react';

import { useLEAccordionItemContext } from '../useLEAccordionItem';
import StatusIcon from './StatusIcon';

const LEAccordionItemHeader = () => {
  const {
    state: { status, title, subtitle },
    isPrevItemsCompleted,
    isExpanded,
  } = useLEAccordionItemContext();

  return (
    <>
      <HStack pb={isExpanded ? '20px' : '0'} w="100%" justifyContent="center">
        <Heading
          textAlign="center"
          size="md"
          letterSpacing="widest"
          color={!isExpanded ? 'gray' : 'leaseEndBlue'}
        >
          <Text>
            {isPrevItemsCompleted && (
              <StatusIcon display="inline-block" status={status} pt="1px" mr={2} />
            )}
            <Text as="span">{title}</Text>
          </Text>
        </Heading>
      </HStack>
      {subtitle && (
        <Box display={isExpanded ? 'none' : 'inherit'} fontSize="14px" pt="10px">
          {subtitle}
        </Box>
      )}
    </>
  );
};

export default LEAccordionItemHeader;
