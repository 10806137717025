import { FormikValues, validateYupSchema, yupToFormErrors } from 'formik';

export const getFormErrorsFromYupValidation = (value: FormikValues, schema: unknown) => {
  try {
    validateYupSchema(value, schema, true, value);
  } catch (err) {
    return yupToFormErrors(err);
  }
  return {};
};

export const checkYupValidation = (value: FormikValues, schema: unknown) => {
  try {
    validateYupSchema(value, schema, true, value);
  } catch (err) {
    return false;
  }
  return true;
};
