import { useEffect, useState } from 'react';

import config, { isLocal } from '../config';

const COOKIE_LIFETIME = 30; // days
const DOMAIN = config.domain;
const USE_SECURE_COOKIE = !isLocal;

export enum CookieKeys {
  GUID_KEY = 'le_guid',
  ACCESS_TOKEN = 'le_access_token',
  QUERY_PARAMS = 'le_query_params',
  PREQUAL_PARAMS = 'le_prequal_params',
}

export const clearCookie = (name: CookieKeys) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; ${
    USE_SECURE_COOKIE ? 'Secure; ' : ''
  }path=/; Domain=${DOMAIN};`;
};

export const getCookieWithRaw = <T>(name: CookieKeys) => {
  const rawCookie = document.cookie.split('; ').find((row) => row.startsWith(name));
  const rawCookieValue = rawCookie ? rawCookie.split('=').slice(1).join('=') : null;

  if (!rawCookieValue) {
    return { value: null, raw: null };
  }

  try {
    const cookieValue = JSON.parse(rawCookieValue) as T;
    return { value: cookieValue, raw: rawCookieValue };
  } catch {
    // since a cookie exists but cannot be parsed, we should clear it
    clearCookie(name);
    return { value: null, raw: null };
  }
};

export const getCookie = <T>(name: CookieKeys) => getCookieWithRaw<T>(name).value;

const setCookie = <T>(name: CookieKeys, value: T, days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${JSON.stringify(value)}; ${expires}; ${
    USE_SECURE_COOKIE ? 'Secure; ' : ''
  }path=/; Domain=${DOMAIN};`;
};

export const useCookie = <T>(name: CookieKeys) => {
  const [cookieValue, setCookieState] = useState<{ value: T | null; raw: string | null }>(
    getCookieWithRaw<T>(name),
  );

  const setCookieValue = (value: T) => {
    setCookie(name, value, COOKIE_LIFETIME);
    setCookieState({ value, raw: null });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const newCookie = getCookieWithRaw<T>(name);
      if (newCookie.raw !== cookieValue.raw) {
        setCookieState(newCookie);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [name, cookieValue]);

  return [cookieValue.value, setCookieValue] as const;
};
