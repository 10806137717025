export enum VEHICLE_COLORS {
  Beige = 'Beige',
  Black = 'Black',
  Blue = 'Blue',
  Brown = 'Brown',
  Gray = 'Gray',
  Green = 'Green',
  Orange = 'Orange',
  Red = 'Red',
  Silver = 'Silver',
  White = 'White',
  Purple = 'Purple',
  Yellow = 'Yellow',
}
