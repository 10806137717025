import { Text, Box } from '@chakra-ui/react';
import { useTempInfo } from '../../../hooks/useTempInfo';

const LienholderLogo = () => {
  const tempInfo = useTempInfo();

  if (tempInfo.info?.data?.lienholderLogo) {
    return (
      <Box
        w="100%"
        h="120px"
        bgImage={`url(${tempInfo.info.data.lienholderLogo})`}
        bgRepeat="no-repeat"
        bgSize="contain"
        bgPosition="center"
      />
    );
  }
  return (
    <Text fontSize={20} fontWeight="bold">
      {tempInfo.info?.data?.other_lienholder}
    </Text>
  );
};

export default LienholderLogo;
