import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { Dispatch, SetStateAction, useEffect } from 'react';

import { useTempInfo } from '../../hooks';
import { formatMoney } from '../../utils/helpers';
import { RudderEvent, rudderanalytics } from '../../utils/rudderstack';
import Modal from '../Modal';
import PaymentBreakdown from './PaymentBreakdown';
import MonthlyPaymentSliders from './ShoppingCartSliders';
import { EstimatorFormFields, PAYMENT_ESTIMATE_DISCLAIMER } from './useEstimatorFormik';

const ShoppingCartDesktopModal = ({
  isOpen,
  setIsOpen,
  loading,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
}) => {
  const { values, handleSubmit } = useFormikContext<EstimatorFormFields>();
  const { info } = useTempInfo('network-only');

  const submitForm = async () => {
    await handleSubmit();
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      rudderanalytics.track(RudderEvent.PaymentCalculatorModalOpen);
    }
  }, [isOpen]);

  const subtitle = `Your payoff amount from ${info?.data?.lienholder} was ${formatMoney(
    values.vehicle_payoff,
  )}. Before moving on to your application, use our payment calculator to to estimate your monthly car payments.`;

  return (
    <Modal
      isOpen={isOpen}
      onClose={submitForm}
      size="4xl"
      title="Picture Your New Monthly Payment"
      subtitle={subtitle}
    >
      <Flex gap={8} p={5}>
        <Flex flexDir="column" width="50%">
          <MonthlyPaymentSliders usedInModal />
        </Flex>
        <Flex flexDir="column" justifyContent="space-between" width="50%">
          <Box
            mb={2}
            bgColor="leaseEndGreen"
            px={4}
            py={5}
            color="white"
            borderRadius="15px"
            textAlign="center"
          >
            <Text fontSize="14px" lineHeight="28px" fontWeight="600">
              Your Estimated Monthly Payment
            </Text>
            <Text fontSize="32px" fontWeight="bold" lineHeight="42px">
              {loading ? <Spinner /> : values.estimated_payment}
            </Text>
            <Text mt={2}>Estimated {values.rate}% APR</Text>
          </Box>
          <PaymentBreakdown />
          <Box>
            <Text color="taupeGray" fontSize="12px" mt={2} mb={3}>
              {PAYMENT_ESTIMATE_DISCLAIMER}
            </Text>
            <Button isLoading={loading} w="100%" onClick={submitForm}>
              LET'S DO THIS
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ShoppingCartDesktopModal;
