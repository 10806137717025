import { Center, Flex, Text } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import React, { useContext } from 'react';

import { helpAtom } from '../..';
import ButtonedRadioGroup from '../../../../components/ButtonedRadioGroup';
import { useLEAccordionItemContext } from '../../../../components/LEAccordion/LEAccordionItem/useLEAccordionItem';
import PrimaryButton from '../../../../components/ui/buttons/PrimaryButton';
import {
  useGetOperatingHoursAndTimeSlotsQuery,
  useTemporaryInfoQuery,
} from '../../../../gql/generated/graphql';
import { CookieKeys, useCookie } from '../../../../hooks/useCookie';
import { LienholderContext, LienholderContextType } from '../../../../hooks/useLienholderInfo';
import LienholderLogo from '../LienholderLogo';
import Copy from './Copy';
import StackWithLoader from './StackWithLoader';
import YellowBox from './YellowBox';

const Content = () => {
  const [guid] = useCookie<string>(CookieKeys.GUID_KEY);
  const { data: tempInfo, loading } = useTemporaryInfoQuery({ variables: { id: guid ?? '' } });
  const { index, updateState, setOpenAccordionIndex } = useLEAccordionItemContext();
  const { data: operatingHoursData } = useGetOperatingHoursAndTimeSlotsQuery();
  const afterHours = !operatingHoursData?.getOperatingHoursAndTimeSlots?.isOpen;

  const { allowsDigital } = useContext<LienholderContextType>(LienholderContext);
  const [help, setHelp] = useAtom(helpAtom);

  const handleSubmit = () => {
    updateState({ status: 'complete' });
    setOpenAccordionIndex(index + 1);
  };

  return (
    <StackWithLoader
      loading={loading || !tempInfo?.temporaryInfo?.data}
      direction="column"
      alignItems="center"
      spacing={0}
    >
      <YellowBox />
      <LienholderLogo />
      <Copy lienholder={tempInfo?.temporaryInfo?.data?.lienholder || ''} afterHours={afterHours} />
      {!allowsDigital && (
        <Flex>
          <ButtonedRadioGroup
            columns={2}
            my={4}
            onChange={() => setHelp(!help)}
            value={allowsDigital ? false : help}
            options={[
              {
                label: afterHours ? "I'D STILL LIKE HELP" : 'HAVE LEASE END GET MY PAYOFF',
                value: true,
              },
              { label: afterHours ? "I'LL GET IT MYSELF" : 'GET PAYOFF MYSELF', value: false },
            ]}
            name="help"
            defaultValue={false}
          />
          {afterHours && (
            <Text textAlign="center" color="gray.500" pt={6}>
              <Text>
                You can select <Text as="b">I’d Still Like Help</Text> and our advisors will <br />{' '}
                get your payoff when they’re back online
              </Text>
              <Text mt={6}>
                <Text as="b">Advisor Hours</Text>
                <br /> Monday thru Friday 8am - 6pm Mountain time
              </Text>
            </Text>
          )}
        </Flex>
      )}

      <Center>
        <PrimaryButton mt="30px" mx="auto" type="submit" onClick={handleSubmit}>
          {allowsDigital ? `LET'S DO IT!` : 'NEXT'}
        </PrimaryButton>
      </Center>
    </StackWithLoader>
  );
};

export default Content;
