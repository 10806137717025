import { Box, Container, Flex } from '@chakra-ui/react';
import React from 'react';

import Nav from '../../components/Nav/Nav';

interface MainProps {
  children: React.ReactNode;
}

const Auth: React.FC<MainProps> = ({ children }) => {
  return (
    <Box bg="grayBackground" minH="100vh">
      <Nav hideMenuItems hideAuthOptions />
      <Container maxW="container.xl">
        <Flex justifyContent="center" flexGrow={1}>
          {children}
        </Flex>
      </Container>
    </Box>
  );
};

export default Auth;
