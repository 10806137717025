import {
  Box,
  Button,
  Flex,
  FlexProps,
  Icon,
  IconButton,
  Input,
  Spinner,
  Text,
  VStack,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { FaIdCard } from '@react-icons/all-files/fa/FaIdCard';
import { FaUpload } from '@react-icons/all-files/fa/FaUpload';
import { Form, Formik, FormikHelpers } from 'formik';
import { useContext, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Dropzone, { FileRejection } from 'react-dropzone';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import * as Yup from 'yup';

import { DropImage } from '../../../../components/DragAndDrop';
import Popover from '../../../../components/Popover';
import Select from '../../../../components/formComponents/Select';
import { Deal, DealMediaTypeEnum } from '../../../../gql/generated/graphql';
import { useHover } from '../../../../hooks';
import { MediaContext, MediaContextType } from '../../../../hooks/useMediaCollection';
import { logger } from '../../../../services/sentry';
import { INPUT_FILE_TYPES, MAX_FILE_SIZE_MB } from '../../../../utils/media';
import { createSuccessToast } from '../../../../utils/toast';
import { buyerDocTypeOptions, coBuyerDocTypeOptions } from './utils';

interface Props extends FlexProps {
  deal?: Deal;
  title?: string;
}

const UploadDocuments = ({ deal, title }: Props) => {
  const [hoverRef, isHovering] = useHover();
  const toast = useToast();

  // disable all hover affects for mobile
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const mouseHovering = !isMobile && isHovering;

  const { uploadImage } = useContext<MediaContextType>(MediaContext);

  const [error, setError] = useState<string>('');

  const inputRef = useRef<HTMLInputElement>(null);

  const validationSchema = Yup.object({
    type: Yup.string().required('Required'),
    file: Yup.mixed(),
  });

  const initialValues = {
    type: '',
    file: null,
  };

  type FormFields = Yup.InferType<typeof validationSchema>;

  const handleFileSelected = async (
    acceptedFiles: File[],
    setFieldValue: FormikHelpers<FormFields>['setFieldValue'],
  ) => {
    setError('');

    const fileToUpload = acceptedFiles[0] as File;

    setFieldValue('file', fileToUpload);
  };

  const handleRejection = (fileRejections: FileRejection[]) => {
    if (fileRejections[0].errors[0].code === 'file-invalid-type') {
      setError(
        `Files must be a valid format (${INPUT_FILE_TYPES.map((filetype) =>
          filetype.split('/')[1].toUpperCase(),
        ).join(', ')}).`,
      );
    } else if (fileRejections[0].errors[0].code === 'file-too-large') {
      setError(`Files cannot exceed ${MAX_FILE_SIZE_MB}MB.`);
    } else if (fileRejections[0].errors[0].code === 'too-many-files') {
      setError('Upload one file at a time.');
    } else {
      setError('There was an error uploading your file.');
    }
  };

  const boxBorder = (isDragActive: boolean) => {
    if (isDragActive) {
      return '2px dashed';
    }

    return 'none';
  };

  const handleUpload = async (values: FormFields, helpers: FormikHelpers<FormFields>) => {
    try {
      await uploadImage({
        mediaType: values.type as DealMediaTypeEnum,
        dealId: deal?.id ?? '',
        file: values.file,
        deletePrev: false,
        allowPdf: true,
      });
    } catch (e) {
      logger.error(
        'dashboard/components/UploadDocuments.tsx:handleUpload',
        'Failed uploading file',
        null,
        e,
      );
      setError((e as Error).message);
    } finally {
      if (inputRef.current) {
        const ref = inputRef;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ref.current!.value = '';
      }
      helpers.setSubmitting(false);
      helpers.resetForm();
      toast(
        createSuccessToast({
          title: 'File uploaded successfully',
        }),
      );
    }
  };

  return (
    <Formik<FormFields>
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleUpload}
    >
      {({ values, isSubmitting, setFieldValue, resetForm }) => (
        <Flex
          direction="column"
          textAlign="center"
          alignItems="center"
          p="15px"
          pb="30px"
          as={Form}
        >
          {title && (
            <Flex alignItems="center" mb={4}>
              <Text fontSize="14px" fontWeight="bold" mr={2}>
                {title}
              </Text>
              <Popover
                placement="top"
                label="Securely upload pictures or documents requested by Lease End with this tool."
              >
                <Box>
                  <Icon as={BsFillQuestionCircleFill} boxSize="16px" color="royalBlue" mt="1px" />
                </Box>
              </Popover>
            </Flex>
          )}
          {!values.file ? (
            <>
              <DndProvider backend={HTML5Backend}>
                <DropImage canDrop set={() => undefined}>
                  {(isOver) => (
                    <Dropzone
                      onDropAccepted={(acceptedFiles) =>
                        handleFileSelected(acceptedFiles, setFieldValue)
                      }
                      onDropRejected={(fileRejections) => {
                        handleRejection(fileRejections);
                        setFieldValue('file', null);
                      }}
                      accept={[...INPUT_FILE_TYPES, 'application/pdf']}
                      maxFiles={1}
                      noClick
                      noKeyboard
                      multiple={false}
                      maxSize={MAX_FILE_SIZE_MB * 1000000}
                    >
                      {({ getRootProps, getInputProps, isDragActive }) => {
                        return (
                          <Flex
                            {...getRootProps()}
                            alignItems={values.file && !isSubmitting ? 'start' : 'center'}
                            justifyItems={values.file && !isSubmitting ? 'start' : 'center'}
                            borderRadius="md"
                            bg={
                              values.file && !isSubmitting
                                ? mouseHovering
                                  ? 'blackAlpha.700'
                                  : 'white'
                                : mouseHovering
                                ? 'grayImageDarkBackground'
                                : 'grayImageBackground'
                            }
                            cursor={isSubmitting ? 'not-allowed' : 'pointer'}
                            border={boxBorder(isOver || isDragActive)}
                            borderColor={values.file ? 'darkMidnightBlue' : 'bostonBlue'}
                            ref={hoverRef}
                            w="100px"
                            h="100px"
                            onClick={() => inputRef.current?.click()}
                            position="relative"
                          >
                            <IconButton
                              variant="solid"
                              display={values.file && mouseHovering ? 'none' : 'inherit'}
                              disabled={isSubmitting}
                              aria-label="Upload File"
                              icon={<FaUpload />}
                              color="grayBlueish"
                              bg="white"
                              rounded="full"
                              position="absolute"
                              left="50%"
                              transform="translate(-50%, -50%)"
                              bottom="-10"
                              _hover={{
                                bg: isSubmitting ? 'auto' : 'grayLightBackground',
                              }}
                              pointerEvents="none"
                            />
                            <VStack
                              w="full"
                              color={values.file ? 'darkMidnightBlue' : 'grayBlueish'}
                              spacing={0}
                              pointerEvents="none"
                            >
                              {isSubmitting ? (
                                <Spinner />
                              ) : (
                                <Icon
                                  display={values.file ? 'none' : 'inherit'}
                                  as={FaIdCard}
                                  w="54px"
                                  h="40px"
                                />
                              )}
                            </VStack>
                            <Input
                              {...getInputProps()}
                              size={undefined}
                              display="none"
                              ref={inputRef}
                            />
                          </Flex>
                        );
                      }}
                    </Dropzone>
                  )}
                </DropImage>
              </DndProvider>
              {error && (
                <Text mt={5} fontSize="12px" wordBreak="break-word" color="leaseEndRed">
                  {error}
                </Text>
              )}
            </>
          ) : (
            <Box mt={5} textAlign="center" w="full">
              <Box>
                <Text mb={2}>{values.file.name}</Text>
                <Select
                  name="type"
                  placeholder="Select a document type"
                  options={deal?.isCobuyer ? coBuyerDocTypeOptions : buyerDocTypeOptions}
                />
                <Flex flexDir="column" alignItems="center">
                  <Button type="submit" size="sm" fontSize="12px" mt={4} isLoading={isSubmitting}>
                    UPLOAD
                  </Button>
                  <Button variant="link" type="button" size="sm" mt={4} onClick={() => resetForm()}>
                    Cancel
                  </Button>
                </Flex>
              </Box>
            </Box>
          )}
        </Flex>
      )}
    </Formik>
  );
};

export default UploadDocuments;
