/**
 * When adding or removing flags, update the following:
 * - LDFlags enum
 * - if flag is not a boolean, add/remove the flag to the <type>Flags array
 */

export enum LDFlags {
  // Active flags
  ATC_API = 'atc-api', // marketing mixed
  DOWN_PAYMENTS = 'down-payments', // Stripe, keeping for future initiative
  FLOW_SHOPPING_CART = 'flow-shopping-cart',
  KBB_OPTIONS_PAGE = 'kbb-options-page',
  PAYMENT_MODAL_VARIATION = 'payment-modal-variation',
  RE_CAPTCHA_ENABLED = 're-captcha-enabled', // marketing mixed?
  SERVICE_DOWN_BANNER = 'service-down-banner',
  SHOW_SAFETY_MESSAGE = 'show-safety-message',
  END_OF_FLOW_APPROVAL_VARIATION = 'end-of-flow-approval-variation',
  FORCE_AFTER_HOURS = 'force-after-hours', // Forces after hours behavior. For testing, or holidays I guess.

  // Temporary flags - flags meant to be removed after a/b test or implementation
  CREDIT_PREQUALIFICATION = 'credit-prequalification',
  LEASE_AGREEMENT_UPLOAD = 'lease-agreement-upload',
  MID_FLOW_PAYMENT_CALC = 'mid-flow-payment-calculator-test',
  OVER_MILEAGE_SAVINGS = 'over-mileage-savings',
  PHONE_NUMBER_PLACEMENT_VARIATION = 'phone-number-placement-variation',
  SSN_DASH_VERIFICATION = 'ssn-dash-verification',
  COM_DASHBOARD_DOWN_PAYMENT = 'com-dashboard-down-payment',
  COM_INCLUDE_SALES_TAX_ON_PAYOFF = 'com-include-sales-tax-on-payoff',
  REMOVE_FLOW_PASSWORD = 'remove-flow-password',
  PREQUAL_CONSENT_CHECKBOX = 'prequal-consent-checkbox',
  BREAK_OUT_CREDIT_AND_DEBIT_CARDS = 'break-out-credit-and-debit-cards',
  HIDE_NAME_ON_GETTING_STARTED = 'hide-name-on-getting-started',
}

// Flag evaluations we don't want tracked in Rudderstack
export const FLAG_TRACKING_BLACK_LIST = [LDFlags.ATC_API, LDFlags.RE_CAPTCHA_ENABLED];

const stringFlags = [LDFlags.SERVICE_DOWN_BANNER] as const;
const numberFlags = [
  LDFlags.MID_FLOW_PAYMENT_CALC,
  LDFlags.PAYMENT_MODAL_VARIATION,
  LDFlags.PHONE_NUMBER_PLACEMENT_VARIATION,
] as const;

export type LDFlagValueTypes = {
  [key in LDFlags]: key extends typeof stringFlags[number]
    ? string
    : key extends typeof numberFlags[number]
    ? number
    : boolean;
};

export type FlagOverrides = {
  [key in LDFlags]?: unknown;
};
