import React from 'react';
import { Button as ChakraButton, ButtonProps } from '@chakra-ui/react';

export const Button: React.FC<ButtonProps> = ({ onClick = () => undefined, children, ...rest }) => {
  return (
    <ChakraButton onClick={onClick} {...rest}>
      {children}
    </ChakraButton>
  );
};

export default Button;
