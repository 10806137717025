import React, { useEffect } from 'react';
import { useJwt } from 'react-jwt';
import { useHistory } from 'react-router-dom';

import {
  ACCOUNT_LOGIN,
  DASHBOARD,
  FINAL_ADJUSTMENTS,
  PAYMENTS_DASHBOARD,
} from '../../constants/urls';
import { useUserDealLazyQuery } from '../../gql/generated/graphql';
import { CookieKeys, useCookie } from '../../hooks/useCookie';
import LERoute, { LERouteProps } from './LERoute';

export const SKIP_REDIRECT_PAGES = [DASHBOARD, FINAL_ADJUSTMENTS, PAYMENTS_DASHBOARD];

export default function AuthenticatedRoute({ children, ...props }: LERouteProps) {
  const history = useHistory();

  const [accessToken] = useCookie<string>(CookieKeys.ACCESS_TOKEN);
  const { isExpired } = useJwt(accessToken || '');
  const isValidToken = !!accessToken && !isExpired;

  const [checkSalesVisibility] = useUserDealLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ userDeal }) => {
      if (userDeal?.sales_visibility) {
        history.replace(DASHBOARD);
      }
    },
  });

  useEffect(() => {
    if (!isValidToken) {
      history.push(ACCOUNT_LOGIN);
      return;
    }

    if (!SKIP_REDIRECT_PAGES.includes(history.location.pathname)) {
      checkSalesVisibility();
    }
  }, [isValidToken]);

  return <LERoute {...props}>{isValidToken && children}</LERoute>;
}
