import { MaritalStatusEnum } from '../gql/generated/graphql';

export const options = [
  {
    value: MaritalStatusEnum.NotMarried,
    label: 'Not Married',
  },
  {
    value: MaritalStatusEnum.Married,
    label: 'Married',
  },
  {
    value: MaritalStatusEnum.Separated,
    label: 'Separated',
  },
  {
    value: MaritalStatusEnum.Widowed,
    label: 'Widowed',
  },
];
