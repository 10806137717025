// eslint-disable-next-line no-restricted-imports
import { LDClient, useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';

import { isLocal, isStaging } from '../config';
import {
  FLAG_TRACKING_BLACK_LIST,
  FlagOverrides,
  LDFlagValueTypes,
  LDFlags,
} from '../constants/flags';
import { RudderEvent, rudderanalytics } from '../utils/rudderstack';

// For reflect
const REFLECT_KEY = 'reflectFlags';
const reflectFlags = JSON.parse(localStorage.getItem(REFLECT_KEY) as string);

// Imports ../flagOverrides.ts but uses a default {} if it doesn't exist
let flagOverrides: { default: FlagOverrides } = { default: {} };
try {
  // eslint-disable-next-line global-require, import/no-unresolved, import/extensions
  flagOverrides = require('../flagOverrides');
} catch (ex) {
  // do nothing: use default empty flags
}

const overrideLogic = (flag: LDFlags, flagValue: unknown) => {
  if (isStaging && reflectFlags?.[flag] != null) {
    return reflectFlags[flag];
  }
  if (isLocal && flagOverrides.default[flag] != null) {
    return flagOverrides.default[flag];
  }
  return flagValue;
};

const getFlagValue = (ldClient: LDClient | undefined, flag: LDFlags) => {
  const flagValue = ldClient?.variation(flag);
  return overrideLogic(flag, flagValue);
};

const trackFlagEval = (flag: LDFlags, flagValue: LDFlagValueTypes) => {
  if (!FLAG_TRACKING_BLACK_LIST.includes(flag)) {
    rudderanalytics.track(RudderEvent.FlowTest, {
      flag,
      flagValue,
    });
  }
};

const evaluatedFlags = {} as Record<LDFlags, LDFlagValueTypes>;

export const evaluateFlag = (ldClient: LDClient | undefined, flag: LDFlags) => {
  const flagValue = getFlagValue(ldClient, flag);
  if (evaluatedFlags[flag] === flagValue) {
    return flagValue;
  }
  const isFirstEvaluation = evaluatedFlags[flag] == null;

  evaluatedFlags[flag] = flagValue;
  window.dispatchEvent(new Event(`evaluateFlag:${flag}`));
  trackFlagEval(flag, flagValue);

  if (isFirstEvaluation) {
    ldClient?.on(`change:${flag}`, () => evaluateFlag(ldClient, flag));
  }
  return flagValue;
};

// a way to override flags for development until launchdarkly supports this https://github.com/launchdarkly/react-client-sdk/issues/53
// view flagOverrides.sample.ts in the root directory for more information
const useFlag = (flag: LDFlags) => {
  const ldClient = useLDClient();
  const [flagValue, setFlagValue] = useState(evaluateFlag(ldClient, flag));

  useEffect(() => {
    const handleEvaluateFlag = () => {
      setFlagValue(evaluateFlag(ldClient, flag));
    };

    handleEvaluateFlag();
    window.addEventListener(`evaluateFlag:${flag}`, handleEvaluateFlag);
    return () => {
      window.removeEventListener(`evaluateFlag:${flag}`, handleEvaluateFlag);
    };
  }, [flag]);

  return flagValue;
};

export default useFlag;
