import { DealMediaTypeEnum } from '../../../../gql/generated/graphql';
import { snakeCaseToSpaceSeparated, titleCase } from '../../../../utils/helpers';

const GENERAL_DOC_TYPES_TO_UPLOAD = [
  DealMediaTypeEnum.Emissions,
  DealMediaTypeEnum.OdometerPicture,
  DealMediaTypeEnum.PicturesOfVehicle,
  DealMediaTypeEnum.PlateTransferLetter,
  DealMediaTypeEnum.ProofOfIncome,
  DealMediaTypeEnum.ProofOfResidence,
  DealMediaTypeEnum.ProofOfResidence2,
  DealMediaTypeEnum.PropertyTaxReceipt,
  DealMediaTypeEnum.Registration,
  DealMediaTypeEnum.SafetyInspection,
  DealMediaTypeEnum.VinInspection,
  DealMediaTypeEnum.Other,
];

const SPECIFIC_BUYER_DOC_TYPES_TO_UPLOAD = [
  DealMediaTypeEnum.BackOfDriversLicense,
  DealMediaTypeEnum.FrontOfDriversLicense,
  DealMediaTypeEnum.BackOfInsuranceCard,
  DealMediaTypeEnum.FrontOfInsuranceCard,
];

const SPECIFIC_COBUYER_DOC_TYPES_TO_UPLOAD = [
  DealMediaTypeEnum.BackOfCoBuyersLicense,
  DealMediaTypeEnum.FrontOfCoBuyersLicense,
  DealMediaTypeEnum.BackOfCoBuyersInsurance,
  DealMediaTypeEnum.FrontOfCoBuyersInsurance,
];

const BUYER_DOC_TYPES_TO_UPLOAD = [
  ...GENERAL_DOC_TYPES_TO_UPLOAD,
  ...SPECIFIC_BUYER_DOC_TYPES_TO_UPLOAD,
];

const COBUYER_DOC_TYPES_TO_UPLOAD = [
  ...GENERAL_DOC_TYPES_TO_UPLOAD,
  ...SPECIFIC_COBUYER_DOC_TYPES_TO_UPLOAD,
];

const buyerDocTypeOptions = BUYER_DOC_TYPES_TO_UPLOAD.map((value) => ({
  value,
  label: titleCase(snakeCaseToSpaceSeparated(value)),
})).sort((a, b) => a.label.localeCompare(b.label));

const coBuyerDocTypeOptions = COBUYER_DOC_TYPES_TO_UPLOAD.map((value) => ({
  value,
  label: titleCase(snakeCaseToSpaceSeparated(value)),
})).sort((a, b) => a.label.localeCompare(b.label));

export { buyerDocTypeOptions, coBuyerDocTypeOptions };
