import { Container, Flex, Link, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

import Title from '../../../../components/Title';
import { CONTACT_US } from '../../../../constants/urls';
import useScript from '../../../../hooks/useScript';
import { FINIX_SCRIPT, FinixAuth, createFinixAuth } from '../../../../utils/payments';
import PaymentForm from '../../../payments/PaymentForm';
import { DealWithFarthestState } from '../../utils';
import PaymentOverview from './PaymentOverview';

interface Props {
  deal: DealWithFarthestState;
}

const DashboardDownPaymentPage = ({ deal }: Props) => {
  const isLoaded = useScript(FINIX_SCRIPT);
  const dealId = deal?.id || undefined;
  const [success, setSuccess] = useState(false);
  const [finixAuth, setFinixAuth] = useState<FinixAuth | null>(null);

  const isMounted = useRef(false);
  useEffect(() => {
    const getFinixAuth = async () => {
      setFinixAuth(await createFinixAuth());
    };

    if (isLoaded && !isMounted.current) {
      isMounted.current = true;
      getFinixAuth();
    }
  }, [isLoaded]);

  return (
    <Container my={20}>
      <Title mb={14} fontSize={{ base: '24px', md: '36px' }}>
        {success ? 'All done!' : 'Securely make your down payment'}
      </Title>
      <Flex
        gap={{ base: 2, md: 8 }}
        justifyContent="center"
        alignItems={{ base: 'center', md: 'start' }}
        flexDirection={{ base: 'column', md: 'row' }}
      >
        {!success && <PaymentOverview deal={deal} />}
        <Flex flexDirection="column" w="100%" alignItems="center">
          {finixAuth && (
            <PaymentForm
              dealId={dealId}
              allowCard
              onSuccess={() => setSuccess(true)}
              finixAuth={finixAuth}
            />
          )}
          <Flex
            flexDir="column"
            alignItems="center"
            mt={10}
            textAlign="center"
            fontSize="12px"
            display={{ md: 'none' }}
          >
            <Text color="gray">
              IMPORTANT: Lease End never stores any of your financial information.
            </Text>
            <Link mx="auto" href={CONTACT_US} isExternal>
              Questions? Contact us
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
};

export default DashboardDownPaymentPage;
