import { Collapse, Flex, Spinner, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import PrimaryButton from '../../components/ui/buttons/PrimaryButton';
import { Deal } from '../../gql/generated/graphql';
import { resetLocalStorage } from '../../services/localStorage';
import { DocumentType } from '../documents/components/ThingsWeNeed/utils';
import UploadItem from './UploadItem';

interface Props {
  requiredDocs: DocumentType[];
  deal: Deal;
}

const UploadList = ({ requiredDocs, deal }: Props) => {
  const [totalFileCount, setTotalFileCount] = useState<number>(0);

  const history = useHistory();

  const handleAllDone = () => {
    resetLocalStorage({ removeGuid: true, removeAccessToken: true });
    history.push('/');
  };
  return (
    <Flex
      gap="20px"
      flexDir="column"
      p="12px 22px 60px"
      boxShadow="inset 0px 3px 6px #00000029"
      borderRadius="25px"
      bgColor="white"
      w="100%"
    >
      <Text textAlign="center">Drag and drop or browse to choose files</Text>
      {!requiredDocs.length && (
        <Flex justifyContent="center" w="100%" gap="10px" color="iconicGreen">
          <Spinner />
          <Text>Loading...</Text>
        </Flex>
      )}
      <Collapse in={!!requiredDocs.length}>
        <Flex gap="20px" flexDir="column">
          {requiredDocs.map((doc) => {
            return doc.images.map((img) => (
              <UploadItem
                doc={doc}
                image={img}
                key={doc.title}
                counter={setTotalFileCount}
                deal={deal}
              />
            ));
          })}
        </Flex>
      </Collapse>
      <Collapse in={!!requiredDocs.length && totalFileCount === requiredDocs.length}>
        <PrimaryButton onClick={handleAllDone}>ALL DONE</PrimaryButton>
      </Collapse>
    </Flex>
  );
};

export default UploadList;
