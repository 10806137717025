/* eslint-disable react/no-array-index-key */
import {
  Box,
  Container,
  Flex,
  SimpleGrid,
  Text,
  useBoolean,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useCallback } from 'react';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Maybe } from '../../../../gql/generated/graphql';
import { DealStateEnum } from '../../../../utils/types/deal';
import { DealStateMap, getDealStatusLabel, getDealStatusTooltip } from '../../utils';
import TimelineFlag from './TimelineFlag';
import TimelineIcon, { TimelineIconProps } from './TimelineIcon';

export type GetStatusType = (statusState: DealStateEnum) => 'completed' | 'inprogress' | 'upcoming';

interface Props {
  farthestDealState: Maybe<DealStateEnum>;
  isRefi: boolean;
}

const TimeLine = ({ farthestDealState, isRefi }: Props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const getStepStatus = useCallback(
    (statusState: DealStateEnum) => {
      if (!farthestDealState) {
        return 'upcoming';
      }

      if (DealStateMap[farthestDealState] > DealStateMap[statusState]) {
        return 'completed';
      }

      if (
        (farthestDealState === DealStateEnum.Closing &&
          statusState === DealStateEnum.SentForSignatures) ||
        DealStateMap[farthestDealState] === DealStateMap[statusState]
      ) {
        return 'inprogress';
      }

      return 'upcoming';
    },
    [farthestDealState],
  );

  const desktopIcons: TimelineIconProps[] = [
    {
      status: getStepStatus(DealStateEnum.Signed),
      label: getDealStatusLabel(DealStateEnum.Signed, isRefi),
      tooltip: getDealStatusTooltip(DealStateEnum.Signed, isRefi),
    },
    {
      status: getStepStatus(DealStateEnum.SendPayoff),
      label: getDealStatusLabel(DealStateEnum.SendPayoff, isRefi),
      tooltip: getDealStatusTooltip(DealStateEnum.SendPayoff, isRefi),
    },
    {
      status: getStepStatus(DealStateEnum.WaitingForTitle),
      label: getDealStatusLabel(DealStateEnum.WaitingForTitle, isRefi),
      tooltip: getDealStatusTooltip(DealStateEnum.WaitingForTitle, isRefi),
    },
    {
      status: getStepStatus(DealStateEnum.TitleReceived),
      label: getDealStatusLabel(DealStateEnum.TitleReceived, isRefi),
      tooltip: getDealStatusTooltip(DealStateEnum.TitleReceived, isRefi),
    },
    {
      status: getStepStatus(DealStateEnum.SentToProcessor),
      label: getDealStatusLabel(DealStateEnum.SentToProcessor, isRefi),
      tooltip: getDealStatusTooltip(DealStateEnum.SentToProcessor, isRefi),
    },
  ];

  const mobileIcons: TimelineIconProps[] = [
    {
      status: getStepStatus(DealStateEnum.SentForSignatures),
      label: getDealStatusLabel(DealStateEnum.SentForSignatures, isRefi),
      tooltip: getDealStatusTooltip(DealStateEnum.SentForSignatures, isRefi),
    },
    ...desktopIcons,
    {
      status: getStepStatus(DealStateEnum.Booted),
      label: getDealStatusLabel(DealStateEnum.Booted, isRefi),
      tooltip: getDealStatusTooltip(DealStateEnum.Booted, isRefi),
      subLabel:
        getStepStatus(DealStateEnum.Booted) === 'completed'
          ? 'Your lease is over!'
          : '60-Day Average',
      last: true,
    },
  ];

  const [refreshTooltip, { toggle: triggerTooltip }] = useBoolean();

  if (!farthestDealState) {
    return null;
  }

  return (
    <Box bg="oceanBoatBlueBG">
      {isMobile ? (
        <Flex w="full">
          <Swiper
            style={{ padding: '20px' }}
            slidesPerView={2}
            breakpoints={{
              520: {
                slidesPerView: 4,
              },
              768: {
                slidesPerView: 6,
              },
            }}
            centeredSlides
            initialSlide={DealStateMap[farthestDealState] - 1}
            onSlideChange={triggerTooltip}
          >
            {mobileIcons.map((props, i) => (
              <SwiperSlide key={`swiper-slide-${i}`} style={{ transform: 'unset' }}>
                <TimelineIcon {...props} refreshTooltip={refreshTooltip} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Flex>
      ) : (
        <Container maxW="container.xl" py="20px">
          <Flex w="full">
            <TimelineFlag
              status={getStepStatus(DealStateEnum.SentForSignatures)}
              text={getStepStatus(DealStateEnum.SentForSignatures) === 'completed' ? 'Woohoo!' : ''}
            >
              <TimelineIcon first {...mobileIcons[0]} />
            </TimelineFlag>

            <Flex direction="column" flexGrow="1" alignItems="center">
              <Text fontSize="36px" fontWeight="bold" py="10px">
                {isRefi ? ' Refinance' : 'Lease End'} Timeline
              </Text>
              <SimpleGrid columns={5} w="full">
                {desktopIcons.map((props, index) => (
                  <TimelineIcon key={`timeline-icon-${index}`} {...props} divider={index !== 0} />
                ))}
              </SimpleGrid>
            </Flex>

            <TimelineFlag
              status={getStepStatus(DealStateEnum.Booted)}
              last
              text={isRefi ? '' : 'Lease Ended!'}
            >
              <TimelineIcon last {...mobileIcons[mobileIcons.length - 1]} />
            </TimelineFlag>
          </Flex>
        </Container>
      )}
    </Box>
  );
};

export default TimeLine;
