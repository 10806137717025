import { Box, Flex, Image, Text, keyframes } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { Advisor, Confetti as ConfettiImage } from '../../assets/Images';
import PrimaryButton from '../../components/ui/buttons/PrimaryButton';
import { Link } from '../../components/ui/links/Link';
import { LDFlags } from '../../constants/flags';
import { FAQS } from '../../constants/urls';
import { useGetOperatingHoursAndTimeSlotsQuery } from '../../gql/generated/graphql';
import { useFlag } from '../../hooks';
import { resetLocalStorage } from '../../services/localStorage';
import EndFlowDocUpload from './EndFlowDocUpload';

const animationKeyframes = keyframes`
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 0% 103%;
  }
  100% {
    background-position: 0% 206%;
  }
`;

interface Props {
  banks: { id: string; bank_logo: string }[];
}

const Confetti = ({ banks }: Props) => {
  const [uploading, setUploading] = useState(false);

  const forceAfterHours = useFlag(LDFlags.FORCE_AFTER_HOURS);
  const { data: hoursData } = useGetOperatingHoursAndTimeSlotsQuery();
  const [afterHours, setAfterHours] = useState<boolean>(false);

  useEffect(() => {
    if (hoursData?.getOperatingHoursAndTimeSlots != null) {
      if (hoursData?.getOperatingHoursAndTimeSlots?.isOpen && !forceAfterHours) {
        resetLocalStorage({ removeGuid: true, removeAccessToken: true });
      } else {
        setAfterHours(true);
      }
    }
  }, [hoursData, forceAfterHours]);

  return (
    <Flex
      animation={`${animationKeyframes} 60s linear 1`}
      minW="100vw"
      minH="calc(100vh + 36px)"
      mt={-36}
      py={36}
      bgPosition="0% -36px"
      bgImage={ConfettiImage}
      backgroundRepeat="repeat"
      backgroundSize="400px"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        textAlign="center"
        bgColor="jungleGreen"
        w="100%"
        h="fit-content"
        maxW={{ base: '80%', md: '380px' }}
        rounded="xl"
      >
        <Box p={6}>
          <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight="semibold" color="white">
            Aw yeah!
          </Text>
          <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight="semibold" color="white" mb={4}>
            You have been approved for loans from our partners at:
          </Text>
          <Box
            bgColor="white"
            rounded="xl"
            p={8}
            boxShadow="inset 0px 0px 5px rgba(0,0,0,0.5)"
            mb={8}
            position="relative"
          >
            <Flex flexDir="column" alignItems="center">
              {banks.map((bank) => (
                <Image key={bank.id} src={bank.bank_logo} w="120px" my={4} />
              ))}
            </Flex>
            <Image
              src={Advisor}
              position="absolute"
              bottom={-8}
              w="65px"
              left="50%"
              transform="translateX(-50%)"
              border="2px solid white"
              rounded="full"
            />
          </Box>
          <Text color="white" fontSize="14px" mt="35px">
            Your Lease End Advisor will be reaching out soon to go over your options! In the
            meantime{afterHours ? ', you can start uploading some required documents.' : ':'}
          </Text>
          {!afterHours && (
            <Link to={FAQS}>
              <PrimaryButton mt="15px">EXPLORE THE FAQS</PrimaryButton>
            </Link>
          )}
          {!uploading && afterHours && (
            <PrimaryButton mt="15px" onClick={() => setUploading(true)}>
              UPLOAD DOCUMENTS
            </PrimaryButton>
          )}
        </Box>
        {uploading && <EndFlowDocUpload />}
      </Box>
    </Flex>
  );
};

export default Confetti;
