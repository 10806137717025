import { string } from 'yup';

export const toHex = (str: string) => {
  const result: string[] = [];
  for (let i = 0; i < str.length; ++i) {
    result.push(str.charCodeAt(i).toString(16).padStart(2, '0'));
  }

  return result.join('');
};

export const notRequiredString = string().notRequired().nullable();
export const requiredStringMaxCharsValidation = ({
  max = 100,
  fieldName = 'Field',
}: {
  max?: number;
  fieldName?: string;
}) =>
  string()
    .max(max, `Must be less than ${max} characters`)
    .nullable()
    .required(`${fieldName} is required`);

export const snakeCaseToHuman = (input: string | null | undefined) => {
  if (!input) return '';

  return input
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
