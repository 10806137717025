import { OnChangeValue, Select } from 'chakra-react-select';
import { atom, useAtom, useSetAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { DASHBOARD } from '../../../constants/urls';
import { Car, DealType, Maybe, useUserDealsQuery } from '../../../gql/generated/graphql';
import { DealIdentifier, NEW_DEAL_VALUE, dashboardDealInfoAtom } from '../Dashboard';

const CHECK_SIGN = '\\2713';
const PLUS_SIGN = '\\2795';

interface OptionType {
  value: DealIdentifier;
  label: string;
}

interface Props {
  inFlow: boolean;
  car: Maybe<Car>;
}

export const dealSwitcherOptionAtom = atom<OptionType[]>([]);

const DealSwitcher = ({ inFlow, car }: Props) => {
  const { data: userDeals, loading } = useUserDealsQuery({ fetchPolicy: 'network-only' });
  const [dealId, setDealId] = useAtom(dashboardDealInfoAtom);
  const setDealSwitcherOptions = useSetAtom(dealSwitcherOptionAtom);
  const history = useHistory();

  const options = useMemo(() => {
    const visibleDeals = userDeals?.userDeals?.filter((userDeal) => userDeal?.sales_visibility);

    const deals =
      visibleDeals?.map((userDeal) => ({
        value: {
          id: userDeal?.id || undefined,
          isCobuyer: userDeal?.isCobuyer,
        },
        label: `${userDeal?.car?.year} ${userDeal?.car?.make} ${userDeal?.car?.model}`,
      })) || [];

    const userHasNonRefiDeal = visibleDeals?.some((deal) => deal?.type !== DealType.Refi);

    return [
      ...deals,
      ...(userHasNonRefiDeal
        ? [
            {
              value: NEW_DEAL_VALUE,
              label: inFlow ? `${car?.year} ${car?.make} ${car?.model}` : 'End Another Lease',
            },
          ]
        : []),
    ] as OptionType[];
  }, [userDeals, inFlow, car]);

  useEffect(() => {
    if (options.length) {
      setDealSwitcherOptions(options);
    }
  }, [options, setDealSwitcherOptions]);

  const currentDeal =
    options.find(({ value }) => value.id === dealId?.id && value.isCobuyer === dealId?.isCobuyer) ||
    options[0];

  useEffect(() => {
    if (userDeals?.userDeals?.length && currentDeal?.value !== dealId) {
      setDealId(currentDeal.value);
    }
  }, [userDeals, currentDeal, dealId]);

  const handleChange = (value: OnChangeValue<OptionType, false>) => {
    const { value: selectedValue } = value || {};

    if (!selectedValue) return;

    history.push(DASHBOARD);

    setDealId(selectedValue);
  };

  if (loading || options.length < 2) {
    return null;
  }

  return (
    <Select
      name="deal"
      options={loading ? [] : options}
      onChange={handleChange}
      value={loading ? null : currentDeal}
      menuPortalTarget={document.body}
      isDisabled={loading}
      isLoading={loading}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
      }}
      chakraStyles={{
        control: (provided) => ({
          ...provided,
          border: 'none',
          borderRadius: '5px',
          minH: 'unset',
        }),
        container: (provided) => ({
          ...provided,
          w: { base: '100%', md: '500px' },
          cursor: 'pointer',
          mb: { base: '10px', md: 0 },
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          w: '50px',
          color: 'grayLight',
          background: 'grayDisabledBackground',
        }),
        valueContainer: (provided) => ({
          ...provided,
          paddingInlineStart: '10px',
          background: 'grayDisabledBackground',
        }),
        input: (provided) => ({
          ...provided,
          minW: '50px',
          border: 'none',
        }),
        menu: (provided) => ({
          ...provided,
          mt: '0.5px',
        }),
        menuList: (provided) => ({
          ...provided,
          background: 'white',
          border: '0px',
          borderRadius: '5px',
          shadow: 'lg',
        }),
        option: (provided, state) => {
          const isAddNewDeal = state.data.value === NEW_DEAL_VALUE && !inFlow;
          const isInProgressDeal = state.data.value === NEW_DEAL_VALUE && inFlow;
          const { isCobuyer } = state.data.value;

          const beforeContent = isAddNewDeal
            ? `'${PLUS_SIGN}'`
            : state.isSelected
            ? `'${CHECK_SIGN}'`
            : '" "';

          return {
            ...provided,
            alignItems: isAddNewDeal ? 'center' : 'start',
            fontSize: '16px',
            bg: 'white',
            borderTop: isAddNewDeal ? '1px solid' : '',
            borderTopColor: 'grayHover',
            color: state.isSelected && 'oceanBoatBlue',
            height: isInProgressDeal || isCobuyer ? '45px' : 'auto',
            position: 'relative',
            _before: {
              fontSize: isAddNewDeal ? '11px' : undefined,
              content: beforeContent,
              mr: state.isSelected || isAddNewDeal ? '5px' : '20px',
            },
            _after: {
              position: 'absolute',
              top: '28px',
              left: '32px',
              content: isInProgressDeal ? '"IN PROGRESS"' : isCobuyer ? '"CO-BUYER"' : undefined,
              fontSize: '12px',
              color: state.isSelected ? 'white' : 'taupeGray',
            },
            _hover: {
              color: state.isSelected ? 'white' : 'black',
              bg: state.isSelected ? 'oceanBoatBlue' : 'gray.100',
            },
          };
        },
      }}
    />
  );
};

export default DealSwitcher;
