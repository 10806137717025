import { PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react';

import LEAccordionItem from '.';
import useDiff from '../../../hooks/useDiff';
import { useDescendant } from '../LEAccordion';
import { useLEAccordionContext } from '../useLEAccordion';
import { ItemType, UpdatableItemStateType } from '../utils';

/**
 * Internal use only for LEAccordionItem
 *
 * Controls the state of LEAccordionItem
 */
export const useLEAccordionItem = (initialItemState: ItemType) => {
  const {
    itemStates,
    itemIndexes,
    updateItemState,
    openAccordionIndex,
    setOpenAccordionIndex,
    rightSidebar,
  } = useLEAccordionContext();

  const state = itemStates[initialItemState.key] || { ...initialItemState, index: -1 };

  const updateState = (update: Partial<UpdatableItemStateType>) =>
    updateItemState(initialItemState.key, update);

  const diff = useDiff(initialItemState);

  useEffect(() => {
    if (diff) {
      updateState({ ...diff });
    }
  }, [diff]);

  const { index, register } = useDescendant();

  useEffect(() => {
    updateItemState(initialItemState.key, { index });
    return () => updateItemState(initialItemState.key, { index: -1 });
  }, [index]);

  const isPrevItemsCompleted = useMemo(
    () =>
      index >= 0 &&
      Array(index)
        .fill(0)
        .map((_, idx) => idx)
        .every((idx) => itemIndexes[idx]?.status === 'complete'),
    [itemIndexes],
  );

  return {
    index,
    initialized: index !== -1,
    register,
    state,
    updateState,
    setOpenAccordionIndex,
    isPrevItemsCompleted,
    isExpanded: openAccordionIndex === index,
    rightSidebar,
  };
};

export type useLEAccordionItemReturn = ReturnType<typeof useLEAccordionItem>;
export type LEAccordionItemContextType = Omit<
  useLEAccordionItemReturn,
  'register' | 'rightSidebar'
>;

const LEAccordionItemContext = createContext<LEAccordionItemContextType>({
  index: -1,
  initialized: false,
  state: { index: -1, key: 'null', status: 'none', title: '' },
  updateState: () => null,
  setOpenAccordionIndex: () => null,
  isPrevItemsCompleted: false,
  isExpanded: false,
});

export const useLEAccordionItemContext = () => {
  const context = useContext(LEAccordionItemContext);
  if (!context) {
    throw new Error(
      `useLEAccordionItemContext returned \`undefined\`. Seems you forgot to use ${LEAccordionItem.name}`,
    );
  }

  return context;
};

export const LEAccordionItemProvider = ({
  value,
  children,
}: PropsWithChildren<{ value: LEAccordionItemContextType }>) => {
  return (
    <LEAccordionItemContext.Provider value={value}>{children}</LEAccordionItemContext.Provider>
  );
};
