import {
  Popover as ChakraPopover,
  PopoverProps as ChakraPopoverProps,
  PopoverArrow,
  PopoverBody,
  PopoverBodyProps,
  PopoverContent,
  PopoverTrigger,
  useBreakpointValue,
} from '@chakra-ui/react';
import React from 'react';

export interface PopoverProps extends Omit<ChakraPopoverProps, 'children'> {
  label: string | React.ReactNode;
  hasArrow?: boolean;
  bg?: PopoverBodyProps['bg'];
  children: React.ReactNode;
  popoverBodyProps?: PopoverBodyProps;
}

const Popover = ({
  label,
  hasArrow = true,
  bg = 'blackAlpha.900',
  popoverBodyProps,
  children,
  ...rest
}: PopoverProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <ChakraPopover trigger={isMobile ? 'click' : 'hover'} {...rest}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        zIndex={2000}
        outline="none"
        _focus={{ boxShadow: 'none' }}
        bg={bg}
        borderColor={bg}
      >
        {hasArrow && <PopoverArrow bg={bg} shadowColor={bg} />}
        <PopoverBody
          {...popoverBodyProps}
          fontSize={{ base: '12px', md: '13px' }}
          color={popoverBodyProps?.color || 'white'}
        >
          {label}
        </PopoverBody>
      </PopoverContent>
    </ChakraPopover>
  );
};

export default Popover;
