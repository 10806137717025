import { Link } from '@chakra-ui/react';
import { Link as RRLink } from 'react-router-dom';

import { ACCOUNT_LOGIN } from './urls';

export const INVALID_SIGNUP_MSG =
  'There is already an account tied to this email. Please login to your account or change the email address to create a new account.';

export const invalidSignupMsg = (
  <>
    {INVALID_SIGNUP_MSG}{' '}
    <Link as={RRLink} to={ACCOUNT_LOGIN} _hover={{ textDecor: 'underline' }}>
      Login now
    </Link>
  </>
);
