/* eslint-disable react/no-array-index-key */
import React from 'react';

import { InstructionSet } from '../../../gql/prs/types';
import Description from '../../Description';
import Label from '../../formComponents/Label';
import Instructions from './Instructions';
import { InstructionsContainer } from './PayoffStylings';
import PhoneInstructions from './PhoneInstructions';
import WebsiteInstructions from './WebsiteInstructions';

interface Props {
  phones: string[];
  phoneInstructions: InstructionSet[];
  webInstructions: InstructionSet[];
  appInstructions: InstructionSet[];
  website?: string;
}

const PayoffTemplate = ({
  phones,
  phoneInstructions,
  webInstructions,
  appInstructions,
  website,
}: Props) => {
  const hasPhones = phones?.length > 0;
  const hasApp = appInstructions?.length > 0;

  return (
    <InstructionsContainer>
      {hasApp && (
        <>
          <Label fontSize={{ md: '16px' }}>
            Get your payoff amount and good-through date using the mobile app.
          </Label>
          {appInstructions.map((appInstruction) => (
            <Instructions instructions={appInstruction.instructions} />
          ))}
        </>
      )}
      {website && hasPhones ? (
        <>
          <WebsiteInstructions website={website} webInstructions={webInstructions} />
          <PhoneInstructions phones={phones} hasBoth phoneInstructions={phoneInstructions} />
        </>
      ) : (
        <>
          {hasPhones && (
            <PhoneInstructions
              phones={phones}
              hasBoth={false}
              phoneInstructions={phoneInstructions}
            />
          )}
          {website && <WebsiteInstructions website={website} webInstructions={webInstructions} />}
        </>
      )}
      <Description mt={4} fontSize={{ md: '16px' }}>
        Once you've got them, press Next.
      </Description>
    </InstructionsContainer>
  );
};

export default PayoffTemplate;
