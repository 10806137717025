import { Box, Container, Flex, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';

import MobileNavFlow from '../../components/MobileNavFlow';
import Nav from '../../components/Nav/Nav';
import useRefSize from '../../hooks/useRefSize';

interface MainFlowProps {
  children: React.ReactNode;
}

const MainFlow: React.FC<MainFlowProps> = ({ children }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [sizeRef, size] = useRefSize();

  return (
    <Box bgColor="grayBackground" h="100%">
      <Box ref={sizeRef}>{isMobile ? <MobileNavFlow /> : <Nav inFlow />}</Box>
      <Container pt={4} maxW="container.xl" minHeight={`calc(100vh - ${size?.height ?? 0}px)`}>
        <Flex justifyContent="center" flexGrow={1}>
          {children}
        </Flex>
      </Container>
    </Box>
  );
};

export default MainFlow;
