import { Link } from '@chakra-ui/react';
import React from 'react';

import {
  InstructionsContainer,
  InstructionsDescription,
  InstructionsLabel,
} from './PayoffStylings';

interface Props {
  name: string;
  phone: string;
  website: string;
}

const YouAreAPioneer: React.FC<Props> = ({ name, phone, website }) => {
  return (
    <InstructionsContainer>
      <InstructionsLabel mb={5}>You're a pioneer!</InstructionsLabel>
      <InstructionsDescription mb={5}>
        We haven't run into enough people that have a lease with {name} to know exactly what the
        process is like yet.
      </InstructionsDescription>
      <InstructionsDescription mb={5}>
        You'll need to contact them at{' '}
        <Link color="royalBlue" href={`tel:${phone}`}>
          {phone}
        </Link>{' '}
        or visit their site{' '}
        <Link color="royalBlue" href={website} isExternal>
          here
        </Link>{' '}
        to access your payoff amount.
      </InstructionsDescription>
      <InstructionsDescription>
        <Link color="royalBlue" href="mailto:hello@leaseend.com">
          Let us know
        </Link>{' '}
        what the process is like, and we'll owe you one.
      </InstructionsDescription>
    </InstructionsContainer>
  );
};

export default YouAreAPioneer;
