import { Box, Container, Flex } from '@chakra-ui/react';
import React from 'react';

import Footer from '../../components/Footer';
import Nav from '../../components/Nav/Nav';

interface MainProps {
  children: React.ReactNode;
}

const Main: React.FC<MainProps> = ({ children }) => {
  return (
    <Box minH="100vh">
      <Nav />
      <Container maxW="container.xl" minH="100vh" p={{ base: '0px', md: 'initial' }}>
        <Flex justifyContent="center" flexGrow={1}>
          <Container>{children}</Container>
        </Flex>
        <Footer />
      </Container>
    </Box>
  );
};

export default Main;
