import React from 'react';

import LEAccordionHeader from '../../../components/LEAccordion/LEAccordionHeader';
import Subtitle from '../../../components/Subtitle';
import { capitalizeFirstLetter } from '../../../utils/text/text';

interface Props {
  make: string;
  model: string;
}

const Header: React.FC<Props> = ({ make, model }) => {
  return (
    <>
      <LEAccordionHeader>Let's Talk Lease</LEAccordionHeader>

      <Subtitle textAlign="center" w={{ base: '100%', lg: '75%' }} mb="10px">
        We need the Payoff Amount for your {capitalizeFirstLetter(make?.toLowerCase())}{' '}
        {capitalizeFirstLetter(model?.toLocaleLowerCase())}... what the heck is that?!
      </Subtitle>
    </>
  );
};

export default Header;
