import { Box, Flex, Image, Input, Text, useBreakpointValue, useToast } from '@chakra-ui/react';
import { SetStateAction } from 'jotai';
import { Dispatch, ReactNode, useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Dropzone, { FileRejection } from 'react-dropzone';

import { DragAndDrop } from '../../assets/Images';
import { TemporaryInfoQuery, useDeleteComFlowFileMutation } from '../../gql/generated/graphql';
import { logger } from '../../services/sentry';
import { FlowFile, INPUT_FILE_TYPES, MAX_FILE_SIZE_MB } from '../../utils/media';
import { createErrorToast } from '../../utils/toast';
import { DropImage } from '../DragAndDrop';
import FileList from './FileList';

const ALLOWED_FILE_TYPES = [...INPUT_FILE_TYPES, 'application/pdf'];

interface Props {
  filesState: [FlowFile[], Dispatch<SetStateAction<FlowFile[]>>];
  tempInfo: TemporaryInfoQuery['temporaryInfo'];
  updateTempInfo: (files: FlowFile[]) => void;
  copy: { base: ReactNode; md: ReactNode };
}

const FileManager = ({ copy, filesState, tempInfo, updateTempInfo }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const toast = useToast();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const [deleteS3Doc] = useDeleteComFlowFileMutation();

  const [files, setFiles] = filesState;
  const isMobile = useBreakpointValue({ base: true, lg: false });

  useEffect(() => {
    setFiles(tempInfo?.data?.uploaded_files ?? []);
  }, [tempInfo]);

  const removeFile = async (index: number) => {
    setLoading(true);
    try {
      const updatedFiles = files.filter((_, i) => i !== index);
      const fileToRemove = files[index];

      // Don't upload state files to tempInfos when removing a file
      if (fileToRemove.isUploadedToS3) {
        const tempInfoFiles = updatedFiles.filter((file) => file?.isUploadedToS3);
        updateTempInfo(tempInfoFiles);
        await deleteS3Doc({
          variables: {
            key: `${tempInfo?.id}/${fileToRemove.mediaType}/${fileToRemove.name}`,
          },
        });
      }
      setError('');
      setFiles(updatedFiles);
    } catch (e) {
      toast(createErrorToast({ title: 'Failed to remove file.' }));
      logger.error('FileManager.tsx:handleFileRemove', 'Failed to remove file', files[index], e);
    }
    setLoading(false);
  };

  const handleFileAdd = async (filesToAdd: File[]) => {
    setError('');
    if (files.some((file) => file.name === filesToAdd[0].name)) {
      setError(`File '${filesToAdd[0].name}' already exists.`);
      return;
    }
    setFiles([...files, filesToAdd[0]]);
  };

  const handleRejection = (fileRejections: FileRejection[]) => {
    if (fileRejections && fileRejections.length > 0) {
      const errorCode = fileRejections[0].errors[0].code;
      if (errorCode === 'file-invalid-type') {
        setError(
          `Files must be a valid format (${ALLOWED_FILE_TYPES.map((filetype) =>
            filetype.split('/')[1].toUpperCase(),
          ).join(', ')}).`,
        );
      } else if (errorCode === 'file-too-large') {
        setError(`Files cannot exceed ${MAX_FILE_SIZE_MB}MB.`);
      } else {
        setError('There was an error uploading your file.');
      }
    }
  };

  return (
    <Flex
      direction="column"
      textAlign="center"
      alignItems="center"
      p="10px"
      mt={1}
      maxWidth={{ base: '75vw', md: '500px' }}
      w="80%"
    >
      <FileList files={files} removeFile={(index) => removeFile(index)} />
      {files.length < 5 && (
        <Box w="100%">
          <DndProvider backend={HTML5Backend}>
            <DropImage canDrop set={() => undefined}>
              {() => (
                <Dropzone
                  accept={ALLOWED_FILE_TYPES}
                  maxFiles={1}
                  maxSize={MAX_FILE_SIZE_MB * 1000000}
                  multiple={false}
                  noClick
                  noKeyboard
                  onDropAccepted={(acceptedFiles) => handleFileAdd(acceptedFiles)}
                  onDropRejected={(fileRejections) => handleRejection(fileRejections)}
                >
                  {({ getRootProps, getInputProps }) => {
                    return (
                      <>
                        <Flex
                          {...getRootProps()}
                          align="center"
                          border="2px dashed"
                          borderColor="leaseEndYellow"
                          borderRadius="10px"
                          bg="lotion"
                          cursor={loading ? 'not-allowed' : 'pointer'}
                          justify="center"
                          mt={6}
                          onClick={() => inputRef.current?.click()}
                        >
                          <Flex h="125px" w="250px" p={2} align="center">
                            <Text textColor="royalBlue" mx={{ base: 2, md: 'unset' }}>
                              {isMobile ? copy.base : copy.md}
                            </Text>
                            <Input {...getInputProps()} size="" display="none" ref={inputRef} />
                          </Flex>
                        </Flex>
                        {!isMobile && (
                          <Image
                            src={DragAndDrop}
                            alt="Drag and Drop"
                            position="absolute"
                            w="100px"
                            top="195px"
                            right="30px"
                          />
                        )}
                      </>
                    );
                  }}
                </Dropzone>
              )}
            </DropImage>
          </DndProvider>
        </Box>
      )}
      {error && (
        <Text fontSize="12px" color="leaseEndRed">
          {error}
        </Text>
      )}
    </Flex>
  );
};

export default FileManager;
