import { Box, Button, Divider, Flex, Link, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { PAYMENTS_PHONE } from '../../constants/contact';
import { formatMoney } from '../../utils/helpers';
import { DOWN_PAYMENTS_STEPS, PaymentData, PlaidData } from '../../utils/payments';
import { snakeCaseToHuman } from '../../utils/strings';
import { PaymentFormValues } from './PaymentForm';

interface FailProps {
  setStep: (step: string) => void;
  paymentData: PaymentData;
  plaidData?: PlaidData;
  errorMessage?: string;
}

const FailedPayment = ({ paymentData, plaidData, setStep, errorMessage }: FailProps) => {
  const { values, resetForm, submitForm, isSubmitting, submitCount } =
    useFormikContext<PaymentFormValues>();

  const isBankPayment = !!plaidData;
  const isCardPayment = !isBankPayment;
  const account = plaidData?.accounts?.[0];

  const handleReset = () => {
    resetForm();
    setStep(DOWN_PAYMENTS_STEPS.PAYMENT_INFO);
  };

  return (
    <Flex flexDir="column">
      <Box
        bgColor="paleRedReplace"
        borderWidth="1px"
        borderColor="gainsboro"
        borderRadius="md"
        textColor="red"
        fontWeight={500}
        p={6}
        mb={6}
      >
        {!isSubmitting && submitCount > 1 && (
          <>
            We couldn't complete your transaction after a second attempt. Please consider using
            another {isBankPayment ? 'account' : 'card'} or reaching out to us at{' '}
            <Link href={`tel:+1-${PAYMENTS_PHONE}`}>{PAYMENTS_PHONE}</Link> for support.
          </>
        )}
        {submitCount <= 1 && isBankPayment && (
          <>
            We were unable to process your transaction. Please try again or contact us at{' '}
            <Link href={`tel:+1-${PAYMENTS_PHONE}`}>{PAYMENTS_PHONE}</Link>.
          </>
        )}
        {submitCount <= 1 &&
          isCardPayment &&
          (errorMessage || (
            <>
              We were unable to process your transaction. Please try again or contact us at{' '}
              <Link href={`tel:+1-${PAYMENTS_PHONE}`}>{PAYMENTS_PHONE}</Link>.
            </>
          ))}
      </Box>
      <Text
        hidden={!plaidData?.institution?.name || !paymentData.cardBrand}
        fontSize="22px"
        fontWeight="bold"
        mb={2}
      >
        {isBankPayment ? plaidData?.institution?.name : `${paymentData.cardBrand} Card`}
      </Text>
      {isBankPayment && account && (
        <>
          <Text fontSize="16px" mb={1}>
            Account: ******{account.id.slice(-4).toUpperCase()}
          </Text>
          <Text fontSize="16px" mb={3} textTransform="capitalize">
            {account.subtype}
          </Text>
        </>
      )}
      {isCardPayment && (
        <>
          <Text hidden={!paymentData.cardBrand} fontSize="22px" fontWeight="bold" mb={2}>
            {`${paymentData.cardBrand} Card`}
          </Text>
          <Text hidden={!paymentData.maskedNumber} fontSize="16px" mb={1}>
            {paymentData.maskedNumber}
          </Text>
          <Text
            hidden={!paymentData.cardType || paymentData.cardType.toLowerCase() === 'unknown'}
            fontSize="16px"
            mb={3}
            textTransform="capitalize"
          >
            {snakeCaseToHuman(paymentData.cardType)}
          </Text>
        </>
      )}
      <Button
        w="max-content"
        justifyContent="flex-start"
        variant="link"
        mb={8}
        onClick={handleReset}
      >
        Use a different {isBankPayment ? 'account' : 'card'}
      </Button>
      <Divider borderColor="grayDarkBackground" mb={8} />
      <Text fontSize="18px" fontWeight="bold" mb={8}>
        Amount to be processed: {formatMoney(values.amount)}
      </Text>
      <Divider borderColor="grayDarkBackground" mb={8} />
      <Button
        mx="auto"
        onClick={submitForm}
        isLoading={isSubmitting}
        hidden={!isSubmitting && submitCount > 1}
      >
        TRY AGAIN
      </Button>
    </Flex>
  );
};

export default FailedPayment;
