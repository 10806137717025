/* eslint-disable no-case-declarations */
import { TtDocumentName } from '../../gql/generated/graphql';
import { TtValidationName } from '../../gql/tatertitleGql';
import { DocumentType } from './components/ThingsWeNeed/utils';

const MIN_EXTRACTED_FIELD_CONFIDENCE = 90;

enum Actions {
  SET_TITLE_AND_REGISTRATION_OPTIONS = 'SET_TITLE_AND_REGISTRATION_OPTIONS',
  SET_ALLOW_PLATE_TRANSFER = 'SET_ALLOW_PLATE_TRANSFER',
  SET_REQUIRED_DOCS = 'SET_REQUIRED_DOCS',
  SET_REQUIRED_DOCS_VALIDATIONS = 'SET_REQUIRED_DOCS_VALIDATIONS',
  SET_VALIDATIONS = 'SET_VALIDATIONS',
  SET_TATER_LOADED = 'SET_TATER_LOADED',
  SET_VALID_DRIVERS_LICENSE_ADDRESSES = 'SET_VALID_DRIVERS_LICENSE_ADDRESSES',
  SET_VALID_DRIVERS_LICENSE_NAME = 'SET_VALID_DRIVERS_LICENSE_NAME',
  SET_VALID_DRIVERS_LICENSE_EXPIRATION = 'SET_VALID_DRIVERS_LICENSE_EXPIRATION',
  SET_VALID_PROOF_OF_INSURANCE = 'SET_VALID_PROOF_OF_INSURANCE',
}

export enum TITLE_AND_REGISTRATION_OPTIONS {
  TITLE_AND_REGISTRATION_ONLY = 'TITLE_AND_REGISTRATION_ONLY',
  BOTH = 'BOTH',
  TITLE_ONLY = 'TITLE_ONLY',
}

type AccordionIndexType = keyof typeof ACCORDION_INDEX_TO_ID_MAPPING;

type BooleanPayload = {
  type:
    | Actions.SET_ALLOW_PLATE_TRANSFER
    | Actions.SET_VALID_DRIVERS_LICENSE_ADDRESSES
    | Actions.SET_VALID_DRIVERS_LICENSE_NAME
    | Actions.SET_VALID_DRIVERS_LICENSE_EXPIRATION
    | Actions.SET_VALID_PROOF_OF_INSURANCE;
  payload: boolean;
};

type TtDocumentNameEnumTypePayload = {
  type: Actions.SET_REQUIRED_DOCS;
  payload: DocumentType[];
};

type TtValidationsPayload = {
  type: Actions.SET_VALIDATIONS;
  payload: TtValidationName[];
};

type TtRequiredDocumentsValidationsPayload = {
  type: Actions.SET_REQUIRED_DOCS_VALIDATIONS;
  payload: Record<string, [string]>;
};

type TtLoadedPayload = {
  type: Actions.SET_TATER_LOADED;
  payload: boolean;
};

type TitleRegistrationOption = {
  type: Actions.SET_TITLE_AND_REGISTRATION_OPTIONS;
  payload: TITLE_AND_REGISTRATION_OPTIONS;
};

type AccordionAction =
  | BooleanPayload
  | TtDocumentNameEnumTypePayload
  | TtValidationsPayload
  | TtRequiredDocumentsValidationsPayload
  | TtLoadedPayload
  | TitleRegistrationOption;

type AccordionState = {
  titleAndRegistrationOptions: TITLE_AND_REGISTRATION_OPTIONS;
  allowPlateTransfer: boolean;
  requiredDocs: DocumentType[];
  requiredDocsValidations: Record<string, [string]>;
  validations: TtValidationName[];
  taterLoaded: boolean;
  validDriversLicenseAddress: boolean;
  validDriversLicenseName: boolean;
  validDriversLicenseExpiration: boolean;
  validProofOfInsurance: boolean;
};

const ACCORDION_IDS = {
  TITLE_AND_REGISTRATION: 'TITLE_AND_REGISTRATION',
  THINGS_WE_NEED_NOW: 'THINGS_WE_NEED_NOW',
  VALIDATION_REQUIREMENTS: 'VALIDATION_REQUIREMENTS',
  THINGS_WE_NEED_LATER: 'THINGS_WE_NEED_LATER',
  ALL_DONE: 'ALL_DONE',
} as const;

const ACCORDION_INDEX_TO_ID_MAPPING = Object.freeze({
  0: ACCORDION_IDS.TITLE_AND_REGISTRATION,
  1: ACCORDION_IDS.THINGS_WE_NEED_NOW,
  2: ACCORDION_IDS.VALIDATION_REQUIREMENTS,
  3: ACCORDION_IDS.THINGS_WE_NEED_LATER,
  4: ACCORDION_IDS.ALL_DONE,
});

const getIndexByAccordionId = (value: AccordionIdType) =>
  Number(
    Object.keys(ACCORDION_INDEX_TO_ID_MAPPING).find(
      (key) => ACCORDION_INDEX_TO_ID_MAPPING[Number(key) as AccordionIndexType] === value,
    ),
  ) as AccordionIndexType;

const initialState: AccordionState = {
  titleAndRegistrationOptions: TITLE_AND_REGISTRATION_OPTIONS.TITLE_AND_REGISTRATION_ONLY,
  allowPlateTransfer: false,
  requiredDocs: [],
  requiredDocsValidations: {},
  validations: [],
  taterLoaded: false,
  validDriversLicenseAddress: true,
  validDriversLicenseName: true,
  validDriversLicenseExpiration: true,
  validProofOfInsurance: true,
};

const reducer = (state: AccordionState, action: AccordionAction): AccordionState => {
  const { type, payload } = action;

  switch (type) {
    case Actions.SET_TITLE_AND_REGISTRATION_OPTIONS:
      return { ...state, titleAndRegistrationOptions: payload };
    case Actions.SET_ALLOW_PLATE_TRANSFER:
      return { ...state, allowPlateTransfer: payload };
    case Actions.SET_REQUIRED_DOCS:
      return { ...state, requiredDocs: payload };
    case Actions.SET_REQUIRED_DOCS_VALIDATIONS:
      return { ...state, requiredDocsValidations: payload };
    case Actions.SET_VALIDATIONS:
      return { ...state, validations: payload };
    case Actions.SET_TATER_LOADED:
      return { ...state, taterLoaded: payload };
    case Actions.SET_VALID_DRIVERS_LICENSE_ADDRESSES:
      return { ...state, validDriversLicenseAddress: payload };
    case Actions.SET_VALID_DRIVERS_LICENSE_NAME:
      return { ...state, validDriversLicenseName: payload };
    case Actions.SET_VALID_DRIVERS_LICENSE_EXPIRATION:
      return { ...state, validDriversLicenseExpiration: payload };
    case Actions.SET_VALID_PROOF_OF_INSURANCE:
      return { ...state, validProofOfInsurance: payload };
    default:
      return state;
  }
};

const getRequiredDocTypes = (
  titleOnly: boolean,
  titleDocumentsNeeded: TtDocumentName[],
  registrationDocumentsNeeded: TtDocumentName[],
) => {
  const documents = titleOnly
    ? titleDocumentsNeeded
    : titleDocumentsNeeded.concat(registrationDocumentsNeeded);

  const normalizedDocuments = documents.map((doc) => doc.replaceAll(' ', ''));

  return normalizedDocuments as TtDocumentName[];
};

type AccordionIdType = keyof typeof ACCORDION_IDS;

export {
  initialState,
  reducer,
  Actions,
  ACCORDION_IDS,
  ACCORDION_INDEX_TO_ID_MAPPING,
  getIndexByAccordionId,
  getRequiredDocTypes,
  MIN_EXTRACTED_FIELD_CONFIDENCE,
};

export type { AccordionAction, AccordionState, AccordionIdType, AccordionIndexType };
