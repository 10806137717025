import { Box, Text } from '@chakra-ui/react';

const ServiceDownBanner = ({ serviceDownBannerMessage }: { serviceDownBannerMessage: string }) => (
  <Box bg="red.400" p={3} textAlign="center">
    <Text color="white" fontWeight="bold">
      {serviceDownBannerMessage}
    </Text>
  </Box>
);

export default ServiceDownBanner;
