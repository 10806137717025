import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';

import { NumberInput } from './formComponents';

interface MonthsAndYearsProps extends FlexProps {
  yearsName: string;
  monthsName: string;
  setShow?: Dispatch<SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values?: any;
}

const MonthsAndYears = ({
  yearsName,
  monthsName,
  setShow,
  values,
  ...rest
}: MonthsAndYearsProps) => {
  const handleBlur = () => {
    if (values[yearsName] < 2 && setShow) {
      setShow(true);
    } else if (values[yearsName] >= 2 && setShow) {
      setShow(false);
    }
  };
  return (
    <Flex alignItems="flex-start" {...rest}>
      <Flex mr={4} alignItems="baseline">
        <NumberInput
          name={yearsName}
          maxLength={3}
          isWhole
          _input={{ inputMode: 'numeric', autoComplete: 'false' }}
          _container={{ maxW: '60px' }}
          errorMessageWidth="200%"
          onBlur={handleBlur}
        />
        <Text color="taupeGray" ml={2} fontSize="12px">
          YEAR(S)
        </Text>
      </Flex>
      <Flex alignItems="baseline">
        <NumberInput
          name={monthsName}
          max={11}
          maxLength={2}
          isWhole
          _input={{ inputMode: 'numeric', autoComplete: 'false' }}
          _container={{ maxW: '60px' }}
          errorMessageWidth="200%"
        />
        <Text color="taupeGray" ml={2} fontSize="12px">
          MONTH(S)
        </Text>
      </Flex>
    </Flex>
  );
};

export default MonthsAndYears;
