import { Button, Container, Flex, Icon, IconButton, Text, useToast } from '@chakra-ui/react';
import { FaEye } from '@react-icons/all-files/fa/FaEye';
import { FaEyeSlash } from '@react-icons/all-files/fa/FaEyeSlash';
import { Form, Formik, FormikHelpers } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import PasswordStrength from '../../components/PasswordStrength';
import SimpleFooter from '../../components/SimpleFooter';
import TextWithTooltip from '../../components/TextWithTooltip';
import Input from '../../components/formComponents/Input';
import { ACCOUNT_LOGIN } from '../../constants/urls';
import { useResetPasswordAndSendEmailMutation } from '../../gql/generated/graphql';
import { parseTokenFromHash } from '../../services/auth0';
import { logger } from '../../services/sentry';
import { createSuccessToast } from '../../utils/toast';
import { passwordConfirmValidation, passwordValidation } from '../../utils/validation/auth';
import Title from './components/Title';
import { StrongPasswordTooltipExplanation } from './utils';

const validationSchema = Yup.object({
  password: passwordValidation,
  password_confirm: passwordConfirmValidation(),
});

interface Values {
  password: string;
  password_confirm: string;
}

const ResetPassword = () => {
  const history = useHistory();
  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [resetPasswordAndSendEmail] = useResetPasswordAndSendEmailMutation({
    context: {
      isErrorHandled: true,
    },
  });

  const alreadyRan = useRef(false);
  useEffect(() => {
    if (!window.location.hash || alreadyRan.current) {
      return;
    }

    alreadyRan.current = true;

    parseTokenFromHash({
      hash: window.location.hash,
      callback: (err, authResult) => {
        if (err) {
          logger.error('ResetPassword.tsx', '', err, null);
          return;
        }

        setAccessToken(authResult?.accessToken ?? '');
      },
    });
  }, [window.location.hash]);

  const handleSubmit = async (values: Values, formikHelpers: FormikHelpers<Values>) => {
    try {
      await resetPasswordAndSendEmail({
        variables: {
          access_token: accessToken,
          password: values.password,
        },
      });

      toast(
        createSuccessToast({
          title: 'Password Changed Successfully',
        }),
      );

      history.push(ACCOUNT_LOGIN);
    } catch (e) {
      let errorMsg = (e as Error).message;
      if (errorMsg.includes('PasswordHistoryError')) {
        errorMsg = 'You cannot use your two most recent passwords.';
      }
      formikHelpers.setErrors({ password: errorMsg });
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <Container pt={10}>
      <Title>Choose A New Password</Title>
      <Formik
        enableReinitialize
        initialValues={{
          password: '',
          password_confirm: '',
        }}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting }) => {
          return (
            <Form>
              <Flex
                maxW="400px"
                mx="auto"
                flexDirection="column"
                alignItems="center"
                bg="white"
                p={8}
                rounded="lg"
              >
                <Text color="leaseEndBlue" mb={1}>
                  Create a new password
                </Text>
                <TextWithTooltip label={StrongPasswordTooltipExplanation}>
                  Password requirements
                </TextWithTooltip>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  label="New Password"
                  name="password"
                  placeholder="Password"
                  _container={{ my: 4 }}
                  icon={
                    <IconButton
                      variant="unstyled"
                      onClick={() => setShowPassword(!showPassword)}
                      aria-label="Hide/Show password"
                      icon={<Icon as={showPassword ? FaEye : FaEyeSlash} mt={1} />}
                    />
                  }
                />
                {values.password && (
                  <PasswordStrength h="auto" w="100%" password={values.password} />
                )}
                <Input
                  type={showConfirmPassword ? 'text' : 'password'}
                  label="Confirm Password"
                  name="password_confirm"
                  placeholder="Password"
                  _container={{ mb: 10 }}
                  icon={
                    <IconButton
                      variant="unstyled"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      aria-label="Hide/Show password confirm"
                      icon={<Icon as={showConfirmPassword ? FaEye : FaEyeSlash} mt={1} />}
                    />
                  }
                />
                <Button w="100%" type="submit" isLoading={isSubmitting}>
                  SUBMIT
                </Button>
              </Flex>
              <SimpleFooter />
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default ResetPassword;
