import { Box, Link, Text } from '@chakra-ui/react';

import { Maybe } from '../../../gql/generated/graphql';
import { useSearchPhoneNumbers } from '../../../hooks';

const TeamMember = ({ type, name }: { type: string; name: Maybe<string> }) => {
  const phoneNumber = useSearchPhoneNumbers();
  return (
    <Box>
      <Text fontWeight="bold">{type}</Text>
      <Text>{name}</Text>
      <Link href={`tel: ${phoneNumber}`} fontSize="16px">
        {phoneNumber}
      </Link>
    </Box>
  );
};

export default TeamMember;
