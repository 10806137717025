import { number, object, string } from 'yup';

import { customerPersonalInfoValidation } from './customerInformation';

export const MAX_PAYMENT_AMOUNT = 50_000;

export const paymentValidation = object({
  ...customerPersonalInfoValidation,
  amount: number()
    .typeError('Field is required')
    .min(1, 'Must be greater than or equal to $1')
    .max(MAX_PAYMENT_AMOUNT, 'Must be less than or equal to $50,000')
    .required('Amount is required'),
  selected_payment_method: string().required('Payment method is required'),
});
