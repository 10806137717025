import { Divider, Flex, FlexProps, Icon, IconButton, Text, useDisclosure } from '@chakra-ui/react';
import { HiUser, HiUsers } from 'react-icons/hi';
import { IoIosClose } from 'react-icons/io';
import { Link as RRLink, useLocation } from 'react-router-dom';

import { COBUYER_LOAN_APPLICATION, LOAN_APPLICATION } from '../../../constants/urls';
import { useTempInfo } from '../../../hooks';
import { hasCobuyer } from '../../../utils/tempInfo';
import RemoveCobuyerModal from './RemoveCobuyerModal';

const BuyerCoBuyerSwitch = ({ ...props }: FlexProps) => {
  const { pathname } = useLocation();
  const tempInfo = useTempInfo();
  const tempInfoData = tempInfo.info?.data;
  const isCobuyerUrl = pathname === COBUYER_LOAN_APPLICATION;

  const { isOpen, onClose, onOpen } = useDisclosure();

  if (!isCobuyerUrl && (!tempInfoData || !hasCobuyer(tempInfoData))) {
    return null;
  }

  return (
    <>
      <Flex {...props}>
        <Flex
          as={RRLink}
          alignItems="center"
          color={!isCobuyerUrl ? 'white' : 'taupeGray'}
          _hover={{
            color: !isCobuyerUrl ? 'white' : 'taupeGray',
            opacity: !isCobuyerUrl ? 1 : 0.9,
            cursor: 'pointer',
          }}
          bg={!isCobuyerUrl ? 'oceanBoatBlue' : 'grayMediumBackground'}
          p={4}
          rounded="md"
          mr={1}
          to={LOAN_APPLICATION}
        >
          <Icon boxSize={5} as={HiUser} mr={1} />
          <Text fontWeight="bold">You</Text>
        </Flex>
        <Flex
          as={RRLink}
          alignItems="center"
          color={isCobuyerUrl ? 'white' : 'taupeGray'}
          _hover={{
            color: isCobuyerUrl ? 'white' : 'taupeGray',
            opacity: isCobuyerUrl ? 1 : 0.9,
            cursor: 'pointer',
          }}
          bg={isCobuyerUrl ? 'oceanBoatBlue' : 'grayMediumBackground'}
          p={4}
          pr={8}
          rounded="md"
          to={COBUYER_LOAN_APPLICATION}
          position="relative"
        >
          <Icon boxSize={5} as={HiUsers} mr={1} />
          <Text fontWeight="bold">Co-Buyer</Text>
          <Divider
            orientation="vertical"
            mx={1}
            position="absolute"
            top={0}
            right="1.25rem"
            height="48px"
            borderColor="white"
            opacity={0.9}
          />
          <IconButton
            zIndex={20}
            aria-label="Remove Co-Buyer"
            _hover={{ opacity: 0.5 }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onOpen();
            }}
            icon={<Icon boxSize={5} as={IoIosClose} />}
            variant="unstyled"
            position="absolute"
            right="-0.4rem"
            top="0.7rem"
            my="auto"
          />
        </Flex>
      </Flex>
      <RemoveCobuyerModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default BuyerCoBuyerSwitch;
