import { Box, Container, Flex, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import Title from '../../components/Title';
import TitleContainer from '../../components/TitleContainer';
import { Input, Select } from '../../components/formComponents';
import TextBox from '../../components/formComponents/TextBox';
import PrimaryButton from '../../components/ui/buttons/PrimaryButton';
import { useCreateFeedbackMutation } from '../../gql/generated/graphql';
import Stars from './Stars';
import { FeedbackFormFields, feedbackVSchema, recommendOptions } from './utils';

const Feedback = () => {
  const [submitted, setSubmitted] = useState(false);
  const [createFeedback] = useCreateFeedbackMutation();
  const { search } = useLocation();

  const feedbackInitials = {
    name: '',
    email: search ? search.split('?email=')[1] : '',
    rating: 0,
    improvements: '',
    recommend: null,
    recommend_reason: '',
    additional_details: '',
  };

  const handleSubmit = async (vals: FeedbackFormFields) => {
    await createFeedback({
      variables: {
        name: vals.name,
        email: vals.email,
        rating: vals.rating,
        improvements: vals.improvements,
        recommend: vals.recommend ?? false,
        recommend_reason: vals.recommend_reason,
        additional_details: vals.additional_details,
      },
    });

    setSubmitted(true);
  };

  if (submitted) {
    return (
      <Container>
        <Box w="100%" pt="75px" h="75vh">
          <Text m="0 auto" w="95%" textAlign="center" fontSize="28px">
            Thank you for sharing your thoughts! We value all feedback and use it to continually
            enhance our customer experience.
          </Text>
        </Box>
      </Container>
    );
  }

  return (
    <Formik
      validationSchema={feedbackVSchema}
      initialValues={feedbackInitials}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ setFieldValue, isSubmitting, errors, values }) => (
        <Form>
          <Container>
            <Flex textAlign="left" mb="80px" flexDir="column">
              <TitleContainer>
                <Title>Customer Feedback</Title>
              </TitleContainer>
              <Flex
                textAlign="left"
                gap="15px"
                flexDir="column"
                border="1px solid"
                borderColor="grayBackground"
                width={{ base: '95%', md: '75%', xl: '650px' }}
                m="0 auto"
                p={{ base: '24px 18px', md: '18px 36px' }}
                borderRadius="15px"
                boxShadow="md"
                fontSize="16px"
                bgColor="white"
              >
                <Input
                  name="name"
                  value={values.name}
                  placeholder="Name"
                  _input={{
                    maxLength: 60,
                  }}
                />
                <Input
                  name="email"
                  value={values.email}
                  placeholder="Email"
                  _input={{
                    maxLength: 60,
                  }}
                />
                <Stars changeRating={setFieldValue} />
                {errors.rating && (
                  <Text color="leaseEndRed" fontSize="12.25px" ml="5px">
                    {errors.rating}
                  </Text>
                )}
                <TextBox
                  name="improvements"
                  placeholder="Max 300 characters"
                  title="What could we have done better to improve your experience?"
                />
                <Flex flexDir="column" gap="8px">
                  <Text>
                    Would you recommend our service to a friend or family member who has a lease?
                  </Text>
                  <Select name="recommend" options={recommendOptions} />
                </Flex>
                <TextBox
                  name="recommend_reason"
                  placeholder="Why or why not? (max 300 characters)"
                />
                <TextBox
                  name="additional_details"
                  placeholder="Especially regarding your coverage options like GAP insurance and Vehicle Service Contracts (VSC)? Were there any additional details or explanations that would have been helpful?"
                  title="How well-informed did you feel throughout each step of the lease end process?"
                />
                <PrimaryButton type="submit" isLoading={isSubmitting} />
              </Flex>
            </Flex>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default Feedback;
