import { CardProps, Divider, Flex, Stack, Text } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import React from 'react';
import { MdContactPhone } from 'react-icons/md';

import { WebCloserName } from '../../../constants/misc';
import { useDealAdvisorsQuery } from '../../../gql/generated/graphql';
import { NEW_DEAL_VALUE, dashboardDealInfoAtom } from '../Dashboard';
import DashboardCard from './DashboardCard';
import TeamMember from './TeamMember';

const YourAdvisorTeamCard = ({ ...props }: CardProps) => {
  const dealId = useAtomValue(dashboardDealInfoAtom);
  const { data: dealAdvisors } = useDealAdvisorsQuery({
    variables: {
      dealId: dealId?.id === NEW_DEAL_VALUE.id ? undefined : dealId?.id,
    },
  });

  const closerName = dealAdvisors?.dealAdvisors?.closer?.name;
  const fundingClerkName = dealAdvisors?.dealAdvisors?.funding_clerk?.name;
  const titleClerkName = dealAdvisors?.dealAdvisors?.title_clerk?.name;

  const hasCloser = closerName && closerName !== WebCloserName;
  const hasFundingClerk = fundingClerkName && fundingClerkName !== WebCloserName;
  const hasTitleClerk = titleClerkName && titleClerkName !== WebCloserName;

  const hasAdvisors = hasCloser || hasFundingClerk || hasTitleClerk;

  if (!hasAdvisors) {
    return null;
  }

  return (
    <DashboardCard title="Your Advisor Team" icon={MdContactPhone} my="20px" {...props}>
      <Flex direction="column">
        <Text textAlign="left">
          <b>Questions?</b> Your Lease End Success Team is ready to help.
        </Text>
        <Divider borderColor="grayDarkBackground" my="5px" />
        <Stack
          direction={{ base: 'column', md: 'row', lg: 'column' }}
          gap={{ base: '10px', md: '40px', lg: '10px' }}
          mt="15px"
          textAlign={{ base: 'center', md: 'left' }}
        >
          {hasCloser && <TeamMember type="Financial Specialist" name={closerName} />}
          {hasFundingClerk && <TeamMember type="Funding Advisor" name={fundingClerkName} />}
          {hasTitleClerk && <TeamMember type="Title Advisor" name={titleClerkName} />}
        </Stack>
      </Flex>
    </DashboardCard>
  );
};

export default YourAdvisorTeamCard;
