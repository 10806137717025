import { Flex, IconButton, Text } from '@chakra-ui/react';
import { MdClose } from 'react-icons/md';

import useWindowSize from '../../hooks/useWindowSize';
import { FlowFile } from '../../utils/media';

interface FileProps {
  filename: string;
  removeFile: () => void;
}
const File = ({ filename, removeFile }: FileProps) => {
  return (
    <Flex
      align="center"
      border="1px solid"
      borderRadius="5px"
      color="charcoal"
      flexDirection={{ base: 'row', md: 'unset' }}
      h="25px"
      mb={{ base: 1, md: 0 }}
      minWidth="195px"
      px={2}
      _hover={{ color: 'leaseEndRed', background: 'grayImageDarkBackground' }}
    >
      <Text fontSize="16px" flex="1" textAlign="center">
        {filename}
      </Text>
      <IconButton
        as={MdClose}
        aria-label="Remove File"
        color="inherit"
        cursor="pointer"
        onClick={removeFile}
        size="xxs"
        variant="ghost"
        _hover={{ color: 'leaseEndRed', background: 'grayImageDarkBackground' }}
      />
    </Flex>
  );
};

interface FileListProps {
  files: FlowFile[];
  removeFile: (index: number) => void;
}
const FileList = ({ files, removeFile }: FileListProps) => {
  const { windowWidth } = useWindowSize();
  if (!files.length) return null;

  const stackFiles = windowWidth < 600;
  const narrowStack = windowWidth < 400;

  const sIndex = stackFiles ? (narrowStack ? 12 : 20) : 13;
  return (
    <Flex
      color="grayBlueish"
      display="grid"
      gap={1}
      gridTemplateColumns={files.length < 2 || stackFiles ? 'unset' : 'repeat(2, 1fr)'}
      mb={2}
    >
      {files.map((file, index) => {
        let displayName = file.name;
        const filenameLength = displayName.split('.')[0].length; // length of filename excluding extension

        if (filenameLength > sIndex + 1) {
          const filename = file.name.slice(0, sIndex);
          const ext = file.name.split('.').pop();
          displayName = `${filename}...${ext}`;
        }

        return <File key={file.name} removeFile={() => removeFile(index)} filename={displayName} />;
      })}
    </Flex>
  );
};

export default FileList;
