import { Box, Divider, SimpleGrid, Skeleton, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { Customer, Deal, useUserDealsLazyQuery } from '../../gql/generated/graphql';
import { sameEmail } from '../../utils/customer';
import Modal from '../Modal';
import Label from '../formComponents/Label';
import ChangePassword from './ChangePassword';

export const PROFILE = 'profile';
export const PASSWORD = 'password';

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
  customer?: Customer;
}

const ProfileModal: React.FC<Props> = ({ open, setOpen, customer }) => {
  const [deals, setDeals] = useState<Deal[]>([]);
  const [getUserDeals, { loading: dealsLoading }] = useUserDealsLazyQuery({
    nextFetchPolicy: 'network-only',
    onCompleted: (data) => {
      setDeals(data?.userDeals as Deal[]);
    },
  });

  const sortedDeals = [...deals].sort((a, b) => (Number(a?.id) ?? 0) - (Number(b?.id) ?? 0));
  const lastDeals = sortedDeals?.slice(-2) ?? [];

  const shareEmail =
    lastDeals.length > 1 &&
    lastDeals[0]?.id === lastDeals[1]?.id &&
    (lastDeals[0]?.isCobuyer || lastDeals[1]?.isCobuyer) &&
    sameEmail(lastDeals[0]?.customer, lastDeals[1]?.cobuyer);

  const buyerDeal = lastDeals.find((d) => !d.isCobuyer);

  const loading = dealsLoading;

  useEffect(() => {
    if (!open) return;

    getUserDeals();
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal onClose={handleClose} isOpen={open} size="2xl">
      <Text fontSize="36px" fontWeight="bold" textAlign={{ base: 'center', md: 'left' }}>
        My Profile
      </Text>
      <SimpleGrid
        mt={customer?.full_name ? 6 : 2}
        columns={{ base: 1, md: 2 }}
        spacingX={2}
        spacingY={4}
        fontSize="16px"
      >
        <Box>
          <Skeleton isLoaded={!loading} height={loading ? '22px' : undefined}>
            <Label>Customer{shareEmail ? 's' : ''}:</Label>
            {shareEmail ? (
              <Box color="black" fontWeight="semibold">
                <Text>{buyerDeal?.customer?.full_name}</Text>
                <Text>{buyerDeal?.cobuyer?.full_name}</Text>
              </Box>
            ) : (
              customer?.full_name && (
                <>
                  <Text color="black" fontWeight="semibold">
                    {customer?.full_name}
                  </Text>
                </>
              )
            )}
          </Skeleton>
        </Box>

        <Box>
          <Skeleton isLoaded={!loading} height={loading ? '22px' : undefined}>
            {customer?.email && (
              <>
                <Label>Email Address:</Label>
                <Text color="black" fontWeight="semibold">
                  {customer?.email}
                </Text>
              </>
            )}
          </Skeleton>
        </Box>
      </SimpleGrid>
      <Divider borderColor="grayDarkBackground" my={4} />
      <ChangePassword setOpen={setOpen} loading={loading} />
    </Modal>
  );
};

export default ProfileModal;
