import { string } from 'yup';

const ssnRegExp = /^[0-9]{3}[-][0-9]{2}[-][0-9]{4}$/;
const ssnLast6RegExp = /^[Xx]{3}[-][0-9]{2}[-][0-9]{4}$/;
const ssnLast4RegExp = /^[Xx]{3}[-][Xx]{2}[-][0-9]{4}$/;

const ssnValidation = (ssnValidationRegex: RegExp, message: string) =>
  string()
    .required(message)
    .matches(ssnValidationRegex, message)
    .test('no-repeating-digits', 'Please enter a valid SSN', (value) => {
      if (!value) return true;
      return !/(\d)\1{5,}/.test(value.replace(/\D/g, '')); // Remove non-digits and check for 6+ consecutive repeats
    });

const loanAppSsnValidation = (ssnValidationRegex: RegExp, message: string) =>
  string().when('must_enter_ssn', {
    is: true,
    then: string()
      .required(message)
      .matches(ssnValidationRegex, message)
      .test('no-repeating-digits', 'Please enter a valid SSN', (value) => {
        if (!value) return true;
        return !/(\d)\1{5,}/.test(value.replace(/\D/g, '')); // Remove non-digits and check for 6+ consecutive repeats
      }),
    otherwise: string().notRequired(),
  });

const enterAValue = 'Please enter a value';

export const ssnLast4Validation = ssnValidation(ssnLast4RegExp, enterAValue);
export const ssnLast6Validation = ssnValidation(ssnLast6RegExp, enterAValue);
export const ssnFullValidation = ssnValidation(ssnRegExp, 'Please enter a valid SSN');
export const loanAppSsnFullValidation = loanAppSsnValidation(ssnRegExp, 'Please enter a valid SSN');
