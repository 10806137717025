import { useLocation } from 'react-router-dom';

import Loader from '../../components/Loader';
import { LDFlags } from '../../constants/flags';
import { useGetApprovedCreditDecisionsByDealIdQuery } from '../../gql/generated/graphql';
import { useFlag } from '../../hooks';
import Confetti from './Confetti';
import FrozenOrLockedCredit from './FrozenOrLockedCredit';
import NicelyDone from './NicelyDone';

const END_OF_FLOW_APPROVAL_VARIATIONS = {
  nicelyDone: 0,
  confetti: 1,
};

const copy = {
  subtitle:
    'We hope that was easier than when the teacher accidentally leaves the map pulled down for a test on the state capitals.',
  whatsNext1:
    'Now you can relax knowing that we are working to find the best loan available. Once we have an approval from the bank, we will call you back and go over the payoff of your lease, the details of your new loan, and next steps regarding paperwork & registration.',
  whatsNext2:
    'It takes anywhere from 5-30 minutes (during business hours) to get responses from our lending partners and get back to you. If we receive your application after business hours we will start working on it first thing in the morning.',
};

interface LocationState {
  isFailed?: boolean;
  dealId?: number | string;
  payoffFailure?: boolean;
  customerFrozenOrLockedCredit?: boolean;
  cobuyerFrozenOrLockedCredit?: boolean;
}

const AllDone = () => {
  const endOfFlowApprovalVariation = useFlag(LDFlags.END_OF_FLOW_APPROVAL_VARIATION);
  const confettiVariationActive =
    endOfFlowApprovalVariation === END_OF_FLOW_APPROVAL_VARIATIONS.confetti;
  const { state } = useLocation() as { state: LocationState };

  const dealId = state?.dealId ?? '';
  const creditFrozen = state?.customerFrozenOrLockedCredit || state?.cobuyerFrozenOrLockedCredit;
  const showConfetti =
    // need to compare with false because can be undefined
    state?.isFailed === false && confettiVariationActive && dealId && !creditFrozen;

  const { data: banksData, loading } = useGetApprovedCreditDecisionsByDealIdQuery({
    skip: !showConfetti,
    variables: { dealId },
  });

  const banks =
    banksData?.getApprovedCreditDecisionsByDealId
      ?.filter((decision) => decision?.bank)
      ?.map((decision) => ({
        id: decision?.id || '',
        bank_logo: decision?.bank?.logo_url || '',
      })) || [];

  if (loading) {
    return <Loader />;
  }

  if (creditFrozen) {
    return (
      <FrozenOrLockedCredit
        customerFrozenOrLockedCredit={state?.customerFrozenOrLockedCredit || false}
        cobuyerFrozenOrLockedCredit={state?.cobuyerFrozenOrLockedCredit || false}
      />
    );
  }

  if (showConfetti && !loading && banks.length > 0) {
    return <Confetti banks={banks} />;
  }

  return <NicelyDone copy={copy} payoffFailure={state?.payoffFailure} />;
};

export default AllDone;
