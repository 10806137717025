import React, { useEffect, useState } from 'react';
import { Progress, BoxProps, Box, FormLabelProps } from '@chakra-ui/react';
import Label from './formComponents/Label';

interface Props extends BoxProps {
  password: string;
  label?: string;
  labelProps?: FormLabelProps;
  validateLength?: boolean;
  minLength?: number;
  maxLength?: number;
  regexValidations?: string[];
}

const PasswordStrength: React.FC<Props> = ({
  password,
  validateLength = true,
  minLength = 8,
  maxLength = 30,
  regexValidations = ['[A-Z]', '[a-z]', '[0-9]'],
  label,
  labelProps,
  ...rest
}) => {
  const [passwordStrength, setPasswordStrength] = useState({ value: 0, color: 'red' });
  const totalRequirements = regexValidations.length + (validateLength ? 1 : 0);

  const maxValue = 100;
  const step = maxValue / totalRequirements;

  const getColor = (value: number) => {
    if (value <= step) {
      return 'red';
    }

    if (value >= step * totalRequirements) {
      return 'green';
    }

    return 'yellow';
  };

  useEffect(() => {
    let completedPasswordRequirements = 0;
    const validateRegex = ['[A-Z]', '[a-z]', '[0-9]'];
    validateRegex.forEach((regex) => {
      if (new RegExp(regex).test(password)) {
        completedPasswordRequirements += 1;
      }
    });

    if (validateLength && password.length >= minLength && password.length <= maxLength) {
      completedPasswordRequirements += 1;
    }

    const value = step * completedPasswordRequirements;

    setPasswordStrength({
      value,
      color: getColor(value),
    });
  }, [password]);

  return (
    <Box {...rest}>
      {label && <Label {...labelProps}>{label}</Label>}
      <Progress
        colorScheme={passwordStrength.color}
        value={passwordStrength.value}
        w="full"
        mb={2}
        rounded="2xl"
      />
    </Box>
  );
};

export default PasswordStrength;
