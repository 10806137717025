import { Icon, IconProps, Spinner } from '@chakra-ui/react';
import { FaCheckCircle } from '@react-icons/all-files/fa/FaCheckCircle';
import { FaExclamationCircle } from '@react-icons/all-files/fa/FaExclamationCircle';
import { FaExclamationTriangle } from '@react-icons/all-files/fa/FaExclamationTriangle';
import { IconType } from 'react-icons/lib';

import { ItemStatusType } from '../../utils';

const statusToIconMap: {
  [key in Exclude<ItemStatusType, 'none' | 'loading'>]: (IconProps & { as?: IconType }) | null;
} = {
  complete: {
    as: FaCheckCircle,
    color: 'leaseEndGreen',
  },
  warn: {
    as: FaExclamationTriangle,
    color: 'leaseEndYellow',
  },
  error: {
    as: FaExclamationCircle,
    color: 'leaseEndRed',
  },
};

interface Props extends IconProps {
  status: ItemStatusType;
}

const StatusIcon = ({ status, ...rest }: Props) => {
  if (status === 'none') {
    return null;
  }

  if (status === 'loading') {
    return <Spinner size="sm" mr={2} />;
  }

  return <Icon w="16px" h="16px" {...rest} {...statusToIconMap[status]} />;
};

export default StatusIcon;
