import {
  BoxProps,
  Center,
  Flex,
  FlexProps,
  Image,
  SimpleGrid,
  SimpleGridProps,
  Text,
  TextProps,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';

import { Checkmark } from '../assets/Images';

interface RadioCardProps extends BoxProps {
  compact?: boolean;
  isChecked?: boolean;
}

const RadioCard = ({ children, compact, ...props }: RadioCardProps) => {
  const { state, getInputProps, getRadioProps } = useRadio(props);

  // <Flex> puts an error in the console when it takes in `isChecked` as a prop
  // eslint-disable-next-line no-param-reassign
  delete props.isChecked;
  return (
    <Flex as="label">
      <input {...getInputProps()} />
      <Flex
        {...getRadioProps()}
        h="full"
        borderRadius={compact ? 0 : 'lg'}
        borderRightRadius={compact ? 0 : 'lg'}
        w={compact ? 120 : '100%'}
        py={compact ? 4 : 7}
        borderRight={compact ? 0 : '1px lightGray solid'}
        alignItems="center"
        justifyContent="center"
        px={{ base: 6, lg: 12 }}
        shadow={state.isChecked ? 'none' : 'md'}
        _hover={{ shadow: state.isChecked ? 'none' : 'lg' }}
        _checked={{ bg: 'royalBlueLight', color: 'white' }}
        bg="white"
        position="relative"
        transitionDuration=".2s"
        cursor="pointer"
        {...props}
      >
        {children}
        <Image
          zIndex={4}
          hidden={!state.isChecked}
          h="28px"
          w="28px"
          src={Checkmark}
          alt="Checkmark"
          position="absolute"
          bottom="-15px"
          left={0}
          right={0}
          mx="auto"
          width="40%"
        />
      </Flex>
    </Flex>
  );
};

interface ButtonedRadioGroupProps extends Omit<SimpleGridProps, 'defaultValue' | 'onChange'> {
  value?: string | number | boolean;
  options: { label: string; value: string | number | boolean }[];
  name: string;
  defaultValue?: string | number | boolean;
  onChange?: (value: string) => void | Promise<void>;
  compact?: boolean;
  reverseOrder?: boolean;
  itemProps?: FlexProps;
  itemTextProps?: TextProps;
  collapseOnMobile?: boolean;
}

const ButtonedRadioGroup = ({
  value,
  options,
  name,
  defaultValue,
  onChange,
  compact,
  reverseOrder,
  collapseOnMobile = true,
  itemProps,
  itemTextProps,
  ...rest
}: ButtonedRadioGroupProps) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    value: value?.toString(),
    name,
    defaultValue: defaultValue?.toString(),
    onChange,
  });

  const reverse = reverseOrder ? '-reverse' : '';

  return (
    <SimpleGrid
      {...getRootProps()}
      direction={
        compact
          ? 'row'
          : { base: collapseOnMobile ? `column${reverse}` : `row${reverse}`, lg: `row${reverse}` }
      }
      alignSelf={{ base: 'center', lg: 'start' }}
      columns={{ base: 2, md: 4 }}
      spacing={compact ? 0 : 2}
      w="100%"
      {...rest}
    >
      {options.map(({ label, value: optionValue }) => (
        <RadioCard
          compact={compact}
          {...itemProps}
          {...getRadioProps({ value: optionValue.toString() })}
          key={label}
        >
          <Center>
            <Text textAlign="center" {...itemTextProps}>
              {label}
            </Text>
          </Center>
        </RadioCard>
      ))}
    </SimpleGrid>
  );
};

export default ButtonedRadioGroup;
