import { Box } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useDrag, useDrop } from 'react-dnd';

interface DragImageProps {
  id: string;
  children: ReactNode;
}

export const DragImage = ({ id, children }: DragImageProps) => {
  const [{ isDragging }, ref] = useDrag({
    type: 'image',
    item: { id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <Box ref={ref} opacity={isDragging ? '50%' : '100%'}>
      {children}
    </Box>
  );
};

interface DropImageProps {
  canDrop: boolean;
  set: (id: string) => void;
  children: (isOver: boolean) => ReactNode;
}

export const DropImage = ({ canDrop, set, children }: DropImageProps) => {
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'image',
      canDrop: () => canDrop,
      drop: (item: { id: string }) => {
        set(item.id);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [],
  );

  return <Box ref={drop}>{children(isOver)}</Box>;
};
