import { StateAbbreviation } from '../gql/generated/graphql';

export const OtherLienholder = 'Other';
export const OtherLienholderSlug = OtherLienholder.toLowerCase();

export const NissanFinanceSlug = 'nissan_finance';

export const NOT_AVAILABLE_LIENHOLDERS_PER_STATE = {
  [StateAbbreviation.Hi]: [NissanFinanceSlug],
};
