import { useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';

import ErrorModal from './ErrorModal';

interface Props {
  title?: string;
  description?: string;
  buttonText?: string;
}

interface ErrorModalSettings {
  title?: string;
  description?: string;
  buttonText?: string;
  retry?: () => Promise<boolean>;
  onSuccessClose?: () => void;
  onFailureClose?: () => void;
}

const useErrorModal = ({ title, description, buttonText }: Props = {}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [errorModalSettings, setErrorModalSettings] = useState<ErrorModalSettings>({});

  const handleOpen = (settings: Partial<ErrorModalSettings> = {}) => {
    setErrorModalSettings(settings);
    onOpen();
  };

  const renderErrorModal = (() => (
    <ErrorModal
      isOpen={isOpen}
      title={title}
      description={description}
      buttonText={buttonText}
      {...errorModalSettings}
      onFailureClose={() => {
        onClose();
        errorModalSettings.onFailureClose?.();
      }}
      onSuccessClose={() => {
        onClose();
        errorModalSettings.onSuccessClose?.();
      }}
    />
  ))();

  return {
    renderErrorModal,
    openErrorModal: handleOpen,
  };
};

export default useErrorModal;
