import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import Loader from '../../components/Loader';
import { LDFlags } from '../../constants/flags';
import { HOME } from '../../constants/urls';
import { useFlag, useTempInfo } from '../../hooks';
import { CookieKeys, useCookie } from '../../hooks/useCookie';
import FlowPassword from './FlowPassword';
import Partners from './Partners';
import Terms from './Terms';

const NextSteps = () => {
  const history = useHistory();
  const [accessToken] = useCookie<string>(CookieKeys.ACCESS_TOKEN);
  const isAuthenticated = !!accessToken;
  const { info, infoLoading } = useTempInfo('network-only', true);
  const removeFlowPassword = useFlag(LDFlags.REMOVE_FLOW_PASSWORD);

  const tempInfoData = info?.data;
  const passedPrequal = !!tempInfoData?.prequalification_success;

  history.listen(() => {
    if (history.action === 'POP') {
      history.push(HOME);
    }
  });

  // Need to memoize isAuthenticated to prevent rendering Partners component after login
  const memoizedIsAuthenticated = useMemo(() => isAuthenticated, []);

  if (infoLoading) {
    return <Loader />;
  }

  if (!removeFlowPassword && !memoizedIsAuthenticated) {
    return <FlowPassword />;
  }

  if (passedPrequal) {
    return <Partners />;
  }

  return <Terms />;
};

export default NextSteps;
