import {
  Button,
  Center,
  Flex,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Select,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { atom, useAtom } from 'jotai';
import { useEffect } from 'react';

import { DealType, useGenerateTestDocumentsLazyQuery } from '../../../gql/generated/graphql';
import { DealStateEnum } from '../../../utils/types/deal';
import { DealWithFarthestState } from '../utils';

export const isDevActiveAtom = atom(false);
export const devDealStateAtom = atom(DealStateEnum.Closed);
export const documentsSigned = atom(false);
export const devDealSTypeAtom = atom(DealType.Refi);

interface DashboardDevToolsProps {
  deal?: DealWithFarthestState;
}

const DashboardDevToolbar = ({ deal }: DashboardDevToolsProps) => {
  const [devDealState, setDevDealState] = useAtom(devDealStateAtom);
  const [devDealType, setDevDealType] = useAtom(devDealSTypeAtom);
  const [documentsSignedAtom, setDocumentsSigned] = useAtom(documentsSigned);
  const [isDevActive, setIsDevActive] = useAtom(isDevActiveAtom);

  const [generateTestDocuments, { loading: generatingDocuments }] =
    useGenerateTestDocumentsLazyQuery({
      onCompleted: () => {
        window.location.reload();
      },
    });

  useEffect(() => {
    if (isDevActive) return;

    if (deal?.state) {
      setDevDealState(deal.state as DealStateEnum);
    }
    if (deal?.type) {
      setDevDealType(deal.type);
    }
  }, [deal?.state, deal?.type]);

  return (
    <Center>
      <VStack
        align="left"
        borderWidth={2}
        borderColor="grayBlueish"
        borderRadius="lg"
        mt="10px"
        p="10px"
        w="300px"
      >
        <Heading textAlign="center">Dev Tools</Heading>
        <Text>
          Deal id: <b>{deal?.id ?? '--'}</b>
        </Text>
        <HStack>
          <Switch isChecked={isDevActive} onChange={(e) => setIsDevActive(e.target.checked)}>
            Dev Tools Active
          </Switch>
        </HStack>
        <HStack>
          <Switch
            isChecked={documentsSignedAtom}
            onChange={(e) => setDocumentsSigned(e.target.checked)}
          >
            Documents Signed
          </Switch>
        </HStack>
        <HStack>
          <FormLabel width="105px">Deal State</FormLabel>
          <Select
            onChange={(e) => {
              setDevDealState(e.target.value as DealStateEnum);
            }}
            value={devDealState}
            bg="white"
          >
            {Object.entries(DealStateEnum).map(([label, value]) => (
              <option key={label} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </HStack>
        <HStack>
          <FormLabel width="105px">Deal Type</FormLabel>
          <Select
            onChange={(e) => {
              setDevDealType(e.target.value as DealType);
            }}
            value={devDealType}
            bg="white"
          >
            {Object.entries(DealType).map(([label, value]) => (
              <option key={label} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </HStack>
        <Popover placement="top">
          {({ onClose }) => (
            <>
              <PopoverTrigger>
                <Button
                  isDisabled={!isDevActive}
                  isLoading={generatingDocuments}
                  loadingText="Generating Test Documents..."
                >
                  Generate Test Documents
                </Button>
              </PopoverTrigger>
              <PopoverContent p="10px" maxW="200px">
                <PopoverArrow />
                <Flex flexDir="column" alignItems="center" gap="5px">
                  Are you sure?
                  <Flex gap="5px">
                    <IconButton
                      icon={<HiCheck size="22.5px" />}
                      aria-label="confirm"
                      bg="green.500"
                      color="white"
                      _hover={{
                        bg: 'green.400',
                        boxShadow: 'md',
                      }}
                      onClick={() => {
                        onClose();
                        generateTestDocuments({
                          variables: { update_deal_id: deal?.id ?? '' },
                        });
                      }}
                    />
                  </Flex>
                </Flex>
              </PopoverContent>
            </>
          )}
        </Popover>
      </VStack>
    </Center>
  );
};

export default DashboardDevToolbar;
