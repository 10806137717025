import { Box, useToken } from '@chakra-ui/react';

const Triangle = ({
  verticalDirection,
  horizontalDirection,
  color,
  size,
}: {
  verticalDirection: 'up' | 'center' | 'down';
  horizontalDirection: 'left' | 'center' | 'right';
  color: string;
  size: number;
}) => {
  const [borderColor] = useToken('colors', [color]);

  const borderStyle = {
    up: {
      left: {
        borderTop: `${size}px solid ${borderColor}`,
        borderRight: `${size}px solid transparent`,
      },
      center: {
        borderRight: `${size / 2}px solid transparent`,
        borderBottom: `${size}px solid ${borderColor}`,
        borderLeft: `${size / 2}px solid transparent`,
      },
      right: {
        borderRight: `${size}px solid ${borderColor}`,
        borderBottom: `${size}px solid transparent`,
      },
    },
    center: {
      left: {
        borderTop: `${size / 2}px solid transparent`,
        borderRight: `${size}px solid ${borderColor}`,
        borderBottom: `${size / 2}px solid transparent`,
      },
      center: {},
      right: {
        borderBottom: `${size / 2}px solid transparent`,
        borderLeft: `${size}px solid ${borderColor}`,
        borderTop: `${size / 2}px solid transparent`,
      },
    },
    down: {
      left: {
        borderRight: `${size}px solid transparent`,
        borderBottom: `${size}px solid ${borderColor}`,
      },
      center: {
        borderRight: `${size / 2}px solid transparent`,
        borderTop: `${size}px solid ${borderColor}`,
        borderLeft: `${size / 2}px solid transparent`,
      },
      right: {
        borderTop: `${size}px solid transparent`,
        borderRight: `${size}px solid ${borderColor}`,
      },
    },
  };

  return <Box {...borderStyle[verticalDirection][horizontalDirection]} />;
};

export default Triangle;
