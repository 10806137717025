import { Box, CardProps, Divider } from '@chakra-ui/react';
import { FaArchive } from 'react-icons/fa';

import { DealStateEnum } from '../../../utils/types/deal';
import { DealWithFarthestState, PRE_SIGNED_DEAL_STATES } from '../utils';
import DashboardCard from './DashboardCard';
import MyDocuments from './MyDocuments';
import UploadDocuments from './UploadDocuments';

type Props = {
  deal?: DealWithFarthestState;
} & CardProps;

const DashboardDocumentsCard = ({ deal, ...props }: Props) => {
  return (
    <DashboardCard
      title="Document Center"
      icon={FaArchive}
      headerColor="gainsboro"
      hasBorder
      {...props}
    >
      <Box color="black">
        {!PRE_SIGNED_DEAL_STATES.includes(deal?.farthestState as DealStateEnum) && (
          <>
            <MyDocuments deal={deal} />
            <Divider my="20px" borderColor="grayDarkBackground" />
          </>
        )}
        <UploadDocuments deal={deal} title="UPLOAD DOCUMENTS" />
      </Box>
    </DashboardCard>
  );
};

export default DashboardDocumentsCard;
