import { useLazyQuery } from '@apollo/client';
import { createContext, useEffect, useState } from 'react';

import { OtherLienholderSlug } from '../constants/lienholders';
import { Maybe } from '../gql/generated/graphql';
import { lienholderQuery } from '../gql/prs/lienholderGql';
import { CustomerInfo, PayoffMethods } from '../gql/prs/types';

const CustomerInfoWeight = {
  [CustomerInfo.FIRST_NAME]: 0,
  [CustomerInfo.LAST_NAME]: 0,
  [CustomerInfo.VIN]: 0,
  [CustomerInfo.ZIP]: 0,
  [CustomerInfo.CURRENT_ODOMETER]: 0,
  [CustomerInfo.DOB]: 1,
  [CustomerInfo.ACCOUNT_NUMBER]: 1,
  [CustomerInfo.PHONE_NUMBER]: 1,
  [CustomerInfo.SSN_LAST_4]: 2,
  [CustomerInfo.SSN_LAST_6]: 3,
  [CustomerInfo.SSN]: 5,
};

const rankRequirements = (requirements: CustomerInfo[]) =>
  requirements.reduce((prev, curr) => prev + CustomerInfoWeight[curr], 0);

const compareRequirements = (a: CustomerInfo[], b: CustomerInfo[]) =>
  rankRequirements(a) - rankRequirements(b);

const useLienholderInfo = ({
  hasName,
  state,
  slug,
}: {
  hasName: boolean;
  state: Maybe<string>;
  slug?: Maybe<string>;
}) => {
  const [getLienholderInfo, { data: lienholderInfo }] = useLazyQuery(lienholderQuery);
  const [lienholderLoading, setLienholderLoading] = useState(true);
  const [lienholderRequirements, setLienholderRequirements] = useState<CustomerInfo[][]>([]);
  const [payoffMethods, setPayoffMethods] = useState<PayoffMethods>({});
  const [allowsDigital, setAllowsDigital] = useState(false);

  useEffect(() => {
    if (!state || !slug) {
      return;
    }

    if (slug === OtherLienholderSlug) {
      setLienholderLoading(false);
      return;
    }

    getLienholderInfo({
      variables: {
        state,
        slug,
      },
    });
  }, [state, slug]);

  useEffect(() => {
    if (!lienholderInfo?.lienholder) {
      return;
    }

    let requirements = (
      [...lienholderInfo.lienholder.requirements_to_get_payoff.options] as CustomerInfo[][]
    ).sort(compareRequirements);

    if (!hasName) {
      requirements = requirements.map((option) => [
        CustomerInfo.FIRST_NAME,
        CustomerInfo.LAST_NAME,
        ...option,
      ]);
    }

    setLienholderRequirements(requirements);

    const computedPayoffMethods = Object.fromEntries(
      Object.entries(
        (lienholderInfo.lienholder.requirements_to_get_payoff.get_payoff_methods ??
          {}) as PayoffMethods,
      ).filter(([, objValue]) => typeof objValue === 'object'),
    );
    setPayoffMethods(computedPayoffMethods);

    // We only do digital payoffs in COM with Route One at the moment
    setAllowsDigital(computedPayoffMethods?.digital?.value === 'ROUTE_ONE');
    setLienholderLoading(false);
  }, [lienholderInfo]);

  return {
    lienholderRequirements,
    lienholderLoading,
    payoffMethods,
    allowsDigital,
  };
};

export type LienholderContextType = ReturnType<typeof useLienholderInfo>;

export const LienholderContext = createContext<ReturnType<typeof useLienholderInfo>>({
  lienholderRequirements: [],
  lienholderLoading: true,
  payoffMethods: {},
  allowsDigital: false,
});

export default useLienholderInfo;
