import { Box, Button, Center, Flex, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';

import { useLEAccordionItemContext } from '../../../components/LEAccordion/LEAccordionItem/useLEAccordionItem';
import LogoutConfirmModal from '../../../components/LogoutConfirmModal';
import TextWithTooltip from '../../../components/TextWithTooltip';
import { Input, Label } from '../../../components/formComponents';
import PrimaryButton from '../../../components/ui/buttons/PrimaryButton';
import { useTemporaryInfoUpsertMutation } from '../../../gql/generated/graphql';
import { useTempInfo } from '../../../hooks';
import { CookieKeys, useCookie } from '../../../hooks/useCookie';
import {
  VerifyPersonalInfoValues,
  verifyPersonalInfoInitialValues,
  verifyPersonalInfoValidationSchema,
} from '../utils';

const VerifyPersonalInfo = () => {
  const [accessToken] = useCookie<string>(CookieKeys.ACCESS_TOKEN);
  const [upsertTemporaryInfo] = useTemporaryInfoUpsertMutation();

  const { updateState, isExpanded, setOpenAccordionIndex, index } = useLEAccordionItemContext();
  const { info } = useTempInfo('network-only', isExpanded);
  const [logoutConfirmModalOpen, setLogoutConfirmModalOpen] = useState(false);

  const tempInfoData = info?.data;

  const onSubmitForm = async (values: VerifyPersonalInfoValues) => {
    updateState({ status: 'loading' });

    const data = {
      first_name: values.first_name,
      middle_name: values.middle_name,
      last_name: values.last_name,
      email: values.email,
      phone_number: values.phone_number,
    };

    await upsertTemporaryInfo({
      variables: {
        info: {
          id: info?.id,
          data,
        },
      },
    });

    updateState({ status: 'complete' });
    setOpenAccordionIndex(index + 1);
  };

  return (
    <>
      <Box>
        <Text textAlign="center" mb={4}>
          Please verify your contact information
        </Text>
        <Center mb={{ base: 4, md: 0 }}>
          <TextWithTooltip label="Providing this information helps us 1) fulfill lender requirements to verify your identity and 2) contact you about your transaction & application status.">
            Why do we need this?
          </TextWithTooltip>
        </Center>
        <Formik
          enableReinitialize
          initialValues={verifyPersonalInfoInitialValues(tempInfoData)}
          validationSchema={verifyPersonalInfoValidationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={onSubmitForm}
        >
          {({ isSubmitting, errors }) => {
            const hasErrors = Object.values(errors).some((value) => value !== '');
            return (
              <Form>
                <Input
                  label="Phone Number"
                  name="phone_number"
                  mask="(999) 999-9999"
                  _container={{ mb: 2 }}
                  placeholder="Phone Number"
                  type="tel"
                  autoFocus
                />
                <Label>NAME</Label>
                <Flex
                  mb={2}
                  w="100%"
                  flexDir={{ base: 'column', md: 'row' }}
                  justifyContent="space-between"
                >
                  <Input name="first_name" placeholder="First" _container={{ mr: { md: 1 } }} />
                  <Input
                    name="middle_name"
                    placeholder="Middle"
                    _container={{ mx: { md: 1 }, my: { base: 2, md: 0 } }}
                  />
                  <Input name="last_name" placeholder="Last" _container={{ ml: { md: 1 } }} />
                </Flex>
                <Input
                  name="email"
                  placeholder="Email"
                  label="EMAIL ADDRESS"
                  isDisabled={!!accessToken}
                  labelProps={{ opacity: '1 !important' }}
                  helperText={
                    accessToken && (
                      <>
                        Not you?{' '}
                        <Button
                          fontSize="sm"
                          variant="link"
                          onClick={() => setLogoutConfirmModalOpen(true)}
                        >
                          Log out here
                        </Button>
                      </>
                    )
                  }
                />
                <Center>
                  <PrimaryButton
                    mt="30px"
                    mb={{
                      base: '20px',
                      lg: '0',
                    }}
                    loading={isSubmitting}
                    type="submit"
                  />
                </Center>
                {hasErrors && (
                  <Text color="leaseEndRed" textAlign="center" mt={{ base: 0, lg: 4 }}>
                    Review fields above for errors.
                  </Text>
                )}
              </Form>
            );
          }}
        </Formik>
      </Box>
      <LogoutConfirmModal
        isOpen={logoutConfirmModalOpen}
        handleClose={() => setLogoutConfirmModalOpen(false)}
      />
    </>
  );
};

export default VerifyPersonalInfo;
