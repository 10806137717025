import { Button, Divider, Flex, Text, VStack } from '@chakra-ui/react';
import { atom, useAtom } from 'jotai';

import { isProd } from '../../../config';
import { LDFlags } from '../../../constants/flags';
import { useFlag } from '../../../hooks';

enum CreditScore {
  Excellent = 770,
  Great = 700,
  Good = 630,
  Poor = 550,
}

export const devCreditPrequalOverride = atom<boolean | null>(isProd ? null : true);
export const devCreditScoreOverride = atom<number | null>(isProd ? null : CreditScore.Excellent);

const DevButton = ({
  condition,
  label,
  action,
}: {
  condition: boolean | null;
  label: string;
  action: () => void;
}) => (
  <Button
    border={condition ? '2px' : 'none'}
    borderColor={condition ? 'oceanBoatBlue' : ''}
    bgColor={condition ? 'oceanBoatBlueBG' : 'grayInput'}
    _hover={{ bgColor: 'oceanBoatBlueBG' }}
    size="sm"
    variant="solid"
    ml={1}
    onClick={action}
  >
    {label}
  </Button>
);

const DevToolbar = () => {
  const creditPrequalificationEnabled = useFlag(LDFlags.CREDIT_PREQUALIFICATION);
  const [isDevCreditSuccess, setDevCreditSuccess] = useAtom(devCreditPrequalOverride);
  const [devCreditScore, setDevCreditScore] = useAtom(devCreditScoreOverride);

  if (!creditPrequalificationEnabled || isProd) return null;

  return (
    <Flex flexDirection="column" justify="center" align="center">
      <Text textAlign="center" mb="2">
        Loan App Dev Tools
      </Text>
      <Flex justify="center" align="center" h="150px" mt="25px" gap={3}>
        <VStack
          align="left"
          borderWidth={2}
          borderColor="grayBlueish"
          borderRadius="lg"
          mt="10px"
          p="10px"
          mx="auto"
        >
          <Text textAlign="center" mb="2">
            Prequal Result
          </Text>
          <DevButton
            condition={isDevCreditSuccess == null}
            label="Off"
            action={() => {
              setDevCreditSuccess(null);
              setDevCreditScore(null);
            }}
          />
          <DevButton
            condition={isDevCreditSuccess}
            label="Success"
            action={() => {
              setDevCreditSuccess(true);
              setDevCreditScore(CreditScore.Excellent);
            }}
          />
          <DevButton
            condition={isDevCreditSuccess === false}
            label="Fail"
            action={() => {
              setDevCreditSuccess(false);
              setDevCreditScore(null);
            }}
          />
        </VStack>
        {isDevCreditSuccess && (
          <>
            <Divider
              borderColor="black"
              orientation="vertical"
              style={{
                transition: 'transform 0.5s, opacity 0.5s',
                transform: isDevCreditSuccess ? 'translateX(0)' : 'translateX(-200px)',
                opacity: isDevCreditSuccess ? 1 : 0,
              }}
            />
            <VStack
              align="left"
              borderWidth={2}
              borderColor="grayBlueish"
              borderRadius="lg"
              mt="10px"
              p="10px"
              mx="auto"
              style={{
                transition: 'transform 0.5s, opacity 0.5s',
                transform: isDevCreditSuccess ? 'translateX(0)' : 'translateX(-200px)',
                opacity: isDevCreditSuccess ? 1 : 0,
              }}
            >
              <Text textAlign="center" mb="2">
                Credit Score
              </Text>
              <DevButton
                condition={devCreditScore === CreditScore.Excellent}
                label="720 ↑"
                action={() => setDevCreditScore(CreditScore.Excellent)}
              />
              <DevButton
                condition={devCreditScore === CreditScore.Great}
                label="680-719"
                action={() => setDevCreditScore(CreditScore.Great)}
              />
              <DevButton
                condition={devCreditScore === CreditScore.Good}
                label="600-679"
                action={() => setDevCreditScore(CreditScore.Good)}
              />
              <DevButton
                condition={devCreditScore === CreditScore.Poor}
                label="599 ↓"
                action={() => setDevCreditScore(CreditScore.Poor)}
              />
            </VStack>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default DevToolbar;
