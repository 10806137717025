import { ListItem, UnorderedList } from '@chakra-ui/react';

import { Instruction } from '../../../gql/prs/types';
import Description from '../../Description';

const Instructions = ({ instructions }: { instructions: Instruction[] }) => (
  <UnorderedList mb={4}>
    {instructions.map(({ details, text }) => (
      <ListItem key={`instruction-${text.slice(10)}`}>
        <Description fontSize={{ md: '16px' }}>{text}</Description>
        {details && (
          <UnorderedList listStyleType="circle">
            {Array.isArray(details) ? (
              details.map((detail) => (
                <ListItem>
                  <Description fontSize={{ md: '14px' }}>{detail}</Description>
                </ListItem>
              ))
            ) : (
              <ListItem>
                <Description fontSize={{ md: '14px' }}>{details}</Description>
              </ListItem>
            )}
          </UnorderedList>
        )}
      </ListItem>
    ))}
  </UnorderedList>
);

export default Instructions;
