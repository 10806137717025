import * as Yup from 'yup';

import { OWNERSHIP_TYPE } from '../../constants/addresses';
import { EmploymentStatusType } from '../../gql/generated/graphql';
import { notRequiredString } from '../strings';
import {
  addressLine2Validation,
  addressLineValidation,
  cityValidation,
  countyValidation,
  stateValidation,
} from './address';
import { emailValidationRequired } from './email';
import {
  employerNameValidation,
  employmentStatusValidation,
  jobTitleValidation,
} from './employment';
import { nameValidationMax30 } from './names';
import { monthsValidation, numberOptionalValidation, numberRequiredValidation } from './numbers';
import { phoneValidationRequired } from './phoneNumber';
import { residenceTypeValidation, residenceYearsValidation } from './residence';
import { zipValidation } from './zipCodes';

// Personal Information
export const cobuyerNameValidation = {
  cobuyer_first_name: nameValidationMax30,
  cobuyer_last_name: nameValidationMax30,
};

export const cobuyerContactInfoValidation = {
  cobuyer_email: emailValidationRequired,
  cobuyer_phone_number: phoneValidationRequired,
};
export const cobuyerPersonalInfoValidation = {
  ...cobuyerNameValidation,
  ...cobuyerContactInfoValidation,
};

// Address
export const cobuyerPrimaryAddressValidation = {
  cobuyer_address_line: addressLineValidation,
  cobuyer_address_line_2: addressLine2Validation,
  cobuyer_city: cityValidation,
  cobuyer_state: stateValidation,
  cobuyer_county: countyValidation,
  cobuyer_zip: zipValidation,
};
export const cobuyerPreviousAddressValidation = {
  cobuyer_previous_address_line: addressLineValidation,
  cobuyer_previous_address_line_2: addressLine2Validation,
  cobuyer_previous_city: cityValidation,
  cobuyer_previous_state: stateValidation,
  cobuyer_previous_county: countyValidation,
  cobuyer_previous_zip: zipValidation,
};

// Residence
export const cobuyerPrimaryResidenceValidation = {
  cobuyer_primary_residence_type: residenceTypeValidation,
  cobuyer_primary_residence_years: residenceYearsValidation,
  cobuyer_primary_residence_months: Yup.number().when('cobuyer_primary_residence_years', {
    is: (cobuyer_primary_residence_years: number) => cobuyer_primary_residence_years === 0,
    then: monthsValidation,
    otherwise: numberOptionalValidation,
  }),
  cobuyer_primary_residence_monthly_payment: Yup.number().when('cobuyer_primary_residence_type', {
    is: (cobuyer_primary_residence_type: string) =>
      cobuyer_primary_residence_type !== OWNERSHIP_TYPE.OWN_HOME_OUTRIGHT,
    then: numberRequiredValidation,
    otherwise: numberOptionalValidation,
  }),
};

export const cobuyerPreviousResidenceValidation = {
  cobuyer_secondary_residence_years: Yup.number().when('cobuyer_primary_residence_years', {
    is: (cobuyer_primary_residence_years: number) => cobuyer_primary_residence_years < 2,
    then: residenceYearsValidation,
    otherwise: numberOptionalValidation,
  }),
  cobuyer_secondary_residence_months: Yup.number().when('cobuyer_primary_residence_years', {
    is: (cobuyer_primary_residence_years: number) => cobuyer_primary_residence_years === 0,
    then: monthsValidation,
    otherwise: numberOptionalValidation,
  }),
  cobuyer_previous_address_line: Yup.string().when('cobuyer_primary_residence_years', {
    is: (cobuyer_primary_residence_years: number) => cobuyer_primary_residence_years < 2,
    then: addressLineValidation,
    otherwise: notRequiredString,
  }),
  cobuyer_previous_address_line_2: Yup.string().when('cobuyer_primary_residence_years', {
    is: (cobuyer_primary_residence_years: number) => cobuyer_primary_residence_years < 2,
    then: addressLine2Validation,
    otherwise: notRequiredString,
  }),
  cobuyer_previous_city: Yup.string().when('cobuyer_primary_residence_years', {
    is: (cobuyer_primary_residence_years: number) => cobuyer_primary_residence_years < 2,
    then: cityValidation,
    otherwise: notRequiredString,
  }),
  cobuyer_previous_state: Yup.string().when('cobuyer_primary_residence_years', {
    is: (cobuyer_primary_residence_years: number) => cobuyer_primary_residence_years < 2,
    then: stateValidation,
    otherwise: notRequiredString,
  }),
  cobuyer_previous_zip: Yup.string().when('cobuyer_primary_residence_years', {
    is: (cobuyer_primary_residence_years: number) => cobuyer_primary_residence_years < 2,
    then: zipValidation,
    otherwise: notRequiredString,
  }),
  cobuyer_previous_county: Yup.string().when('cobuyer_primary_residence_years', {
    is: (cobuyer_primary_residence_years: number) => cobuyer_primary_residence_years < 2,
    then: countyValidation,
    otherwise: notRequiredString,
  }),
};

// Employment
export const cobuyerEmploymentValidation = {
  cobuyer_employment_status: employmentStatusValidation,
  cobuyer_employer_name: Yup.string().when('cobuyer_employment_status', {
    is: (cobuyer_employment_status: EmploymentStatusType) =>
      cobuyer_employment_status === EmploymentStatusType.Employed,
    then: employerNameValidation,
    otherwise: notRequiredString,
  }),
  cobuyer_employer_phone_number: Yup.string().when('cobuyer_employment_status', {
    is: (cobuyer_employment_status: EmploymentStatusType) =>
      cobuyer_employment_status === EmploymentStatusType.Employed,
    then: phoneValidationRequired,
    otherwise: notRequiredString,
  }),
  cobuyer_job_title: Yup.string().when('cobuyer_employment_status', {
    is: (cobuyer_employment_status: EmploymentStatusType) =>
      cobuyer_employment_status === EmploymentStatusType.Employed,
    then: jobTitleValidation,
    otherwise: notRequiredString,
  }),
  cobuyer_years_at_job: numberRequiredValidation,
  cobuyer_months_at_job: Yup.number().when('cobuyer_years_at_job', {
    is: (cobuyer_years_at_job: number) => cobuyer_years_at_job === 0,
    then: monthsValidation,
    otherwise: numberOptionalValidation,
  }),
  cobuyer_salary: numberRequiredValidation,
};

export const cobuyerPreviousEmploymentValidation = {
  cobuyer_previous_employer_name: Yup.string().when('cobuyer_years_at_job', {
    is: (cobuyer_years_at_job: number) => cobuyer_years_at_job < 2,
    then: employerNameValidation,
    otherwise: notRequiredString,
  }),
  cobuyer_previous_employer_phone_number: Yup.string().when('cobuyer_years_at_job', {
    is: (cobuyer_years_at_job: number) => cobuyer_years_at_job < 2,
    then: phoneValidationRequired,
    otherwise: notRequiredString,
  }),
  cobuyer_previous_job_title: Yup.string().when('cobuyer_years_at_job', {
    is: (cobuyer_years_at_job: number) => cobuyer_years_at_job < 2,
    then: jobTitleValidation,
    otherwise: notRequiredString,
  }),
  cobuyer_previous_years_at_job: Yup.number().when('cobuyer_years_at_job', {
    is: (cobuyer_years_at_job: number) => cobuyer_years_at_job < 2,
    then: numberRequiredValidation,
    otherwise: numberOptionalValidation,
  }),
  cobuyer_previous_months_at_job: Yup.number().when('cobuyer_previous_years_at_job', {
    is: (cobuyer_previous_years_at_job: number) => cobuyer_previous_years_at_job === 0,
    then: monthsValidation,
    otherwise: numberOptionalValidation,
  }),
};
