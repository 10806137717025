const TERMS_LABEL = `
  By clicking this box, I agree to the Lease End terms & conditions listed here
  and I understand that I am submitting an application for credit.
`;

const WISCONSIN_BUYER_LABEL = `
  By clicking this box, I acknowledge that Wisconsin law may require notice to be given to my spouse
  of this application for credit.
`;

const WISCONSIN_COBUYER_LABEL = `
  By clicking this box, the co-buyer acknowledges that Wisconsin law may require notice to be given
  to their spouse of this application for credit.
`;

interface TermBools {
  hardPullTermsAccepted: boolean;
  wisconsinBuyerTermsAccepted: boolean;
  wisconsinCobuyerTermsAccepted: boolean;
}

export { TERMS_LABEL, WISCONSIN_BUYER_LABEL, WISCONSIN_COBUYER_LABEL };
export type { TermBools };
