import {
  Button,
  ButtonProps,
  Divider,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { MdPerson } from '@react-icons/all-files/md/MdPerson';
import React, { useEffect, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';

import ProfileModal from '../../../components/ProfileModal';
import { DASHBOARD } from '../../../constants/urls';
import { Customer, useGetByAuth0IdLazyQuery } from '../../../gql/generated/graphql';
import { logout } from '../../../services/auth0';
import { titleCase } from '../../../utils/helpers';

const AuthMenu = ({ ...props }: Omit<ButtonProps, 'aria-label'>) => {
  const [customer, setCustomer] = useState<Customer>();
  const [profileModalOpen, setProfileModalOpen] = useState(false);

  const { pathname } = useLocation();
  const history = useHistory();
  const handleLogout = () => {
    logout();
  };

  const [fetchCustomer] = useGetByAuth0IdLazyQuery({
    onCompleted: (data) => {
      setCustomer(data?.getByAuth0Id as Customer);
    },
  });

  useEffect(() => {
    fetchCustomer();
  }, []);

  return (
    <>
      <Menu>
        <MenuButton
          aria-label="Profile"
          as={Button}
          p={0}
          variant="ghost"
          _active={{ outline: 'none', bg: 'transparent' }}
          _focus={{ outline: 'none', bg: 'transparent' }}
          _hover={{ bg: 'transparent' }}
          leftIcon={<Icon as={MdPerson} boxSize="26px" />}
          rightIcon={<Icon as={FaChevronDown} boxSize="12px" />}
          {...props}
        >
          {customer
            ? `${titleCase(`${customer?.first_name} ${customer?.last_name?.charAt(0)}`)}`
            : ''}
        </MenuButton>
        <MenuList zIndex="99">
          <>
            <MenuItem onClick={() => setProfileModalOpen(true)}>Profile</MenuItem>
            {pathname !== DASHBOARD && (
              <MenuItem onClick={() => history.push(DASHBOARD)}>My Dashboard</MenuItem>
            )}
            <Divider mx={4} w="auto" />
          </>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </MenuList>
      </Menu>
      <ProfileModal open={profileModalOpen} setOpen={setProfileModalOpen} customer={customer} />
    </>
  );
};

export default AuthMenu;
