import { useLocation } from 'react-router-dom';

import { LDFlags } from '../../../constants/flags';
import { PAYMENTS_DASHBOARD } from '../../../constants/urls';
import { Car, Maybe } from '../../../gql/generated/graphql';
import { useFlag } from '../../../hooks';
import BootedDashboard from '../dashboardStates/BootedDashboard';
import MainDashboard from '../dashboardStates/MainDashboard';
import NewDealDashboard from '../dashboardStates/NewDealDashboard';
import PendingDashboard from '../dashboardStates/PendingDashboard';
import { DealWithFarthestState } from '../utils';
import DashboardDownPaymentPage from './payments/DashboardDownPaymentPage';

interface DashboardContentProps {
  deal: DealWithFarthestState;
  hasNoDeals: boolean;
  isNewDeal: boolean;
  isDealBooted: boolean;
  isDealPending: boolean;
  inFlow: boolean;
  car: Maybe<Car>;
}

const DashboardContent = ({
  deal,
  hasNoDeals,
  isNewDeal,
  isDealBooted,
  isDealPending,
  inFlow,
  car,
}: DashboardContentProps) => {
  const dashboardDownPaymentEnabled = useFlag(LDFlags.COM_DASHBOARD_DOWN_PAYMENT);
  const { pathname } = useLocation();

  if (hasNoDeals || isNewDeal) {
    return <NewDealDashboard inFlow={inFlow} car={car} />;
  }

  if (dashboardDownPaymentEnabled && pathname === PAYMENTS_DASHBOARD) {
    return <DashboardDownPaymentPage deal={deal} />;
  }

  if (isDealBooted) {
    return <BootedDashboard deal={deal} />;
  }

  if (isDealPending) {
    return <PendingDashboard deal={deal} />;
  }

  return <MainDashboard deal={deal} />;
};

export default DashboardContent;
