import { Box, Button, HStack, Text, useBreakpointValue } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';

import ButtonedRadioGroup from '../../components/ButtonedRadioGroup';
import Loader from '../../components/Loader';
import { Input, Label, NumberInput } from '../../components/formComponents';
import { LDFlags } from '../../constants/flags';
import { PaymentMethod, useUserDealQuery } from '../../gql/generated/graphql';
import { useFlag, useLocalStorage } from '../../hooks';
import { LocalStorageKeys } from '../../services/localStorage';
import { PaymentFormValues, initialValues } from './PaymentForm';

const BasicInfoInputs = ({ dealId, allowCard }: { dealId?: string; allowCard?: boolean }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [, setFinixIdempotencyId] = useLocalStorage<string>(
    LocalStorageKeys.FINIX_IDEMPOTENCY_ID,
    '',
  );
  const [changeInformation, setChangeInformation] = useState(false);
  const breakOutCreditAndDebitCards = useFlag(LDFlags.BREAK_OUT_CREDIT_AND_DEBIT_CARDS);

  const { errors, setFieldValue, setFieldError, values, setValues } =
    useFormikContext<PaymentFormValues>();
  useEffect(() => {
    setFinixIdempotencyId(`down_payment_${new Date().getTime()}_${crypto.randomUUID()}`);
  }, [values.selected_payment_method]);

  const paymentMethodOptions = useMemo(
    () => ({
      bankAccount: {
        label: 'BANK TRANSFER',
        value: PaymentMethod.BankAccount,
      },
      ...(breakOutCreditAndDebitCards
        ? {
            creditCard: {
              label: 'CREDIT CARD',
              value: PaymentMethod.Credit,
            },
            debitCard: {
              label: 'DEBIT CARD',
              value: PaymentMethod.Debit,
            },
          }
        : {
            card: {
              label: isMobile ? 'CARD PAYMENT' : 'CREDIT/DEBIT CARD',
              value: PaymentMethod.CreditDebit,
            },
          }),
    }),
    [isMobile],
  );

  const { data: dealData, loading } = useUserDealQuery({
    skip: !dealId,
    variables: {
      id: dealId,
    },
  });

  useEffect(() => {
    if (!dealData?.userDeal) {
      return;
    }

    const customer = dealData?.userDeal?.customer;
    if (!customer) {
      return;
    }

    setValues({
      ...initialValues,
      ...{
        first_name: customer.first_name || '',
        last_name: customer.last_name || '',
        email: customer.email || '',
        phone_number: customer.phone_number || '',
      },
    });
  }, [dealData]);

  const showAutoFilledInfo =
    !changeInformation &&
    values.first_name &&
    values.last_name &&
    values.email &&
    values.phone_number;

  if (loading) {
    return <Loader my={20} />;
  }

  return (
    <Box>
      {showAutoFilledInfo ? (
        <Box mb={4} fontSize={{ base: '14px', md: '16px' }}>
          <Label mb={0}>Name</Label>
          <Text mb={3}>
            {values.first_name} {values.last_name}
          </Text>
          <Label mb={0}>Email</Label>
          <Text mb={3}>{values.email}</Text>
          <Label mb={0}>Phone Number</Label>
          <Text mb={3}>{values.phone_number}</Text>
          <Button
            onClick={() => setChangeInformation(!changeInformation)}
            variant="link"
            fontSize={{ base: '12px', md: '14px' }}
          >
            Change information
          </Button>
        </Box>
      ) : (
        <>
          <Text fontSize={14} fontWeight="semibold" color="leaseEndBlue" mb={2}>
            NAME ON ACCOUNT
          </Text>
          <HStack wrap={{ base: 'wrap', md: 'initial' }}>
            <Input
              name="first_name"
              _container={{ mb: { base: 0, md: 5 } }}
              invalid={!!errors.first_name}
              placeholder="First"
            />
            <Input
              name="last_name"
              _container={{ mb: 5 }}
              invalid={!!errors.last_name}
              placeholder="Last"
            />
          </HStack>
          <Input
            label="Email Address"
            name="email"
            _container={{ mb: 5 }}
            invalid={!!errors.email}
            placeholder="Email"
          />
          <Input
            label="Phone Number"
            name="phone_number"
            _container={{ mb: 5 }}
            mask="(999) 999-9999"
            type="tel"
            invalid={!!errors.phone_number}
            placeholder="Phone Number"
          />
        </>
      )}

      <Label mb={2}>DOWN PAYMENT METHOD</Label>
      {allowCard && (
        <ButtonedRadioGroup
          columns={breakOutCreditAndDebitCards ? 3 : 2}
          itemProps={{ px: 4, py: 3 }}
          itemTextProps={{ fontSize: { base: '12px', md: '14px' } }}
          collapseOnMobile={false}
          defaultValue={PaymentMethod.BankAccount}
          mb={8}
          name="selected_payment_method"
          onChange={(value) => {
            setFieldValue('selected_payment_method', value);
            setFieldError('amount', '');
          }}
          options={Object.values(paymentMethodOptions).map((method) => ({
            label: method.label,
            value: method.value,
          }))}
          value={values.selected_payment_method}
        />
      )}
      <NumberInput
        label="Down Payment Amount"
        name="amount"
        _container={{ mb: 5 }}
        isMoney
        placeholder="Down Payment Amount"
        invalid={!!errors.amount}
      />
    </Box>
  );
};

export default BasicInfoInputs;
