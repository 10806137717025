import { useEffect } from 'react';
import { FormikContextType, useFormikContext } from 'formik';

interface FormikEffectProps<T> {
  onChange: (context: FormikContextType<T>) => void;
}

// The comma must be there so that React knows it's a generic Type and not JSX
const FormikEffect = <T,>({ onChange }: FormikEffectProps<T>) => {
  const context = useFormikContext<T>();

  useEffect(() => {
    onChange(context);
  }, [context.errors, context.values]);

  return null;
};

export default FormikEffect;
