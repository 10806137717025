import { Card, Image } from '@chakra-ui/react';
import { Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

import { PointingGuyFlipped } from '../../assets/Images';
import {
  ConsentNameEnumType,
  PaymentMethod,
  PaymentState,
  useExecuteCardDownPaymentMutation,
  useExecuteDownPaymentMutation,
  useSaveConsentMutation,
} from '../../gql/generated/graphql';
import { useReadLocalStorage } from '../../hooks';
import { LocalStorageKeys } from '../../services/localStorage';
import {
  DOWN_PAYMENTS_STEPS,
  FINIX_APPLICATION_ID,
  FINIX_ENVIRONMENT,
  FinixAuth,
  PaymentData,
  PlaidData,
} from '../../utils/payments';
import { customerPersonalInfoValidation } from '../../utils/validation/customerInformation';
import BankAdded from './BankAdded';
import CardDetails from './CardDetails';
import DownPaymentInfo from './DownPaymentInfo';
import FailedPayment from './FailedPayment';
import Success from './Success';

const validationSchema = Yup.object({
  ...customerPersonalInfoValidation,
  amount: Yup.number()
    .typeError('Field is required')
    .min(1, 'Must be greater than or equal to $1')
    .max(50_000, 'Must be less than or equal to $50,000')
    .required('Amount is required'),
});

export const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  selected_payment_method: PaymentMethod.BankAccount,
  amount: null,
};

export type PaymentFormValues = typeof initialValues;

interface ProcessPaymentProps {
  allowCard?: boolean;
  dealId?: string;
  onSuccess?: () => void;
  finixAuth: FinixAuth;
}

const PaymentForm = ({ allowCard, dealId, onSuccess, finixAuth }: ProcessPaymentProps) => {
  const finixIdempotencyId = useReadLocalStorage<string>(
    LocalStorageKeys.FINIX_IDEMPOTENCY_ID,
  ) as string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [finixForm, setFinixForm] = useState<any>(null);
  const [step, setStep] = useState(DOWN_PAYMENTS_STEPS.PAYMENT_INFO);
  const [paymentData, setPaymentData] = useState<PaymentData>({ id: null });
  const [plaidData, setPlaidData] = useState<PlaidData | null>(null);
  const [errorMessage, setErrorMessage] = useState('');

  const [executeAchPayment] = useExecuteDownPaymentMutation();
  const [executeCardPayment] = useExecuteCardDownPaymentMutation({
    context: {
      isErrorHandled: true,
    },
  });
  const [saveConsentMutation] = useSaveConsentMutation();

  const performAchTransaction = async (
    values: PaymentFormValues,
    formikHelpers: FormikHelpers<PaymentFormValues>,
  ) => {
    if (!plaidData) {
      return;
    }

    const account = plaidData.accounts?.[0];

    if (dealId) {
      await saveConsentMutation({
        variables: {
          type: ConsentNameEnumType.AchPayment,
          dealId,
        },
      });
    }
    const fraudSessionId = finixAuth.getSessionKey();

    await executeAchPayment({
      variables: {
        firstName: values.first_name,
        lastName: values.last_name,
        phoneNumber: values.phone_number,
        email: values.email,
        amount: values.amount || 0,
        bank: plaidData.institution?.name ?? '',
        plaidAccountId: account.id,
        plaidToken: plaidData.publicToken,
        fraudSessionId,
        idempotencyId: finixIdempotencyId,
        dealId,
      },
      onCompleted: ({ executeDownPayment }) => {
        setPaymentData({ id: executeDownPayment?.id, amount: executeDownPayment?.amount });
        formikHelpers.resetForm();
        setStep(DOWN_PAYMENTS_STEPS.SUCCESS);
        onSuccess?.();
      },
      onError: () => {
        setStep(DOWN_PAYMENTS_STEPS.FAIL);
      },
      context: {
        isErrorHandled: true,
      },
    });
  };

  const performCardTransaction = async (values: PaymentFormValues) => {
    if (!finixForm) {
      return;
    }

    finixForm?.submit(
      FINIX_ENVIRONMENT,
      FINIX_APPLICATION_ID,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      async (err: unknown, res: { data: any }) => {
        const tokenData = res.data || {};
        const token = tokenData.id;

        const fraudSessionId = finixAuth.getSessionKey();

        await executeCardPayment({
          variables: {
            firstName: values.first_name,
            lastName: values.last_name,
            phoneNumber: values.phone_number,
            email: values.email,
            amount: Number(values.amount),
            token,
            fraudSessionId,
            idempotencyId: finixIdempotencyId,
            dealId,
            paymentMethod: values.selected_payment_method,
          },
          onCompleted: ({ executeCardDownPayment }) => {
            setPaymentData({
              id: executeCardDownPayment?.id,
              amount: executeCardDownPayment?.amount,
              cardBrand: executeCardDownPayment?.cardBrand,
              cardType: executeCardDownPayment?.cardType,
              maskedNumber: executeCardDownPayment?.maskedNumber,
            });

            if (err || executeCardDownPayment?.state !== PaymentState.Succeeded) {
              setErrorMessage((err as Error)?.message ?? '');
              setStep(DOWN_PAYMENTS_STEPS.FAIL);
            } else {
              setStep(DOWN_PAYMENTS_STEPS.SUCCESS);
              onSuccess?.();
            }
          },
          onError: (er) => {
            setErrorMessage(er.message);
            setStep(DOWN_PAYMENTS_STEPS.FAIL);
          },
          context: {
            isErrorHandled: true,
          },
        });
      },
    );
  };

  const handleSubmit = async (
    values: PaymentFormValues,
    formikHelpers: FormikHelpers<PaymentFormValues>,
  ) => {
    const isBankPayment = values.selected_payment_method === PaymentMethod.BankAccount;
    const isCardPayment = [
      PaymentMethod.CreditDebit,
      PaymentMethod.Credit,
      PaymentMethod.Debit,
    ].includes(values.selected_payment_method);

    if (isCardPayment) {
      await performCardTransaction(values);
    }

    if (isBankPayment) {
      await performAchTransaction(values, formikHelpers);
    }
  };

  const getStep = () => {
    switch (step) {
      case DOWN_PAYMENTS_STEPS.PAYMENT_INFO:
        return (
          <DownPaymentInfo
            dealId={dealId}
            setStep={setStep}
            setPlaidData={setPlaidData}
            allowCard={allowCard}
          />
        );
      case DOWN_PAYMENTS_STEPS.CARD_DETAILS:
        return <CardDetails setFinixForm={setFinixForm} />;
      case DOWN_PAYMENTS_STEPS.BANK_ADDED:
        return <BankAdded setStep={setStep} plaidData={plaidData as PlaidData} />;
      case DOWN_PAYMENTS_STEPS.FAIL:
        return (
          <FailedPayment
            paymentData={paymentData}
            plaidData={plaidData as PlaidData}
            setStep={setStep}
            errorMessage={errorMessage}
          />
        );
      case DOWN_PAYMENTS_STEPS.SUCCESS:
        return <Success paymentData={paymentData} plaidData={plaidData as PlaidData} />;
      default:
        return null;
    }
  };

  return (
    <Card
      position="relative"
      borderWidth="1px"
      borderRadius="10px"
      borderColor="gray.100"
      width="400px"
      maxW="100%"
      px={10}
      py={{ base: 8, md: 10 }}
    >
      {step !== DOWN_PAYMENTS_STEPS.SUCCESS && step !== DOWN_PAYMENTS_STEPS.FAIL && (
        <Image
          display={{ base: 'none', md: 'block' }}
          position="absolute"
          right="-82px"
          top={0}
          zIndex={1}
          src={PointingGuyFlipped}
          pointerEvents="none"
        />
      )}
      <Formik
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>{getStep()}</Form>
      </Formik>
    </Card>
  );
};

export default PaymentForm;
