import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useBreakpointValue,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { FaPhoneAlt } from '@react-icons/all-files/fa/FaPhoneAlt';
import { IoIosMenu, IoMdLock } from 'react-icons/io';
import { useLocation } from 'react-router-dom';

import { Logo, PaymentCalculatorIcon, SmallLogo } from '../../assets/Images';
import {
  ABOUT_US,
  ACCOUNT_LOGIN,
  BEST_MONEY,
  FAQS,
  FLOW_ENTRY,
  HOW_IT_WORKS,
  LEARN,
  MKT_HOME,
  MONTHLY_PAYMENT_CALCULATOR,
  REVIEWS,
} from '../../constants/urls';
import { CookieKeys, useCookie } from '../../hooks/useCookie';
import useSearchPhoneNumbers from '../../hooks/useSearchPhoneNumbers';
import useWindowSize from '../../hooks/useWindowSize';
import AuthMenu from '../../pages/auth/components/AuthMenu';
import BestMoneyPartnershipBadge from '../ui/GetStarted/BestMoneyPartnershipBadge';
import FlowProgressBar from './FlowProgressBar';
import HomeSimpleNavDropdown from './NavDropdown';
import NavLink from './NavLink';
import SidebarAuthMenu from './SidebarAuthMenu';

export const FAQ_BREAKPOINT = 1000;
export const LEARN_BREAKPOINT = 1050;
export const ABOUT_US_BREAKPOINT = 1100;
export const REVIEWS_BREAKPOINT = 1150;

const HIDE_MENU_ITEMS_PATHS = [FLOW_ENTRY];

interface NavProps {
  inFlow?: boolean;
  hideMenuItems?: boolean;
  hideAuthOptions?: boolean;
}

const Nav = ({ inFlow = false, hideMenuItems = false, hideAuthOptions = false }: NavProps) => {
  const isDesktop = useBreakpointValue({
    base: false,
    md: true,
  });

  const showSmallerLogo = useBreakpointValue({
    base: false,
    md: true,
    lg: false,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { pathname } = useLocation();
  const phoneNumber = useSearchPhoneNumbers();
  const { windowWidth } = useWindowSize();
  const [isSmallerThan355] = useMediaQuery('(max-width: 355px)');

  const [accessToken] = useCookie<string>(CookieKeys.ACCESS_TOKEN);
  const isAuthenticated = !!accessToken;

  const hideMenuLinks = hideMenuItems || inFlow || HIDE_MENU_ITEMS_PATHS.includes(pathname);
  const showAuthMenu = isAuthenticated && !inFlow;
  const showLoginBtn = !isAuthenticated && !inFlow;
  const showPartnershipBadge = pathname === BEST_MONEY && !isDesktop;

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      p={{ base: '10px', md: inFlow ? '20px 30px' : '20px 20px 40px' }}
      maxWidth="1920px"
      m="0 auto"
      position="relative"
      zIndex="1"
    >
      {isDesktop ? (
        <>
          <Flex
            alignItems="center"
            gap={inFlow ? 'unset' : '30px'}
            justifyContent={inFlow ? 'space-between' : 'unset'}
          >
            {inFlow && showSmallerLogo ? (
              <Link href={MKT_HOME}>
                <Image src={SmallLogo} alt="Lease End Logo" w="40px" />
              </Link>
            ) : (
              <Link href={MKT_HOME} minW={hideMenuLinks ? '220px' : 'unset'}>
                <Image src={Logo} alt="Lease End Logo" w="130px" />
              </Link>
            )}
            {!hideMenuLinks && (
              <>
                <NavLink to={HOW_IT_WORKS}>How it Works</NavLink>
                {windowWidth > FAQ_BREAKPOINT && <NavLink to={FAQS}>FAQs</NavLink>}
                {windowWidth > LEARN_BREAKPOINT && <NavLink to={LEARN}>Blog</NavLink>}
                {windowWidth > ABOUT_US_BREAKPOINT && <NavLink to={ABOUT_US}>About Us</NavLink>}
                {windowWidth > REVIEWS_BREAKPOINT && <NavLink to={REVIEWS}>Reviews</NavLink>}
                {windowWidth < REVIEWS_BREAKPOINT && <HomeSimpleNavDropdown />}
                <Flex alignItems="center" gap="10px">
                  <Image
                    src={PaymentCalculatorIcon}
                    alt="Payment Calculator Icon"
                    borderLeft="solid 2px #0000002E"
                    pl="30px"
                  />
                  <Link
                    href={MONTHLY_PAYMENT_CALCULATOR}
                    color="oceanBoatBlue"
                    fontSize="16px"
                    fontWeight="semibold"
                  >
                    Payment Calculator
                  </Link>
                </Flex>
              </>
            )}
          </Flex>
          <FlowProgressBar inFlow={inFlow} />
          <Flex alignItems="center" gap="40px">
            {!hideAuthOptions && (
              <>
                {showAuthMenu && <AuthMenu mr={4} color="oceanBoatBlue" />}
                {showLoginBtn && (
                  <Link href={ACCOUNT_LOGIN}>
                    <Button
                      leftIcon={<IoMdLock size="20px" color="white" />}
                      fontSize="16px"
                      backgroundColor="oceanBoatBlue"
                      color="white"
                      padding="20px 17.5px"
                      _hover={{
                        boxShadow: 'md',
                        backgroundColor: 'royalBlueLight',
                      }}
                    >
                      Log in
                    </Button>
                  </Link>
                )}
              </>
            )}
            {!showLoginBtn && !showAuthMenu && (
              <>
                <Box display={{ base: 'none', lg: 'block' }} mr="10px">
                  <Box mb="-5px" mt="5px">
                    Give Us a Call:
                  </Box>
                  <Link
                    fontSize="2rem"
                    fontWeight="bold"
                    _hover={{ textDecor: 'none' }}
                    href={`tel:+1-${phoneNumber}`}
                    aria-label="Phone number"
                    color="leaseEndBlue"
                  >
                    {phoneNumber}
                  </Link>
                </Box>
                <Box display={{ base: 'block', lg: 'none' }}>
                  <Link fontSize="2rem" href={`tel:+1-${phoneNumber}`} aria-label="Phone Number">
                    <FaPhoneAlt />
                  </Link>
                </Box>
              </>
            )}
          </Flex>
        </>
      ) : (
        <>
          <Flex direction={isSmallerThan355 ? 'column' : 'row'}>
            <Link href={MKT_HOME} mx={hideMenuLinks ? 'auto' : undefined} mb={1}>
              <Image src={Logo} alt="Lease End Logo" w="130px" />
            </Link>
            {showPartnershipBadge && <BestMoneyPartnershipBadge alignLeft={isSmallerThan355} />}
          </Flex>
          <Flex pr="10px">
            <Icon as={IoIosMenu} boxSize="40px" onClick={onOpen} color="oceanBoatBlue" />
          </Flex>
          <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton color="white" />
              <DrawerBody bg="matisseBlue" overflow="hidden">
                <SidebarAuthMenu />
                {!hideMenuLinks && (
                  <>
                    <Flex flexDir="column" gap="15px" mt="10px">
                      <NavLink
                        color="white"
                        to={MKT_HOME}
                        textTransform="uppercase"
                        _hover={{ color: 'white', textDecor: 'underline' }}
                      >
                        Home
                      </NavLink>
                      <NavLink
                        onClick={onClose}
                        color="white"
                        to={HOW_IT_WORKS}
                        textTransform="uppercase"
                        _hover={{ color: 'white', textDecor: 'underline' }}
                      >
                        How it Works
                      </NavLink>
                      <NavLink
                        onClick={onClose}
                        color="white"
                        to={FAQS}
                        textTransform="uppercase"
                        _hover={{ color: 'white', textDecor: 'underline' }}
                      >
                        FAQs
                      </NavLink>
                      <NavLink
                        onClick={onClose}
                        color="white"
                        isExternal
                        to={LEARN}
                        textTransform="uppercase"
                        _hover={{ color: 'white', textDecor: 'underline' }}
                      >
                        Blog
                      </NavLink>
                      <NavLink
                        onClick={onClose}
                        color="white"
                        to={ABOUT_US}
                        textTransform="uppercase"
                        _hover={{ color: 'white', textDecor: 'underline' }}
                      >
                        About Us
                      </NavLink>
                      <NavLink
                        onClick={onClose}
                        color="white"
                        to={REVIEWS}
                        textTransform="uppercase"
                        _hover={{ color: 'white', textDecor: 'underline' }}
                      >
                        Reviews
                      </NavLink>
                      <NavLink
                        onClick={onClose}
                        color="white"
                        to={MONTHLY_PAYMENT_CALCULATOR}
                        textTransform="uppercase"
                        _hover={{ color: 'white', textDecor: 'underline' }}
                      >
                        Payment Calculator
                      </NavLink>
                    </Flex>
                    <Divider m="10px 0" />
                  </>
                )}
                <Text color="white" fontSize="20px">
                  Give us a call
                </Text>
                <Link
                  href={`tel:${phoneNumber}`}
                  color="white"
                  fontSize="20px"
                  fontWeight="bold"
                  _hover={{ color: 'white', textDecor: 'underline' }}
                >
                  {phoneNumber}
                </Link>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      )}
    </Flex>
  );
};

export default Nav;
