import { InteractivityProps } from '@chakra-ui/react';
import React from 'react';

export enum ACCORDION_NAME {
  STORY_ACCORDION = 'Story Accordion',
  VEHICLE_ACCORDION = 'Vehicle Accordion',
  PAYOFF_ACCORDION = 'Payoff Accordion',
  LOAN_ACCORDION = 'Loan Accordion',
  LOAN_APP_ACCORDION = 'Loan App Accordion',
  COBUYER_LOAN_APP_ACCORDION = 'Cobuyer Loan App Accordion',
  FINAL_ADJUSTMENTS_ACCORDION = 'Final Adjustments Accordion',
  CUSTOMIZE_PACKAGE_ACCORDION = 'Customize Package Accordion',
  DOCUMENTS_ACCORDION = 'Documents Accordion',
}

export const ACCORDION_NAME_TO_INDEX: { [K in ACCORDION_NAME]: number } = {
  [ACCORDION_NAME.STORY_ACCORDION]: 999,
  [ACCORDION_NAME.VEHICLE_ACCORDION]: 0,
  [ACCORDION_NAME.PAYOFF_ACCORDION]: 1,
  [ACCORDION_NAME.LOAN_ACCORDION]: 2,
  [ACCORDION_NAME.LOAN_APP_ACCORDION]: 3,
  [ACCORDION_NAME.COBUYER_LOAN_APP_ACCORDION]: 4,
  [ACCORDION_NAME.FINAL_ADJUSTMENTS_ACCORDION]: 5,
  [ACCORDION_NAME.CUSTOMIZE_PACKAGE_ACCORDION]: 6,
  [ACCORDION_NAME.DOCUMENTS_ACCORDION]: 7,
} as const;

export type ItemStatusType = 'complete' | 'warn' | 'error' | 'loading' | 'none';
export type ItemProgressType = {
  currentProgress: number;
  totalProgress: number;
};

export type UpdatableItemStateType = {
  status: ItemStatusType;
  progress?: ItemProgressType;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
};

export type ItemType = { key: string } & UpdatableItemStateType;

export type InternalItemType = { index: number } & ItemType;

export const getCursor = (
  isExpanded: boolean,
  isDisabled: boolean,
): InteractivityProps['cursor'] => {
  if (isDisabled) {
    return 'not-allowed';
  }

  if (isExpanded) {
    return 'default';
  }

  return 'pointer';
};
