import { usePrevious } from '@chakra-ui/react';
import { useMemo } from 'react';

const useDiff = (value: { [key: string]: unknown }) => {
  const previous = usePrevious(value);

  const difference = useMemo(() => {
    let hasDiff = false;
    const changedState = Object.keys(value).reduce((diff, key) => {
      if (value?.[key] === previous?.[key]) return diff;
      hasDiff = true;
      return {
        ...diff,
        [key]: value[key],
      };
    }, {});
    return hasDiff ? changedState : undefined;
  }, [value]);

  return difference;
};

export default useDiff;
