import { MutableRefObject, useEffect, useRef, useState } from 'react';

// https://usehooks.com/useHover/
const useHover = <T = HTMLDivElement>(): [MutableRefObject<T>, boolean] => {
  const [value, setValue] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref: any = useRef<T>(null);
  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);
  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);
        return () => {
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseout', handleMouseOut);
        };
      }
      return () => null;
    },
    [ref.current], // Recall only if ref changes
  );
  return [ref, value];
};

export default useHover;
