import { Button, Flex } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';

import {
  PaymentMethod,
  useCreateDownPaymentLinkMutation,
  useUserDealQuery,
} from '../../gql/generated/graphql';
import { formatMoney } from '../../utils/helpers';
import { DOWN_PAYMENTS_STEPS, PlaidData } from '../../utils/payments';
import { MAX_PAYMENT_AMOUNT } from '../../utils/validation/payments';
import BasicInfoInputs from './BasicInfoInputs';
import { PaymentFormValues } from './PaymentForm';

interface DownPaymentProps {
  dealId?: string;
  setStep: (step: string) => void;
  setPlaidData: (plaidData: PlaidData) => void;
  allowCard?: boolean;
}

const DownPaymentInfo = ({ dealId, setStep, setPlaidData, allowCard }: DownPaymentProps) => {
  const { values, validateForm, setFieldError } = useFormikContext<PaymentFormValues>();
  const isBankPayment = values.selected_payment_method === PaymentMethod.BankAccount;
  const isCreditDebitPayment = values.selected_payment_method === PaymentMethod.CreditDebit;
  const isDebitPayment = values.selected_payment_method === PaymentMethod.Debit;
  const isCreditPayment = values.selected_payment_method === PaymentMethod.Credit;

  const isCardPayment = isCreditDebitPayment || isDebitPayment || isCreditPayment;

  const { data: dealData } = useUserDealQuery({
    skip: !dealId,
    variables: {
      id: dealId,
    },
    fetchPolicy: 'network-only',
  });
  const userDeal = dealData?.userDeal;

  const [token, setToken] = useState<string | null>(null);
  const [loadingPaymentLink, setLoadingPaymentLink] = useState(false);

  const [createPaymentLink] = useCreateDownPaymentLinkMutation({
    onCompleted: (data) => {
      setToken(data.createDownPaymentLink || null);
    },
  });

  const { open, ready } = usePlaidLink({
    token,
    onSuccess: (publicToken, metadata) => {
      setPlaidData({
        publicToken,
        ...metadata,
      });
      setToken(null);
      setLoadingPaymentLink(false);
      setStep(DOWN_PAYMENTS_STEPS.BANK_ADDED);
    },
    onExit: () => setLoadingPaymentLink(false),
  });

  const validateAndProceed = async () => {
    const formErrors = await validateForm(values);
    if (Object.keys(formErrors).length > 0) {
      return;
    }

    let allowedAmount = MAX_PAYMENT_AMOUNT;
    if (isCreditDebitPayment) {
      allowedAmount = Number(userDeal?.financial_info?.credit_debit_payment_amount_allowed);
    }
    if (isDebitPayment) {
      allowedAmount = Number(userDeal?.financial_info?.debit_payment_amount_allowed);
    }
    if (isCreditPayment) {
      allowedAmount = Number(userDeal?.financial_info?.credit_payment_amount_allowed);
    }

    let amountLimit = MAX_PAYMENT_AMOUNT;
    if (isCreditDebitPayment || isCreditPayment) {
      amountLimit = Number(userDeal?.financial_info?.credit_payment_amount_limit);
    }
    if (isDebitPayment) {
      amountLimit = Number(userDeal?.financial_info?.debit_payment_amount_limit);
    }

    if (
      isCardPayment &&
      dealId &&
      allowedAmount === amountLimit &&
      Number(values.amount) > allowedAmount
    ) {
      const cardType = `${isCreditPayment ? 'Credit card ' : ''}${
        isDebitPayment ? 'Debit card ' : ''
      }${isCreditDebitPayment ? 'Card ' : ''}`;
      setFieldError(
        'amount',
        `${cardType}transactions cannot exceed ${formatMoney(allowedAmount, { noCents: true })}`,
      );
      return;
    }

    if (isCardPayment && Number(values.amount) > allowedAmount) {
      setFieldError(
        'amount',
        `Payment exceeds card transaction limit. ${formatMoney(allowedAmount, {
          noCents: true,
        })} is still available.`,
      );
      return;
    }

    if (isCardPayment) {
      setStep(DOWN_PAYMENTS_STEPS.CARD_DETAILS);
      return;
    }
    setLoadingPaymentLink(true);
    open();
  };

  useEffect(() => {
    const getToken = async () => {
      await createPaymentLink();
    };

    if (!token) {
      getToken();
    }
  }, [token, createPaymentLink]);

  return (
    <Flex flexDir="column">
      <BasicInfoInputs dealId={dealId} allowCard={allowCard} />
      <Button
        mx="auto"
        isLoading={loadingPaymentLink}
        isDisabled={!ready}
        onClick={validateAndProceed}
      >
        {isBankPayment ? 'NEXT - LINK MY BANK' : 'ADD CARD'}
      </Button>
    </Flex>
  );
};

export default DownPaymentInfo;
