import { atom, useSetAtom } from 'jotai';
import { useEffect, useRef } from 'react';

import { LEAccordion } from '../../components/LEAccordion';
import LEAccordionContainer from '../../components/LEAccordion/LeAccordionContainer';
import { ACCORDION_NAME } from '../../components/LEAccordion/utils';
import Loader from '../../components/Loader';
import SafetyMessage from '../../components/SafetyMessage';
import { ButtonWrapper } from '../../components/ui/buttons/ButtonWrapper';
import SecondaryButton from '../../components/ui/buttons/SecondaryButton';
import { LDFlags } from '../../constants/flags';
import { OtherLienholder } from '../../constants/lienholders';
import { PayoffRequestStatus, usePayoffRequestQuery } from '../../gql/generated/graphql';
import useFlag from '../../hooks/useFlag';
import useLienholderInfo, { LienholderContext } from '../../hooks/useLienholderInfo';
import { useTempInfo } from '../../hooks/useTempInfo';
import { RudderEvent, rudderanalytics } from '../../utils/rudderstack';
import EnterYourPayoffItem from './components/EnterYourPayoffItem';
import Header from './components/Header';
import LienholderRequirements from './components/LienholderRequirements/LienholderRequirements';
import UploadLeaseAgreement from './components/PayoffDocumentsUpload';
import PayoffEducationItem from './components/PayoffEducationItem';
import PayoffRequestHelperButtons from './components/PayoffRequestHelperButtons';
import WeCanHelpItem from './components/WeCanHelpItem';

export const delayDigitalResponseAtom = atom(true);
export const helpAtom = atom(false);
export const digitallyFoundAtom = atom(false);
export const requirementsCompletedAtom = atom(false);

const AlternatePayoffInstructions = () => {
  const { info, infoLoading } = useTempInfo();
  const tempInfoData = info?.data;

  const setHelp = useSetAtom(helpAtom);
  const setDigitallyFound = useSetAtom(digitallyFoundAtom);
  const uploadLeaseAgreementFlag = useFlag(LDFlags.LEASE_AGREEMENT_UPLOAD);
  const isInitialized = useRef(false);

  const { data: payoffRequest, loading: payoffRequestLoading } = usePayoffRequestQuery({
    fetchPolicy: 'network-only',
  });

  const isOtherLienholder = tempInfoData?.lienholder === OtherLienholder;
  const lienholderCtx = useLienholderInfo({
    hasName: !!tempInfoData?.first_name && !!tempInfoData?.last_name,
    state: info?.data?.state ?? '',
    slug: tempInfoData?.lienholder_slug,
  });

  const forceCustomerFindPayoff = Object.values(lienholderCtx?.payoffMethods)
    .map((objValue) => (objValue as { value: string }).value)
    .every((val) => val === 'FALSE');
  const payoffMethodsLoading =
    tempInfoData?.lienholder !== OtherLienholder &&
    Object.keys(lienholderCtx?.payoffMethods).length === 0;
  const loading = infoLoading || lienholderCtx.lienholderLoading || payoffMethodsLoading;

  useEffect(() => {
    if (
      isInitialized.current ||
      infoLoading ||
      lienholderCtx.lienholderLoading ||
      payoffRequestLoading ||
      !info?.data
    ) {
      return;
    }
    isInitialized.current = true;

    const alreadyDigitallyFound =
      !payoffRequest?.payoffRequest && lienholderCtx.allowsDigital && !!info.data.vehicle_payoff;

    const showPayoffPending =
      payoffRequest?.payoffRequest?.status === PayoffRequestStatus.Pending ||
      payoffRequest?.payoffRequest?.status === PayoffRequestStatus.Fetching ||
      payoffRequest?.payoffRequest?.status === PayoffRequestStatus.FailureRequested;
    const showPayoffCompleted =
      alreadyDigitallyFound ||
      payoffRequest?.payoffRequest?.status === PayoffRequestStatus.Completed;
    const showPayoffFailure =
      !info.data.vehicle_payoff &&
      payoffRequest?.payoffRequest?.status === PayoffRequestStatus.Failed;
    const showDigitalPayoffFlow = !info.data.vehicle_payoff && lienholderCtx.allowsDigital;

    setHelp(showPayoffPending || showPayoffCompleted || showPayoffFailure || showDigitalPayoffFlow);

    setDigitallyFound(alreadyDigitallyFound);
  }, [
    info,
    infoLoading,
    payoffRequest,
    payoffRequestLoading,
    lienholderCtx.allowsDigital,
    lienholderCtx.lienholderLoading,
  ]);
  useEffect(() => {
    rudderanalytics.track(RudderEvent.RStackWebEstimate);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <LienholderContext.Provider value={lienholderCtx}>
      <LEAccordionContainer>
        <Header make={tempInfoData?.make || ''} model={tempInfoData?.model || ''} />
        <LEAccordion name={ACCORDION_NAME.PAYOFF_ACCORDION} minutes={5}>
          {!isOtherLienholder && !forceCustomerFindPayoff ? (
            <>
              {lienholderCtx.allowsDigital && <WeCanHelpItem />}
              <LienholderRequirements />
            </>
          ) : (
            <PayoffEducationItem
              isOtherLienholder={isOtherLienholder}
              forceCustomerFindPayoff={forceCustomerFindPayoff}
            />
          )}
          <EnterYourPayoffItem />
          {uploadLeaseAgreementFlag && <UploadLeaseAgreement />}
        </LEAccordion>
        <SafetyMessage />
        <ButtonWrapper>
          <SecondaryButton />
        </ButtonWrapper>
        <PayoffRequestHelperButtons />
      </LEAccordionContainer>
    </LienholderContext.Provider>
  );
};

export default AlternatePayoffInstructions;
