import { vehicleType } from '../constants/vehicleType';
import { TtFuelType } from '../gql/generated/graphql';

const fuelTypeOptions = [
  { label: TtFuelType.Gasoline, value: TtFuelType.Gasoline },
  { label: TtFuelType.Electric, value: TtFuelType.Electric },
  { label: TtFuelType.Hybrid, value: TtFuelType.Hybrid },
  { label: TtFuelType.Diesel, value: TtFuelType.Diesel },
];

const vehicleTypeOptions = [
  { label: 'Sedan', value: vehicleType.PASSENGER },
  { label: 'Truck', value: vehicleType.TRUCK },
  { label: 'SUV/Van/Crossover', value: vehicleType.MPV },
];

export { fuelTypeOptions, vehicleTypeOptions };
