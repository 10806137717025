import { Flex, Text } from '@chakra-ui/react';
import { Form, Formik, FormikValues } from 'formik';
import { useState } from 'react';

import CarDetails from '../../../components/CarDetails';
import Modal from '../../../components/Modal';
import TextWithTooltip from '../../../components/TextWithTooltip';
import { SSNInput } from '../../../components/formComponents';
import Button from '../../../components/ui/buttons/Button';
import { Deal, DealType, useVerifyAccountMutation } from '../../../gql/generated/graphql';
import { useLocalStorage } from '../../../hooks';
import { LocalStorageKeys } from '../../../services/localStorage';
import { pluralize } from '../../../utils/helpers';
import { DealWithFarthestState } from '../../dashboard/utils';

interface Props {
  deal: DealWithFarthestState | Deal | undefined;
  open: boolean;
  onClose: () => void;
  handleNextSubmit: () => void;
  download?: boolean;
}

const MAX_ATTEMPTS = 5;
const SSN_DIGITS = '4';

const VerifyAccountModal = ({ deal, open, onClose, handleNextSubmit, download = false }: Props) => {
  const [error, setError] = useState<string>('');
  const [verifyAccountMutation] = useVerifyAccountMutation();
  const [linkAccountBlocked, setLinkAccountBlocked] = useLocalStorage(
    LocalStorageKeys.LINK_ACCOUNT_INVALID_SSN,
    [{ dealId: '', isCobuyer: false, attempts: 0, expiresAt: 0 }],
    (value) => value instanceof Array,
  );

  const isRefi = deal?.type === DealType.Refi;

  const validateMaxAttempts = () => {
    let linkAcc = linkAccountBlocked.filter(
      (blocked) => blocked.dealId === deal?.id && blocked.isCobuyer === deal?.isCobuyer,
    )[0];
    if (!linkAcc) {
      setLinkAccountBlocked([
        ...linkAccountBlocked,
        { dealId: deal?.id ?? '', isCobuyer: deal?.isCobuyer ?? false, attempts: 0, expiresAt: 0 },
      ]);
      [linkAcc] = linkAccountBlocked.filter(
        (blocked) => blocked.dealId === deal?.id && blocked.isCobuyer === deal?.isCobuyer,
      );
    }

    const HALF_HOUR = new Date().getTime() + 1000 * 60 * 30;

    const remainingMinutes = linkAcc
      ? Math.round((linkAcc.expiresAt - new Date().getTime()) / 1000 / 60)
      : 0;

    if (remainingMinutes > 0 && linkAcc && linkAcc?.attempts >= MAX_ATTEMPTS) {
      setError(
        `Max one-time login attempts reached. Please try again in ${remainingMinutes} ${pluralize(
          'minute',
          remainingMinutes,
        )}.`,
      );

      return false;
    }

    if (remainingMinutes <= 0) {
      setLinkAccountBlocked([
        ...linkAccountBlocked.filter((blocked) => blocked.dealId !== deal?.id),
        {
          dealId: deal?.id ?? '',
          isCobuyer: deal?.isCobuyer ?? false,
          expiresAt: HALF_HOUR,
          attempts: 1,
        },
      ]);
      setError('');
    } else {
      setLinkAccountBlocked([
        ...linkAccountBlocked.filter((blocked) => blocked.dealId !== deal?.id),
        {
          dealId: deal?.id ?? '',
          isCobuyer: deal?.isCobuyer ?? false,
          expiresAt: HALF_HOUR,
          attempts: linkAcc ? linkAcc.attempts + 1 : 1,
        },
      ]);
    }

    return true;
  };

  const handleSubmit = async (values: FormikValues) => {
    if (!validateMaxAttempts()) return;

    try {
      await verifyAccountMutation({
        variables: {
          ssn_last_4: values.ssn.slice(-4),
          deal_id: deal?.id ?? '',
          is_cobuyer: deal?.isCobuyer,
        },
      }).then(() => {
        handleNextSubmit();
        onClose();
        setLinkAccountBlocked([
          ...linkAccountBlocked.filter((blocked) => blocked.dealId !== deal?.id),
          {
            dealId: deal?.id ?? '',
            isCobuyer: deal?.isCobuyer ?? false,
            expiresAt: 0,
            attempts: 0,
          },
        ]);
      });
    } catch {
      setError('SSN does not match. Check your SSN and try again.');
    }
  };

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      size="md"
      subtitle={`Confirm your SSN to ${download ? 'access' : 'sign'} your documents.`}
    >
      <Flex flexDir="column" textAlign="center">
        <Formik
          enableReinitialize
          initialValues={{
            ssn: '',
            optOut: false,
            id: deal?.id, // This resets formik when changing between cars
            isCobuyer: deal?.isCobuyer, // This resets formik when changing between cars (where cobuyer and buyer have the same email)
          }}
          onSubmit={handleSubmit}
        >
          <Form>
            {!download && (
              <>
                <CarDetails car={deal?.car} pb={0} />
                <Text textAlign="center" pt="10.5px">
                  You are a {deal?.isCobuyer && 'co'}buyer on this vehicle
                </Text>
                <TextWithTooltip label="If this is not your vehicle or if you are experiencing difficulty verifying the last four digits of your SSN, please reach out to us.">
                  {deal?.isCobuyer
                    ? `Not part of this ${isRefi ? 'loan' : 'lease'}?`
                    : 'Not your vehicle?'}
                </TextWithTooltip>
              </>
            )}
            <SSNInput
              mt="8px"
              label={`Last ${SSN_DIGITS} of ${deal?.isCobuyer ? 'cobuyer' : 'buyer'} SSN`}
              onChange={error ? () => setError('') : undefined}
              digits={SSN_DIGITS}
              name="ssn"
              _container={{ h: 'auto' }}
            />
            {error && (
              <Text color="red" fontSize="sm" mt={1} maxH="80px">
                {error}
              </Text>
            )}
            <Button mt="20px" mb="5px" w="full" type="submit">
              {download ? 'DOWNLOAD' : 'CONFIRM'}
            </Button>
          </Form>
        </Formik>
      </Flex>
    </Modal>
  );
};

export default VerifyAccountModal;
