import { Box, Button, Center, Checkbox, Container, Flex, Text } from '@chakra-ui/react';
import { Form, Formik, FormikValues } from 'formik';
import { useSetAtom } from 'jotai';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import CarDetails from '../../components/CarDetails';
import Loader from '../../components/Loader';
import SSNInput from '../../components/formComponents/ssn/SSNInput';
import { DASHBOARD } from '../../constants/urls';
import {
  DealType,
  useGetUnlinkedDealsQuery,
  useLinkAccountMutation,
  useOptOutMutation,
} from '../../gql/generated/graphql';
import { useLocalStorage } from '../../hooks';
import { LocalStorageKeys } from '../../services/localStorage';
import { pluralize } from '../../utils/helpers';
import { dashboardDealInfoAtom } from '../dashboard/Dashboard';
import Subtitle from './components/Subtitle';
import Title from './components/Title';

const MAX_ATTEMPTS = 5;
const SSN_DIGITS = '4';

const LinkMyAccount = () => {
  const history = useHistory();
  const [error, setError] = useState('');
  const setDealId = useSetAtom(dashboardDealInfoAtom);
  const [showOptOut, setShowOptOut] = useState(false);

  const { data, loading, refetch } = useGetUnlinkedDealsQuery({ fetchPolicy: 'network-only' });
  const [linkAccountMutation] = useLinkAccountMutation();
  const [optOutMutation] = useOptOutMutation();

  const unlinkedDeals = data?.getUnlinkedDeals ?? [];
  const deal = unlinkedDeals?.[0];
  const isRefi = deal?.type === DealType.Refi;

  const [linkAccountBlocked, setLinkAccountBlocked] = useLocalStorage(
    LocalStorageKeys.LINK_ACCOUNT_INVALID_SSN,
    { attempts: 0, expiresAt: 0 },
    (value) => value?.attempts != null,
  );

  const validateMaxAttempts = () => {
    const HALF_HOUR = new Date().getTime() + 1000 * 60 * 30;

    const remainingMinutes = Math.round(
      (linkAccountBlocked.expiresAt - new Date().getTime()) / 1000 / 60,
    );

    if (remainingMinutes > 0 && linkAccountBlocked.attempts >= MAX_ATTEMPTS) {
      setError(
        `Max one-time login attempts reached. Please try again in ${remainingMinutes} ${pluralize(
          'minute',
          remainingMinutes,
        )}.`,
      );

      return false;
    }

    if (remainingMinutes <= 0) {
      setLinkAccountBlocked({
        expiresAt: HALF_HOUR,
        attempts: 1,
      });
      setError('');
    } else {
      setLinkAccountBlocked({
        expiresAt: HALF_HOUR,
        attempts: linkAccountBlocked.attempts + 1,
      });
    }

    return true;
  };

  const reset = () => {
    setLinkAccountBlocked({
      expiresAt: 0,
      attempts: 0,
    });
    setShowOptOut(false);
    setError('');
    setDealId(undefined);
    refetch();
  };

  const handleSubmit = async (values: FormikValues) => {
    if (!validateMaxAttempts()) return;

    try {
      await linkAccountMutation({
        variables: {
          ssn_last_4: values.ssn.slice(-4),
          deal_id: deal?.id,
          is_cobuyer: deal?.isCobuyer,
        },
      });
      reset();
    } catch {
      setError('SSN does not match. Check your SSN and try again.');
    }
  };

  const handleOptOut = async () => {
    await optOutMutation({ variables: { deal_id: deal?.id, is_cobuyer: deal?.isCobuyer } });
    reset();
  };

  if (!loading && !deal) {
    history.push(DASHBOARD);
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <Container pt={10} maxW="container.lg" width="100%">
      <Title>Confirm Your Information</Title>
      <Subtitle>Verify your SSN to add this vehicle to your account.</Subtitle>

      <Formik
        enableReinitialize
        initialValues={{
          ssn: '',
          optOut: false,
          id: deal?.id, // This resets formik when changing between cars
          isCobuyer: deal?.isCobuyer, // This resets formik when changing between cars (where cobuyer and buyer have the same email)
          showOptOut, // This resets formik when changing between states
        }}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Center>
              <Box bg="white" borderRadius="lg" shadow="md" pb={4} px={8} maxW="320px">
                <CarDetails car={deal?.car} pb={0} />
                <Text textAlign="center" pt="10.5px">
                  You are a {deal?.isCobuyer && 'co'}buyer on this vehicle
                </Text>
                {!showOptOut ? (
                  <>
                    <Button
                      variant="link"
                      onClick={() => setShowOptOut(true)}
                      mt={3}
                      mb={6}
                      mx="auto"
                      w="full"
                    >
                      {deal?.isCobuyer
                        ? `Not part of this ${isRefi ? 'loan' : 'lease'}?`
                        : 'Not your vehicle?'}
                    </Button>
                    <SSNInput
                      label={`Last ${SSN_DIGITS} of ${deal?.isCobuyer ? 'cobuyer' : 'buyer'} SSN`}
                      onChange={error ? () => setError('') : undefined}
                      digits={SSN_DIGITS}
                      name="ssn"
                      _container={{ h: 'auto' }}
                    />
                    {error && (
                      <Text color="red" fontSize="sm" mt={1} maxH="80px">
                        {error}
                      </Text>
                    )}
                    <Button mt="20px" mb="5px" w="full" type="submit">
                      CONFIRM
                    </Button>
                  </>
                ) : (
                  <>
                    <Checkbox
                      mt={3}
                      mb={6}
                      name="optOut"
                      isChecked={values.optOut}
                      onChange={() => setFieldValue('optOut', !values.optOut)}
                    >
                      <Text fontSize="11px" color="black">
                        I confirm that I am not associated with this vehicle{' '}
                        {isRefi ? 'loan' : 'lease'} and do not want it to be linked to my account or
                        displayed on my dashboard.
                      </Text>
                    </Checkbox>
                    <Flex direction="column">
                      <Button w="100%" onClick={handleOptOut} isDisabled={!values.optOut}>
                        THIS IS NOT MY {isRefi ? 'LOAN' : 'LEASE'}
                      </Button>
                      <Button mt={4} w="100%" variant="link" onClick={() => setShowOptOut(false)}>
                        Go back
                      </Button>
                    </Flex>
                  </>
                )}
              </Box>
            </Center>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default LinkMyAccount;
