import { string } from 'yup';

import { JOB_TITLE_MAX_LENGTH } from '../../constants/customers';
import { requiredStringMaxCharsValidation } from '../strings';

export const employerNameValidation = requiredStringMaxCharsValidation({
  max: 50,
  fieldName: 'Employer Name',
}).required('Please enter employer name');

export const employmentStatusValidation = string()
  .nullable()
  .required('Employment status is required');

export const jobTitleValidation = requiredStringMaxCharsValidation({
  max: JOB_TITLE_MAX_LENGTH,
  fieldName: 'Job Title',
});
