import { Center, Stack } from '@chakra-ui/react';
import { Form, Formik, FormikHelpers } from 'formik';
// eslint-disable-next-line no-restricted-imports
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { ChangeEvent, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { useLEAccordionItemContext } from '../../../../components/LEAccordion/LEAccordionItem/useLEAccordionItem';
import Input from '../../../../components/formComponents/Input';
import NumberInput from '../../../../components/formComponents/NumberInput';
import PrimaryButton from '../../../../components/ui/buttons/PrimaryButton';
import { VEHICLE_COLORS } from '../../../../constants/vehicleColors';
import {
  useCalculateOptionsLazyQuery,
  useKbbValuesLazyQuery,
  useTemporaryInfoUpsertMutation,
  useVehicleMileageQuery,
} from '../../../../gql/generated/graphql';
import { useTempInfo } from '../../../../hooks/useTempInfo';
import {
  autoCheckWarningApplicable,
  endingInZeroMileageWarningApplicable,
  overMileageWarningApplicable,
} from '../../../../utils/mileageWarnings';
import { customerAddressValidation } from '../../../../utils/validation/customerInformation';
import { mileageValidation } from '../../../../utils/validation/mileage';
import ColorPicker from './components/ColorPicker';
import MileageWarning from './components/MileageWarning';
import { useZip } from './useZip';

interface FormikValues {
  mileage: string;
  zip: string;
  city: string;
  county: string;
  state: string;
  color: VEHICLE_COLORS;
}

const Content = ({ refreshToggle }: { refreshToggle: () => void }) => {
  const { updateState, index, setOpenAccordionIndex, isExpanded } = useLEAccordionItemContext();
  const { info } = useTempInfo('network-only', isExpanded);
  const { isCityCountyNeeded, handleZip, CityCounty } = useZip<FormikValues>();
  const ldClient = useLDClient();

  const [calculateOptions] = useCalculateOptionsLazyQuery({ fetchPolicy: 'network-only' });
  const [getKbbValues] = useKbbValuesLazyQuery();
  const [upsertTemporaryInfo] = useTemporaryInfoUpsertMutation();
  const { data: mileageData } = useVehicleMileageQuery({
    skip: !info?.data?.vin,
    variables: { vin: info?.data?.vin as string },
    context: {
      isErrorHandled: true,
    },
  });

  const [showingErrors, setShowingErrors] = useState<boolean>(false);
  const [inputMileage, setInputMileage] = useState<number>(0);
  const [mileageWarnings, setMileageWarnings] = useState<{
    autoCheck: boolean;
    endingInZero: boolean;
    overMileage: boolean;
  }>({
    autoCheck: false,
    endingInZero: false,
    overMileage: false,
  });
  const { autoCheck, endingInZero, overMileage } = mileageWarnings;

  const handleNext = async (values: FormikValues, helpers: FormikHelpers<FormikValues>) => {
    if (!info?.data) {
      return;
    }
    updateState({ status: 'loading' });

    const mileage = Number(values.mileage);
    if (autoCheck || ((endingInZero || overMileage) && !showingErrors)) {
      setShowingErrors(true);
      updateState({ status: 'warn' });
      return;
    }

    const response = await calculateOptions({
      variables: {
        // vehicleId can be undefined/null if the kbbValues query failed find the vehicle.
        // This is most common with brand new vehicles and Volvos
        vehicleId: info.data.kbb_vehicle_id as number,
        allKbbVehicleOptions: info.data.kbb_all_options,
        vin: info.data.vin ?? '',
        color: values.color,
      },
    });

    const selectedOptions = response?.data?.calculateOptions ?? [];
    const selectedOptionIds = selectedOptions.map((option) => Number(option?.vehicleOptionId));

    // KBB Stuff
    const { data: valuesData } = await getKbbValues({
      variables: {
        data: {
          mileage,
          allKbbVehicleOptions: info.data.kbb_all_options,
          vehicleId: info.data.kbb_vehicle_id,
          vehicleOptionIds: selectedOptionIds,
        },
      },
    });

    const zipValues = await handleZip(values, helpers);
    if (!zipValues) {
      updateState({ status: 'warn' });
      return;
    }
    const { city, county, state, zip } = zipValues;

    const data = {
      retail_book_value: valuesData?.kbbValues?.retail,
      kbb_retail_mileage_adjustment: valuesData?.kbbValues?.retailMileageAdjustment,
      kbb_retail_option_adjustment: valuesData?.kbbValues?.retailOptionAdjustment,
      book_value: valuesData?.kbbValues?.lending,
      kbb_lending_mileage_adjustment: valuesData?.kbbValues?.lendingMileageAdjustment,
      kbb_lending_option_adjustment: valuesData?.kbbValues?.lendingOptionAdjustment,
      kbb_valuation_date: valuesData?.kbbValues?.valuationDate,
      kbb_vehicle_name: info.data.kbb_vehicle_name,
      kbb_trim_name: info.data.kbb_trim_name,
      kbb_selected_options: valuesData?.kbbValues?.kbbSelectedOptions,
      zip,
      city: city.trim(),
      county: county.trim(),
      state,
      mileage: Number(values.mileage),
      color: values.color,
    };

    refreshToggle();

    await upsertTemporaryInfo({
      variables: {
        info: {
          id: info.id,
          data,
        },
      },
    });

    updateState({ status: 'complete' });
    ldClient?.track('vehicle-options-selected', {});
    setOpenAccordionIndex(index + 1);
  };

  useEffect(() => {
    if (!inputMileage || !mileageData) return;

    setMileageWarnings({
      autoCheck: autoCheckWarningApplicable(
        mileageData?.vehicleMileage?.lastOdometer,
        mileageData?.vehicleMileage?.lastOdometerDate,
        inputMileage,
      ),
      endingInZero: endingInZeroMileageWarningApplicable(inputMileage),
      overMileage: overMileageWarningApplicable(inputMileage),
    });
  }, [inputMileage, mileageData]);

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleNext}
      initialValues={{
        mileage: String(info?.data.mileage || ''),
        zip: info?.data.zip || '',
        city: info?.data.city || '',
        county: info?.data.county || '',
        state: info?.data.state || '',
        color: (info?.data.color || '') as VEHICLE_COLORS,
      }}
      validationSchema={Yup.object({
        zip: customerAddressValidation.zip,
        mileage: mileageValidation,
        color: Yup.string().required('Color is required'),
        ...(isCityCountyNeeded
          ? {
              city: customerAddressValidation.city,
              county: customerAddressValidation.county,
              state: customerAddressValidation.state,
            }
          : {}),
      })}
    >
      {({ isSubmitting, values }) => {
        const mileage = Number(values.mileage);

        return (
          <Form>
            <Stack direction={{ base: 'column', md: 'row' }} mt="45px" mb="15px">
              <NumberInput
                name="mileage"
                placeholder="Mileage"
                label="Current Odometer"
                showThousandSeparator
                isWhole
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setInputMileage(Number(event.target.value.replace(',', '')));
                  setShowingErrors(false);
                }}
              />
              <Stack direction="row">
                <Input
                  name="zip"
                  placeholder="ZIP Code"
                  label="Zip Code"
                  alignSelf="flex-start"
                  _input={{
                    inputMode: 'numeric',
                    maxLength: 5,
                  }}
                />
                <ColorPicker />
              </Stack>
            </Stack>
            {showingErrors && (
              <MileageWarning
                lastOdometer={mileageData?.vehicleMileage?.lastOdometer ?? 0}
                lastOdometerDate={mileageData?.vehicleMileage?.lastOdometerDate ?? ''}
                mileage={mileage}
                mileageWarnings={mileageWarnings}
                vin={info?.data.vin}
              />
            )}
            {CityCounty}
            <Center mt="30px">
              <PrimaryButton type="submit" isLoading={isSubmitting}>
                NEXT
              </PrimaryButton>
            </Center>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Content;
