import { gql } from '@apollo/client';

export const externalCustomerUpdate = gql`
  mutation externalCustomerUpdate($customerId: ID!, $customer: ExternalCustomerInput) {
    externalCustomerUpdate(customerId: $customerId, customer: $customer) {
      id
    }
  }
`;

export const getByAuth0Id = gql`
  query getByAuth0Id {
    getByAuth0Id {
      first_name
      last_name
      full_name
      email
    }
  }
`;

export const linkAccount = gql`
  mutation linkAccount($ssn_last_4: String, $deal_id: ID, $is_cobuyer: Boolean) {
    linkAccount(ssn_last_4: $ssn_last_4, deal_id: $deal_id, is_cobuyer: $is_cobuyer)
  }
`;

export const optOut = gql`
  mutation optOut($deal_id: ID, $is_cobuyer: Boolean) {
    optOut(deal_id: $deal_id, is_cobuyer: $is_cobuyer)
  }
`;

export const verifyAccount = gql`
  mutation verifyAccount($ssn_last_4: String!, $deal_id: ID!, $is_cobuyer: Boolean) {
    verifyAccount(ssn_last_4: $ssn_last_4, deal_id: $deal_id, is_cobuyer: $is_cobuyer)
  }
`;

export const linkUnlinkedCustomers = gql`
  mutation linkUnlinkedCustomers {
    linkUnlinkedCustomers
  }
`;
