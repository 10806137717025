import { useLazyQuery } from '@apollo/client';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useContext, useEffect, useState } from 'react';

import {
  delayDigitalResponseAtom,
  digitallyFoundAtom,
  helpAtom,
  requirementsCompletedAtom,
} from '../..';
import { MagnifyingGlassPurpleGuy } from '../../../../assets/Images';
import { LEAccordionItem } from '../../../../components/LEAccordion';
import {
  PayoffRequest,
  PayoffRequestStatus,
  useOnPayoffRequestUpdateSubscription,
  usePayoffRequestQuery,
  useTemporaryInfoLazyQuery,
  useTemporaryInfoUpsertMutation,
} from '../../../../gql/generated/graphql';
import { payoffQuery } from '../../../../gql/prs/payoffGql';
import { CustomerInfoValues } from '../../../../gql/prs/types';
import { CookieKeys, useCookie } from '../../../../hooks/useCookie';
import { LienholderContext, LienholderContextType } from '../../../../hooks/useLienholderInfo';
import { useTempInfo } from '../../../../hooks/useTempInfo';
import { RudderEvent, rudderanalytics } from '../../../../utils/rudderstack';
import EducationContent from '../PayoffEducationItem/Content';
import PayoffFailure from '../PayoffFailure';
import Content from './Content';
import PayoffRetrieval from './PayoffRetrieval';

const GET_PAYOFF_TIMEOUT = 5000;

const LienholderRequirements = () => {
  const [guid] = useCookie<string>(CookieKeys.GUID_KEY);

  const { allowsDigital, lienholderRequirements } =
    useContext<LienholderContextType>(LienholderContext);

  const { info } = useTempInfo();
  const [requirementsCompleted, setRequirementsCompleted] = useAtom(requirementsCompletedAtom);
  const [delayDigitalResponse, setDelayDigitalResponse] = useAtom(delayDigitalResponseAtom);
  const help = useAtomValue(helpAtom);
  const setDigitallyFound = useSetAtom(digitallyFoundAtom);
  const [payoffRequest, setPayoffRequest] = useState<PayoffRequest | undefined | null>(undefined);

  useEffect(() => {
    if (
      (!allowsDigital && lienholderRequirements.length === 0) ||
      info?.data?.vehicle_payoff ||
      (!!payoffRequest && payoffRequest.status !== PayoffRequestStatus.Cancelled)
    ) {
      setRequirementsCompleted(true);
      setDelayDigitalResponse(false);
    }
  }, [info, payoffRequest]);

  const [customerInfoValues, setCustomerInfoValues] = useState<CustomerInfoValues>(
    {} as CustomerInfoValues,
  );

  const [upsertTemporaryInfo] = useTemporaryInfoUpsertMutation();
  const [getPayoffInfo, { data: autoPayoffGatheringData, error: autoPayoffGatheringError }] =
    useLazyQuery(payoffQuery);

  const [digitalGatheringExplanationExpanded, setDigitalGatheringExplanationExpanded] =
    useState(false);

  const [tempInfoLazyQuery] = useTemporaryInfoLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: guid || '',
    },
  });

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!digitalGatheringExplanationExpanded) {
      return;
    }

    const getPayoffInfoTimer = setTimeout(() => {
      setDelayDigitalResponse(false);
    }, GET_PAYOFF_TIMEOUT);
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(getPayoffInfoTimer);
  }, [digitalGatheringExplanationExpanded]);

  const { refetch: payoffRequestRefetch } = usePayoffRequestQuery({
    fetchPolicy: 'network-only',
    onCompleted: (payoffRequestData) => {
      if (payoffRequestData.payoffRequest) {
        setPayoffRequest(payoffRequestData.payoffRequest);
      } else {
        setPayoffRequest(null);
      }
    },
  });

  useOnPayoffRequestUpdateSubscription({
    onSubscriptionData: ({ subscriptionData }) => {
      const updatedPayoffRequest = subscriptionData.data?.onPayoffRequestUpdate as PayoffRequest;
      if (updatedPayoffRequest) {
        setPayoffRequest(updatedPayoffRequest);
        // This is only here to update the cache
        if (updatedPayoffRequest.status !== PayoffRequestStatus.Failed) {
          tempInfoLazyQuery();
          payoffRequestRefetch();
        }
      }
    },
  });

  useEffect(() => {
    if (autoPayoffGatheringData) {
      const { payoff } = autoPayoffGatheringData;
      const salesTax = payoff.includesSalesTax
        ? payoff.grossPayoffAmount - payoff.netPayoffAmount
        : undefined;

      setDigitallyFound(true);

      upsertTemporaryInfo({
        variables: {
          info: {
            id: guid,
            data: {
              vehicle_payoff: payoff.grossPayoffAmount,
              good_through_date: new Date(payoff.goodThroughDate),
              payoff_includes_sales_tax: payoff.includesSalesTax,
              taxes: salesTax,
            },
          },
        },
      });
    }
  }, [guid, autoPayoffGatheringData]);

  const failed = payoffRequest?.status === PayoffRequestStatus.Failed;
  const digitalFailure =
    allowsDigital && ((!delayDigitalResponse && !!autoPayoffGatheringError) || failed);

  useEffect(() => {
    if (digitalFailure) {
      rudderanalytics.track(RudderEvent.PayoffAutomaticallyRetrieveFailure, {
        lienholder: info?.data?.lienholder ?? '',
      });
    }
  }, [digitalFailure]);

  const renderContent = () => {
    if (!help) {
      return <EducationContent failed={failed} />;
    }

    if (failed) {
      return <PayoffFailure />;
    }

    if (requirementsCompleted) {
      return (
        <PayoffRetrieval
          customerInfoValues={customerInfoValues}
          digitalFailure={digitalFailure}
          getPayoffInfo={getPayoffInfo}
          payoffRequest={payoffRequest}
          payoffRequestRefetch={payoffRequestRefetch}
          setDigitalGatheringExplanationExpanded={setDigitalGatheringExplanationExpanded}
        />
      );
    }

    return <Content setCustomerInfoValues={setCustomerInfoValues} />;
  };

  return (
    <LEAccordionItem
      accordionKey="gettingYourPayoff"
      title="GETTING YOUR PAYOFF"
      imgSrc={!help || !failed ? MagnifyingGlassPurpleGuy : undefined}
    >
      {() => renderContent()}
    </LEAccordionItem>
  );
};

export default LienholderRequirements;
