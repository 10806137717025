export const WebCloserName = 'Web';

export enum BankEnum {
  FifthThird = 'Fifth Third Bank NA',
  Mechanics = 'Mechanics Bank',
  WellsFargo = 'Wells Fargo Auto',
  BankOfAmerica = 'Bank of America NA',
  CapitalOne = 'Capital One',
  AllyFinancial = 'Ally Financial',
  PNC = 'PNC Bank, NA',
  Westlake = 'Westlake Financial Services',
  TD = 'TD Bank N.A.',
  FirstInterstate = 'First Interstate Bank',
  ICCU = 'Idaho Central Credit Union',
}
