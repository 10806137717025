import { Button, Spinner, Stack, Text } from '@chakra-ui/react';
import React, { useState } from 'react';

import { TemporaryInfo } from '../../../../../gql/generated/graphql';
import LetsFixThat from './LetsFixThat';

interface Props {
  info?: TemporaryInfo;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const SomethingsNotRight = ({ info, setRefresh }: Props) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <Stack
      direction={{ base: 'column-reverse', md: 'row' }}
      w="full"
      alignItems="center"
      justifyContent="space-between"
    >
      <Button variant="link" pl={1} cursor="pointer" onClick={() => setOpenModal(!openModal)}>
        No, something's not right
      </Button>
      <Text as="b" pr={1}>
        VIN: {info?.data?.vin ? info.data.vin : <Spinner size="sm" />}
      </Text>

      <LetsFixThat
        info={info}
        isOpen={openModal}
        setOpenModal={setOpenModal}
        setRefresh={setRefresh}
      />
    </Stack>
  );
};

export default SomethingsNotRight;
