import {
  Box,
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabelProps,
  Icon,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  StackProps,
  VStack,
} from '@chakra-ui/react';
import { useField } from 'formik';
import React, { ForwardedRef, ReactNode, forwardRef, useRef } from 'react';
import ReactDatePicker, {
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom';
import { BsCalendar3, BsFillXCircleFill } from 'react-icons/bs';
import InputMask, { Props as InputMaskProps } from 'react-input-mask';

import FormErrorMessage from '../FormErrorMessage';
import Label from '../Label';
import CustomHeader from './CustomHeader';
import './styles.css';

interface Props extends InputProps {
  mask: InputMaskProps['mask'];
  maskPlaceholder?: InputMaskProps['maskPlaceholder'];
  hideCalendar?: boolean;
}

const dateInput = (
  { value, onClick, onChange, fontSize, mask, maskPlaceholder, hideCalendar }: Props,
  ref: ForwardedRef<HTMLInputElement>,
) => (
  <InputMask mask={mask} maskPlaceholder={maskPlaceholder} value={value} onChange={onChange}>
    <Input
      placeholder={maskPlaceholder ?? undefined}
      fontSize={fontSize}
      autoComplete="off"
      value={value}
      onChange={onChange}
      ref={ref}
      onClick={onClick}
      style={{ caretColor: hideCalendar ? 'unset' : 'transparent' }}
      inputMode="numeric"
    />
  </InputMask>
);
dateInput.displayName = 'DateInput';
const CustomInput = forwardRef(dateInput);

const root = document.getElementById('root');
const PopperContainer = (props: { children: ReactNode[] }) => {
  const { children } = props;
  return root ? ReactDOM.createPortal(children, root) : null;
};

const transformValue = (value: string) => {
  if (typeof value !== 'string') {
    return value;
  }

  const date = new Date(value);
  if (date instanceof Date && date.getTime()) {
    return date;
  }
  return null;
};

interface DateInputProps extends FormControlProps {
  name: NonNullable<InputProps['name']>;
  label?: string;
  showPopperArrow?: boolean;
  isClearable?: boolean;
  filterDate?: (d: Date) => boolean;
  hideCalendar?: boolean;
  labelProps?: FormLabelProps;
  isDisabled?: boolean;
  dateFormat?: ReactDatePickerProps['dateFormat'];
  mask?: InputMaskProps['mask'];
  maskPlaceholder?: InputMaskProps['maskPlaceholder'];
  monthAndYearOnly?: boolean;
  helperText?: string;
  readOnly?: boolean;
  openToDate?: Date;
  _container?: StackProps;
}

const DateInput: React.FC<DateInputProps> = ({
  name,
  label,
  showPopperArrow,
  isClearable,
  filterDate,
  hideCalendar,
  labelProps,
  fontSize,
  isDisabled = false,
  dateFormat = 'MM/dd/yyyy',
  mask = '99/99/9999',
  maskPlaceholder = 'MM/DD/YYYY',
  monthAndYearOnly = false,
  helperText,
  readOnly = false,
  openToDate = new Date(),
  _container,
  ...rest
}) => {
  const [field, { error, touched }, { setValue, setTouched }] = useField(name);
  const datePickerRef = useRef<ReactDatePicker>(null);

  const today = new Date();
  const hasValue = !!field.value;

  const isInvalid = touched && error != null;

  const handleChange = (date: Date | null) => {
    setTouched(true);

    if (date && monthAndYearOnly) {
      const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
      date.setDate(lastDayOfMonth);
    }

    setValue(date);
  };

  return (
    <VStack w="100%" spacing={0} h="100%" {..._container}>
      <FormControl isInvalid={isInvalid} isDisabled={isDisabled} isReadOnly={readOnly} {...rest}>
        {label && <Label {...labelProps}>{label}</Label>}
        <InputGroup>
          <ReactDatePicker
            disabled={isDisabled}
            readOnly={readOnly}
            calendarContainer={hideCalendar ? () => null : undefined}
            ref={datePickerRef}
            disabledKeyboardNavigation
            filterDate={filterDate}
            selected={transformValue(field.value)}
            onChange={(date) => handleChange(date)}
            showPopperArrow={showPopperArrow}
            startDate={!monthAndYearOnly ? today : undefined}
            openToDate={!monthAndYearOnly ? openToDate : undefined}
            dateFormat={dateFormat}
            popperPlacement="bottom"
            customInput={
              <CustomInput
                fontSize={fontSize}
                mask={mask}
                maskPlaceholder={maskPlaceholder}
                hideCalendar={hideCalendar}
              />
            }
            renderCustomHeader={(params: ReactDatePickerCustomHeaderProps) => (
              <CustomHeader {...params} />
            )}
            popperContainer={PopperContainer}
            popperModifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
              {
                name: 'preventOverflow',
                options: {
                  rootBoundary: 'viewport',
                  tether: false,
                  altAxis: true,
                },
              },
            ]}
            showMonthYearPicker={monthAndYearOnly}
            showFullMonthYearPicker={monthAndYearOnly}
          />
          {!hideCalendar && (
            <InputRightElement>
              <Icon
                opacity={isDisabled ? 0.4 : 1}
                as={hasValue && isClearable ? BsFillXCircleFill : BsCalendar3}
                w={5}
                h={5}
                onClick={() => {
                  if (isDisabled) {
                    return;
                  }
                  if (hasValue && isClearable) {
                    setValue(null);
                  }
                  datePickerRef?.current?.setOpen(true);
                }}
                cursor={isDisabled ? 'not-allowed' : 'pointer'}
              />
            </InputRightElement>
          )}
        </InputGroup>
        {(isInvalid || helperText) && (
          <Box mt="5px" ml="5px">
            {isInvalid ? (
              <FormErrorMessage color="leaseEndRed">{error}</FormErrorMessage>
            ) : (
              <FormHelperText color="taupeGray">{helperText}</FormHelperText>
            )}
          </Box>
        )}
      </FormControl>
    </VStack>
  );
};

export default DateInput;
