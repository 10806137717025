import { FormLabel, FormLabelProps } from '@chakra-ui/react';

const Label = ({ textAlign = 'left', children, ...props }: FormLabelProps) => (
  <FormLabel
    textAlign={textAlign}
    mb={props.mb || '5px'}
    color="leaseEndBlue"
    fontWeight="semibold"
    letterSpacing="1px"
    textTransform="uppercase"
    {...props}
  >
    {children}
  </FormLabel>
);

export default Label;
