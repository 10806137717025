import { Box } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Loader from '../../components/Loader';
import { GETTING_STARTED, MKT_HOME } from '../../constants/urls';
import { useTemporaryInfoLazyQuery } from '../../gql/generated/graphql';
import { CookieKeys, useCookie } from '../../hooks/useCookie';
import { rudderanalytics } from '../../utils/rudderstack';

const FLOW_REENTRY_ID_PARAM = 'id';

const FlowReenter = () => {
  const [, setGuid] = useCookie<string>(CookieKeys.GUID_KEY);

  const { search } = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(search);
  const id = params.get(FLOW_REENTRY_ID_PARAM);

  const [getTempInfo] = useTemporaryInfoLazyQuery({ context: { isErrorHandled: true } });

  const returnHome = () => {
    window.location.href = MKT_HOME;
  };

  const checkTempInfo = async () => {
    if (!id || id === 'default') {
      returnHome();
      return;
    }
    const tempInfo = await getTempInfo({ variables: { id } });

    if (!tempInfo?.data?.temporaryInfo?.data) {
      returnHome();
      return;
    }

    setGuid(id);
    rudderanalytics.identify({ guid: id });
    history.push(tempInfo.data.temporaryInfo.data.last_url || GETTING_STARTED);
  };

  useEffect(() => {
    checkTempInfo();
  }, [id]);

  return (
    <Box h="100vh">
      <Loader />
    </Box>
  );
};

export default FlowReenter;
