const YEAR_DIFF = 8;

export enum ERROR_TYPES {
  MISSING_DATA = 'Missing data',
  DECODING_SERVICE_ISSUE = 'Decoding Service Issue',
  INVALID_LICENSE_PLATE = 'Invalid license plate or state',
  OLD_CAR = 'Old Car',
  DEAL_EXISTS = 'Deal exists',
  IN_FLOW = 'In flow',
  RESTRICTED_CAR = 'Restricted Car',
}

export const OLD_CAR_ERROR_MSG =
  'We are typically unable to secure financing for leases that ' +
  `are ${YEAR_DIFF}+ years old. Thank you.`;

export const UNABLE_TO_ASSIST_ERROR_MSG =
  'We cannot assist with lease buyouts for this vehicle type due to certain restrictions. ' +
  'Thank you.';

export const UNAVAILABLE_LIEHOLDER_ERROR_MSG =
  'Unfortunately, we are unable to assist customers with this specific lienholder. ' +
  'Thank you for understanding.';
