import { Box, Text, useDisclosure } from '@chakra-ui/react';
import { ReactNode, useMemo } from 'react';

import useRefSize from '../hooks/useRefSize';

interface Props {
  defaultHeight: number;
  children: ReactNode;
  enabled?: boolean;
}

const ExpandableContent = ({ defaultHeight = 200, enabled = true, children }: Props) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: false });
  const [sizeRef, size] = useRefSize('scroll');

  const hasExpandableContent = useMemo(
    () => size.height > defaultHeight,
    [size.height, defaultHeight],
  );

  const isClosed = hasExpandableContent && !isOpen && enabled;

  return (
    <Box>
      <Box
        position="relative"
        fontSize="16px"
        overflow="hidden"
        height={isClosed ? `${defaultHeight}px` : '100%'}
      >
        <Box ref={sizeRef}>{children}</Box>
        {isClosed && (
          <Box
            position="absolute"
            bottom="0"
            width="100%"
            height="3em"
            backgroundImage="linear-gradient(to bottom, transparent, white)"
          />
        )}
      </Box>
      {hasExpandableContent && enabled && (
        <Text color="oceanBoatBlue" fontWeight="bold" cursor="pointer" onClick={onToggle}>
          {isOpen ? 'Read Less' : 'Read More'}
        </Text>
      )}
    </Box>
  );
};

export default ExpandableContent;
