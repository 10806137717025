import { Box, BoxProps, Button, Text } from '@chakra-ui/react';

import { useTempInfo } from '../../../hooks';
import { hasCobuyer } from '../../../utils/tempInfo';

interface Props extends BoxProps {
  onClick?: () => void;
  isDisabled?: boolean;
}

const AddCobuyerButton = ({ onClick, isDisabled, ...props }: Props) => {
  const tempInfo = useTempInfo();
  const tempInfoData = tempInfo.info?.data;

  if (!tempInfoData || hasCobuyer(tempInfoData)) {
    return null;
  }

  return (
    <Box {...props} my={2}>
      <Button onClick={onClick} isDisabled={isDisabled} mx={1} variant="link">
        Add Co-Buyer
      </Button>
      <Text color="taupeGray" fontSize="11px" textAlign="center">
        Not Required
      </Text>
    </Box>
  );
};

export default AddCobuyerButton;
