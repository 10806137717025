import { useBreakpoint } from '@chakra-ui/react';
// eslint-disable-next-line no-restricted-imports
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { Dispatch, SetStateAction, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import {
  ERROR_TYPES,
  OLD_CAR_ERROR_MSG,
  UNABLE_TO_ASSIST_ERROR_MSG,
} from '../../constants/tempInfos';
import {
  TemporaryData,
  useCreateManualEntryTemporaryInfoMutation,
} from '../../gql/generated/graphql';
import { useStep } from '../../hooks';
import { CookieKeys, clearCookie, useCookie } from '../../hooks/useCookie';
import { PrequalParams, getExtraInfo, getFlowParams } from '../../pages/flowEntryPages/utils';
import { RudderEvent, rudderanalytics } from '../../utils/rudderstack';
import { stateValidation } from '../../utils/validation/address';
import validateLicenseNumber, {
  INVALID_LICENSE_PLATE_MSG,
  INVALID_VIN_MSG,
} from '../../utils/validation/licenseNumberValidator';
import validateVin from '../../utils/validation/vinValidator';
import CarDetailsModal from '../CarDetailsModal';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const validationSchema = Yup.object({
  license_plate_number: Yup.string()
    .required('Please enter a value')
    .test('is-valid-license-number', INVALID_LICENSE_PLATE_MSG, async (value) =>
      validateLicenseNumber({ licenseNumber: value }),
    ),
  license_plate_state: stateValidation,
  vin: Yup.string()
    .required('Please enter a value')
    .test('is-valid-vin', INVALID_VIN_MSG, (value) => validateVin(value)),
  year: Yup.string().required('Please enter a value'),
  make: Yup.string().required('Please enter a value'),
  model: Yup.string().required('Please enter a value'),
  fuel_type: Yup.string().required('Please select a value'),
  vehicle_type: Yup.string().required('Please select a value'),
  kbb_trim_name: Yup.string(),
});

const initialValues = {
  license_plate_number: '',
  license_plate_state: '',
  vin: '',
  year: '',
  make: '',
  model: '',
  fuel_type: '',
  vehicle_type: '',
  kbb_trim_name: '',
};

const VehicleManualEntryModal = ({ isOpen, setIsOpen }: Props) => {
  const step = useStep();
  const ldClient = useLDClient();
  const [, setGuid] = useCookie<string>(CookieKeys.GUID_KEY);
  const [prequalInfo] = useCookie<PrequalParams>(CookieKeys.PREQUAL_PARAMS);
  const device = useBreakpoint();
  const { pathname, search } = useLocation();
  const flowParams = getFlowParams(search);

  const [dealExists, setDealExists] = useState(false);
  const [flowStartError, setFlowStartError] = useState('');

  const [createManualEntryTempInfo] = useCreateManualEntryTemporaryInfoMutation({
    context: {
      isErrorHandled: true,
    },
    onCompleted: ({ createManualEntryTemporaryInfo }) => {
      const id = createManualEntryTemporaryInfo?.id as string;
      const tempInfoData = createManualEntryTemporaryInfo?.data;

      if (tempInfoData?.vin) {
        ldClient?.track('Flow Entry', {});
        TagManager.dataLayer({
          dataLayer: {
            VIN: tempInfoData.vin,
            event: 'hotjar',
          },
        });
      }

      setGuid(id);
      clearCookie(CookieKeys.PREQUAL_PARAMS);
      rudderanalytics.identify({ guid: id });
      rudderanalytics.track(RudderEvent.VinProvided, {
        year: tempInfoData?.year || undefined,
        make: tempInfoData?.make || undefined,
        model: tempInfoData?.model || undefined,
        vin: tempInfoData?.vin || undefined,
        zip: tempInfoData?.zip || undefined,
        license_plate: tempInfoData?.license_plate_number || undefined,
        license_plate_state: tempInfoData?.license_plate_state || undefined,
        fuel_type: tempInfoData?.fuel_type || undefined,
        vehicle_type: tempInfoData?.vehicle_type || undefined,
        manual_entry: true,
      });

      setIsOpen(false);
      step.moveNext({}, true);
    },
    onError: (error) => {
      rudderanalytics.track(RudderEvent.Error, {
        error_message: error.message,
        pathname,
      });

      if (error.message === ERROR_TYPES.DEAL_EXISTS) {
        setDealExists(true);
      }
      if (error.message === ERROR_TYPES.OLD_CAR) {
        setFlowStartError(OLD_CAR_ERROR_MSG);
      }
      if (error.message === ERROR_TYPES.RESTRICTED_CAR) {
        setFlowStartError(UNABLE_TO_ASSIST_ERROR_MSG);
      }
    },
  });

  const handleWarningsReset = () => {
    setDealExists(false);
    setFlowStartError('');
  };

  const handleSubmit = async (values: Partial<TemporaryData>) => {
    await createManualEntryTempInfo({
      variables: {
        license_plate: values.license_plate_number,
        state: values.license_plate_state,
        vin: values.vin || '',
        year: values.year,
        make: values.make,
        model: values.model,
        fuel_type: values.fuel_type,
        vehicle_type: values.vehicle_type,
        kbb_trim_name: values.kbb_trim_name,
        device_type: device,
        extra_input: getExtraInfo(flowParams, prequalInfo),
        query_params: search,
      },
    });
  };

  return (
    <CarDetailsModal
      subtitle="Double check that all the information is correct"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      validationSchema={validationSchema}
      resetWarnings={handleWarningsReset}
      flowStartError={flowStartError}
      dealExists={dealExists}
      isFlowStart
    />
  );
};

export default VehicleManualEntryModal;
