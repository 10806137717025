import { string } from 'yup';

// validate for US phone numbers
const phoneRegExp = /^(?:\+?1-?)?(?:\d{3}|\(\d{3}\))[- ]?\d{3}[- ]?\d{4}$/;

const checkSequential = (value: string | null | undefined) => {
  if (!value) return true;
  const digitsOnly = value.replace(/\D/g, '');
  return !['1234567890', '012345678'].some((seq) => digitsOnly.includes(seq));
};

const checkRepeating = (value: string | null | undefined) => {
  if (!value) return true;
  const digitsOnly = value.replace(/\D/g, '');
  return !/(\d)\1{6,}/.test(digitsOnly); // Remove non-digits and check for 7+ consecutive repeats
};

export const phoneValidationRequired = string()
  .nullable()
  .required('Field is required')
  .matches(phoneRegExp, 'Please enter a valid phone number')
  .test('no-sequential-digits', 'Please enter a valid phone number', (value) =>
    checkSequential(value),
  )
  .test('no-repeating-digits', 'Please enter a valid phone number', (value) =>
    checkRepeating(value),
  );

export const phoneValidationOptional = string()
  .nullable()
  .matches(phoneRegExp, 'Please enter a valid phone number')
  .test('no-sequential-digits', 'Please enter a valid phone number', (value) =>
    checkSequential(value),
  )
  .test('no-repeating-digits', 'Please enter a valid phone number', (value) =>
    checkRepeating(value),
  );
