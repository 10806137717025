import { Box, Flex, Icon, Image, Input, Text, Tooltip, useToast } from '@chakra-ui/react';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import { IconType } from 'react-icons';
import { MdCheckCircle, MdPictureAsPdf } from 'react-icons/md';

import {
  Deal,
  DealMediaTypeEnum,
  useCreateExternalDealUploadUrlMutation,
  useMediaInsertComMutation,
} from '../../gql/generated/graphql';
import { logger } from '../../services/sentry';
import {
  INPUT_FILE_TYPES,
  MAX_FILE_SIZE_MB,
  convertFile,
  generateFileName,
} from '../../utils/media';
import { createSuccessToast } from '../../utils/toast';
import { DocumentSideType, DocumentType } from '../documents/components/ThingsWeNeed/utils';

interface Props {
  doc: DocumentType;
  image: {
    side: DocumentSideType;
    placeholderIcon: IconType;
    type: DealMediaTypeEnum;
  };
  counter: Dispatch<SetStateAction<number>>;
  deal: Deal;
}

const UploadItem = ({ doc, image, counter, deal }: Props) => {
  const [error, setError] = useState<string>('');
  const [imageUrl, setImageUrl] = useState<string | ArrayBuffer | null>('');
  const [file, setFile] = useState<File | null>();
  const [alreadyCounted, setAlreadyCounted] = useState<boolean>(false);

  const [createUploadUrlCom] = useCreateExternalDealUploadUrlMutation();
  const [mediaInsert] = useMediaInsertComMutation();

  const toast = useToast();
  const inputRef = useRef<HTMLInputElement>(null);

  const uploadImage = async ({
    dealId,
    allowPdf,
    fileToUpload,
  }: {
    dealId: string | number;
    allowPdf?: boolean;
    fileToUpload: File;
  }) => {
    if (!fileToUpload) {
      return;
    }

    const type = doc.images[0].type as DealMediaTypeEnum;
    const convertedFile = await convertFile(fileToUpload, allowPdf);

    const filename = generateFileName(type, convertedFile.name);

    try {
      const { data } = await createUploadUrlCom({
        variables: {
          fileName: filename,
          fileDescription: type,
          dealId,
        },
      });

      if (data?.createExternalDealUploadUrl?.url && data?.createExternalDealUploadUrl?.key) {
        const uploadResponse = await fetch(data?.createExternalDealUploadUrl?.url, {
          method: 'PUT',
          body: convertedFile,
        });

        if (!uploadResponse.ok) {
          throw Error();
        }

        const { data: mediaInsertData } = await mediaInsert({
          variables: {
            key: data?.createExternalDealUploadUrl?.key,
            type,
            dealId,
          },
        });

        const newMedia = mediaInsertData?.mediaInsertCom;
        if (!newMedia) {
          throw Error();
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      logger.error(
        'useMediaCollection.tsx',
        'There was an error uploading your file.',
        type,
        error,
      );
      throw Error(e.message);
    }
  };

  const handleFileSelected = async (acceptedFiles: File[]) => {
    setError('');

    const fileToUpload = acceptedFiles[0] as File;

    setFile(fileToUpload);

    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result);
    };
    reader.readAsDataURL(fileToUpload);

    try {
      await uploadImage({
        dealId: deal?.id ?? '',
        allowPdf: true,
        fileToUpload,
      });

      if (!alreadyCounted) {
        counter((prev) => prev + 1);
        setAlreadyCounted(true);
      }
    } catch (e) {
      logger.error(
        'dashboard/components/UploadDocuments.tsx:handleUpload',
        'Failed uploading file',
        null,
        e,
      );
      setError((e as Error).message);
    } finally {
      if (inputRef.current) {
        inputRef.current.value = '';
      }
      toast(
        createSuccessToast({
          title: 'File uploaded successfully',
        }),
      );
    }
  };

  const handleRejection = (fileRejections: FileRejection[]) => {
    if (fileRejections[0].errors[0].code === 'file-invalid-type') {
      setError(
        `Files must be a valid format (${INPUT_FILE_TYPES.map((filetype) =>
          filetype.split('/')[1].toUpperCase(),
        ).join(', ')}).`,
      );
    } else if (fileRejections[0].errors[0].code === 'file-too-large') {
      setError(`Files cannot exceed ${MAX_FILE_SIZE_MB}MB.`);
    } else if (fileRejections[0].errors[0].code === 'too-many-files') {
      setError('Upload one file at a time.');
    } else {
      setError('There was an error uploading your file.');
    }
  };

  return (
    <Flex gap="15px">
      <Dropzone
        onDropAccepted={(acceptedFiles) => handleFileSelected(acceptedFiles)}
        onDropRejected={(fileRejections) => {
          handleRejection(fileRejections);
          setFile(null);
        }}
        accept={[...INPUT_FILE_TYPES, 'application/pdf']}
        maxFiles={1}
        noClick
        noKeyboard
        multiple={false}
        maxSize={MAX_FILE_SIZE_MB * 1000000}
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <Flex
              {...getRootProps()}
              boxSize="100px"
              border="2px dashed"
              borderColor="teacupBlue"
              borderRadius="5px"
              bgColor="white"
              _hover={{
                border: '2px solid',
                borderColor: 'teacupBlue',
                bgColor: 'grayBackground',
              }}
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
              onClick={() => inputRef.current?.click()}
              position="relative"
              zIndex="1"
            >
              {!file ? (
                <Icon as={image.placeholderIcon} boxSize="50px" color="iconicGreen" />
              ) : (
                <>
                  {file.type === 'application/pdf' ? (
                    <Icon as={MdPictureAsPdf} boxSize="50px" color="iconicGreen" />
                  ) : (
                    <Image src={imageUrl as string} boxSize="90px" borderRadius="5px" />
                  )}
                </>
              )}
              {file && (
                <Box
                  position="absolute"
                  bottom="-11px"
                  boxSize="22px"
                  bgColor="white"
                  borderRadius="50%"
                  p="1px"
                  boxShadow="md"
                >
                  <Icon boxSize="20px" as={MdCheckCircle} color="iconicGreen" zIndex="2" />
                </Box>
              )}
              <Input {...getInputProps()} size={undefined} display="none" ref={inputRef} />
            </Flex>
          );
        }}
      </Dropzone>
      <Flex
        flexDir="column"
        gap="7px"
        pt="5px"
        textAlign="center"
        justifyContent="center"
        w="fit-content"
      >
        <Text>
          {doc.title}
          {image.side && ` - ${image.side}`}
        </Text>
        {doc.whyWeAsk && (
          <Tooltip label={doc.whyWeAsk}>
            <Text fontSize="12px" color="royalBlue">
              Why do we need this?
            </Text>
          </Tooltip>
        )}
      </Flex>
    </Flex>
  );
};

export default UploadItem;
