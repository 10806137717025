import {
  Box,
  Card,
  CardProps,
  Divider,
  HStack,
  Icon,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { IoMdInformationCircle } from 'react-icons/io';

import ExpandableContent from '../../../components/ExpandableContent';
import { LDFlags } from '../../../constants/flags';
import { statesArray } from '../../../constants/states';
import { ttJurisdictionDefaultConditions } from '../../../constants/taterTitle';
import {
  DealType,
  StateAbbreviation,
  TitleRegistrationOption,
  TtDocumentName,
  TtProductType,
  useGetTtJurisdictionLazyQuery,
} from '../../../gql/generated/graphql';
import { useFlag } from '../../../hooks';
import { getAddressValue } from '../../../utils/address';
import { dateOrdinal } from '../../../utils/dates';
import { DealStateEnum } from '../../../utils/types/deal';
import { documents } from '../../documents/components/ThingsWeNeed/utils';
import { getRequiredDocTypes } from '../../documents/utils';
import { DealWithFarthestState, dealStatusDescription, getDealStatusLabel } from '../utils';

interface Props extends CardProps {
  deal?: DealWithFarthestState;
}

const StatusDetails = ({ deal, ...rest }: Props) => {
  const dashboardDownPaymentEnabled = useFlag(LDFlags.COM_DASHBOARD_DOWN_PAYMENT);

  const shouldCollapseContent =
    useBreakpointValue({ base: true, md: false }) || dashboardDownPaymentEnabled;
  const [registrationDocumentsNeeded, setRegistrationDocumentsNeeded] = useState<TtDocumentName[]>(
    [],
  );
  const [titleDocumentsNeeded, setTitleDocumentsNeeded] = useState<TtDocumentName[]>([]);
  const customer = deal?.customer;
  const stateAbbr = customer?.address?.state?.toUpperCase();
  const driversLicenseStateAbbr = customer?.drivers_license_address?.state?.toUpperCase();
  const customerState = statesArray.find((states) => states.value === stateAbbr)?.name ?? '';
  const [ttJurisdictionQuery] = useGetTtJurisdictionLazyQuery();

  const driversLicenseAddress = getAddressValue({
    address_line: customer?.drivers_license_address?.address_line,
    address_line_2: customer?.drivers_license_address?.address_line_2,
    city: customer?.drivers_license_address?.city,
    state: customer?.drivers_license_address?.state,
    zip: customer?.drivers_license_address?.zip,
  });
  const currentAddress = getAddressValue({
    address_line: customer?.address?.address_line,
    address_line_2: customer?.address?.address_line_2,
    city: customer?.address?.city,
    state: customer?.address?.state,
    zip: customer?.address?.zip,
  });

  useEffect(() => {
    if (!stateAbbr) {
      return;
    }

    const fetchData = async () => {
      const { data } = await ttJurisdictionQuery({
        fetchPolicy: 'network-only',
        variables: {
          state: stateAbbr as StateAbbreviation,
          types: [TtProductType.Registration, TtProductType.Title],
          conditions: {
            ...ttJurisdictionDefaultConditions(stateAbbr as StateAbbreviation),
            lienholder: deal?.car?.payoff?.lienholder_slug,
            hasCobuyer: !!deal?.cobuyer?.first_name,
            driversLicenseAddress,
            currentAddress,
            vehicleAge: new Date().getFullYear() - parseInt(deal?.car?.year || '0', 10),
            transferringPlates: !!deal?.financial_info?.plate_transfer,
            driversLicenseState: driversLicenseStateAbbr || stateAbbr,
            movedStateDuringLease: !!customer?.address?.moved_states,
            jurisdictionState: stateAbbr,
          },
        },
      });

      if (data?.jurisdiction == null) {
        return;
      }

      const registrationDocuments =
        data.jurisdiction.products?.items?.find((item) => item?.type === TtProductType.Registration)
          ?.documents?.items ?? [];
      const titleDocuments =
        data.jurisdiction.products?.items?.find((item) => item?.type === TtProductType.Title)
          ?.documents?.items ?? [];

      setRegistrationDocumentsNeeded(
        (registrationDocuments?.map((item) => item?.type?.name) || []) as TtDocumentName[],
      );
      setTitleDocumentsNeeded(
        (titleDocuments?.map((item) => item?.type?.name) || []) as TtDocumentName[],
      );
    };
    if (deal?.farthestState === DealStateEnum.WaitingForTitle) {
      fetchData();
    }
  }, [
    currentAddress,
    driversLicenseAddress,
    stateAbbr,
    driversLicenseStateAbbr,
    customer?.address?.moved_states,
    deal?.financial_info?.plate_transfer,
  ]);

  const neededDocsTypes = useMemo(
    () =>
      getRequiredDocTypes(
        deal?.financial_info?.title_registration_option === TitleRegistrationOption.TitleOnly,
        titleDocumentsNeeded,
        registrationDocumentsNeeded,
      ),
    [
      deal?.financial_info?.title_registration_option,
      titleDocumentsNeeded,
      registrationDocumentsNeeded,
    ],
  );

  const backOfDLRequired = neededDocsTypes.includes(TtDocumentName.BackOfDriversLicense);
  const backOfCobuyerDLRequired = neededDocsTypes.includes(
    TtDocumentName.CobuyerBackOfDriversLicense,
  );

  const requiredDocs = useMemo(() => {
    if (deal && customerState && neededDocsTypes.length > 0) {
      return documents({
        backOfDLRequired,
        backOfCobuyerDLRequired,
        state: customerState,
        movedStates: deal.customer?.address?.moved_states ?? false,
        hasCobuyer: !!deal.cobuyer?.first_name,
      }).filter((doc) => !doc.neededNow && neededDocsTypes.includes(doc.ttDocumentNameEnumType));
    }
    return [];
  }, [deal, customerState, neededDocsTypes]);

  const firstState = deal?.deal_states?.reduce((prev, curr) => {
    if (curr?.state !== deal.farthestState) {
      return prev;
    }
    if (new Date(curr?.created_at).getTime() < new Date(prev?.created_at).getTime()) {
      return curr;
    }
    return prev;
  });
  const date = new Date(firstState?.created_at);

  const month = date.toLocaleString('default', { month: 'long' });
  const day = `${date.getDate()}${dateOrdinal(date.getDate())}`;

  return (
    <Card
      border="1px solid"
      borderColor="gainsboro"
      shadow="md"
      px="30px"
      py="25px"
      alignItems={{ base: 'center', md: 'start' }}
      {...rest}
    >
      {deal?.farthestState !== DealStateEnum.Finalized && (
        <HStack>
          <Icon as={IoMdInformationCircle} w={6} h={6} color="black" />
          <Text fontWeight="semibold" lineHeight="22px">
            WHAT'S BEING WORKED ON
          </Text>
        </HStack>
      )}
      <Text
        fontWeight="bold"
        fontSize="42px"
        lineHeight="50px"
        my={2}
        textAlign={{ base: 'center', md: 'left' }}
      >
        {getDealStatusLabel(deal?.farthestState as DealStateEnum, deal?.type === DealType.Refi)}
      </Text>
      <Text mb={{ base: 0, md: '20px' }}>
        We started this on{' '}
        <b>
          {month} {day}
        </b>
      </Text>
      <Divider my="20px" display={{ md: 'none' }} borderColor="lightGray" />
      <ExpandableContent
        defaultHeight={150}
        enabled={deal?.farthestState !== DealStateEnum.SentForSignatures && shouldCollapseContent}
        key={deal?.farthestState}
      >
        <Box fontSize="16px">{dealStatusDescription({ deal, requiredDocs })}</Box>
      </ExpandableContent>
    </Card>
  );
};

export default StatusDetails;
