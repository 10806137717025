import { Center, CenterProps, Spinner, Text } from '@chakra-ui/react';
import React from 'react';

interface Props extends CenterProps {
  hideText?: boolean;
  isWhite?: boolean;
}

const Loader: React.FC<Props> = ({ hideText = false, isWhite = false, ...props }) => (
  <Center mt={2} overflow="hidden" {...props}>
    <Spinner color={isWhite ? 'white' : 'unset'} />
    {!hideText && <Text ml={2}>Loading...</Text>}
  </Center>
);

export default Loader;
