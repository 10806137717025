import React, { useEffect } from 'react';
import { useJwt } from 'react-jwt';
import { RouteProps, useHistory } from 'react-router-dom';

import { DASHBOARD, MKT_HOME } from '../../constants/urls';
import { useTempInfo } from '../../hooks';
import { CookieKeys, useCookie } from '../../hooks/useCookie';
import LERoute from './LERoute';

interface FlowRouteProps extends RouteProps {
  title: string;
  children?: React.ReactNode;
}

const FlowRoute = ({ children, title, ...rest }: FlowRouteProps) => {
  const history = useHistory();
  const { info } = useTempInfo('network-only', true);
  const [guid] = useCookie<string>(CookieKeys.GUID_KEY);
  const [accessToken] = useCookie<string>(CookieKeys.ACCESS_TOKEN);
  const { isExpired } = useJwt(accessToken || '');
  const isValidToken = !!accessToken && !isExpired;

  useEffect(() => {
    if (!guid) {
      if (isValidToken) {
        history.replace(DASHBOARD);
      } else {
        window.location.href = MKT_HOME;
      }
    }
  }, [guid, info?.data, history.location]);

  return (
    <LERoute title={title} {...rest}>
      {guid && children}
    </LERoute>
  );
};

export default FlowRoute;
