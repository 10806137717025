import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import React, { Suspense, useState } from 'react';
import TagManager from 'react-gtm-module';

import { Esign } from '../../../../assets/Images';
import useErrorModal from '../../../../components/errorModal/useErrorModal';
import { LDFlags } from '../../../../constants/flags';
import { BankEnum } from '../../../../constants/misc';
import {
  PaperworkType,
  SigningErrorEnum,
  useLogSigningErrorMutation,
  useUserDealQuery,
} from '../../../../gql/generated/graphql';
import { useFlag, useWindowSize } from '../../../../hooks';
import { getPhoneNumber } from '../../../../hooks/useSearchPhoneNumbers';
import { convertToLeaseEndTimeZone, getDatePart } from '../../../../utils/dates';
import { isBooleanSetAndFalse } from '../../../../utils/helpers';
import { RudderEvent, rudderanalytics } from '../../../../utils/rudderstack';
import { DealStateEnum } from '../../../../utils/types/deal';
import VerifyAccountModal from '../../../auth/components/VerifyAccountModal';
import { NEW_DEAL_VALUE, dashboardDealInfoAtom } from '../../Dashboard';
import { DealWithFarthestState } from '../../utils';
import SignButtons from '../SignButtons';

const SigningModal = React.lazy(() => import('../SigningModal'));

const SentForSignatures = ({ deal }: { deal?: DealWithFarthestState }) => {
  const phoneNumber = getPhoneNumber();
  const sentForSignatures = deal?.state === DealStateEnum.SentForSignatures;
  const [openSigningModal, setOpenSigningModal] = useState(false);
  const [openVerifyModal, setOpenVerifyModal] = useState(false);
  const [logSigningError] = useLogSigningErrorMutation();
  const { renderErrorModal, openErrorModal } = useErrorModal();
  const { windowWidth } = useWindowSize();
  const { isCobuyer } = deal || {};
  const inDashVerification = useFlag(LDFlags.SSN_DASH_VERIFICATION);
  const { renderErrorModal: renderScreenSizeErrorModal, openErrorModal: openScreenSizeErrorModal } =
    useErrorModal({
      title: 'Screen size not supported',
      buttonText: 'OK',
      description:
        'We apologize for this inconvenience. Document signing is not supported on devices with ' +
        'this screen size. Please switch to a device with a larger screen, ' +
        'such as a tablet or desktop.',
    });

  const dealId = useAtomValue(dashboardDealInfoAtom);
  const isNewDeal = dealId?.id === NEW_DEAL_VALUE.id;

  const { data: dealData, refetch: refetchDeal } = useUserDealQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: isNewDeal ? undefined : dealId?.id,
      isCobuyer: dealId?.isCobuyer,
    },
  });

  if (
    !deal ||
    isBooleanSetAndFalse(deal.signing_on_com) ||
    deal.paperwork_type !== PaperworkType.Esign ||
    deal.financial_info?.bank === BankEnum.ICCU
  ) {
    return (
      <Text>
        Your advisor will be reaching out soon to go over the signing process and review any
        requirements. If you have any questions or need assistance, please don’t hesitate to contact
        us at <Link href={`tel:+1-${phoneNumber}`}>{phoneNumber}</Link>. We’re excited to guide you
        through the rest of your buyout process!
        <br />
        <br />
        (If your online application comes in outside of our business hours, we’ll contact you first
        thing the next business day.)
      </Text>
    );
  }

  const handleSign = () => {
    if (windowWidth <= 260) {
      openScreenSizeErrorModal();
      return;
    }

    TagManager.dataLayer({
      dataLayer: {
        VIN: deal.car?.vin,
        opened_signing: true,
        event: 'hotjar',
      },
    });
    const todaysDate = getDatePart(convertToLeaseEndTimeZone(new Date()));
    const generationDate = convertToLeaseEndTimeZone(
      deal?.r1_contract_generation_date_utc || new Date(),
    );

    if (!deal?.r1_contract_generation_date_utc || generationDate < todaysDate) {
      logSigningError({
        variables: {
          error: {
            deal_id: deal?.id,
            error_type: SigningErrorEnum.GenerationDate,
            error_message: 'Document generation date is in the past',
          },
        },
      });
      openErrorModal();

      return;
    }
    if (sentForSignatures) {
      rudderanalytics.track(RudderEvent.SigningExperienceOpened, {
        is_cobuyer: deal?.isCobuyer || false,
        deal_id: deal?.id || undefined,
      });
      setOpenSigningModal(true);
    }
  };

  return (
    <>
      {renderScreenSizeErrorModal}
      {renderErrorModal}
      <Box>
        <Flex alignItems="flex-start" mb={4} direction={{ base: 'column', md: 'row' }}>
          <Image display={{ base: 'none', md: 'block' }} src={Esign} maxW="80px" mr={2} mb={0} />
          <Text>
            Your documents are being prepared. Once they're ready, you can review and digitally sign
            them by following the button below.
          </Text>
        </Flex>
        <Text mb={4}>
          Rest assured, our Signing service prioritizes the security and privacy of your
          information. We’ve implemented robust security measures to safeguard your data throughout
          the signing process, ensuring a secure and confidential experience for you.
          {deal.signing_on_com && (
            <>
              {' '}
              Please note,{' '}
              <Text as="span" color="leaseEndRed" fontWeight="bold">
                DOCUMENTS EXPIRE AT MIDNIGHT MOUNTAIN TIME.
              </Text>
            </>
          )}
        </Text>
        <SignButtons
          deal={deal}
          sentForSignatures={sentForSignatures}
          handleSign={() => {
            const { verified: customerVerified } = dealData?.userDeal?.customer || {};
            const { verified: cobuyerVerified } = dealData?.userDeal?.cobuyer || {};
            if (
              inDashVerification &&
              ((!isCobuyer && !customerVerified) || (isCobuyer && !cobuyerVerified))
            ) {
              setOpenVerifyModal(true);
              return;
            }
            handleSign();
          }}
        />
        {inDashVerification && sentForSignatures && (
          <VerifyAccountModal
            deal={deal}
            open={openVerifyModal}
            onClose={() => setOpenVerifyModal(false)}
            handleNextSubmit={() => {
              refetchDeal();
              handleSign();
            }}
          />
        )}

        {sentForSignatures && (
          <Suspense>
            <SigningModal
              deal={deal}
              isOpen={openSigningModal}
              onClose={() => {
                setOpenSigningModal(false);
                refetchDeal();
              }}
            />
          </Suspense>
        )}
      </Box>
    </>
  );
};

export default SentForSignatures;
