import { createContext, useState } from 'react';

import {
  DealMedia,
  DealMediaTypeEnum,
  useCreateExternalDealUploadUrlMutation,
  useMediaDeleteFromComMutation,
  useMediaFromComQuery,
  useMediaInsertComMutation,
  useMediaUpdateComMutation,
  useOnMediaMessagedInSubscription,
} from '../gql/generated/graphql';
import { logger } from '../services/sentry';
import { convertFile, generateFileName, getFilePathFromSignedUrl } from '../utils/media';

const useMediaCollection = () => {
  // queries and mutations
  const [createUploadUrlCom] = useCreateExternalDealUploadUrlMutation();
  const [mediaInsert] = useMediaInsertComMutation();
  const [updateImage] = useMediaUpdateComMutation();
  const [deleteMedia] = useMediaDeleteFromComMutation();

  // state
  const [media, setMedia] = useState<DealMedia[]>([]);
  const [messagedIn, setMessagedIn] = useState<boolean>(false);

  // queries with effects
  useMediaFromComQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (media.length === 0 && data.mediaFromCom) setMedia(data.mediaFromCom);
    },
  });
  useOnMediaMessagedInSubscription({
    onSubscriptionData: (options) => {
      if (options.subscriptionData.data?.onMediaMessagedIn) {
        setMessagedIn(true);
        setMedia((oldMedia) =>
          options.subscriptionData.data?.onMediaMessagedIn
            ? [...oldMedia, options.subscriptionData.data.onMediaMessagedIn]
            : [...oldMedia],
        );
      }
    },
  });

  // util functions
  const updateMedia = (newMedia: DealMedia) => {
    setMedia((prev) => prev.map((picture) => (picture.id === newMedia.id ? newMedia : picture)));
  };

  const deleteImageForKey = (mediaType: DealMediaTypeEnum) => {
    return new Promise((resolve) => {
      setMedia((prev) => {
        const image = prev.find((doc) => doc.type === mediaType);
        if (!image?.signed_url) {
          resolve(null);
          return prev;
        }
        deleteMedia({
          variables: {
            dealId: image.deal_id || '',
            filePath: getFilePathFromSignedUrl(image.signed_url),
          },
        });
        const newMedia = prev.filter((picture) => image.id !== picture.id);
        resolve(null);
        return newMedia;
      });
    });
  };

  // general functions
  const uploadImage = async ({
    mediaType,
    dealId,
    file,
    deletePrev = true,
    allowPdf,
  }: {
    mediaType: DealMediaTypeEnum;
    dealId: string | number;
    file?: File;
    deletePrev?: boolean;
    allowPdf?: boolean;
  }) => {
    const convertedFile = await convertFile(file, allowPdf);

    const filename = generateFileName(mediaType, convertedFile.name);

    try {
      const { data } = await createUploadUrlCom({
        variables: {
          fileName: filename,
          fileDescription: mediaType,
          dealId,
        },
      });

      if (data?.createExternalDealUploadUrl?.url && data?.createExternalDealUploadUrl?.key) {
        if (deletePrev) {
          await deleteImageForKey(mediaType);
        }

        const uploadResponse = await fetch(data?.createExternalDealUploadUrl?.url, {
          method: 'PUT',
          body: convertedFile,
        });

        if (!uploadResponse.ok) {
          throw Error();
        }

        const { data: mediaInsertData } = await mediaInsert({
          variables: {
            key: data?.createExternalDealUploadUrl?.key,
            type: mediaType,
            dealId,
          },
        });

        const newMedia = mediaInsertData?.mediaInsertCom;
        if (!newMedia) {
          throw Error();
        }

        setMedia((prev) => [...prev, mediaInsertData.mediaInsertCom as DealMedia]);
      }
    } catch (error) {
      logger.error(
        'useMediaCollection.tsx',
        'There was an error uploading your file.',
        mediaType,
        error,
      );
      throw Error('There was an error uploading your file.');
    }
  };

  const deleteImage = async (mediaToDelete: DealMedia) => {
    try {
      if (!mediaToDelete.signed_url) {
        throw Error();
      }
      await deleteMedia({
        variables: {
          dealId: mediaToDelete.deal_id || '',
          filePath: getFilePathFromSignedUrl(mediaToDelete.signed_url),
        },
      });
      setMedia((prev) => prev.filter((image) => image.id !== mediaToDelete.id));
    } catch (error) {
      logger.error(
        'useMediaCollection.tsx',
        'There was an error deleting your image.',
        mediaToDelete,
        error,
      );
      throw Error('There was an error deleting your image.');
    }
  };

  // This will also delete a previous image if it exists
  const setImageType = async (oldKey: string, mediaType?: DealMediaTypeEnum) => {
    try {
      if (mediaType) {
        await deleteImageForKey(mediaType);
      }

      let newFileName: string | null = null;
      if (mediaType) {
        newFileName = generateFileName(mediaType, oldKey);
      }
      const newMedia = await updateImage({
        variables: {
          dealId: media[0].deal_id || '',
          oldKey,
          newFileName,
          type: mediaType,
        },
      });
      if (newMedia.data?.mediaUpdateCom) {
        updateMedia(newMedia.data.mediaUpdateCom);
      }
    } catch (error) {
      logger.error(
        'useMediaCollection.tsx',
        'There was an error setting your image.',
        oldKey,
        error,
      );
      throw Error('There was an error setting your image.');
    }
  };

  return {
    media,
    messagedIn,
    uploadImage,
    deleteImage,
    setImageType,
  };
};

export type MediaContextType = ReturnType<typeof useMediaCollection>;

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyFunction = async () => {};

export const MediaContext = createContext<ReturnType<typeof useMediaCollection>>({
  media: [],
  messagedIn: false,
  uploadImage: emptyFunction,
  deleteImage: emptyFunction,
  setImageType: emptyFunction,
});

export default useMediaCollection;
