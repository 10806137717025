/* eslint-disable react/no-array-index-key */
import { Flex, Image, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';

import { BankEnum } from '../../constants/misc';
import { useGetExternalAutoStructureBankLogosQuery } from '../../gql/generated/graphql';
import ImageCarousel from './ImageCarousel';

const PartnersLogos = React.memo(() => {
  const bankLogos = useGetExternalAutoStructureBankLogosQuery();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const logos =
    (bankLogos.data?.getExternalAutoStructureBankLogos?.map((bank) => ({
      src: bank?.logo_url,
      alt: bank?.bank_name,
    })) as [{ src: string; alt: string }]) || [];

  if (!logos.length) {
    return null;
  }

  return (
    <Flex direction="column" mb={6} px={2} justify="center">
      {isMobile ? (
        <ImageCarousel images={logos} />
      ) : (
        <Flex
          minH="82px"
          minW="90%"
          px={2}
          align="center"
          justify="center"
          flexWrap="wrap"
          gap="20px"
        >
          {logos.map(({ src, alt }, index) => (
            <Image
              key={index}
              maxH={alt === BankEnum.AllyFinancial ? '60px' : '82px'}
              maxW="150px"
              src={src}
              alt={alt}
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
});

export default PartnersLogos;
