import { useCallback, useEffect, useState } from 'react';

import {
  LocalStorageKeys,
  getLocalStorageItem,
  useLocalStorageEvent,
} from '../../services/localStorage';

// Taken from https://usehooks-ts.com/react-hook/use-read-local-storage
function useReadLocalStorage<T>(key: LocalStorageKeys): T | null {
  // Get from local storage then
  // parse stored json or return null
  const readValue = useCallback((): T | null => {
    // Prevent build error "window is undefined" but keep keep working
    if (typeof window === 'undefined') {
      return null;
    }

    return getLocalStorageItem<T>(key);
  }, [key]);

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T | null>(readValue);

  // Listen if localStorage changes
  useEffect(() => {
    setStoredValue(readValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLocalStorageEvent(key, () => {
    setStoredValue(readValue());
  });

  return storedValue;
}

export default useReadLocalStorage;
