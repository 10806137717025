import React, { ReactNode } from 'react';
import TitleContainer from '../TitleContainer';
import Title from '../Title';

const LEAccordionHeader = ({ children }: { children: ReactNode }) => (
  <TitleContainer>
    <Title fontSize="36px" textAlign={{ base: 'center', md: 'left' }}>
      {children}
    </Title>
  </TitleContainer>
);

export default LEAccordionHeader;
