import { Box, Button, Flex, FlexProps, Image, Text, useMediaQuery } from '@chakra-ui/react';
import { FaChevronRight } from 'react-icons/fa6';
import { useHistory } from 'react-router-dom';

import Loader from '../../../components/Loader';
import { PAYMENTS_DASHBOARD } from '../../../constants/urls';
import { useBankLogoQuery } from '../../../gql/generated/graphql';
import { getUTCDate } from '../../../utils/dates';
import { formatMoney } from '../../../utils/helpers';
import { DealWithFarthestState } from '../utils';

interface Props extends FlexProps {
  deal?: DealWithFarthestState;
  showDownPaymentButton: boolean;
}

const PaymentInfo = ({ deal, showDownPaymentButton, ...props }: Props) => {
  const formattedMonthlyPayment = formatMoney(deal?.financial_info?.payment ?? 0);
  const formattedDownPayment = formatMoney(deal?.financial_info?.money_down ?? 0);

  const [isLargerThan390] = useMediaQuery('(min-width: 390px)');

  const history = useHistory();
  const { data: bankLogoData, loading } = useBankLogoQuery({
    variables: { bank_name: deal?.financial_info?.bank || '' },
  });

  return (
    <Flex
      flexDirection={{ base: 'column', xl: 'row' }}
      justifyContent="space-between"
      flexGrow={2}
      borderRadius="10px"
      backgroundColor="mountainGreen"
      padding="20px"
      minHeight="100%"
      color="white"
      position="relative"
      textAlign={{ base: 'center', xl: 'left' }}
      {...props}
    >
      {deal ? (
        <>
          <Box
            position="absolute"
            bgColor="viridianGreen"
            top={0}
            left={0}
            borderTopLeftRadius="10px"
            borderTopRightRadius={{ base: '10px', xl: 0 }}
            borderBottomRightRadius={{ base: 0, xl: '10px' }}
            px={8}
            py={2}
            w={{ base: '100%', xl: 'auto' }}
          >
            <Text fontSize="18px" fontWeight="semibold">
              {showDownPaymentButton ? 'Down Payment Due' : 'Monthly Payment'}
            </Text>
          </Box>
          <Box mb={{ base: 6, xl: 0 }} mr={2}>
            {showDownPaymentButton ? (
              <Box pt={10}>
                <Text fontSize="42px" fontWeight="bold">
                  {formattedDownPayment.split('.')[0]}
                  <Text
                    as="sup"
                    fontSize="18px"
                    textDecor="underline"
                    verticalAlign="middle"
                    pl={1}
                  >
                    {formattedDownPayment.split('.')[1]}
                  </Text>
                </Text>
                <Button
                  rightIcon={<FaChevronRight />}
                  onClick={() => history.push(PAYMENTS_DASHBOARD)}
                >
                  MAKE PAYMENT
                </Button>
              </Box>
            ) : (
              <Flex
                alignContent={{ base: 'center' }}
                justifyContent={{ base: 'center', xl: 'space-between' }}
                minHeight="100%"
                alignItems={{ md: 'center' }}
                pt={8}
                pl="9px"
              >
                <Text fontSize="42px" fontWeight="bold">
                  {formattedMonthlyPayment.split('.')[0]}
                  <Text
                    as="sup"
                    fontSize="18px"
                    textDecor="underline"
                    verticalAlign="middle"
                    pl={1}
                  >
                    {formattedMonthlyPayment.split('.')[1]}
                  </Text>
                </Text>
              </Flex>
            )}
          </Box>
          <Flex direction="column" justifyContent="space-between" alignItems="center">
            {loading ? (
              <Box w="150px" h="60px" />
            ) : (
              <>
                {bankLogoData?.bankLogo && (
                  <Image
                    maxW="150px"
                    maxH="60px"
                    src={bankLogoData.bankLogo}
                    alt={deal?.financial_info?.bank || 'bank'}
                    mb={{ base: 4, xl: 0 }}
                    pt={{ xl: 4 }}
                  />
                )}
              </>
            )}

            <Flex
              flexDir={{ base: 'column', sm: 'row' }}
              justifyContent={{ base: 'center', xl: 'space-around' }}
              gap="20px"
            >
              {showDownPaymentButton && (
                <Box>
                  <Text fontSize={isLargerThan390 ? '20px' : '16px'} fontWeight="bold">
                    {formatMoney(deal?.financial_info?.payment)}
                  </Text>
                  <Text fontSize={isLargerThan390 ? '16px' : '12px'} fontWeight="600">
                    Mo. Payment
                  </Text>
                </Box>
              )}
              <Box>
                <Text fontSize={isLargerThan390 ? '20px' : '16px'} fontWeight="bold">
                  {deal?.financial_info?.sell_rate}%
                </Text>
                <Text fontSize={isLargerThan390 ? '16px' : '12px'} fontWeight="600">
                  APR
                </Text>
              </Box>
              <Box>
                <Text fontSize={isLargerThan390 ? '20px' : '16px'} fontWeight="bold">
                  {deal?.financial_info?.term} mos
                </Text>
                <Text fontSize={isLargerThan390 ? '16px' : '12px'} fontWeight="600">
                  Loan term
                </Text>
              </Box>
              {!showDownPaymentButton && (
                <Box>
                  <Text fontSize={isLargerThan390 ? '20px' : '16px'} fontWeight="bold">
                    {deal?.financial_info?.first_payment_date &&
                      getUTCDate(deal?.financial_info?.first_payment_date).toLocaleDateString()}
                  </Text>
                  <Text fontSize={isLargerThan390 ? '16px' : '12px'} fontWeight="600">
                    First Payment
                  </Text>
                </Box>
              )}
            </Flex>
          </Flex>
        </>
      ) : (
        <Loader />
      )}
    </Flex>
  );
};

export default PaymentInfo;
