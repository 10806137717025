/* eslint-disable @typescript-eslint/no-explicit-any */
import { FetchPolicy } from '@apollo/client';
import { useEffect, useState } from 'react';

import { MKT_HOME } from '../constants/urls';
import { ErrorCodeEnum, TemporaryInfo, useTemporaryInfoLazyQuery } from '../gql/generated/graphql';
import { resetLocalStorage } from '../services/localStorage';
import { logger } from '../services/sentry';
import { CookieKeys, useCookie } from './useCookie';

// TODO-JK: Remove other references to the temporaryInfo query
export const useTempInfo = (fetchPolicy?: FetchPolicy, refresh?: boolean) => {
  const [getTempInfo] = useTemporaryInfoLazyQuery();
  const [guid] = useCookie<string>(CookieKeys.GUID_KEY);
  const [info, setInfo] = useState<TemporaryInfo>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let isMounted = true;

    const fetchInfo = async () => {
      setLoading(true);
      try {
        const { data, error } = await getTempInfo({
          fetchPolicy: fetchPolicy || 'cache-first',
          variables: { id: guid as string },
        });

        if (isMounted && data?.temporaryInfo?.data) {
          setInfo(data.temporaryInfo);
        }

        if (error) {
          throw error;
        }
      } catch (error: any) {
        if (isMounted) {
          logger.error('useTempInfo.ts', 'Error fetching info', null, error);
          if (error?.graphQLErrors?.[0]?.extensions?.code === ErrorCodeEnum.ResetGuidError) {
            resetLocalStorage({ removeGuid: true });
            window.location.href = MKT_HOME;
          }
        }
      }

      if (isMounted) {
        setLoading(false);
      }
    };

    if (guid) {
      fetchInfo();
    } else {
      setLoading(false);
    }

    return () => {
      isMounted = false;
    };
  }, [guid, refresh]);

  return { info, infoLoading: loading };
};
