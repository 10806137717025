/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable no-param-reassign */
import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { getEnv, isLocal, isProd } from '../config';

export const initSentry = () => {
  if (isLocal) {
    return;
  }

  Sentry.init({
    dsn: 'https://3947a3aadb4a44c7b980552053c6aa08@o583742.ingest.sentry.io/6144141',
    integrations: [new Integrations.BrowserTracing()],
    environment: getEnv(),

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: isProd ? 0.2 : 1.0,
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleErrorWithSentry = (error: any, errorInfo: any = {}) => {
  let message = error.toString();

  // Auth errors
  if (!(error instanceof Error) && error.message) {
    errorInfo = error;
    message = error.message;
    error = new Error(message);
    // API errors
  } else if (error.config && error.config.url) {
    errorInfo.url = error.config.url;
  }

  if (isLocal) {
    return;
  }

  Sentry.withScope((scope) => {
    if (errorInfo) {
      scope.setExtras(errorInfo);
    }
    Sentry.captureException(error);
  });
};

export const logger = {
  log(filename: string, message: string, data: any) {
    console.log(`Log - Filename: ${filename}; Message: ${message}; Data: ${JSON.stringify(data)}`);
  },
  warn(filename: string, message: string, data: any) {
    console.warn(
      `Warn - Filename: ${filename}; Message: ${message}; Data: ${JSON.stringify(data)}`,
    );
  },
  error(
    filename: string,
    message: string,
    data: any,
    error: any,
    serverity?: Sentry.Severity | undefined,
  ) {
    console.error(
      `Error - Filename: ${filename}; 
      Message: ${message};
      Data: ${JSON.stringify(data)};
      Error: ${error}`,
    );
    // send error to sentry
    const scope: Sentry.Scope = new Sentry.Scope();
    scope.setLevel(serverity || Severity.Error);
    scope.setExtra('filename', filename);
    scope.setExtra('message', message);
    scope.setExtra('data', data);
    Sentry.captureException(error, scope);
  },
};
