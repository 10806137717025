// eslint-disable-next-line no-restricted-imports
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { RudderEvent, rudderanalytics } from '../../../../../utils/rudderstack';
import AutoCheckMessage from './AutoCheckMessage';
import EndingInZeroMessage from './EndingInZeroMessage';
import OverMileageFeeWarning from './OverMileageFeeWarning';

interface Props {
  lastOdometer: number;
  lastOdometerDate: string;
  mileage: number;
  mileageWarnings: {
    autoCheck: boolean;
    endingInZero: boolean;
    overMileage: boolean;
  };
  vin?: string | null;
}

const MileageWarning = ({
  lastOdometer,
  lastOdometerDate,
  mileage,
  mileageWarnings,
  vin,
}: Props) => {
  const { autoCheck, endingInZero, overMileage } = mileageWarnings;
  const ldClient = useLDClient();
  if (
    (!autoCheck && !overMileage && !endingInZero) ||
    (autoCheck && (!lastOdometer || !lastOdometerDate))
  ) {
    return null;
  }

  if (autoCheck && lastOdometer && lastOdometerDate) {
    rudderanalytics.track(RudderEvent.AutoCheckLowMilage, {
      last_odometer: lastOdometer as number,
      mileage,
      vin: vin as string,
    });

    return <AutoCheckMessage lastOdometer={lastOdometer} lastOdometerDate={lastOdometerDate} />;
  }

  if (overMileage) {
    ldClient?.track('over-mileage-warning', {});
    return <OverMileageFeeWarning mileage={Number(mileage)} />;
  }

  return <EndingInZeroMessage />;
};

export default MileageWarning;
