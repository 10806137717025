import { Box, Button, Container, Image } from '@chakra-ui/react';
import { useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { DealProcessingComplete, DealProcessing as DealProcessingImage } from '../../assets/Images';
import Title from '../../components/Title';
import TitleContainer from '../../components/TitleContainer';
import { ALL_DONE } from '../../constants/urls';
import {
  AutoImportVariation,
  DealReadyType,
  EndSalesFlowReasonEnum,
  ImportType,
  useEndSalesFlowMutation,
  useExternalUpdateDealMutation,
  useGetComPackageOptionsLazyQuery,
  useUserDealQuery,
  useUserDealReadyQuery,
} from '../../gql/generated/graphql';
import { CookieKeys, useCookie } from '../../hooks/useCookie';
import { useStep } from '../../hooks/useStep';
import { dashboardDealInfoAtom } from '../dashboard/Dashboard';
import DealProcessingDevToolbar from './DealProcessingDevToolbar';

const maxWaitInMs = 75000;
const minWaitInMs = 53000;

const DealProcessing = () => {
  const step = useStep();
  const history = useHistory();
  const [endSalesFlow] = useEndSalesFlowMutation();
  const [getPackageOptions] = useGetComPackageOptionsLazyQuery();
  const [updateDealMutation] = useExternalUpdateDealMutation();

  const [guid] = useCookie<string>(CookieKeys.GUID_KEY);
  const setDealIdAtom = useSetAtom(dashboardDealInfoAtom);
  const [dealId, setDealId] = useState<string>();
  const [minWaitOver, setMinWaitOver] = useState<boolean>(false);
  const [maxWaitOver, setMaxWaitOver] = useState<boolean>(false);

  useUserDealQuery({
    variables: {
      tempInfoId: guid as string,
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ userDeal }) => {
      if (userDeal?.id) {
        setDealId(userDeal?.id);
      }
    },
  });

  const {
    data,
    stopPolling,
    refetch: refetchDealReady,
  } = useUserDealReadyQuery({
    variables: {
      tempInfoId: guid as string,
    },
    pollInterval: 2000,
    skip: !dealId,
  });

  const handleSuccess = () => {
    stopPolling();
  };

  const handleFailure = async (reason: EndSalesFlowReasonEnum, importType: ImportType) => {
    stopPolling();
    setDealIdAtom(undefined);
    await endSalesFlow({
      variables: {
        tempInfoId: guid as string,
        importType,
        reason,
      },
    });
  };

  useEffect(() => {
    const minWaitTimer = setTimeout(() => setMinWaitOver(true), minWaitInMs);
    const maxWaitTimer = setTimeout(() => setMaxWaitOver(true), maxWaitInMs);
    return () => {
      clearTimeout(minWaitTimer);
      clearTimeout(maxWaitTimer);
    };
  }, []);

  useEffect(() => {
    if (!data?.userDealReady) {
      return;
    }

    if (maxWaitOver && data.userDealReady === DealReadyType.Loading) {
      handleFailure(EndSalesFlowReasonEnum.TimedOut, ImportType.AutoStructureTimeout);
    } else if (data.userDealReady === DealReadyType.ImmediateImport) {
      handleFailure(
        EndSalesFlowReasonEnum.ImmediateImport,
        ImportType.AutoStructureSuccessImmediateImport,
      );
    } else if (data.userDealReady === DealReadyType.Ready) {
      handleSuccess();
    } else if (data.userDealReady === DealReadyType.Failed) {
      handleFailure(EndSalesFlowReasonEnum.FailedDealProcessing, ImportType.AutoStructureFailure);
    } else if (data.userDealReady === DealReadyType.Conditioned) {
      handleFailure(
        EndSalesFlowReasonEnum.ConditionedFailedDealProcessing,
        ImportType.AutoStructureConditioned,
      );
    }
  }, [data?.userDealReady, maxWaitOver]);

  const next = async () => {
    const isReady = data?.userDealReady === DealReadyType.Ready;
    const isFailed = data?.userDealReady === DealReadyType.Failed;

    await updateDealMutation({
      variables: {
        tempInfoId: guid as string,
        data: {
          auto_import_variation: AutoImportVariation.ImmediateImport,
        },
      },
    });

    if (isReady) {
      await getPackageOptions({ variables: { dealId } });
      step.moveNext();
    } else {
      history.replace({ pathname: ALL_DONE, state: { isFailed, dealId } });
    }
  };

  const canProceed = (minWaitOver && data?.userDealReady !== DealReadyType.Loading) || maxWaitOver;

  return (
    <Container textAlign="center" maxW="container.md">
      <Box>
        <TitleContainer marginTop="25px" marginBottom="25px">
          {canProceed ? (
            <Title fontSize={{ base: '24px', lg: '36px' }}>
              Your Personalized Lease End Package is ready!
            </Title>
          ) : (
            <Title fontSize={{ base: '24px', lg: '36px' }}>
              Give us just a sec... Don't refresh or leave this page.
            </Title>
          )}
        </TitleContainer>
        <Image
          maxW="300px"
          mx="auto"
          mt={{ base: 4, lg: 10 }}
          src={canProceed ? DealProcessingComplete : DealProcessingImage}
        />
        {canProceed && (
          <Button mx="auto" mt={{ base: 10, lg: 20 }} onClick={next}>
            LET'S GO!
          </Button>
        )}
      </Box>
      <DealProcessingDevToolbar
        dealId={dealId}
        dealReadyState={data?.userDealReady}
        setMaxWaitOver={() => setMaxWaitOver(true)}
        refetchDealReady={refetchDealReady}
        stopPolling={stopPolling}
        handleFailure={handleFailure}
      />
    </Container>
  );
};

export default DealProcessing;
