const colors = {
  // Chakra colorScheme takes the `500` value
  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },

  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)',
  },

  gray: {
    50: '#F7FAFC',
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: '#CBD5E0',
    400: '#A0AEC0',
    500: '#718096',
    600: '#4A5568',
    700: '#2D3748',
    800: '#1A202C',
    900: '#171923',
  },

  red: {
    50: '#FFF5F5',
    100: '#FED7D7',
    200: '#FEB2B2',
    300: '#FC8181',
    400: '#F56565',
    500: '#E53E3E',
    600: '#C53030',
    700: '#9B2C2C',
    800: '#822727',
    900: '#63171B',
  },

  orange: {
    50: '#FFFAF0',
    100: '#FEEBC8',
    200: '#FBD38D',
    300: '#F6AD55',
    400: '#ED8936',
    500: '#DD6B20',
    600: '#C05621',
    700: '#9C4221',
    800: '#7B341E',
    900: '#652B19',
  },

  yellow: {
    50: '#FFFFF0',
    100: '#FEFCBF',
    200: '#FAF089',
    300: '#F6E05E',
    400: '#ECC94B',
    500: '#D69E2E',
    600: '#B7791F',
    700: '#975A16',
    800: '#744210',
    900: '#5F370E',
  },

  green: {
    50: '#F0FFF4',
    100: '#C6F6D5',
    200: '#9AE6B4',
    300: '#68D391',
    400: '#48BB78',
    500: '#38A169',
    600: '#2F855A',
    700: '#276749',
    800: '#22543D',
    900: '#1C4532',
  },

  teal: {
    50: '#E6FFFA',
    100: '#B2F5EA',
    200: '#81E6D9',
    300: '#4FD1C5',
    400: '#38B2AC',
    500: '#319795',
    600: '#2C7A7B',
    700: '#285E61',
    800: '#234E52',
    900: '#1D4044',
  },

  blue: {
    50: '#ebf8ff',
    100: '#bee3f8',
    200: '#90cdf4',
    300: '#63b3ed',
    400: '#4299e1',
    500: '#3182ce',
    600: '#2b6cb0',
    700: '#2c5282',
    800: '#2a4365',
    900: '#1A365D',
  },

  cyan: {
    50: '#EDFDFD',
    100: '#C4F1F9',
    200: '#9DECF9',
    300: '#76E4F7',
    400: '#0BC5EA',
    500: '#00B5D8',
    600: '#00A3C4',
    700: '#0987A0',
    800: '#086F83',
    900: '#065666',
  },

  purple: {
    50: '#FAF5FF',
    100: '#E9D8FD',
    200: '#D6BCFA',
    300: '#B794F4',
    400: '#9F7AEA',
    500: '#805AD5',
    600: '#6B46C1',
    700: '#553C9A',
    800: '#44337A',
    900: '#322659',
  },

  pink: {
    50: '#FFF5F7',
    100: '#FED7E2',
    200: '#FBB6CE',
    300: '#F687B3',
    400: '#ED64A6',
    500: '#D53F8C',
    600: '#B83280',
    700: '#97266D',
    800: '#702459',
    900: '#521B41',
  },
  progressBarYellow: {
    500: '#FFD23B',
  },

  // Standard colors
  transparent: 'transparent',
  semiTransparent: '#FFFFFF4D',
  current: 'currentColor',

  // Blues
  leaseEndBlue: '#002D5D',
  leaseEndBlueDark: '#00264d',
  leaseEndSkyBlue: '#69A5D9',
  teacupBlue: '#69A6DB',
  oceanBoatBlue: '#087DC5',
  oceanBoatBlueBG: '#DBE7EE',
  trueBlue: '#015FCC',
  darkCornflowerBlue: '#27437C',
  bostonBlue: '#367BBF',
  royalBlue: '#007DC5',
  royalBlueLight: '#5AACF8',
  royalBlueDark: '#005194',
  matisseBlue: '#1D669F',
  midnightBlueLight: '#3C558B',
  midnightBlueDark: '#000133',
  cobuyerBlue: '#327CBF',
  darkMidnightBlue: '#093462',
  anchorTagBlue: '#4183C4',
  headlightLadyBlue: '#002E60',
  headlightLadyBlueLight: '#002E6074',
  activeBlue: '#D8F1FA',

  // Yellows
  leaseEndYellow: '#F4C53E',
  yellowLight: '#F7D267',
  yellowPure: '#FFFF00',
  yellowDark: '#BD9500',
  beige: '#F8F6DA',
  maize: '#EBC557',
  maizeCrayola: '#EDC659',
  driversYellow: '#F6B526',
  bananaMania: '#FFECB7',
  mustardYellow: '#F3C43E',

  // Reds
  leaseEndRed: '#BC5353',
  leaseEndRedBG: '#F2DCDC',
  redPure: '#ED0000',
  redReplace: '#DD0012',
  paleRedReplace: '#DD00121A',
  cardinalRed: '#CC1D3A',
  darkRed: '#AF1831',

  // Grays
  leaseEndGray: '#414042',
  black: '#000000',
  grayBackground: '#F2F2F2',
  gainsboro: '#DDDDDD',
  grayRadio: '#666666',
  grayLight: '#6C6B6D',
  grayLightBackground: '#F0F0F0',
  grayMedium: '#707070',
  grayMediumBackground: '#E0E0E0',
  grayDarkBackground: '#BCBCBC',
  grayDark: '#1B1A1B',
  grayInput: 'rgba(34,36,38,0.15)',
  grayImageBackground: '#E6EAEF',
  grayImageDarkBackground: '#DAE0E7',
  grayBlueish: '#A3B3C4',
  grayDisabledBackground: '#EEEEEE',
  grayDisabledText: '#908F90',
  darkCharcoal: '#333333',
  grayHover: 'rgba(0,0,0,0.05)',
  platinum: '#E6E6E6',
  silver: '#AAAAAA',
  taupeGray: '#888888',
  charcoal: '#444444',
  brightGray: '#E4ECF0',
  readOnlyGray: '#EAEAEA',
  lightGray: '#999999',
  oldSilver: '#858485',
  boxShadowGray: '#00000029',

  // Greens
  leaseEndGreen: '#29A17D',
  greenBackground: '#8DD1C0',
  greenLight: '#61EBCB',
  greenDark: '#00876C',
  greenRadioBG: '#7DCBBB',
  greenRadioDark: '#1A7659',
  greenRadioBGLight: '#F4FAFA',
  greenRadioBGLightPressed: '#EEF8F5',
  greenSilverTree: '#53A889',
  greenTradewindLight: '#70C2AE',
  greenTradewind: '#70C2AE29',
  jungleGreen: '#2AAB86',
  mountainGreen: '#19b796',
  paleJungleGreen: '#2AAB8733',
  deepGreenCyanTurquoise: '#1D785E',
  vividMalachite: '#00BE24',
  evergreen: '#0D9F40',
  iconicGreen: '#2AAA87',
  viridianGreen: '#008163',

  // Whites
  white: '#FFFFFF',
  azureishWhite: '#D9E6ED',
  lotion: '#FCFCFC',

  // Oranges
  apricotOrange: '#E58719',
  apricotOrangeLight: '#F0962B',
  carrotOrange: '#EB8A21',

  // Purples
  magenta: '#883A89',
  magentaLight: '#AA48AB',
  violet: '#93338D',
  russianViolet: '#3E0046',

  // Other
  gold: '#F9D05D',
  darkGold: '#FBC926',
  bronze: '#C99969',
  darkBronze: '#CB9552',
  irresistiblePink: '#BB4572',
};

export default colors;
