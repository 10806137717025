import { ref, string } from 'yup';

export const passwordValidation = string()
  .required('Please enter a value')
  .min(8, 'Must be between 8-30 characters.')
  .max(30, 'Must be between 8-30 characters.')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
    'Must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number',
  );

export const passwordConfirmValidation = (passwordToMatch = 'password') =>
  string()
    .required('Please enter a value')
    .oneOf([ref(passwordToMatch), null], 'Passwords must match');
