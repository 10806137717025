import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link';

import config from '../config';
import { Maybe } from '../gql/generated/graphql';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Finix: any;
  }
}

export const FINIX_SCRIPT = 'https://js.finix.com/v/1/finix.js';

export const FINIX_ENVIRONMENT = config.finix.environment;
export const FINIX_MERCHANT_ID = config.finix.merchantId;
export const FINIX_APPLICATION_ID = config.finix.applicationId;

export interface FinixAuth {
  getSessionKey: () => string;
}

export const createFinixAuth = async () =>
  new Promise<FinixAuth>((resolve) => {
    const interval = setInterval(() => {
      if (window.Finix) {
        clearInterval(interval);
        const finixAuth = window.Finix.Auth(FINIX_ENVIRONMENT, FINIX_MERCHANT_ID);
        resolve(finixAuth);
      }
    }, 100);
  });

export interface PlaidData extends PlaidLinkOnSuccessMetadata {
  publicToken: string;
}

export const DOWN_PAYMENTS_STEPS = {
  BANK_ADDED: 'bankAdded',
  CARD_DETAILS: 'cardDetails',
  FAIL: 'fail',
  PAYMENT_INFO: 'paymentInfo',
  SUCCESS: 'success',
};

export interface PaymentData {
  id: Maybe<string>;
  amount?: Maybe<number>;
  cardBrand?: Maybe<string>;
  cardType?: Maybe<string>;
  failureCode?: Maybe<string>;
  maskedNumber?: Maybe<string>;
}

export const getFinixCardTokenForm = (formId: string, options: unknown) =>
  window.Finix?.CardTokenForm(formId, options);

export const paymentFormOptions = {
  // https://finix.com/docs/guides/payments/online-payments/payment-details/token-forms/#step-3-define-input-fields
  // Supported Fields unless otherwise specified: "name", "number", "expiration_date", "security_code", "address_line1",
  // "address_line2", "address_city", "address_state", "address_region", "address_country", "address_postal_code"
  showAddress: true,
  showLabels: true,
  labels: {
    // custom labels
    security_code: 'CVV',
    address_line1: 'Address',
    address_line2: ' ', // Must be a space or label still shows
  },
  showPlaceholders: true,
  placeholders: {
    // custom placeholders
    number: 'Card Number',
    expiration_date: 'MM/YYYY',
    security_code: 'CVV',
    address_line1: 'Street',
    address_line2: 'Apt., Suite, Unit',
  },
  hideFields: ['name'],
  requiredFields: [
    // Supported Fields : "name", "address_line1", "address_line2", "address_city", "address_state", "address_region", "address_country", "address_postal_code"
    'name',
    'address_line1',
    'address_city',
    'address_state',
    'address_region',
    'address_country',
    'address_postal_code',
  ],
  hideErrorMessages: false, // if you want to require a field, but not hide input error messages (default is false)
  errorMessages: {
    name: 'Please enter a valid name',
    address_city: 'Please enter a valid city',
  },
  styles: {
    default: {
      color: '#444444',
      border: '1px solid #BCBCBC',
      borderRadius: '3px',
      padding: '10px',
      fontFamily: 'Helvetica',
      fontSize: '14px',
      boxShadow: 'none',
    },
    error: {
      color: '#BC5353', // specific styling if the field has errors
      border: '1px solid rgba(188,83,83,0.25)',
    },
  },
};
