import { Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';

export const ButtonWrapper: React.FC<FlexProps> = ({ children, ...rest }) => {
  return (
    <Flex
      justifyContent={{ base: 'center', lg: 'space-around' }}
      maxW="500px"
      m="30px auto 80px"
      flexDir={{ base: 'column-reverse', lg: 'row' }}
      alignItems="center"
      {...rest}
    >
      {children}
    </Flex>
  );
};
