import { Box, Card, Flex, Image, Link, Text } from '@chakra-ui/react';

import { JumpingMan } from '../../../assets/Images';
import { CONTACT_US } from '../../../constants/urls';
import { DealType } from '../../../gql/generated/graphql';
import { DealWithFarthestState } from '../utils';

const ClosingDashboardContent = ({ deal }: { deal?: DealWithFarthestState }) => {
  const isRefi = deal?.type === DealType.Refi;

  return (
    <Card
      border="1px solid"
      borderColor="gainsboro"
      shadow="md"
      px="30px"
      py="25px"
      alignItems={{ base: 'center', md: 'start' }}
    >
      <Flex justifyContent="space-between">
        <Box maxW={{ lg: '60%' }}>
          <Text
            fontWeight="bold"
            fontSize="42px"
            lineHeight="50px"
            my={2}
            textAlign={{ base: 'center', md: 'left' }}
          >
            Welcome to Your Dashboard
          </Text>
          <Text>
            This is where you'll find updates on your {isRefi ? 'auto refinance' : 'lease payoff'}{' '}
            status as you move forward with our services. We'll keep you informed every step of the
            way. If you have any questions or concerns, please don't hesitate to{' '}
            <Link href={CONTACT_US} isExternal>
              reach out to us
            </Link>
            . Our team is here to help you navigate this process.
          </Text>
          <Text as="br" />
          <Text>Thank you for choosing Lease End — we look forward to working with you!</Text>
        </Box>
        <Image
          src={JumpingMan}
          alt="Jumping Man"
          maxWidth="250px"
          display={{ base: 'none', lg: 'block' }}
        />
      </Flex>
    </Card>
  );
};

export default ClosingDashboardContent;
