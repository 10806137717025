import { Button, Flex, Link, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { getPhoneNumber } from '../../hooks/useSearchPhoneNumbers';
import Modal from '../Modal';

interface Props {
  isOpen: boolean;
  onFailureClose: () => void;
  onSuccessClose: () => void;
  retry?: () => Promise<boolean>;
  title?: string;
  description?: string;
  buttonText?: string;
}

const ErrorModal = ({
  isOpen,
  onFailureClose,
  onSuccessClose,
  retry,
  title = 'Sorry, an error occurred',
  description,
  buttonText,
}: Props) => {
  const phoneNumber = getPhoneNumber();
  const [retrying, setRetrying] = useState(false);
  const [failed, setFailed] = useState(false);

  const handleRetry = async () => {
    if (!retry) return;

    setRetrying(true);
    try {
      const result = await retry();
      if (!result) {
        throw new Error('retry failed');
      }
      onSuccessClose();
      setRetrying(false);
    } catch (e) {
      // adds a delay to show the loading state
      setTimeout(() => {
        setRetrying(false);
        setFailed(true);
      }, 3000);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setFailed(false);
    }
  }, [isOpen]);

  const cantRetry = failed || !retry;

  return (
    <Modal title={title} isOpen={isOpen} onClose={onFailureClose}>
      <Flex direction="column" textAlign="center" alignItems="center">
        <Text>
          {description || (
            <>
              {cantRetry ? (
                <>
                  Looks like we're still encountering issues. Please try refreshing the page to see
                  if that resolves the problem. If the issue persists, give us a call at{' '}
                  <Link href={`tel:+1-${phoneNumber}`}>{phoneNumber}</Link>, and we'll be happy to
                  help you further.
                </>
              ) : (
                <>We apologize for this inconvenience. Please use the button below to try again.</>
              )}
            </>
          )}
        </Text>
        <Button
          mt="20px"
          onClick={cantRetry ? onFailureClose : handleRetry}
          w="fit-content"
          isLoading={retrying}
        >
          {buttonText || (cantRetry ? 'CLOSE' : 'TRY AGAIN')}
        </Button>
      </Flex>
    </Modal>
  );
};

export default ErrorModal;
