import { Button, ButtonProps } from '@chakra-ui/react';
import React from 'react';

import { BackOptions } from '../../../experiments/itineraries';
import { useStep } from '../../../hooks/useStep';

interface SecondaryButtonProps extends ButtonProps {
  options?: BackOptions;
  loading?: boolean;
  onClick?: () => void;
}

const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  options,
  loading,
  children,
  onClick,
  ...props
}) => {
  const step = useStep();
  const onClickProp = onClick || (() => step.movePrevious(options));

  return (
    <Button
      isLoading={loading}
      onClick={onClickProp}
      variant={{ base: 'secondaryLink', lg: 'secondary' }}
      _before={{ content: '"« "', display: { base: 'inline-block', lg: 'none' }, mr: '5px' }}
      p="24px 39px"
      {...props}
    >
      {children ?? 'Back'}
    </Button>
  );
};

export default SecondaryButton;
