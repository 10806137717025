import { ApolloError } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { LDFlags } from '../../constants/flags';
import { OtherLienholder } from '../../constants/lienholders';
import { ALL_DONE, DASHBOARD } from '../../constants/urls';
import {
  ErrorCodeEnum,
  LeExperianErrorCodeEnum,
  TemporaryInfoStatusEnumType,
  TransformType,
  useTemporaryInfoImportMutation,
} from '../../gql/generated/graphql';
import { useFlag, useStep, useTempInfo } from '../../hooks';
import { highLtvRatio } from '../../utils/estimatedPayment';
import { rudderanalytics } from '../../utils/rudderstack';
import { getImportType, getTransformType } from '../../utils/tempInfo';
import { createErrorToast } from '../../utils/toast';

const useNextSteps = () => {
  const step = useStep();
  const toast = useToast();
  const history = useHistory();
  const creditPrequalificationEnabled = useFlag(LDFlags.CREDIT_PREQUALIFICATION);
  const kbbOptionsPageEnabled = useFlag(LDFlags.KBB_OPTIONS_PAGE);
  const { info } = useTempInfo();

  const [importTemporaryInfo] = useTemporaryInfoImportMutation({
    context: {
      isErrorHandled: true,
    },
  });

  const importInfo = async () => {
    if (!info?.data) {
      return;
    }

    const isHighLtvRatio = highLtvRatio({
      payoff: info.data.vehicle_payoff,
      estimatedTaxesAndFees: info.data.taxes_fees,
      kbbRetailValue: info.data.retail_book_value,
    });

    const redirectToKbbOptionsPage = kbbOptionsPageEnabled && isHighLtvRatio;
    if (redirectToKbbOptionsPage) {
      step.moveNext({ redirectToKbbOptionsPage });
      return;
    }

    const passedPrequal = !!info.data.prequalification_success;
    const failedPrequalification = creditPrequalificationEnabled && !passedPrequal;
    const hasKbbValues = !!info.data.retail_book_value && !!info.data.book_value;
    const hasOtherLienholder = info.data.lienholder === OtherLienholder;

    const customerFrozenOrLockedCredit =
      info.data.prequalification_errors?.some(
        (error) =>
          error === LeExperianErrorCodeEnum.Frozen || error === LeExperianErrorCodeEnum.Locked,
      ) || false;
    const cobuyerFrozenOrLockedCredit =
      info.data.cobuyer_prequalification_errors?.some(
        (error) =>
          error === LeExperianErrorCodeEnum.Frozen || error === LeExperianErrorCodeEnum.Locked,
      ) || false;
    const frozenOrLockedCredit = customerFrozenOrLockedCredit || cobuyerFrozenOrLockedCredit;

    const transformType = getTransformType(
      failedPrequalification,
      hasKbbValues,
      hasOtherLienholder,
      frozenOrLockedCredit,
    );
    const importType = getImportType(
      failedPrequalification,
      hasKbbValues,
      hasOtherLienholder,
      frozenOrLockedCredit,
    );

    try {
      const deal = await importTemporaryInfo({
        variables: {
          id: info?.id ?? '',
          transformType,
          importType,
        },
      });

      const dealInfo = deal.data?.temporaryInfoImport;
      if (dealInfo?.id && dealInfo?.customer?.id) {
        rudderanalytics.identify({
          deal_id: dealInfo?.id,
          customer_id: dealInfo?.customer.id,
        });
      }

      if (transformType !== TransformType.AutoStructureBegin) {
        history.replace({
          pathname: ALL_DONE,
          state: { customerFrozenOrLockedCredit, cobuyerFrozenOrLockedCredit },
        });
      } else {
        step.moveNext({}, true);
      }
    } catch (e: unknown) {
      if (
        e instanceof ApolloError &&
        e?.graphQLErrors?.[0]?.extensions?.code === ErrorCodeEnum.TempInfoNotFound
      ) {
        history.replace(ALL_DONE);
      } else {
        toast(createErrorToast({ errorMessage: (e as ApolloError).message }));
      }
    }
  };

  useEffect(() => {
    if (info?.status === TemporaryInfoStatusEnumType.Imported) {
      history.replace(DASHBOARD);
    }
  }, [info?.status]);

  return {
    importInfo,
  };
};

export default useNextSteps;
