import { Box, BoxProps, Collapse, Image, ImageProps, VStack } from '@chakra-ui/react';
import React from 'react';

import { ItemProgressType, ItemStatusType, getCursor } from '../utils';
import LEAccordionItemHeader from './components/LEAccordionItemHeader';
import LEAccordionItemProgress from './components/LEAccordionItemProgress';
import {
  LEAccordionItemContextType,
  LEAccordionItemProvider,
  useLEAccordionItem,
} from './useLEAccordionItem';

export const isFunction = (obj: unknown) => typeof obj === 'function';

export interface LEAccordionItemProps {
  accordionKey: string;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  initialStatus?: ItemStatusType;
  initialProgress?: ItemProgressType;
  imgSrc?: string;
  imgProps?: ImageProps;
  children?: ((props: LEAccordionItemContextType) => React.ReactNode) | React.ReactNode;
  contentWrapperProps?: BoxProps;
  rightSidebar?: React.ReactNode;
}

const LEAccordionItem = ({
  accordionKey,
  title: initialTitle,
  subtitle: initialSubTitle,
  initialStatus,
  initialProgress,
  imgSrc,
  imgProps,
  children,
  contentWrapperProps,
  rightSidebar: itemRightSidebar,
}: LEAccordionItemProps) => {
  const {
    register,
    rightSidebar: parentRightSidebar,
    ...context
  } = useLEAccordionItem({
    key: accordionKey,
    status: initialStatus || 'none',
    progress: initialProgress,
    title: initialTitle,
    subtitle: initialSubTitle,
  });

  const rightSidebar = itemRightSidebar || parentRightSidebar;

  const { index, setOpenAccordionIndex, isPrevItemsCompleted, isExpanded } = context;

  const handleItemOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    if (!isPrevItemsCompleted || isExpanded) {
      return;
    }

    setOpenAccordionIndex(index);
  };

  return (
    <LEAccordionItemProvider value={context}>
      <Box
        position="relative"
        ref={register}
        bg={isExpanded ? 'white' : 'lotion'}
        color={isExpanded ? 'leaseEndBlue' : 'taupeGray'}
        my="10px"
        p={{ base: '15px', lg: '30px' }}
        rounded="15px"
        shadow={isExpanded ? 'lg' : 'none'}
        transition="all 1s ease"
        cursor={getCursor(isExpanded, !isPrevItemsCompleted)}
        onClick={handleItemOpen}
      >
        {isExpanded && imgSrc && (
          <Image
            src={imgSrc}
            alt="Accordion Item Image"
            display={{ base: 'none', md: 'block' }}
            position="absolute"
            left={-82}
            top={-50}
            zIndex={2}
            {...imgProps}
          />
        )}
        <VStack mx="auto">
          <LEAccordionItemHeader />
          <LEAccordionItemProgress />
        </VStack>
        <Box
          pt={0}
          zIndex={3}
          position="relative"
          {...contentWrapperProps}
          display={isExpanded ? 'block' : 'none'}
        >
          <Collapse in={isExpanded}>
            <Box pb={2}>
              {isFunction(children)
                ? (children as (props: LEAccordionItemContextType) => React.ReactNode)(context)
                : (children as React.ReactNode)}
            </Box>
          </Collapse>
        </Box>
        {rightSidebar && isExpanded && (
          <Box
            position="absolute"
            left="100%"
            top="0px"
            pl="15px"
            display={{ base: 'none', lg: 'block' }}
          >
            {rightSidebar}
          </Box>
        )}
      </Box>
    </LEAccordionItemProvider>
  );
};

export default LEAccordionItem;
