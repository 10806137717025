import {
  Box,
  Button,
  Container,
  Flex,
  Icon,
  IconButton,
  Image,
  Link,
  Text,
} from '@chakra-ui/react';
import { FaEye } from '@react-icons/all-files/fa/FaEye';
import { FaEyeSlash } from '@react-icons/all-files/fa/FaEyeSlash';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { decodeToken } from 'react-jwt';
import { Link as RRLink, useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { AccountChair, BBBLogo, GoogleReviews, Inc5000 } from '../../assets/Images';
import PasswordStrength from '../../components/PasswordStrength';
import SimpleFooter from '../../components/SimpleFooter';
import TextWithTooltip from '../../components/TextWithTooltip';
import Input from '../../components/formComponents/Input';
import { invalidSignupMsg } from '../../constants/auth';
import { ACCOUNT_LOGIN, DASHBOARD } from '../../constants/urls';
import { CookieKeys, useCookie } from '../../hooks/useCookie';
import { signupAndAuthorize } from '../../services/auth0';
import { logger } from '../../services/sentry';
import { rudderanalytics } from '../../utils/rudderstack';
import { passwordConfirmValidation, passwordValidation } from '../../utils/validation/auth';
import { emailValidationRequired } from '../../utils/validation/email';
import Subtitle from './components/Subtitle';
import Title from './components/Title';
import { StrongPasswordTooltipExplanation } from './utils';

const validationSchema = Yup.object({
  email: emailValidationRequired,
  password: passwordValidation,
  password_confirm: passwordConfirmValidation(),
});

interface Values {
  email: string;
  password: string;
  password_confirm: string;
}

const CreateAccount = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const email = params.get('email');
  const [showPassword, setShowPassword] = useState(false);
  const [helperText, setHelperText] = useState<React.ReactNode>(null);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [, setAccessToken] = useCookie<string>(CookieKeys.ACCESS_TOKEN);
  const history = useHistory();

  const handleSubmit = (values: Values, helpers: FormikHelpers<Values>) => {
    signupAndAuthorize({
      email: values.email,
      password: values.password,
      callback: async (err, result) => {
        helpers.setSubmitting(false);

        if (err) {
          logger.error('CreateAccount', '', err, null);

          if (err?.code === 'invalid_signup') {
            setHelperText(invalidSignupMsg);
            return;
          }

          const description = err?.description ?? err?.toString() ?? 'Something went wrong';
          helpers.setFieldError('email', description);
          return;
        }

        setAccessToken(result?.accessToken);

        const decodedToken = decodeToken<{ sub: string }>(result?.accessToken);
        if (decodedToken) {
          rudderanalytics.identify({ auth0_id: decodedToken.sub });
        }

        history.push(DASHBOARD);
      },
    });
  };

  return (
    <Container pt={10} minW={{ lg: 'container.xl' }}>
      <Title>
        {email ? 'Create a Password to Sign Your Documents' : 'Create Your Lease End Account'}
      </Title>
      <Subtitle>
        {email
          ? 'Seamlessly manage your lease buyout with eSigning capabilities.'
          : 'One account for all your lease end information'}
      </Subtitle>
      <Formik
        enableReinitialize
        initialValues={{
          email: email || '',
          password: '',
          password_confirm: '',
        }}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting }) => {
          return (
            <Form>
              <Box maxW={{ lg: 'container.lg' }} mx="auto" w="100%">
                <Flex bg="white" rounded="lg">
                  <Flex
                    bg="leaseEndSkyBlue"
                    display={{ base: 'none', lg: 'block' }}
                    w={{ lg: '50%' }}
                    roundedLeft="lg"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Image src={AccountChair} />
                  </Flex>
                  <Flex
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="space-around"
                    w={{ base: '100%', lg: '50%' }}
                    p={10}
                  >
                    <Text fontSize="16px" fontWeight="semibold" mb={4} color="leaseEndBlue">
                      Confirm your email address and set your password
                    </Text>
                    <Input
                      label="Email Address"
                      name="email"
                      placeholder="Email Address"
                      autoFocus={!!email}
                      _container={{ mb: 6, h: 'auto' }}
                      readOnly={!!email}
                      helperText={helperText}
                      _helperText={{ color: 'leaseEndRed' }}
                    />
                    <Flex flexDir={{ base: 'column', lg: 'row' }} w="100%" mb={6}>
                      <Input
                        type={showPassword ? 'text' : 'password'}
                        label="Your Secret Password"
                        name="password"
                        autoFocus={!!email}
                        placeholder="Password"
                        _container={{ w: { base: '100%', lg: '50%' } }}
                        icon={
                          <IconButton
                            variant="unstyled"
                            onClick={() => setShowPassword(!showPassword)}
                            aria-label="Hide/Show password"
                            icon={<Icon as={showPassword ? FaEye : FaEyeSlash} mt={1} />}
                          />
                        }
                      />
                      <Flex flexDirection="column" pl={{ lg: 4 }} w={{ base: '100%', lg: '50%' }}>
                        <PasswordStrength
                          label="Strength"
                          labelProps={{ display: { base: 'none', lg: 'block' } }}
                          h="auto"
                          password={values.password}
                          mt={{ base: 4, lg: 0 }}
                        />
                        <TextWithTooltip
                          label={StrongPasswordTooltipExplanation}
                          textProps={{ fontSize: '12px' }}
                        >
                          Strong password tips
                        </TextWithTooltip>
                      </Flex>
                    </Flex>
                    <Input
                      type={showPasswordConfirm ? 'text' : 'password'}
                      label="Confirm Password"
                      name="password_confirm"
                      placeholder="Confirm password"
                      _container={{
                        mb: 8,
                        h: 'auto',
                        w: { base: '100%', lg: '50%' },
                        alignSelf: 'flex-start',
                      }}
                      icon={
                        <IconButton
                          variant="unstyled"
                          onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                          aria-label="Hide/Show password confirm"
                          icon={<Icon as={showPasswordConfirm ? FaEye : FaEyeSlash} mt={1} />}
                        />
                      }
                    />
                    <Button type="submit" isLoading={isSubmitting}>
                      CREATE ACCOUNT
                    </Button>
                    <Text mt={4} fontSize="14px" fontWeight="semibold">
                      Already have an account?{' '}
                      <Link as={RRLink} to={ACCOUNT_LOGIN} _hover={{ textDecor: 'underline' }}>
                        Login now
                      </Link>
                    </Text>
                  </Flex>
                </Flex>
                <Flex
                  mt={4}
                  w="50%"
                  alignItems="center"
                  justifyContent="space-around"
                  display={{ base: 'none', lg: 'flex' }}
                >
                  <Flex direction="column" alignItems="center" justifyContent="center">
                    <Image src={Inc5000} alt="Inc500" maxW="8rem" />
                    <Text textAlign="center" maxW="12rem" mt={2}>
                      171st fastest-growing company in the U.S.
                    </Text>
                  </Flex>
                  <Image maxW="8rem" src={GoogleReviews} alt="Google Reviews" />
                  <Image maxW="8rem" src={BBBLogo} alt="BBB Logo" />
                </Flex>
              </Box>
              <SimpleFooter />
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default CreateAccount;
