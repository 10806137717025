import {
  Modal as ChakraModal,
  ModalProps as ChakraModalProps,
  Flex,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalCloseButtonProps,
  ModalContent,
  ModalContentProps,
  ModalHeader,
  ModalHeaderProps,
  ModalOverlay,
  Text,
  TextProps,
} from '@chakra-ui/react';
import React from 'react';

export interface ModalProps extends ChakraModalProps {
  title?: string | React.ReactNode;
  titleFontSize?: TextProps['fontSize'];
  titleFontWeight?: TextProps['fontWeight'];
  subtitle?: string | React.ReactNode;
  subtitleFontSize?: TextProps['fontSize'];
  size?: ChakraModalProps['size'];
  showCloseButton?: boolean;
  _body?: ModalBodyProps;
  _content?: ModalContentProps;
  _header?: ModalHeaderProps;
  _modalCloseButton?: ModalCloseButtonProps;
}

const Modal: React.FC<ModalProps> = ({
  title,
  subtitle,
  isOpen,
  children,
  onClose,
  size,
  titleFontSize = { base: '24px', md: '36px' },
  titleFontWeight = 'light',
  subtitleFontSize = '16px',
  showCloseButton = true,
  _body,
  _content,
  _header,
  _modalCloseButton,
  ...rest
}) => {
  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      size={size}
      isCentered
      scrollBehavior="inside"
      {...rest}
    >
      <ModalOverlay />
      <ModalContent
        bg={_content?.bg || 'white'}
        mx={{ base: 4, md: 0 }}
        maxW={_content?.maxW ? _content?.maxW : size ? undefined : '600px'}
      >
        <ModalHeader textAlign="center" mb={title || subtitle ? '20px' : 0} {..._header}>
          <Flex justify="center" align="center" flexDir="column" boxSize="100%" gap="20px">
            {title && (
              <Text fontSize={titleFontSize} fontWeight={titleFontWeight}>
                {title}
              </Text>
            )}
            {subtitle && (
              <Text fontSize={subtitleFontSize} fontWeight="medium">
                {subtitle}
              </Text>
            )}
          </Flex>
        </ModalHeader>
        {showCloseButton && <ModalCloseButton {..._modalCloseButton} />}
        <ModalBody {..._body}>{children}</ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};

export default Modal;
