import { Box } from '@chakra-ui/react';

import Label from '../../components/formComponents/Label';

const InTheMeantime = () => (
  <Box w={{ base: '100%', lg: '615px' }} m="40px auto 20px" p="0px 20px">
    <Label fontSize={{ base: 'unset', lg: '20px' }}>IN THE MEANTIME:</Label>
  </Box>
);

export default InTheMeantime;
