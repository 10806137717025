import { Box, Center, Spinner } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import TextWithTooltip from '../../../../../components/TextWithTooltip';
import Select from '../../../../../components/formComponents/Select';
import PrimaryButton from '../../../../../components/ui/buttons/PrimaryButton';
import { KbbVinResult, Maybe, useKbbVinQuery } from '../../../../../gql/generated/graphql';
import { notRequiredString } from '../../../../../utils/strings';

interface Props {
  initialTrim?: string;
  vin?: string;
  handleNext: (vehicle: Maybe<KbbVinResult>) => Promise<void>;
}

const TRIM_EXPLANATION = `
  Trim is basically a fancy term for the different features and options that come with a specific
  model of car. This can include everything from the engine and transmission to exterior and
  interior upgrades. The trim is usually located on the inside of the driver's side door or in
  the owner's manual.
`;

const SelectTrim = ({ initialTrim, vin, handleNext }: Props) => {
  const { data: vinData, loading: kbbLoading } = useKbbVinQuery({
    variables: { vin: vin as string },
    skip: !vin,
  });
  const options = Array.from(
    new Set(
      vinData?.kbbVin?.vinResults?.map((vinResult) => ({
        label: vinResult?.trimName || '',
        value: vinResult?.trimName || '',
      })),
    ),
  );

  const handleSubmit = async (values: { vehicleTrim: Maybe<string> }) => {
    const vehicle: Maybe<KbbVinResult> = vinData?.kbbVin?.vinResults?.find(
      (vinResult) => vinResult?.trimName === values.vehicleTrim,
    );
    await handleNext(vehicle);
  };

  if (kbbLoading) {
    return (
      <Box mt="24px !important">
        <Spinner />
      </Box>
    );
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        vehicleTrim: options.length === 1 ? options[0].label : initialTrim,
      }}
      validateOnChange
      validateOnBlur
      onSubmit={handleSubmit}
      validationSchema={Yup.object({
        vehicleTrim:
          options.length || initialTrim
            ? Yup.string().required('Please select a trim')
            : notRequiredString,
      })}
    >
      {({ isSubmitting }) => {
        return (
          <Form style={{ width: '100%' }}>
            {options.length > 1 && (
              <>
                <Select
                  display={options.length > 0 ? 'block' : 'none'}
                  name="vehicleTrim"
                  placeholder="Select Trim"
                  alignSelf="self-start"
                  w="full"
                  mt={5}
                  mb={2}
                  options={options}
                  menuListHeight="100px"
                />
                <TextWithTooltip label={TRIM_EXPLANATION}>What is my trim?</TextWithTooltip>
              </>
            )}
            <Center mt="30px">
              <PrimaryButton type="submit" isLoading={isSubmitting} isDisabled={kbbLoading}>
                NEXT
              </PrimaryButton>
            </Center>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SelectTrim;
