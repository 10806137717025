import { Box, Center, Flex } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useAtomValue } from 'jotai';

import ButtonedRadioGroup from '../../../components/ButtonedRadioGroup';
import { useLEAccordionItemContext } from '../../../components/LEAccordion/LEAccordionItem/useLEAccordionItem';
import TextWithTooltip from '../../../components/TextWithTooltip';
import { Label, SSNInput } from '../../../components/formComponents';
import DateInput from '../../../components/formComponents/DateInput';
import PrimaryButton from '../../../components/ui/buttons/PrimaryButton';
import { LDFlags } from '../../../constants/flags';
import { options } from '../../../constants/maritalStatuses';
import {
  RelationToBuyerEnum,
  StateAbbreviation,
  TtGetFeesSourceType,
  useExecuteExternalSoftPullMutation,
  useTemporaryInfoUpsertMutation,
} from '../../../gql/generated/graphql';
import { useFlag, useStep, useTempInfo } from '../../../hooks';
import { getCreditScore } from '../../../utils/creditScore';
import { RudderEvent, rudderanalytics } from '../../../utils/rudderstack';
import {
  devCreditPrequalOverride,
  devCreditScoreOverride,
} from '../../LoanApp/components/LoanAppDevToolbar';
import {
  CobuyerSsnAndBirthdayValues,
  cobuyerSsnAndBirthdayInitialValues,
  cobuyerSsnAndBirthdayValidationSchema,
} from '../utils';

const CobuyerSsnAndBirthdate = () => {
  const creditPrequalificationEnabled = useFlag(LDFlags.CREDIT_PREQUALIFICATION);
  const devCreditPrequalSuccess = useAtomValue(devCreditPrequalOverride);
  const devCreditScore = useAtomValue(devCreditScoreOverride);

  const [executeSoftPullPrequalification] = useExecuteExternalSoftPullMutation({
    context: {
      isErrorHandled: true,
    },
  });
  const [upsertTemporaryInfo] = useTemporaryInfoUpsertMutation();

  const { updateState, isExpanded } = useLEAccordionItemContext();
  const step = useStep();
  const { info } = useTempInfo('network-only', isExpanded);
  const tempInfoData = info?.data;

  const onSubmitForm = async (values: CobuyerSsnAndBirthdayValues) => {
    updateState({ status: 'loading' });

    await upsertTemporaryInfo({
      variables: {
        info: {
          id: info?.id,
          data: {
            cobuyer_dob: values?.cobuyer_dob,
            cobuyer_ssn: values?.cobuyer_ssn,
            cobuyer_marital_status: values?.cobuyer_marital_status,
          },
        },
      },
    });

    if (creditPrequalificationEnabled && info?.data) {
      const prequal = await executeSoftPullPrequalification({
        variables: {
          paymentEstimateInput: {
            term: info.data.term ?? 0,
            payoff: info.data.vehicle_payoff ?? 0,
            moneyDown: info.data.down_payment ?? 0,
            creditScore: getCreditScore(devCreditScore ?? info.data.credit_score ?? 0),
            zipCode: info.data.zip as string,
            vin: info.data.vin,
            year: info.data.year,
            make: info.data.make,
            model: info.data.model,
            vehicleType: info.data.vehicle_type,
            fuelType: info.data.fuel_type,
            ttGetFeesSource: TtGetFeesSourceType.ComPrequalification,
          },
          devCreditScore: devCreditScore ?? undefined,
          devOverride: devCreditPrequalSuccess,
        },
      });

      const passedPrequal = devCreditPrequalSuccess ?? prequal?.data?.executeExternalSoftPull;

      rudderanalytics.track(RudderEvent.CreditSoftPull, {
        prequalification_success: passedPrequal ?? undefined,
      });
    }

    updateState({ status: 'complete' });

    step.moveNext();
  };

  const showMaritalStatusSelector =
    tempInfoData?.cobuyer_relation_to_buyer !== RelationToBuyerEnum.Spouse &&
    tempInfoData?.cobuyer_state === StateAbbreviation.Wi;

  if (!tempInfoData) {
    return null;
  }

  return (
    <Box>
      <Center mb={{ base: 4, md: 0 }}>
        <TextWithTooltip label="Providing this information helps us fulfill lender requirements to verify identities.">
          Why do we need this?
        </TextWithTooltip>
      </Center>
      <Formik
        enableReinitialize
        initialValues={cobuyerSsnAndBirthdayInitialValues(tempInfoData)}
        validationSchema={cobuyerSsnAndBirthdayValidationSchema(showMaritalStatusSelector)}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSubmitForm}
      >
        {({ isSubmitting, values, setFieldValue }) => {
          return (
            <Form>
              <Flex
                flexDir={{ base: 'column', md: 'row' }}
                alignItems="flex-start"
                my={4}
                mx="auto"
                w={{ base: '100%', md: '90%', lg: '95%', xl: '100%' }}
              >
                <DateInput
                  _container={{ mr: { base: 0, md: 2 }, mb: { base: 4, md: 0 } }}
                  hideCalendar
                  label="BIRTHDATE"
                  name="cobuyer_dob"
                />
                <SSNInput
                  _container={{ ml: { base: 0, md: 2 }, mb: { base: 4, md: 0 } }}
                  name="cobuyer_ssn"
                  digits="9"
                  label="SOCIAL SECURITY NUMBER"
                />
              </Flex>
              {showMaritalStatusSelector && (
                <Box mt={4} mb={10}>
                  <Label mb={2}>MARITAL STATUS</Label>
                  <ButtonedRadioGroup
                    columns={{ base: 2, md: 4 }}
                    value={values.cobuyer_marital_status}
                    onChange={(value: string) => setFieldValue('cobuyer_marital_status', value)}
                    options={options}
                    name="cobuyer_marital_status"
                  />
                </Box>
              )}

              <Center>
                <PrimaryButton
                  mt="30px"
                  mb={{
                    base: '20px',
                    lg: '0',
                  }}
                  loading={isSubmitting}
                  type="submit"
                >
                  SUBMIT
                </PrimaryButton>
              </Center>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default CobuyerSsnAndBirthdate;
