import { Flex, Image, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React from 'react';

import { Checkmark } from '../../../../assets/Images';

interface Props {
  lienholder?: string;
  lienholderLogo?: string;
  otherLienholder?: string | null;
}

const LienholderSelector: React.FC<Props> = ({ lienholder, lienholderLogo, otherLienholder }) => {
  const { values, setFieldValue } = useFormikContext<{
    lienholder: string;
  }>();

  const isChecked = values.lienholder === lienholder;
  const onClick = () => setFieldValue('lienholder', lienholder);

  return (
    <Flex
      as="label"
      position="relative"
      borderRadius="15px"
      boxShadow="md"
      _hover={{
        bg: 'grayLightBackground',
      }}
      h="130px"
      alignItems="center"
      justifyContent="center"
      bg={isChecked ? 'grayLightBackground' : ''}
      onClick={onClick}
      cursor="pointer"
      p={2}
    >
      {lienholderLogo ? (
        <Image m="0 auto" w="auto" maxH="130px" src={lienholderLogo} alt={`${lienholder} Logo`} />
      ) : (
        <Text textAlign="center" fontSize="2xl" color="leaseEndBlue">
          {otherLienholder}
        </Text>
      )}
      <Image
        hidden={!isChecked}
        h="28px"
        w="28px"
        src={Checkmark}
        alt="Checkmark"
        position="absolute"
        bottom={4}
        right={4}
      />
    </Flex>
  );
};

export default LienholderSelector;
