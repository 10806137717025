import { Container } from '@chakra-ui/react';

import NextStepsTerms from './NextStepsTerms';
import PartnersTitle from './PartnersTitle';
import useNextSteps from './useNextSteps';

const Partners = () => {
  const { importInfo } = useNextSteps();

  return (
    <Container textAlign="center">
      <PartnersTitle />
      <NextStepsTerms color="leaseEndBlue" onSubmit={importInfo} />
    </Container>
  );
};

export default Partners;
