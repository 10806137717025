import { Text, useBreakpointValue } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import {
  DriverLicensePurpleGuy,
  HousePurpleGuy,
  JobCardPurpleGuy,
  PointingUpPurpleGuy,
} from '../../assets/Images';
import { LEAccordion, LEAccordionItem } from '../../components/LEAccordion';
import LEAccordionHeader from '../../components/LEAccordion/LEAccordionHeader';
import LEAccordionContainer from '../../components/LEAccordion/LeAccordionContainer';
import { ACCORDION_NAME } from '../../components/LEAccordion/utils';
import SafetyMessage from '../../components/SafetyMessage';
import TextWithTooltip from '../../components/TextWithTooltip';
import ShoppingCartDesktopSidebar from '../../components/shoppingCart/ShoppingCartDesktopSidebar';
import ShoppingCartMobile from '../../components/shoppingCart/ShoppingCartMobile';
import useEstimatorFormik from '../../components/shoppingCart/useEstimatorFormik';
import { ButtonWrapper } from '../../components/ui/buttons/ButtonWrapper';
import SecondaryButton from '../../components/ui/buttons/SecondaryButton';
import { LDFlags } from '../../constants/flags';
import { MID_FLOW_PAYMENT_CALC_VARIATIONS } from '../../constants/paymentCalculator';
import { useFlag, useTempInfo } from '../../hooks';
import BuyerCoBuyerSwitch from './components/BuyerCobuyerSwitch';
import Employment from './components/Employment';
import LoanAppDevToolbar from './components/LoanAppDevToolbar';
import OptionalDownPayment from './components/OptionalDownPayment';
import Residence from './components/Residence';
import SsnAndBirthdate from './components/SsnAndBirthdate';
import VerifyPersonalInfo from './components/VerifyPersonalInfo';

export const LAST_ACCORDION_ITEM_INDEX = 3;
const PAYMENT_MODAL_VARIATIONS = {
  default: 0,
  dontOpenModalOnLoad: 1,
  openModalWithDelay: 2,
};

const WHATS_ON_A_LOAN_APPLICATION_COPY =
  'To prepare your loan application and successfully complete a credit check, ' +
  'lenders require us to collect certain information from our customers. Everything ' +
  'that we ask for is standard on a credit application. With this information, lenders ' +
  'can confirm your identity, establish income & payment histories, and assess any ' +
  'potential risk they could face.';

const LoanApp = () => {
  const { info } = useTempInfo();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const paymentModalVariation = useFlag(LDFlags.PAYMENT_MODAL_VARIATION);
  const paymentCalcVariation = useFlag(LDFlags.MID_FLOW_PAYMENT_CALC);

  const showPaymentCalc = paymentCalcVariation === MID_FLOW_PAYMENT_CALC_VARIATIONS.showPaymentCalc;
  const showDownPaymentAccordion =
    paymentCalcVariation === MID_FLOW_PAYMENT_CALC_VARIATIONS.showDownPaymentAccordion;

  useEffect(() => {
    if (!info?.data?.phone_number) {
      if (paymentModalVariation != null) {
        if (paymentModalVariation === PAYMENT_MODAL_VARIATIONS.dontOpenModalOnLoad) {
          return;
        }

        if (paymentModalVariation === PAYMENT_MODAL_VARIATIONS.openModalWithDelay) {
          setTimeout(() => setIsOpen(true), 1500);
          return;
        }

        setIsOpen(true);
      }
    }
  }, [info, paymentModalVariation]);

  const [refetchEstimator, setRefetchEstimator] = useState(false);
  const { formik, loading: shoppingCartLoading } = useEstimatorFormik(refetchEstimator);
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <LEAccordionContainer>
      <LEAccordionHeader>Your Application</LEAccordionHeader>
      <Text textAlign="center" fontSize="16px" mb="5px">
        Enter your info below and you'll be on your way to approval for your new loan!{' '}
        <TextWithTooltip label={WHATS_ON_A_LOAN_APPLICATION_COPY}>
          What's on a loan application?
        </TextWithTooltip>
      </Text>
      <BuyerCoBuyerSwitch justifyContent="center" my={4} />
      <LEAccordion
        name={ACCORDION_NAME.LOAN_APP_ACCORDION}
        minutes={5}
        rightSidebar={
          <>
            {showPaymentCalc && (
              <ShoppingCartDesktopSidebar
                isOpen={isOpen && !isMobile}
                setIsOpen={setIsOpen}
                loading={shoppingCartLoading}
                formik={formik}
              />
            )}
          </>
        }
      >
        <LEAccordionItem
          accordionKey="loanVerifyPersonalInfo"
          title="PERSONAL INFO"
          imgSrc={PointingUpPurpleGuy}
          imgProps={{ left: '-106px', top: '-120px' }}
        >
          <VerifyPersonalInfo />
        </LEAccordionItem>
        <LEAccordionItem
          accordionKey="loanResidence"
          title="RESIDENTIAL INFO"
          imgSrc={HousePurpleGuy}
          imgProps={{ left: '-76px' }}
        >
          <Residence refetchEstimator={() => setRefetchEstimator(!refetchEstimator)} />
        </LEAccordionItem>
        <LEAccordionItem
          accordionKey="loanEmployment"
          title="EMPLOYMENT INFO"
          imgSrc={JobCardPurpleGuy}
          imgProps={{ top: '-1px', left: '-55px' }}
        >
          <Employment />
        </LEAccordionItem>
        {showDownPaymentAccordion && (
          <LEAccordionItem accordionKey="test" title="OPTIONAL DOWN PAYMENT">
            <OptionalDownPayment formik={formik} />
          </LEAccordionItem>
        )}
        <LEAccordionItem
          accordionKey="loanBirthdate"
          title="YOUR DATE OF BIRTH/SSN"
          imgSrc={DriverLicensePurpleGuy}
          imgProps={{ left: '-76px' }}
        >
          <SsnAndBirthdate />
        </LEAccordionItem>
      </LEAccordion>
      <SafetyMessage />
      <ButtonWrapper>
        <SecondaryButton />
      </ButtonWrapper>
      <LoanAppDevToolbar />
      {showPaymentCalc && (
        <ShoppingCartMobile
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          loading={shoppingCartLoading}
          formik={formik}
        />
      )}
    </LEAccordionContainer>
  );
};

export default LoanApp;
