import { Flex, Icon, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import React from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';

import { ABOUT_US, FAQS, LEARN, REVIEWS } from '../../constants/urls';
import { ABOUT_US_BREAKPOINT, FAQ_BREAKPOINT, LEARN_BREAKPOINT, REVIEWS_BREAKPOINT } from './Nav';
import NavLink from './NavLink';

const NavDropdown = () => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
  }, []);

  return (
    <>
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton color="leaseEndBlue" fontSize="16px" fontWeight="semibold">
              <Flex alignItems="center" gap="5px">
                More
                <Icon
                  as={IoMdArrowDropdown}
                  transform={isOpen ? 'rotate(180deg)' : 'none'}
                  transition="200ms"
                />
              </Flex>
            </MenuButton>
            <MenuList>
              {windowWidth < FAQ_BREAKPOINT && (
                <MenuItem>
                  <NavLink to={FAQS}>FAQs</NavLink>
                </MenuItem>
              )}
              {windowWidth < LEARN_BREAKPOINT && (
                <MenuItem>
                  <NavLink to={LEARN} isExternal>
                    Blog
                  </NavLink>
                </MenuItem>
              )}
              {windowWidth < ABOUT_US_BREAKPOINT && (
                <MenuItem>
                  <NavLink to={ABOUT_US}>About Us</NavLink>
                </MenuItem>
              )}
              {windowWidth < REVIEWS_BREAKPOINT && (
                <MenuItem>
                  <NavLink to={REVIEWS}>Reviews</NavLink>
                </MenuItem>
              )}
            </MenuList>
          </>
        )}
      </Menu>
    </>
  );
};

export default NavDropdown;
