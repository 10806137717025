import { vehicleType } from '../constants/vehicleType';
import {
  StateAbbreviation,
  TtFuelType,
  TtVehicleType,
  useGetFeesLazyQuery,
} from '../gql/generated/graphql';

export const FEES_DEFAULT_VALUES = {
  vin: '1FTEW1C46LKE39821',
  year: new Date().getFullYear() - 3,
  make: 'Sample',
  model: 'Sample',
  zip: '12345',
  fuelType: TtFuelType.Gasoline,
  firstName: 'Sample',
  lastName: 'Sample',
  bookValue: 1.02,
  retailBookValue: 1.02,
  payoff: 1.02,
  estimatedPayoff: 1.02,
  ssn: '111111111',
  warranty: 0,
};

const transformVehicleType = (type: string) => {
  switch (type) {
    case vehicleType.PASSENGER:
      return TtVehicleType.PassengerCar;
    case vehicleType.MPV:
      return TtVehicleType.MultipurposePassengerVehicle;
    case vehicleType.TRUCK:
      return TtVehicleType.Truck;
    default:
      return TtVehicleType.PassengerCar;
  }
};

export interface FeesInput {
  state: StateAbbreviation;
  previouslyTitledState: StateAbbreviation;
  city: string;
  county: string;
  zip: string;
  vin: string;
  make: string;
  model: string;
  year: string;
  vehicleType: string;
  fuelType: TtFuelType;
  firstName: string;
  lastName: string;
  retailBookValue: number;
  bookValue: number;
  docFee: number;
  payoff: number;
  estimatedPayoff: number;
  warranty: number;
  source: string;
}

export interface FeesOutput {
  state: StateAbbreviation;
  totalFeeAmount: number;
  registrationTransferFee: number;
  salesTaxRate: number;
  baseTaxAmount: number;
  warrantyTaxAmount: number;
}

const getFeesFromTT = async (
  getTTFeesLazyQuery: ReturnType<typeof useGetFeesLazyQuery>[0],
  input: Partial<FeesInput> & Pick<FeesInput, 'source'>,
): Promise<FeesOutput> => {
  const ttFees = await getTTFeesLazyQuery({
    variables: {
      state: (input.state ?? '') as StateAbbreviation,
      previouslyTitledState: (input.previouslyTitledState ??
        input.state ??
        '') as StateAbbreviation,
      city: input.city ?? '',
      county: input.county ?? '',
      zip: input.zip ?? FEES_DEFAULT_VALUES.zip,
      vin: input.vin ?? FEES_DEFAULT_VALUES.vin,
      make: input.make ?? FEES_DEFAULT_VALUES.make,
      model: input.model ?? FEES_DEFAULT_VALUES.model,
      year: parseInt(input.year ?? '', 10),
      vehicleType: transformVehicleType(input.vehicleType ?? ''),
      fuelType: input.fuelType ?? TtFuelType.Gasoline,
      retailBookValue: input.retailBookValue ?? FEES_DEFAULT_VALUES.retailBookValue,
      bookValue: input.bookValue ?? FEES_DEFAULT_VALUES.bookValue,
      docFee: input.docFee ?? 0,
      payoff: input.payoff ?? FEES_DEFAULT_VALUES.payoff,
      estimatedPayoff: input.estimatedPayoff ?? FEES_DEFAULT_VALUES.estimatedPayoff,
      // If firstName or lastName are missing, the request fails
      firstName: input.firstName ?? FEES_DEFAULT_VALUES.firstName,
      lastName: input.lastName ?? FEES_DEFAULT_VALUES.lastName,
      // ssn is required by TT but doesn't affect the result
      ssn: FEES_DEFAULT_VALUES.ssn,
      warranty: FEES_DEFAULT_VALUES.warranty,
      source: input.source,
    },
  });

  return {
    state: (ttFees.data?.getFees?.state ?? '') as StateAbbreviation,
    totalFeeAmount: ttFees.data?.getFees?.totalFeeAmount ?? 0,
    registrationTransferFee: ttFees.data?.getFees?.registrationTransferFee ?? 0,
    salesTaxRate: ttFees.data?.getFees?.salesTaxRate ?? 0,
    baseTaxAmount: ttFees.data?.getFees?.baseTaxAmount ?? 0,
    warrantyTaxAmount: ttFees.data?.getFees?.warrantyTaxAmount ?? 0,
  };
};

export default getFeesFromTT;
