// eslint-disable-next-line no-restricted-imports
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useHistory, useLocation } from 'react-router-dom';

import { LDFlags } from '../constants/flags';
import {
  BackOptions,
  NextOptions,
  Step,
  defaultBack,
  defaultNext,
  itineraries,
} from '../experiments/itineraries';
import { evaluateFlag } from './useFlag';

export const useStep = () => {
  const location = useLocation();
  const history = useHistory();
  const ldClient = useLDClient();
  const { pathname } = location;

  const step = itineraries.find((s: Step) => s.url === pathname);

  return {
    url: step?.url,
    moveNext: (options: NextOptions = {}, replace?: boolean) => {
      const nextValue =
        step?.next(options, (flag: LDFlags) => evaluateFlag(ldClient, flag)) || defaultNext();
      if (replace) {
        history.replace(nextValue, { from: step?.url });
      } else {
        history.push(nextValue, { from: step?.url });
      }
    },
    movePrevious: (options: BackOptions = {}, replace?: boolean) => {
      const prevValue = step?.back(options, (flag: LDFlags) => evaluateFlag(ldClient, flag));
      if (options.redirect) {
        window.location.href = prevValue || defaultBack();
      } else if (replace) {
        history.replace(prevValue || defaultBack(), { from: step?.url });
      } else {
        history.push(prevValue || defaultBack(), { from: step?.url });
      }
    },
  };
};
