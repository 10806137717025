import { Flex } from '@chakra-ui/react';
import { FaRegCalendarAlt } from '@react-icons/all-files/fa/FaRegCalendarAlt';
import { useFormikContext } from 'formik';
import { MdOutlineCreditScore } from 'react-icons/md';
import { RiMoneyDollarBoxFill } from 'react-icons/ri';

import { creditScores, getCreditScoreRangeText, termsArr } from '../../utils/paymentCalculator';
import SliderOption from '../SliderOption';
import { EstimatorFormFields } from './useEstimatorFormik';

const ShoppingCartSliders = ({ usedInModal }: { usedInModal: boolean }) => {
  const { values, setFieldValue } = useFormikContext<EstimatorFormFields>();

  return (
    <Flex flexDir="column" gap="10px">
      <SliderOption
        label="Credit Score"
        icon={MdOutlineCreditScore}
        min={1}
        max={creditScores.length}
        w={usedInModal ? '100%' : undefined}
        minMark="300"
        maxMark="800+"
        name="credit_score"
        readOnly
        value={
          creditScores.findIndex(
            ({ min, max }) => values.credit_score >= min && values.credit_score <= max,
          ) + 1
        }
        customOnChange={(val) => {
          const score = creditScores[val - 1];
          setFieldValue('credit_score', score.max);
        }}
        inputValue={getCreditScoreRangeText(values.credit_score)}
      />
      <SliderOption
        label="Down Payment"
        icon={RiMoneyDollarBoxFill}
        min={0}
        max={4000}
        w={usedInModal ? '100%' : undefined}
        inputMax={values.vehicle_payoff}
        maxMark="$4000+"
        step={100}
        isMoney
        name="down_payment"
      />
      <SliderOption
        label="Loan Term"
        icon={FaRegCalendarAlt}
        min={1}
        max={4}
        w={usedInModal ? '100%' : undefined}
        minMark="48 mos"
        maxMark="84 mos"
        name="term"
        readOnly
        value={termsArr.indexOf(values.term) + 1}
        inputValue={`${values.term} mos`}
        customOnChange={(val) => setFieldValue('term', termsArr[val - 1])}
      />
    </Flex>
  );
};

export default ShoppingCartSliders;
