/* eslint-disable react/no-array-index-key */
import { Box, Fade, Flex, Icon, Image, useBreakpointValue } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';

import useRefSize from '../../hooks/useRefSize';

interface Props {
  images: { src: string; alt: string }[];
  imageWdith?: number;
  height?: string;
}

const ImageCarousel = ({ images, imageWdith = 150, height = '82px' }: Props) => {
  const numImages = images.length;

  const isMobile = useBreakpointValue({ base: true, sm: false });
  const [sizeRef, { width }] = useRefSize('client');
  const [activeIndex, setActiveIndex] = useState(Math.floor(Math.random() * numImages));

  const logosShown = Math.min(Math.floor(width / imageWdith), numImages - 1, isMobile ? 2 : 3);
  const allLogosShown = logosShown === numImages - 1;

  const clickPrev = () => {
    setActiveIndex((activeIndex - 1 - logosShown + numImages) % numImages);
  };

  const clickNext = () => {
    setActiveIndex((activeIndex + 1 + logosShown) % numImages);
  };

  const [fadeIn, setFadeIn] = useState(false); // Add show state

  useEffect(() => {
    setFadeIn(true);
  }, []);

  return (
    <Flex minW="100%" align="center" justify="center" ref={sizeRef}>
      <Box
        boxSize="20px"
        onClick={clickPrev}
        hidden={allLogosShown}
        color="leaseEndBlue"
        cursor="pointer"
      >
        <Icon as={MdArrowBackIosNew} />
      </Box>
      <Flex minH={height} minW="90%" px={2} align="center" justify="space-around" gap="20px">
        {Array.from({ length: logosShown }).map((_, index) => {
          const imageIndex = (activeIndex + index) % numImages;

          return (
            <Fade
              in={fadeIn}
              transition={{ enter: { duration: 1 } }}
              key={`partner-logo-${imageIndex}`}
            >
              <Image
                p={3}
                w="100%"
                maxH={height}
                src={images[imageIndex].src}
                alt={images[imageIndex].alt}
                transition="opacity 2s ease-in-out"
              />
            </Fade>
          );
        })}
      </Flex>
      <Box
        boxSize="20px"
        hidden={allLogosShown}
        onClick={clickNext}
        color="leaseEndBlue"
        cursor="pointer"
      >
        <Icon as={MdArrowForwardIos} />
      </Box>
    </Flex>
  );
};

export default ImageCarousel;
