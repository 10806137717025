import { Button, Flex, Text } from '@chakra-ui/react';
import React from 'react';

import Modal from '../../../components/Modal';

interface Props {
  isRefi: boolean;
  isOpen: boolean;
  onClose: () => void;
}

const WelcomeModal = ({ isRefi, isOpen, onClose }: Props) => (
  <Modal title="Welcome to your dashboard!" isOpen={isOpen} onClose={onClose}>
    <Flex direction="column" textAlign="center" alignItems="center">
      <Text>
        This is it - the perfect place to check on your {isRefi ? 'refinance' : 'lease-end'}{' '}
        progress. As your Lease End Success Team continues working{' '}
        {isRefi ? 'on your new auto loan' : 'to end your lease'}, you'll see which stage you're in
        (and what comes next) here. Your team will be in touch if we need anything else from you. In
        the meantime, have a look around!
      </Text>
      <Button mt="20px" onClick={onClose} w="fit-content">
        OK
      </Button>
    </Flex>
  </Modal>
);

export default WelcomeModal;
