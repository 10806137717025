import { Link, Text } from '@chakra-ui/react';
import { FaCarCrash } from '@react-icons/all-files/fa/FaCarCrash';
import { FaCreditCard } from '@react-icons/all-files/fa/FaCreditCard';
import { FaIdCard } from '@react-icons/all-files/fa/FaIdCard';
import React from 'react';
import { IconType } from 'react-icons';
import { MdArticle, MdOutlineReceiptLong } from 'react-icons/md';

import TextWithTooltip from '../../../../components/TextWithTooltip';
import { DealMediaTypeEnum, TtDocumentName } from '../../../../gql/generated/graphql';

export const enum DocumentSideEnum {
  FRONT = 'FRONT',
  BACK = 'BACK',
}

export type DocumentSideType = DocumentSideEnum.FRONT | DocumentSideEnum.BACK | '';

export type DocumentType = {
  title: string;
  ttDocumentNameEnumType: TtDocumentName;
  whyWeAsk?: string;
  howToGetIt?: React.ReactNode | string;
  neededNow: boolean;
  isCobuyer?: boolean;
  images: {
    side: DocumentSideType;
    placeholderIcon: IconType;
    type: DealMediaTypeEnum;
  }[];
};

const SafetyInspectionLinks = {
  Texas: 'https://www.dps.texas.gov/rsd/vi/viactivestationlocator/visrchresults.aspx',
  Maryland: 'https://egov.maryland.gov/msp/vsi/Lookup#!/',
};

const SafetyInspectionHowToGetIt = (state?: string) => {
  if (state && Object.keys(SafetyInspectionLinks).includes(state)) {
    return (
      <>
        You can locate the nearest Inspection Station in your state by clicking{' '}
        <Link
          color="blue.500"
          target="_blank"
          href={SafetyInspectionLinks[state as keyof typeof SafetyInspectionLinks]}
        >
          here.
        </Link>
      </>
    );
  }
  return 'You can search for nearby Inspection Station’s on your state DMV’s website';
};

type DocumentsParams = {
  backOfDLRequired?: boolean;
  backOfCobuyerDLRequired?: boolean;
  state?: string;
  movedStates?: boolean;
  hasCobuyer?: boolean;
};

export const vehiclePropertyTaxLabel = (
  <>
    Just to clarify things a bit, in South Carolina a vehicle is considered property and has a
    separate property tax associated with it (that isn't sales tax). So even if you are caught up on
    the property taxes on your home, you'll still need to pay a property tax on the lease payoff.{' '}
    <Text display="inline" textDecoration="underline">
      This is not included in the fees we collect
    </Text>
    , and is typically between $200-$600. If you want us to do your registration for you, you’ll
    need to pay this and send us a picture of the property tax receipt before we can process your
    registration
  </>
);

const documents: (params: DocumentsParams) => DocumentType[] = (params) => {
  const { backOfDLRequired, backOfCobuyerDLRequired, state, movedStates, hasCobuyer } = params;

  return [
    {
      title: "Driver's License",
      ttDocumentNameEnumType: TtDocumentName.DriversLicense,
      neededNow: true,
      images: [
        {
          side: DocumentSideEnum.FRONT,
          placeholderIcon: FaIdCard,
          type: DealMediaTypeEnum.FrontOfDriversLicense,
        },
        ...(backOfDLRequired
          ? [
              {
                side: DocumentSideEnum.BACK,
                placeholderIcon: FaCreditCard,
                type: DealMediaTypeEnum.BackOfDriversLicense,
              },
            ]
          : []),
      ],
    },
    ...(hasCobuyer
      ? [
          {
            title: "Driver's License",
            ttDocumentNameEnumType: TtDocumentName.CobuyerDriversLicense,
            neededNow: true,
            isCobuyer: true,
            images: [
              {
                side: DocumentSideEnum.FRONT,
                placeholderIcon: FaIdCard,
                type: DealMediaTypeEnum.FrontOfCoBuyersLicense,
              },
              ...(backOfCobuyerDLRequired
                ? [
                    {
                      side: DocumentSideEnum.BACK,
                      placeholderIcon: FaCreditCard,
                      type: DealMediaTypeEnum.BackOfCoBuyersLicense,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    {
      title: 'Proof of Insurance',
      images: [
        { side: '', placeholderIcon: FaCarCrash, type: DealMediaTypeEnum.FrontOfInsuranceCard },
      ],
      ttDocumentNameEnumType: TtDocumentName.ProofOfInsurance,
      neededNow: true,
    },
    {
      title: 'Current Registration',
      images: [
        { side: '', placeholderIcon: MdOutlineReceiptLong, type: DealMediaTypeEnum.Registration },
      ],
      ttDocumentNameEnumType: TtDocumentName.Registration,
      neededNow: true,
    },
    {
      title: 'Emissions Report',
      images: [{ side: '', placeholderIcon: MdArticle, type: DealMediaTypeEnum.Emissions }],
      ttDocumentNameEnumType: TtDocumentName.Emissions,
      whyWeAsk: `${state} requires an emissions inspection to ensure the vehicle is in compliance with state law.`,
      howToGetIt:
        'Many automotive repair shops offer emissions testing/reporting. You can also contact your county emissions authority to get a list of approved providers. ',
      neededNow: false,
    },
    {
      title: 'Picture of Odometer',
      images: [{ side: '', placeholderIcon: MdArticle, type: DealMediaTypeEnum.OdometerPicture }],
      ttDocumentNameEnumType: TtDocumentName.Odometer,
      whyWeAsk:
        'We need to verify that the mileage on your vehicle matches the mileage information in your paperwork.',
      howToGetIt: 'Find your odometer on your car’s dashboard and take a picture.',
      neededNow: false,
    },
    {
      title: 'Proof of Residence',
      images: [{ side: '', placeholderIcon: MdArticle, type: DealMediaTypeEnum.ProofOfResidence }],
      ttDocumentNameEnumType: TtDocumentName.ProofOfResidence,
      whyWeAsk: `Your residential address doesn’t match the address on your driver’s license, so ${state} requires additional proof of residence.`,
      howToGetIt:
        'Proof of residence can be a document like a phone or utility bill, bank statement, etc. that clearly shows your current address.',
      neededNow: false,
    },
    {
      title: 'Proof of Residence 2',
      images: [{ side: '', placeholderIcon: MdArticle, type: DealMediaTypeEnum.ProofOfResidence2 }],
      ttDocumentNameEnumType: TtDocumentName.ProofOfResidence2,
      whyWeAsk: `Some states require two proof of residence documents in certain situations.`,
      howToGetIt:
        'Proof of residence can be a document like a phone or utility bill, bank statement, etc. that clearly shows your current address.',
      neededNow: false,
    },
    {
      title: 'Property Tax Receipt',
      images: [
        { side: '', placeholderIcon: MdArticle, type: DealMediaTypeEnum.PropertyTaxReceipt },
      ],
      ttDocumentNameEnumType: TtDocumentName.PropertyTaxReceipt,
      whyWeAsk: `${state} requires a property tax receipt when you get new license plates.`,
      howToGetIt: (
        <Text fontSize="lg" mt="8">
          You can go to your local DMV office to
          <TextWithTooltip label={vehiclePropertyTaxLabel}>
            pay your vehicle property tax
          </TextWithTooltip>{' '}
          and obtain this receipt.
        </Text>
      ),
      neededNow: false,
    },
    {
      title: 'VIN Inspection',
      images: [{ side: '', placeholderIcon: MdArticle, type: DealMediaTypeEnum.VinInspection }],
      ttDocumentNameEnumType: TtDocumentName.VinInspection,
      whyWeAsk: `${
        movedStates
          ? `${state} requires a VIN inspection because you relocated during your lease term.`
          : `${state} requires a VIN inspection to ensure that the correct vehicle is registered.`
      }`,
      howToGetIt: 'Most DMVs or local police stations offer VIN inspections.',
      neededNow: false,
    },
    {
      title: 'Safety Inspection',
      images: [{ side: '', placeholderIcon: MdArticle, type: DealMediaTypeEnum.SafetyInspection }],
      ttDocumentNameEnumType: TtDocumentName.SafetyInspection,
      whyWeAsk: `${state} requires a Safety Inspection to be done when registering your vehicle.`,
      howToGetIt: SafetyInspectionHowToGetIt(state),
      neededNow: false,
    },
  ];
};

export { documents };
