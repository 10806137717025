import { Progress, Text, VStack } from '@chakra-ui/react';

import { useLEAccordionItemContext } from '../useLEAccordionItem';

const LEAccordionItemProgress = () => {
  const {
    state: { status, progress },
    isExpanded,
  } = useLEAccordionItemContext();

  if (
    progress?.currentProgress == null ||
    progress?.totalProgress == null ||
    progress.totalProgress === 0
  ) {
    return null;
  }

  return (
    <VStack w="full" display={isExpanded ? 'none' : 'inherit'} fontSize="12px">
      <Progress
        w="50%"
        bg="white"
        colorScheme={status === 'warn' ? 'progressBarYellow' : status === 'error' ? 'red' : 'green'}
        rounded="2xl"
        value={(progress.currentProgress / progress.totalProgress) * 100}
      />
      <Text>
        <Text as="span" fontWeight="semibold">
          {progress.currentProgress} / {progress.totalProgress}
        </Text>{' '}
        completed
      </Text>
    </VStack>
  );
};

export default LEAccordionItemProgress;
