import { Text, TextProps } from '@chakra-ui/react';
import React from 'react';

const Subtitle: React.FC<TextProps> = ({ children, ...props }) => (
  <Text
    color="leaseEndBlue"
    fontSize={{ base: '16px', lg: '20px' }}
    textAlign="center"
    mb="20px"
    {...props}
  >
    {children}
  </Text>
);

export default Subtitle;
