import { Provider as JotaiProvider } from 'jotai';
import { useEffect, useState } from 'react';
import { Switch, useLocation } from 'react-router-dom';

import AuthenticatedRoute from '../components/routeComponents/AuthenticatedRoute';
import FlowRoute from '../components/routeComponents/FlowRoute';
import LERoute from '../components/routeComponents/LERoute';
import {
  ALL_DONE,
  COBUYER_LOAN_APPLICATION,
  DEAL_PAGES,
  DEAL_PROCESSING,
  FINAL_ADJUSTMENTS,
  FLOW_REENTER,
  GETTING_STARTED,
  LOAN_APPLICATION,
  NEXT_STEPS,
  PAYOFF_INSTRUCTIONS,
  TEMP_INFO_PAGES,
} from '../constants/urls';
import {
  useExternalCustomerUpdateMutation,
  useTemporaryInfoUpsertMutation,
  useUserDealQuery,
} from '../gql/generated/graphql';
import { useTempInfo } from '../hooks';
import { CookieKeys, useCookie } from '../hooks/useCookie';
import PageFor404s from '../pages/404Page';
import AlternatePayoffInstructions from '../pages/AlternatePayoffInstructions';
import CobuyerLoanApp from '../pages/CobuyerLoanApp';
import GettingStarted from '../pages/GettingStarted';
import LoanApp from '../pages/LoanApp';
import AllDone from '../pages/allDone/AllDone';
import DealProcessing from '../pages/dealProcessing/DealProcessing';
import FinalAdjustments from '../pages/finalAdjustments/FinalAdjustments';
import FlowReenter from '../pages/flowEntryPages/FlowReenter';
import NextSteps from '../pages/nextSteps/NextSteps';

const FlowRoutes = () => {
  const { pathname } = useLocation();
  const { info } = useTempInfo('network-only', true);

  const [guid] = useCookie<string>(CookieKeys.GUID_KEY);
  const [accessToken] = useCookie<string>(CookieKeys.ACCESS_TOKEN);
  const [customerUpdateMutation] = useExternalCustomerUpdateMutation();
  const [updateTempInfo] = useTemporaryInfoUpsertMutation();
  const [prevPathname, setPrevPathname] = useState('');

  const { data: dealData } = useUserDealQuery({
    fetchPolicy: 'network-only',
    skip: !accessToken,
  });

  const customerId = dealData?.userDeal?.customer?.id;
  useEffect(() => {
    if (pathname === prevPathname) {
      return;
    }
    setPrevPathname(pathname);

    if (TEMP_INFO_PAGES.includes(pathname) && !!guid) {
      const hasHitPrequalification = info?.data?.prequalification_success != null;
      updateTempInfo({
        variables: {
          info: {
            id: guid,
            data: {
              last_url: hasHitPrequalification ? NEXT_STEPS : pathname,
            },
          },
        },
      });
    }

    if (DEAL_PAGES.includes(pathname) && !!customerId) {
      customerUpdateMutation({
        variables: {
          customerId,
          customer: {
            last_authenticated_route: pathname,
          },
        },
      });
    }
  }, [pathname, guid, customerId]);

  return (
    <Switch>
      <FlowRoute path={GETTING_STARTED} component={GettingStarted} title="Getting Started" />
      <FlowRoute
        path={COBUYER_LOAN_APPLICATION}
        component={CobuyerLoanApp}
        title="Co-Buyer Loan Application"
      />
      <FlowRoute path={LOAN_APPLICATION} component={LoanApp} title="Loan Application" />
      <FlowRoute path={PAYOFF_INSTRUCTIONS} title="Payoff Instructions">
        <JotaiProvider key="alternatePayoffInstructions">
          <AlternatePayoffInstructions />
        </JotaiProvider>
      </FlowRoute>
      <FlowRoute path={NEXT_STEPS} component={NextSteps} title="Next Steps" />
      <FlowRoute path={DEAL_PROCESSING} component={DealProcessing} title="Deal Processing" />
      <AuthenticatedRoute
        path={FINAL_ADJUSTMENTS}
        component={FinalAdjustments}
        title="Final Adjustments"
      />
      <LERoute path={ALL_DONE} component={AllDone} title="All Done" />
      <LERoute path={FLOW_REENTER} component={FlowReenter} title="Flow | Lease End" exact />
      <LERoute path="*" component={PageFor404s} title="LeaseEnd - 404" />
    </Switch>
  );
};

export default FlowRoutes;
