import { gql } from '@apollo/client';

export const addressDetail = gql`
  query addressDetail($address: String) {
    addressDetail(address: $address) {
      city
      state
      county
    }
  }
`;

export const validZip = gql`
  query validZip($zipCode: String!) {
    validZip(zipCode: $zipCode)
  }
`;
