import React from 'react';
import { Divider, Flex, FlexProps, Text } from '@chakra-ui/react';

interface Props extends FlexProps {
  text: string;
}

const DividerWithText: React.FC<Props> = ({ text, ...rest }) => (
  <Flex align="center" {...rest}>
    <Divider />
    <Text px="2">{text}</Text>
    <Divider />
  </Flex>
);

export default DividerWithText;
