import * as Yup from 'yup';

import { CustomerInfo } from '../../../../gql/prs/types';
import {
  ssnFullValidation,
  ssnLast4Validation,
  ssnLast6Validation,
} from '../../../../utils/validation/SSNumber';
import { dobValidation } from '../../../../utils/validation/dob';
import { phoneValidationRequired } from '../../../../utils/validation/phoneNumber';

// VIN, ODO, AND ZIP don't have validations
export const CustomerInfoValidations = {
  [CustomerInfo.VIN]: Yup.string(),
  [CustomerInfo.ZIP]: Yup.string(),
  [CustomerInfo.DOB]: dobValidation,
  [CustomerInfo.SSN]: ssnFullValidation,
  [CustomerInfo.SSN_LAST_6]: ssnLast6Validation,
  [CustomerInfo.SSN_LAST_4]: ssnLast4Validation,
  [CustomerInfo.PHONE_NUMBER]: phoneValidationRequired,
  [CustomerInfo.ACCOUNT_NUMBER]: Yup.string().required('Please enter an account number'),
  [CustomerInfo.CURRENT_ODOMETER]: Yup.string(),
  [CustomerInfo.FIRST_NAME]: Yup.string().required('Please enter your first name'),
  [CustomerInfo.LAST_NAME]: Yup.string().required('Please enter your last name'),
};
