import { Link, LinkProps } from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router-dom';

interface NavLinkProps extends LinkProps {
  children: React.ReactNode;
  color?: string;
  to?: string;
}

// TODO-JK: check this for ReactRouterLink
const NavLink = ({ children, color = 'leaseEndBlue', to, isExternal, ...props }: NavLinkProps) => {
  const location = useLocation();
  const { pathname } = location;

  const active = pathname === to;

  return (
    <Link
      fontWeight={active ? 'bold' : 'semibold'}
      href={to}
      color={color}
      fontSize="16px"
      isExternal={isExternal}
      {...props}
    >
      {children}
    </Link>
  );
};

export default NavLink;
