import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

import Modal from './Modal';
import PrimaryButton from './ui/buttons/PrimaryButton';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const DoubleTaxModal: React.FC<Props> = ({ isOpen, onClose }) => (
  <Modal
    title="Please be aware"
    isOpen={isOpen}
    onClose={onClose}
    blockScrollOnMount={false}
    isCentered
    closeOnOverlayClick={false}
  >
    <Flex flexDir="column">
      <Text color="leaseEndBlue" mb={6} textAlign="center" fontSize={{ base: 'lg', md: 'xl' }}>
        To ensure compliance with state and lienholder requirements, we must verify that the name(s)
        on your lease match the buyers before paying off the lease. If the name(s) are different,
        the payoff amount may be higher. We will request a copy of your current registration for
        verification at a later point.
      </Text>
      <PrimaryButton w="fit-content" alignSelf="center" onClick={onClose}>
        NEXT
      </PrimaryButton>
    </Flex>
  </Modal>
);

export default DoubleTaxModal;
