import { Divider, Flex, Icon, Text } from '@chakra-ui/react';
import { RiAccountCircleFill } from '@react-icons/all-files/ri/RiAccountCircleFill';
import React, { useEffect, useState } from 'react';

import { ACCOUNT_LOGIN, DASHBOARD } from '../../constants/urls';
import { Customer, useGetByAuth0IdLazyQuery } from '../../gql/generated/graphql';
import { CookieKeys, useCookie } from '../../hooks/useCookie';
import { logout } from '../../services/auth0';
import ProfileModal from '../ProfileModal';
import NavLink from './NavLink';

const SidebarAuthMenu = () => {
  const [customer, setCustomer] = useState<Customer>();
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [accessToken] = useCookie<string>(CookieKeys.ACCESS_TOKEN);
  const [getCustomer] = useGetByAuth0IdLazyQuery({
    onCompleted: (data) => {
      setCustomer(data?.getByAuth0Id as Customer);
    },
  });

  useEffect(() => {
    if (accessToken) {
      getCustomer();
    }
  }, [accessToken]);

  return accessToken ? (
    <>
      <Flex
        flexDir="column"
        gap="7.5px"
        bg="royalBlueDark"
        w="280px"
        mt="-7px"
        ml="-21px"
        p="10px 21px"
      >
        <Icon as={RiAccountCircleFill} boxSize="30px" color="white" />
        <Text color="white" fontSize="18px" fontWeight="semibold">
          {customer?.full_name}
        </Text>
        <Text color="white" fontSize="15px">
          {customer?.email}
        </Text>
      </Flex>
      <Flex flexDir="column" gap="15px" mt="10px">
        <NavLink
          color="white"
          textTransform="uppercase"
          onClick={() => setProfileModalOpen(true)}
          _hover={{ color: 'white', textDecor: 'underline' }}
        >
          Profile
        </NavLink>
        <NavLink
          to={DASHBOARD}
          color="white"
          textTransform="uppercase"
          _hover={{ color: 'white', textDecor: 'underline' }}
        >
          Dashboard
        </NavLink>
        <NavLink
          onClick={() => logout()}
          color="white"
          textTransform="uppercase"
          _hover={{ color: 'white', textDecor: 'underline' }}
        >
          Logout
        </NavLink>
      </Flex>
      <Divider m="10px 0" />
      <ProfileModal open={profileModalOpen} setOpen={setProfileModalOpen} />
    </>
  ) : (
    <>
      <Flex alignItems="center" gap="5px">
        <Icon as={RiAccountCircleFill} boxSize="30px" color="white" />
        <NavLink
          to={ACCOUNT_LOGIN}
          mt="3px"
          color="white"
          textTransform="uppercase"
          _hover={{ color: 'white', textDecor: 'underline' }}
        >
          Login
        </NavLink>
      </Flex>
      <Divider m="10px 0" />
    </>
  );
};

export default SidebarAuthMenu;
