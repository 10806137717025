import { Text, TextProps } from '@chakra-ui/react';
import React from 'react';

import Popover, { PopoverProps } from './Popover';

interface Props extends Omit<PopoverProps, 'children'> {
  children: string;
  label: PopoverProps['label'];
  popoverBodyProps?: PopoverProps['popoverBodyProps'];
  textProps?: {
    color?: TextProps['color'];
    fontWeight?: TextProps['fontWeight'];
    textAlign?: TextProps['textAlign'];
    fontSize?: TextProps['fontSize'];
    cursor?: TextProps['cursor'];
  };
}

const TextWithTooltip = ({ textProps, children, label, popoverBodyProps }: Props) => {
  return (
    <Popover
      label={label}
      popoverBodyProps={{ maxW: { base: '250px', md: '350px' }, ...popoverBodyProps }}
    >
      <Text
        as="span"
        color={textProps?.color ?? 'royalBlue'}
        fontWeight={textProps?.fontWeight ?? 'normal'}
        textAlign={textProps?.textAlign ?? 'left'}
        fontSize={textProps?.fontSize ?? '14px'}
        display="inline-block"
        textDecorationLine="underline"
        textDecorationStyle="dashed"
        textUnderlineOffset="3px"
        textDecorationThickness="1px"
        cursor={textProps?.cursor ?? 'help'}
      >
        {children}
      </Text>
    </Popover>
  );
};

export default TextWithTooltip;
