const makes = [
  { value: 'ACURA', label: 'Acura' },
  { value: 'ALFA ROMEO', label: 'Alfa Romeo' },
  { value: 'ASTON MARTIN', label: 'Aston Martin' },
  { value: 'AUDI', label: 'Audi' },
  { value: 'BMW', label: 'BMW' },
  { value: 'BENTLEY MOTORS', label: 'Bentley Motors' },
  { value: 'BOLLINGER MOTORS', label: 'Bollinger Motors' },
  { value: 'BUGATTI', label: 'Bugatti' },
  { value: 'BUICK', label: 'Buick' },
  { value: 'CADILLAC', label: 'Cadillac' },
  { value: 'CANOO', label: 'Canoo' },
  { value: 'CHEVROLET', label: 'Chevrolet' },
  { value: 'CHRYSLER', label: 'Chrysler' },
  { value: 'DODGE', label: 'Dodge' },
  { value: 'FERRARI', label: 'Ferrari' },
  { value: 'FIAT', label: 'Fiat' },
  { value: 'FISKER', label: 'Fisker' },
  { value: 'FORD', label: 'Ford' },
  { value: 'GMC', label: 'GMC' },
  { value: 'GENESIS', label: 'Genesis' },
  { value: 'HONDA', label: 'Honda' },
  { value: 'HUMMER', label: 'Hummer' },
  { value: 'HYUNDAI', label: 'Hyundai' },
  { value: 'INFINITI', label: 'Infiniti' },
  { value: 'JAGUAR', label: 'Jaguar' },
  { value: 'JEEP', label: 'Jeep' },
  { value: 'KARMA', label: 'Karma' },
  { value: 'KIA', label: 'Kia' },
  { value: 'LAMBORGHINI', label: 'Lamborghini' },
  { value: 'LAND ROVER', label: 'Land Rover' },
  { value: 'LEXUS', label: 'Lexus' },
  { value: 'LINCOLN', label: 'Lincoln' },
  { value: 'LORDSTOWN MOTORS', label: 'Lordstown Motors' },
  { value: 'LOTUS', label: 'Lotus' },
  { value: 'LUCID MOTORS', label: 'Lucid Motors' },
  { value: 'MASERATI', label: 'Maserati' },
  { value: 'MAYBACH', label: 'Maybach' },
  { value: 'MAZDA', label: 'Mazda' },
  { value: 'MCLAREN', label: 'McLaren' },
  { value: 'MERCEDES-AMG', label: 'Mercedes-AMG' },
  { value: 'MERCEDES-BENZ', label: 'Mercedes-Benz' },
  { value: 'MERCURY', label: 'Mercury' },
  { value: 'MINI', label: 'Mini' },
  { value: 'MITSUBISHI', label: 'Mitsubishi' },
  { value: 'NIKOLA', label: 'Nikola' },
  { value: 'NISSAN', label: 'Nissan' },
  { value: 'OLDSMOBILE', label: 'Oldsmobile' },
  { value: 'POLESTAR', label: 'Polestar' },
  { value: 'PONTIAC', label: 'Pontiac' },
  { value: 'PORSCHE', label: 'Porsche' },
  { value: 'PLYMOUTH', label: 'Plymouth' },
  { value: 'RAM', label: 'Ram' },
  { value: 'RIVIAN', label: 'Rivian' },
  { value: 'ROLLS-ROYCE', label: 'Rolls-Royce' },
  { value: 'SAAB', label: 'Saab' },
  { value: 'SATURN', label: 'Saturn' },
  { value: 'SCION', label: 'Scion' },
  { value: 'SMART', label: 'Smart' },
  { value: 'SUBARU', label: 'Subaru' },
  { value: 'SUZUKI', label: 'Suzuki' },
  { value: 'TESLA', label: 'Tesla' },
  { value: 'TOYOTA', label: 'Toyota' },
  { value: 'VOLKSWAGEN', label: 'Volkswagen' },
  { value: 'VOLVO', label: 'Volvo' },
];

export default makes;
