export type Digits = '4' | '6' | '9';

export const MaskMap: { [digit in Digits]: { mask: string; placeholder: string; label: string } } =
  {
    '4': {
      mask: 'XXX-XX-9999',
      placeholder: 'XXX-XX-____',
      label: 'Last 4 of SSN',
    },
    '6': {
      mask: 'XXX-99-9999',
      placeholder: 'XXX-__-_____',
      label: 'Last 6 of SSN',
    },
    '9': {
      mask: '999-99-9999',
      placeholder: '___-__-____',
      label: 'Social Security Number',
    },
  };

export const formatSSN = (value: string, showSSN: boolean) => {
  const SSN = value.replace(/\D/g, '');
  if (!showSSN) {
    return SSN;
  }
  return value.concat('___-__-____'.substring(value.length));
};

export const parseSSN = (value: string, digits: Digits) => {
  let SSN = value.replace(/\D/g, '');
  let prefix = '';
  if (digits === '6') {
    prefix = 'XXX-';
    if (SSN.length > 2) {
      SSN = SSN.slice(0, 2).concat('-').concat(SSN.slice(2));
    }
  } else if (digits === '4') {
    prefix = 'XXX-XX-';
  } else if (SSN.length > 5) {
    SSN = SSN.slice(0, 3).concat('-').concat(SSN.slice(3, 5)).concat('-').concat(SSN.slice(5));
  } else if (SSN.length > 3) {
    SSN = SSN.slice(0, 3).concat('-').concat(SSN.slice(3));
  }
  if ((value.length === 4 || value.length === 7) && value.endsWith('-')) {
    SSN = SSN.concat('-');
  }
  return prefix.concat(SSN);
};
