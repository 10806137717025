import { useAtomValue } from 'jotai';

import Subtitle from '../../components/Subtitle';
import Title from '../../components/Title';
import TitleContainer from '../../components/TitleContainer';
import PartnersLogos from '../../components/partnersLogos/PartnersLogos';
import { prequalRanInMktAtom } from '../LoanApp/components/SsnAndBirthdate';

const PartnersTitle = () => {
  const prequalRanInMkt = useAtomValue(prequalRanInMktAtom);

  return (
    <>
      <TitleContainer>
        <Title>{prequalRanInMkt ? 'Find Your Loan!' : 'You’ve Prequalified!'}</Title>
      </TitleContainer>
      {!prequalRanInMkt && (
        <>
          <Subtitle>
            We’re confident in matching you with one of our partnered lenders below that align with
            your financial objectives.
          </Subtitle>
          <PartnersLogos />
        </>
      )}
    </>
  );
};

export default PartnersTitle;
