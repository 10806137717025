import { Button, ButtonGroup } from '@chakra-ui/react';
import React, { useState } from 'react';

type Props = {
  onChange: (val: string) => unknown;
  firstOption: string;
  secondOption: string;
  selectedOption?: string;
};

const Switch = ({ onChange, firstOption, secondOption, selectedOption }: Props) => {
  const [selected, setSelected] = useState(selectedOption || firstOption);

  const handleChange = (val: string): void => {
    setSelected(val);
    onChange(val);
  };

  return (
    <ButtonGroup
      size="sm"
      variant="outline"
      borderWidth="1px"
      borderRadius="24px"
      borderColor="lightGray"
      bg="gray.100"
    >
      <Button
        bg={selected === firstOption ? 'royalBlue' : 'gray.100'}
        color={selected === firstOption ? 'white' : 'taupeGray'}
        _hover={{
          bg: selected === firstOption ? 'royalBlueLight' : 'gray.100',
          color: selected === firstOption ? 'white' : '#444',
        }}
        h="auto"
        borderRadius="20px"
        borderWidth="0"
        minW="85px"
        onClick={() => handleChange(firstOption)}
      >
        {firstOption}
      </Button>
      <Button
        bg={selected === secondOption ? 'royalBlue' : 'gray.100'}
        color={selected === secondOption ? 'white' : 'taupeGray'}
        _hover={{
          bg: selected === secondOption ? 'royalBlueLight' : 'gray.100',
          color: selected === secondOption ? 'white' : '#444',
        }}
        h="auto"
        borderRadius="20px"
        borderWidth="0"
        minW="85px"
        ml="0 !important"
        onClick={() => handleChange(secondOption)}
      >
        {secondOption}
      </Button>
    </ButtonGroup>
  );
};

export default Switch;
