import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Spinner,
  Text,
  keyframes,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Form, FormikContext, FormikContextType } from 'formik';
import { motion } from 'framer-motion';
import { SetStateAction } from 'jotai';
import { Dispatch, useEffect, useRef } from 'react';
import { BsCurrencyDollar } from 'react-icons/bs';

import { RudderEvent, rudderanalytics } from '../../utils/rudderstack';
import PaymentBreakdown from './PaymentBreakdown';
import MonthlyPaymentSliders from './ShoppingCartSliders';
import { EstimatorFormFields, PAYMENT_ESTIMATE_DISCLAIMER } from './useEstimatorFormik';

const animationKeyframes = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
`;

const ShoppingCartMobile = ({
  setIsOpen,
  isOpen,
  loading,
  formik,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  formik: FormikContextType<EstimatorFormFields>;
}) => {
  const btnRef = useRef<HTMLButtonElement>(null);
  const { values, handleSubmit } = formik;
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const submitForm = async () => {
    await handleSubmit();
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      rudderanalytics.track(RudderEvent.PaymentCalculatorModalOpen);
    }
  }, [isOpen]);

  if (!isMobile) {
    return null;
  }

  return (
    <>
      <IconButton
        as={motion.button}
        animation={`${animationKeyframes} 3s ease 4`}
        zIndex={999}
        aria-label="toggle-payment-estimator"
        isRound
        position="fixed"
        bottom={6}
        right={6}
        ref={btnRef}
        onClick={() => setIsOpen(true)}
        boxSize="62px"
        bgColor="leaseEndGreen"
        _hover={{ bgColor: 'leaseEndGreen' }}
        icon={<Icon as={BsCurrencyDollar} boxSize={9} color="white" />}
      />
      <Drawer
        closeOnOverlayClick
        isOpen={isOpen}
        placement="bottom"
        onClose={submitForm}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent
          style={{ maxHeight: '80vh' }}
          motionProps={{
            variants: {
              enter: {
                y: '0%',
                transition: { duration: 0.6, type: 'tween' },
              },
              exit: {
                y: '100%',
                transition: { duration: 10, type: 'tween' },
              },
            },
          }}
        >
          <DrawerCloseButton color="white" boxSize={10} fontSize="18px" />
          <DrawerBody px={0} pb={0} bg="leaseEndGreen">
            <FormikContext.Provider value={formik}>
              <Form>
                <Flex flexDir="column" alignItems="center" px={2} py={6} color="white">
                  <Text maxW="80%" textAlign="center">
                    PICTURE YOUR NEW MONTHLY PAYMENT
                  </Text>
                  <Box minH="60px" pt={4}>
                    {loading ? (
                      <Spinner color="white" />
                    ) : (
                      <Text as="span" fontSize="28px">
                        {values.estimated_payment}
                      </Text>
                    )}
                  </Box>
                  <PaymentBreakdown />
                </Flex>
                <Box px={6} pt={3} pb={8} bgColor="white" color="leaseEndGray" overflowY="auto">
                  <MonthlyPaymentSliders usedInModal />
                </Box>
              </Form>
            </FormikContext.Provider>
          </DrawerBody>
          <DrawerFooter mt={0}>
            <Flex flexDir="column" alignItems="center">
              <Button onClick={submitForm}>LET'S DO THIS</Button>
              <Text fontSize="12px" color="taupeGray" mt={2} textAlign="center">
                {PAYMENT_ESTIMATE_DISCLAIMER}
              </Text>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default ShoppingCartMobile;
