import { Text, UnorderedList, ListItem, Flex } from '@chakra-ui/react';
import Modal from '../../../components/Modal';
import PrimaryButton from '../../../components/ui/buttons/PrimaryButton';

interface PleaseBeAwareModalProps {
  isOpen: boolean;
  onClose: () => void;
  showDirectPayWarning: boolean;
  showRequireWalkInWarning: boolean;
  handleSubmit: () => void;
}

const PleaseBeAwareModal = ({
  isOpen,
  onClose,
  showDirectPayWarning,
  showRequireWalkInWarning,
  handleSubmit,
}: PleaseBeAwareModalProps) => {
  return (
    <Modal
      title="Please be aware"
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={false}
      isCentered
      closeOnOverlayClick={false}
    >
      <Flex flexDir="column">
        <Text color="leaseEndBlue" mb={6} fontSize={{ base: 'lg', md: 'xl' }}>
          At Lease End, we're all about making your life easier... except when your state or
          lienholder decides to make it difficult! You happen to live in one of these tricky places,
          so brace yourself for some hands-on action. Don't worry though, we're here to help every
          step of the way!
          <br />
          <br />
          <b>To complete your buyout, you'll need to:</b>
          <UnorderedList>
            {showDirectPayWarning && (
              <ListItem>
                Go to the dealership in person to buy out your lease. Don’t worry, we will still
                arrange your loan and get all your paperwork ready. You will just have to physically
                walk it in.
              </ListItem>
            )}
            {showRequireWalkInWarning && (
              <ListItem>
                Head to the DMV to finalize your title and registration. We will provide you with
                all the proper documentation, you will just need to submit it in person.
              </ListItem>
            )}
          </UnorderedList>
        </Text>
        <PrimaryButton w="fit-content" alignSelf="center" onClick={() => handleSubmit()}>
          NEXT
        </PrimaryButton>
      </Flex>
    </Modal>
  );
};

export default PleaseBeAwareModal;
