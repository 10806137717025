import { Flex, FlexProps, Image, Text } from '@chakra-ui/react';
import React from 'react';

import { Car, Maybe } from '../gql/generated/graphql';
import { carIconFromCarData } from '../utils/images/carImage';
import Loader from './Loader';

interface Props extends FlexProps {
  car: Maybe<Car>;
  title?: string;
}

const CarDetails: React.FC<Props> = ({ car, title, ...props }) => {
  return (
    <Flex direction="column" textAlign="center" alignItems="center" p="15px" {...props}>
      {!car ? (
        <Loader />
      ) : (
        <>
          {title && (
            <Text fontSize="14px" fontWeight="bold">
              {title}
            </Text>
          )}
          <Image src={carIconFromCarData(car)} w={{ base: '150px', lg: '125px' }} my="10px" />
          <Text fontSize="18px" fontWeight="semibold">
            {car?.year}
          </Text>
          <Text fontSize="18px">
            <b>{car?.make}</b> {car?.model}
          </Text>
          <Text lineHeight="14px" color="taupeGray">
            VIN: {car?.vin}
          </Text>
        </>
      )}
    </Flex>
  );
};

export default CarDetails;
