import { Container, ContainerProps } from '@chakra-ui/react';

const LEAccordionContainer = ({ children }: ContainerProps) => {
  return (
    <Container
      maxW="container.sm"
      position="relative"
      mt={{ '2xl': 0 }}
      px={{ base: 2, md: 4 }}
      pb={40}
    >
      {children}
    </Container>
  );
};

export default LEAccordionContainer;
