import { Box, Button, Container, Flex, Icon, Link, Text } from '@chakra-ui/react';
import { Form, Formik, FormikValues } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { MdMarkEmailRead } from 'react-icons/md';
import { Link as RRLink, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import Loader from '../../components/Loader';
import SimpleFooter from '../../components/SimpleFooter';
import Input from '../../components/formComponents/Input';
import { ACCOUNT_LOGIN, CREATE_ACCOUNT } from '../../constants/urls';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { RedirectType, startPasswordlessEmailLink } from '../../services/auth0';
import { LocalStorageKeys } from '../../services/localStorage';
import { pluralize } from '../../utils/helpers';
import { emailValidationRequired, validateEmail } from '../../utils/validation/email';
import Subtitle from './components/Subtitle';
import Title from './components/Title';

const STEP_EMAIL = 'email';
const STEP_SENT = 'sent';
const MAX_ONE_CLICK_LOGIN_ATTEMPTS = 3;

const OneClickLogin = () => {
  const hasRun = useRef(false);
  const { state } = useLocation<{ email: string } | undefined>();

  const [oneClickLoginEmail, setOneClickLoginEmail] = useLocalStorage(
    LocalStorageKeys.ONE_CLICK_LOGIN_EMAIL,
    { email: '', expiresAt: 0, timesSent: 0 },
  );

  const [step, setStep] = useState<keyof typeof STEPS>(STEP_EMAIL);
  const [error, setError] = useState<string>('');
  const [emailSentTo, setEmailSentTo] = useState<string>('');

  const validateMaxAttempts = (email: string) => {
    const ONE_HOUR = new Date().getTime() + 1000 * 60 * 60;

    if (email !== oneClickLoginEmail.email) {
      setOneClickLoginEmail({
        email,
        expiresAt: ONE_HOUR,
        timesSent: 1,
      });
      setError('');

      return true;
    }

    const remainingMinutes = Math.round(
      (oneClickLoginEmail.expiresAt - new Date().getTime()) / 1000 / 60,
    );

    if (remainingMinutes > 0 && oneClickLoginEmail.timesSent >= MAX_ONE_CLICK_LOGIN_ATTEMPTS) {
      setError(
        `Max one-time login attempts reached. Please try again in ${remainingMinutes} ${pluralize(
          'minute',
          remainingMinutes,
        )}.`,
      );

      return false;
    }

    if (remainingMinutes <= 0 && oneClickLoginEmail.timesSent >= MAX_ONE_CLICK_LOGIN_ATTEMPTS) {
      setOneClickLoginEmail({
        email,
        expiresAt: ONE_HOUR,
        timesSent: 1,
      });
      setError('');
    } else {
      setOneClickLoginEmail({
        email,
        expiresAt: ONE_HOUR,
        timesSent: oneClickLoginEmail.timesSent + 1,
      });
    }

    return true;
  };

  const handleSubmit = (values: FormikValues) => {
    const valid = validateMaxAttempts(values.email);

    if (!valid) {
      return;
    }

    startPasswordlessEmailLink({ redirectType: RedirectType.ACCOUNT_LOGIN, email: values.email });
    setEmailSentTo(values.email);

    setStep(STEP_SENT);
  };

  useEffect(() => {
    const passedEmail = state?.email;
    if (passedEmail && validateEmail(passedEmail) && !hasRun.current) {
      handleSubmit({ email: state.email });
      hasRun.current = true;
    }
  }, [state]);

  const STEPS = {
    [STEP_EMAIL]: {
      title: 'One-Time Link',
      subtitle: 'We’ll send you a link to quickly log you in',
      content: (
        <Formik
          enableReinitialize
          initialValues={{ email: '' }}
          validationSchema={Yup.object({
            email: emailValidationRequired,
          })}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {({ errors, isSubmitting }) =>
            isSubmitting ? (
              <Loader />
            ) : (
              <Form>
                <Flex flexDirection="column" bg="white" p={8} rounded="lg" alignItems="center">
                  <Input label="Email" name="email" placeholder="Email" autoFocus />
                  {error && !errors.email && (
                    <Text mt={1} mx={1.5} fontSize="sm" color="leaseEndRed">
                      {error}
                    </Text>
                  )}
                  <Button w="100%" mt={8} mb={4} type="submit">
                    EMAIL MY LINK
                  </Button>
                  <Box textAlign="center">
                    <Link as={RRLink} to={ACCOUNT_LOGIN}>
                      Back to login
                    </Link>
                  </Box>
                </Flex>
                <SimpleFooter />
              </Form>
            )
          }
        </Formik>
      ),
    },
    [STEP_SENT]: {
      title: 'Check your email',
      subtitle: '',
      content: (
        <>
          <Flex flexDirection="column" bg="white" p={8} rounded="lg" alignItems="center">
            <Text textAlign="center" color="leaseEndBlue" fontSize="lg" mb={4}>
              If the email {emailSentTo} is associated with an account, you will receive a login
              link via email. This link will expire in 10 minutes
            </Text>
            <Icon as={MdMarkEmailRead} w={16} h={16} mb={4} color="taupeGray" />
            <Text color="taupeGray" fontSize="sm" textAlign="center" mb={4}>
              If you don't see the email in your inbox, check your spam folder. Don't have an
              account with us yet? Click{' '}
              <Link as={RRLink} to={CREATE_ACCOUNT}>
                here
              </Link>{' '}
              to create one now!
            </Text>
            <Button w="100%" mb={4} type="submit" as={RRLink} to={ACCOUNT_LOGIN}>
              OK
            </Button>
            <Link onClick={() => setStep(STEP_EMAIL)}>Resend email</Link>
          </Flex>
          <SimpleFooter />
        </>
      ),
    },
  };

  return (
    <Container pt={10}>
      <Title>{STEPS[step].title}</Title>
      {STEPS[step].subtitle && <Subtitle>{STEPS[step].subtitle}</Subtitle>}

      <Box maxW="400px" mx="auto">
        {STEPS[step].content}
      </Box>
    </Container>
  );
};

export default OneClickLogin;
