import { useLazyQuery } from '@apollo/client';
import { Box, Center, Collapse, Divider, Text, useDisclosure } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';

import ButtonedRadioGroup from '../../../components/ButtonedRadioGroup';
import { useLEAccordionItemContext } from '../../../components/LEAccordion/LEAccordionItem/useLEAccordionItem';
import MonthsAndYears from '../../../components/MonthsAndYears';
import TextWithTooltip from '../../../components/TextWithTooltip';
import { AddressInput, Label } from '../../../components/formComponents';
import NumberInput from '../../../components/formComponents/NumberInput';
import PrimaryButton from '../../../components/ui/buttons/PrimaryButton';
import { OWNERSHIP_TYPE, PARTIAL_STATES } from '../../../constants/addresses';
import { OtherLienholderSlug } from '../../../constants/lienholders';
import { statesArray } from '../../../constants/states';
import { ttJurisdictionDefaultConditions } from '../../../constants/taterTitle';
import {
  StateAbbreviation,
  TtProductType,
  useGetTtJurisdictionLazyQuery,
  useTemporaryInfoUpsertMutation,
} from '../../../gql/generated/graphql';
import { lienholderQuery } from '../../../gql/prs/lienholderGql';
import { useTempInfo } from '../../../hooks';
import { getAddressValue } from '../../../utils/address';
import { rudderanalytics } from '../../../utils/rudderstack';
import { ResidenceFormValues, residenceInitialValues, residenceValidationSchema } from '../utils';
import PleaseBeAwareModal from './PleaseBeAwareModal';

const Residence = ({ refetchEstimator }: { refetchEstimator: () => void }) => {
  const { info } = useTempInfo();
  const tempInfoData = info?.data;

  const [upsertTemporaryInfo] = useTemporaryInfoUpsertMutation();
  const [ttJurisdictionQuery] = useGetTtJurisdictionLazyQuery();
  const [getLienholderInfo] = useLazyQuery(lienholderQuery);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { updateState, index, setOpenAccordionIndex } = useLEAccordionItemContext();

  const [showDirectPayWarning, setShowDirectPayWarning] = useState(false);
  const [showRequireWalkInWarning, setShowRequireWalkInWarning] = useState(false);
  const [showPreviousResidence, setShowPreviousResidence] = useState<boolean>(false);

  useEffect(() => {
    if (tempInfoData?.primary_residence_years) {
      setShowPreviousResidence(tempInfoData.primary_residence_years < 2);
    }
  }, [tempInfoData]);

  const checkWalkIn = async ({
    address_line,
    address_line_2,
    city,
    state,
    zip,
    moved_states,
  }: Partial<ResidenceFormValues>) => {
    const address = getAddressValue({
      address_line,
      address_line_2,
      city,
      state,
      zip: zip?.toString(),
    });
    const lienholderSlug = info?.data?.lienholder_slug;

    let directPay = false;
    if (lienholderSlug !== OtherLienholderSlug) {
      const response = await getLienholderInfo({
        fetchPolicy: 'network-only',
        variables: {
          state,
          slug: lienholderSlug,
        },
      });
      directPay = response?.data?.lienholder?.requirements_to_payoff_lease?.direct_pay?.value;
    }

    const jurisdiction = await ttJurisdictionQuery({
      variables: {
        state: state as StateAbbreviation,
        types: [TtProductType.Registration, TtProductType.Title],
        conditions: {
          ...ttJurisdictionDefaultConditions(state as StateAbbreviation),
          lienholder: lienholderSlug,
          hasCobuyer: false,
          driversLicenseAddress: address,
          currentAddress: address,
          vehicleAge: new Date().getFullYear() - parseInt(info?.data?.year || '0', 10),
          transferringPlates: false,
          driversLicenseState: state,
          movedStateDuringLease: moved_states === true.toString(),
          jurisdictionState: state,
        },
      },
    });

    const requireWalkIn = jurisdiction.data?.jurisdiction?.requireWalkIn;

    setShowDirectPayWarning(!!directPay);
    setShowRequireWalkInWarning(!!requireWalkIn);

    const shouldShowModal = directPay || requireWalkIn;

    if (shouldShowModal && !isOpen) {
      onOpen();
      return true;
    }
    return false;
  };

  const onSubmitForm = async (values: ResidenceFormValues) => {
    if (await checkWalkIn(values)) {
      return;
    }

    onClose();

    updateState({ status: 'loading' });

    const addressData = {
      address_line: values.address_line,
      address_line_2: values.address_line_2,
      city: values.city.trim(),
      state: values.state,
      county: values.county.trim(),
      zip: values.zip.toString(),
      moved_states: values.moved_states === 'true',
    };

    const residenceData = {
      primary_residence_type: values.primary_residence_type,
      primary_residence_years: values.primary_residence_years,
      primary_residence_months: values.primary_residence_months ?? 0,
      primary_residence_monthly_payment:
        values.primary_residence_type !== OWNERSHIP_TYPE.OWN_HOME_OUTRIGHT
          ? values.primary_residence_monthly_payment ?? undefined
          : undefined,
      secondary_residence_years: values.secondary_residence_years,
      secondary_residence_months: values.secondary_residence_months ?? 0,
      previous_address_line: values.previous_address_line,
      previous_address_line_2: values.previous_address_line_2,
      previous_city: values.previous_city?.trim(),
      previous_state: values.previous_state,
      previous_zip: values.previous_zip ? values.previous_zip.toString() : undefined,
      previous_county: values.previous_county?.trim(),
    };

    const data = {
      ...addressData,
      ...residenceData,
    };

    await upsertTemporaryInfo({
      variables: {
        info: {
          id: info?.id,
          data,
        },
      },
    });

    refetchEstimator();

    rudderanalytics.identify({ address: addressData });

    updateState({ status: 'complete' });
    setOpenAccordionIndex(index + 1);
  };

  if (!tempInfoData) {
    return null;
  }

  return (
    <Box>
      <Center mb={{ base: 4, md: 0 }}>
        <TextWithTooltip label="Verifying your residential information gives lenders insight into your regular expenses and, if applicable, your payment history.">
          Why do we need this?
        </TextWithTooltip>
      </Center>
      <Formik
        enableReinitialize
        initialValues={residenceInitialValues(tempInfoData)}
        validationSchema={residenceValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSubmitForm}
      >
        {({ isSubmitting, errors, values, handleSubmit, setFieldValue }) => {
          const hasErrors = Object.values(errors).some((value) => value !== '');
          const residenceType = values?.primary_residence_type;

          return (
            <>
              <Form>
                <Label>ADDRESS</Label>
                <AddressInput dropLabel />
                {PARTIAL_STATES.includes(values.state) && (
                  <>
                    <Label mt={5}>
                      DID YOU START YOUR LEASE IN{' '}
                      {statesArray
                        .find((state) => state.value === values.state)
                        ?.name.toUpperCase()}
                      ?
                    </Label>
                    <>
                      <ButtonedRadioGroup
                        value={values.moved_states}
                        columns={2}
                        onChange={(value) => setFieldValue('moved_states', value)}
                        options={[
                          { label: 'YES', value: 'false' },
                          { label: 'NO', value: 'true' },
                        ]}
                        name="moved_states"
                        defaultValue="false"
                      />
                      {errors.moved_states && <Box color="leaseEndRed">{errors.moved_states}</Box>}
                    </>
                  </>
                )}
                <Divider mt={8} mb={8} />
                <Label mt={5}>LIVING SITUATION</Label>
                <ButtonedRadioGroup
                  columns={{ base: 2, md: 4 }}
                  collapseOnMobile={false}
                  onChange={(value) => setFieldValue('primary_residence_type', value)}
                  options={[
                    { label: 'PAYING MORTGAGE', value: OWNERSHIP_TYPE.CURRENTLY_HAVE_A_MORTGAGE },
                    { label: 'RENTING', value: OWNERSHIP_TYPE['RENTING/_LEASING'] },
                    { label: 'OWN OUTRIGHT', value: OWNERSHIP_TYPE.OWN_HOME_OUTRIGHT },
                    { label: 'LIVE W/FAMILY', value: OWNERSHIP_TYPE.LIVING_WITH_FAMILY },
                  ]}
                  value={values.primary_residence_type}
                  name="primary_residence_type"
                  defaultValue={OWNERSHIP_TYPE.CURRENTLY_HAVE_A_MORTGAGE}
                />
                <Box color="leaseEndRed">{errors.primary_residence_type}</Box>
                <Box
                  mt={8}
                  hidden={!residenceType || residenceType === OWNERSHIP_TYPE.OWN_HOME_OUTRIGHT}
                >
                  <Label>MONTHLY PAYMENT</Label>
                  <NumberInput
                    name="primary_residence_monthly_payment"
                    type="decimal"
                    isMoney
                    maxW="200px"
                    maxLength={8}
                    _input={{ inputMode: 'numeric' }}
                    _container={{ alignItems: 'flex-start' }}
                  />
                </Box>
                <Divider mt={8} mb={8} />
                <Label mb={3}>TIME AT CURRENT ADDRESS?</Label>
                <MonthsAndYears
                  setShow={setShowPreviousResidence}
                  yearsName="primary_residence_years"
                  monthsName="primary_residence_months"
                  values={values}
                  mb={showPreviousResidence ? 4 : 0}
                />
                <Collapse in={showPreviousResidence} animateOpacity>
                  <Box w="100%" mb={2} p="8px" borderRadius="xl" bgColor="beige">
                    You've lived here for <b>less than two years</b>. Where did you live before?{' '}
                    <TextWithTooltip
                      textProps={{
                        fontSize: { base: '10px', md: '12px' },
                        cursor: showPreviousResidence ? 'help' : 'default',
                      }}
                      label="To assess risk (and your payment history), lenders prefer looking at more than two years’ worth of data."
                    >
                      (Why does this matter?)
                    </TextWithTooltip>
                  </Box>
                  <Box overflow="hidden" mb={8}>
                    <Label>PREVIOUS ADDRESS</Label>
                    <AddressInput prefix="previous" dropLabel />
                    <Label mt={6} mb={3}>
                      TIME AT PREVIOUS ADDRESS?
                    </Label>
                    <MonthsAndYears
                      yearsName="secondary_residence_years"
                      monthsName="secondary_residence_months"
                    />
                  </Box>
                </Collapse>
                <Center>
                  <PrimaryButton
                    mt="30px"
                    mb={{
                      base: '20px',
                      lg: '0',
                    }}
                    loading={isSubmitting}
                    type="submit"
                  />
                </Center>
                {hasErrors && (
                  <Text color="leaseEndRed" textAlign="center" mt={{ base: 0, lg: 4 }}>
                    Review fields above for errors.
                  </Text>
                )}
              </Form>
              <PleaseBeAwareModal
                isOpen={isOpen}
                onClose={onClose}
                showDirectPayWarning={showDirectPayWarning}
                showRequireWalkInWarning={showRequireWalkInWarning}
                handleSubmit={handleSubmit}
              />
            </>
          );
        }}
      </Formik>
    </Box>
  );
};

export default Residence;
