import { string } from 'yup';

import { validZip } from '../../gql/addressGql';
import { apolloClient } from '../../providers/AuthorizedApolloProvider';
import { RudderEvent, rudderanalytics } from '../rudderstack';

export const zipRegExp = /^\d{5}$/;

export const zipValidation = string()
  .nullable()
  .required('Zipcode is required')
  .min(5, 'Must be 5 digits')
  .max(5, 'Must be 5 digits')
  .test('valid-zip', 'Please enter a valid zip code', async (value) => {
    if (!value) return false;
    if (!/^\d{5}$/.test(value)) return false;

    const { data } = await apolloClient.query({
      query: validZip,
      variables: { zipCode: value },
    });

    const zipValid = data.validZip;

    if (!zipValid) {
      rudderanalytics.track(RudderEvent.Error, {
        error_message: 'Invalid zipcode',
        zipcode: value,
      });
    }

    return zipValid;
  });
