import { Flex, Icon, Image, Link } from '@chakra-ui/react';
import { FaPhone } from 'react-icons/fa';

import { Logo } from '../assets/Images';
import { MKT_HOME } from '../constants/urls';
import useSearchPhoneNumbers from '../hooks/useSearchPhoneNumbers';

const MobileNavFlow = () => {
  const phoneNumber = useSearchPhoneNumbers();

  return (
    <Flex
      position="relative"
      justifyContent="center"
      alignItems="center"
      m="0 -15px 0 -2em"
      p={3}
      bgColor="white"
      zIndex={1000}
    >
      <Link href={MKT_HOME}>
        <Image w="135px" src={Logo} alt="Lease End Logo" />
      </Link>
      <Link
        position="absolute"
        top="50%"
        right="20px"
        transform="translateX(-50%) translateY(-50%)"
        href={`tel:+1-${phoneNumber}`}
        textDecoration="none"
      >
        <Icon color="leaseEndBlue" w={8} h={8} name="phone" as={FaPhone} />
      </Link>
    </Flex>
  );
};

export default MobileNavFlow;
