import { Box, Flex, Image, Link, useBreakpointValue } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { Logo } from '../../../assets/Images';
import { DASHBOARD, MKT_HOME } from '../../../constants/urls';
import { Car, Maybe } from '../../../gql/generated/graphql';
import AuthMenu from '../../auth/components/AuthMenu';
import DealSwitcher from './DealSwitcher';

interface Props {
  inFlow: boolean;
  car: Maybe<Car>;
}

const DashboardHeader = ({ inFlow, car }: Props) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { pathname } = useLocation();
  return (
    <Flex flexDirection="column">
      <Box bg="white" px="20px">
        <Flex alignItems="center" justifyContent="space-between" mx="5px">
          <Link py="11px" href={MKT_HOME}>
            <Image w="130px" h="auto" src={Logo} alt="Lease End Logo" />
          </Link>
          {!isMobile && <DealSwitcher inFlow={inFlow} car={car} />}
          <Flex w="150px" justifyContent="flex-end">
            <AuthMenu color="taupeGray" />
          </Flex>
        </Flex>
        {isMobile && (
          <Flex justifyContent="center">
            <DealSwitcher inFlow={inFlow} car={car} />
          </Flex>
        )}
      </Box>
      {pathname !== DASHBOARD && (
        <Link mt={1} ml={4} href={DASHBOARD} w="100%" h="100%">
          {'< BACK TO DASHBOARD'}
        </Link>
      )}
    </Flex>
  );
};

export default DashboardHeader;
