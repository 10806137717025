import { Button, Center, Checkbox, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import { useState } from 'react';

import { isProd } from '../../config';
import {
  DealReadyType,
  EndSalesFlowReasonEnum,
  ImportType,
  Maybe,
  useMockDealProcessingMutation,
} from '../../gql/generated/graphql';

interface Props {
  dealId: string | undefined;
  dealReadyState: Maybe<DealReadyType>;
  handleFailure: (
    reason: EndSalesFlowReasonEnum,
    importType: ImportType,
    dealReadyType?: DealReadyType,
  ) => void;
  refetchDealReady: () => void;
  setMaxWaitOver: () => void;
  stopPolling: () => void;
}

const DealProcessingDevToolbar = ({
  dealId,
  dealReadyState,
  handleFailure,
  refetchDealReady,
  setMaxWaitOver,
  stopPolling,
}: Props) => {
  const [mockConditioned, setMockConditioned] = useState<boolean>(false);
  const [mockGap, setMockGap] = useState<boolean>(true);
  const [mockVsc, setMockVsc] = useState<boolean>(true);

  const [mockDealProcessing] = useMockDealProcessingMutation();

  const handleMockedFailure = async () => {
    handleFailure(EndSalesFlowReasonEnum.FailedDealProcessing, ImportType.AutoStructureFailure);
    setMaxWaitOver();
  };

  const handleMockedSuccess = async () => {
    stopPolling();
    if (dealId) {
      await mockDealProcessing({
        variables: {
          deal_id: dealId,
          is_conditioned: mockConditioned,
          has_gap: mockGap,
          has_vsc: mockVsc,
        },
      });
    }
    await refetchDealReady();
    setMaxWaitOver();
  };

  const handleImmediateImport = () => {
    handleFailure(
      EndSalesFlowReasonEnum.ImmediateImport,
      ImportType.AutoStructureSuccessImmediateImport,
      DealReadyType.ImmediateImport,
    );
    setMaxWaitOver();
  };

  const imported = dealReadyState === DealReadyType.ImmediateImport;

  if (isProd) return null;

  return (
    <Center>
      <VStack
        align="left"
        borderWidth={2}
        borderColor="grayBlueish"
        borderRadius="lg"
        mt="50px"
        p="10px"
      >
        <Heading textAlign="center">Dev Tools</Heading>
        <Text>DealReadyState: {dealReadyState}</Text>
        <Flex justifyContent="center">
          <Button
            colorScheme="blackAlpha"
            size="sm"
            variant="solid"
            onClick={handleMockedFailure}
            isDisabled={imported}
          >
            Fail
          </Button>
          <Flex direction="column">
            <Button
              colorScheme="blackAlpha"
              size="sm"
              variant="solid"
              mx={2}
              onClick={handleMockedSuccess}
              isDisabled={imported}
            >
              Success
            </Button>
            <Checkbox
              isChecked={mockConditioned}
              onChange={() => setMockConditioned((prev) => !prev)}
            >
              Conditioned
            </Checkbox>
            <Text>Unchecking these will delete them from products:</Text>
            <Checkbox isChecked={mockGap} onChange={() => setMockGap((prev) => !prev)}>
              Gap (if ltv &lt;= .07 gap won't be offered)
            </Checkbox>
            <Checkbox isChecked={mockVsc} onChange={() => setMockVsc((prev) => !prev)}>
              Vsc
            </Checkbox>
          </Flex>
          <Button
            colorScheme="blackAlpha"
            size="sm"
            variant="solid"
            onClick={handleImmediateImport}
            isDisabled={imported}
          >
            Immediate Import
          </Button>
        </Flex>
      </VStack>
    </Center>
  );
};

export default DealProcessingDevToolbar;
