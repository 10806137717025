import { Text, VStack } from '@chakra-ui/react';

const EndingInZeroMessage = () => (
  <VStack>
    <Text color="leaseEndBlue" fontWeight="bold" fontSize="16px">
      Are you sure?
    </Text>
    <Text maxW="container.sm" textAlign="center">
      It’s pretty rare that odometers are even numbers - but sometimes they are! The more accurate
      mileage we can get the faster your Lease End process will be!
    </Text>
  </VStack>
);

export default EndingInZeroMessage;
