import { Box, Button, Divider, Flex, SkeletonText, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import {
  ConsentAgreementTypeEnum,
  useCurrentConsentAgreementTextQuery,
} from '../../gql/generated/graphql';
import { formatMoney } from '../../utils/helpers';
import { DOWN_PAYMENTS_STEPS, PlaidData } from '../../utils/payments';
import { PaymentFormValues } from './PaymentForm';

interface BankAddedProps {
  setStep: (step: string) => void;
  plaidData: PlaidData;
}

const MIN_WAIT_MS = 4000;

const BankAdded = ({ setStep, plaidData }: BankAddedProps) => {
  const account = plaidData.accounts?.[0];

  const { values, isSubmitting } = useFormikContext<PaymentFormValues>();

  const [processingPayment, setProcessingPayment] = useState(false);

  useEffect(() => {
    if (isSubmitting) {
      setProcessingPayment(true);
      const timer = setTimeout(() => setProcessingPayment(false), MIN_WAIT_MS);

      return () => clearTimeout(timer);
    }

    setProcessingPayment(false);
    return undefined;
  }, [isSubmitting]);

  const { loading: consentLoading, data } = useCurrentConsentAgreementTextQuery({
    variables: { agreementType: ConsentAgreementTypeEnum.AchPayment },
  });

  const consentText = data?.currentConsentAgreementText?.text;

  return (
    <Flex flexDir="column" w="330px" maxW="100%">
      {processingPayment ? (
        <Box
          bgColor="bananaMania"
          borderWidth="1px"
          borderColor="gainsboro"
          borderRadius="md"
          px="15px"
          h="50px"
          alignContent="center"
        >
          Processing payment... don't refresh the page!
        </Box>
      ) : (
        <>
          <Text fontSize="16px">
            Take a moment to review the payment information before processing.
          </Text>
          <Divider borderColor="grayDarkBackground" my={10} />
          <Text fontSize="22px" fontWeight="bold" mb={2}>
            {plaidData.institution?.name}
          </Text>
          {account && (
            <>
              <Text fontSize="16px" mb={1}>
                Account: ******{account.id.slice(-4).toUpperCase()}
              </Text>
              <Text fontSize="16px" mb={3} textTransform="capitalize">
                {account.subtype}
              </Text>
            </>
          )}
          <Button
            alignSelf="flex-start"
            variant="link"
            onClick={() => setStep(DOWN_PAYMENTS_STEPS.PAYMENT_INFO)}
          >
            Change information
          </Button>
          <Divider borderColor="grayDarkBackground" mt={10} mb={6} />
          <Text fontSize="18px" fontWeight="bold" mb={2}>
            Amount to be processed: {formatMoney(values.amount)}
          </Text>
        </>
      )}
      <Divider borderColor="grayDarkBackground" mt={6} mb={10} />
      <Button
        mx="auto"
        type="submit"
        isDisabled={processingPayment || consentLoading || !consentText}
        isLoading={processingPayment}
        mb={processingPayment ? undefined : 4}
        loadingText="PROCESSING..."
      >
        PROCESS PAYMENT
      </Button>
      {!processingPayment && (
        <Box>
          <SkeletonText
            isLoaded={!consentLoading || !consentText}
            mt={2}
            noOfLines={{ base: 6, md: 4 }}
          />
          <Text color="gray" fontSize="12px" textAlign="center">
            {consentText}
          </Text>
        </Box>
      )}
    </Flex>
  );
};

export default BankAdded;
