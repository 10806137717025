const INVALID_LICENSE_PLATE_MSG =
  'Unable to decode the license plate. If the issue persists, try entering your VIN.';

interface ValidateLicenseNumberOptions {
  licenseNumber?: string;
}
const validateLicenseNumber = ({ licenseNumber }: ValidateLicenseNumberOptions) => {
  if (!licenseNumber) {
    return false;
  }

  const strippedLicensePlate = licenseNumber.replace(/[\s-]*/g, '');
  const regEx = /^[a-z0-9\s]{4,8}$/;
  if (!strippedLicensePlate.toLowerCase().match(regEx)) {
    return false;
  }

  return true;
};

const INVALID_VIN_MSG = 'Invalid VIN or vehicle not found. Please verify your entry and try again';

export { INVALID_LICENSE_PLATE_MSG, INVALID_VIN_MSG };
export default validateLicenseNumber;
