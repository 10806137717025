import * as Yup from 'yup';

import { OWNERSHIP_TYPE } from '../../constants/addresses';
import {
  EmploymentStatusType,
  MaritalStatusEnum,
  RelationToBuyerEnum,
  TemporaryData,
} from '../../gql/generated/graphql';
import { ssnFullValidation } from '../../utils/validation/SSNumber';
import {
  cobuyerEmploymentValidation,
  cobuyerPersonalInfoValidation,
  cobuyerPreviousAddressValidation,
  cobuyerPreviousEmploymentValidation,
  cobuyerPreviousResidenceValidation,
  cobuyerPrimaryAddressValidation,
  cobuyerPrimaryResidenceValidation,
} from '../../utils/validation/cobuyerInformation';
import { dobValidation } from '../../utils/validation/dob';
import { maritalStatusValidation } from '../../utils/validation/maritalStatuses';
import { PayFrequency } from '../LoanApp/utils';

export interface CobuyerPersonalInfoValues {
  cobuyer_first_name: string;
  cobuyer_last_name: string;
  cobuyer_phone_number: string;
  cobuyer_email: string;
  cobuyer_relation_to_buyer: RelationToBuyerEnum;
}

export const cobuyerPersonalInfoInitialValues = (tempInfoData?: TemporaryData) => ({
  cobuyer_first_name: tempInfoData?.cobuyer_first_name ?? '',
  cobuyer_last_name: tempInfoData?.cobuyer_last_name ?? '',
  cobuyer_phone_number: tempInfoData?.cobuyer_phone_number ?? '',
  cobuyer_email: tempInfoData?.cobuyer_email ?? '',
  cobuyer_relation_to_buyer: tempInfoData?.cobuyer_relation_to_buyer ?? RelationToBuyerEnum.Spouse,
});

export const cobuyerPersonalInfoValidationSchema = Yup.object({
  ...cobuyerPersonalInfoValidation,
  cobuyer_relation_to_buyer: Yup.string().required('Please select a relation to Buyer'),
});

export interface CobuyerResidenceValues {
  cobuyer_address_line: string;
  cobuyer_address_line_2: string;
  cobuyer_city: string;
  cobuyer_state: string;
  cobuyer_zip: string | number;
  cobuyer_county: string;

  cobuyer_primary_residence_type: string | undefined | null;
  cobuyer_primary_residence_years: number | undefined | null;
  cobuyer_primary_residence_months: number | undefined | null;
  cobuyer_primary_residence_monthly_payment: number | undefined | null;
  cobuyer_secondary_residence_years: number | undefined | null;
  cobuyer_secondary_residence_months: number | undefined | null;
  cobuyer_previous_address_line: string | undefined | null;
  cobuyer_previous_address_line_2: string | undefined | null;
  cobuyer_previous_city: string | undefined | null;
  cobuyer_previous_state: string | undefined | null;
  cobuyer_previous_zip: string | undefined | null;
  cobuyer_previous_county: string | undefined | null;
}

export const cobuyerResidenceInitialValues = (tempInfoData?: TemporaryData) => ({
  cobuyer_address_line: tempInfoData?.cobuyer_address_line ?? '',
  cobuyer_address_line_2: tempInfoData?.cobuyer_address_line_2 ?? '',
  cobuyer_city: tempInfoData?.cobuyer_city ?? '',
  cobuyer_state: tempInfoData?.cobuyer_state ?? '',
  cobuyer_zip: tempInfoData?.cobuyer_zip ?? '',
  cobuyer_county: tempInfoData?.cobuyer_county ?? '',

  cobuyer_primary_residence_type:
    tempInfoData?.cobuyer_primary_residence_type ?? OWNERSHIP_TYPE.CURRENTLY_HAVE_A_MORTGAGE,
  cobuyer_primary_residence_years: tempInfoData?.cobuyer_primary_residence_years,
  cobuyer_primary_residence_months: tempInfoData?.cobuyer_primary_residence_months ?? undefined,
  cobuyer_primary_residence_monthly_payment:
    tempInfoData?.cobuyer_primary_residence_type !== OWNERSHIP_TYPE.OWN_HOME_OUTRIGHT
      ? tempInfoData?.cobuyer_primary_residence_monthly_payment
      : undefined,
  cobuyer_secondary_residence_years: tempInfoData?.cobuyer_secondary_residence_years,
  cobuyer_secondary_residence_months: tempInfoData?.cobuyer_secondary_residence_months ?? undefined,
  cobuyer_previous_address_line: tempInfoData?.cobuyer_previous_address_line,
  cobuyer_previous_address_line_2: tempInfoData?.cobuyer_previous_address_line_2,
  cobuyer_previous_city: tempInfoData?.cobuyer_previous_city,
  cobuyer_previous_state: tempInfoData?.cobuyer_previous_state,
  cobuyer_previous_zip: tempInfoData?.cobuyer_previous_zip,
  cobuyer_previous_county: tempInfoData?.cobuyer_previous_county,
});

export const cobuyerResidenceValidationSchema = (liveTogether: boolean) => {
  if (liveTogether) {
    return undefined;
  }

  return Yup.object({
    ...cobuyerPrimaryAddressValidation,
    ...cobuyerPrimaryResidenceValidation,
    ...cobuyerPreviousAddressValidation,
    ...cobuyerPreviousResidenceValidation,
  });
};

export interface CobuyerEmploymentFormValues {
  cobuyer_employment_status: string;
  cobuyer_job_title: string;
  cobuyer_employer_name: string;
  cobuyer_employer_phone_number: string;
  cobuyer_years_at_job: number | undefined | null;
  cobuyer_months_at_job: number | undefined | null;
  cobuyer_salary: number | undefined | null;
  cobuyer_previous_employer_name: string;
  cobuyer_previous_employer_phone_number: string;
  cobuyer_previous_job_title: string;
  cobuyer_previous_years_at_job: number | undefined | null;
  cobuyer_previous_months_at_job: number | undefined | null;
  cobuyer_pay_frequency: PayFrequency;
}

export const cobuyerEmploymentInitialValues = (tempInfoData: TemporaryData) => ({
  cobuyer_employment_status:
    tempInfoData.cobuyer_employment_status ?? EmploymentStatusType.Employed,
  cobuyer_employer_name: tempInfoData.cobuyer_employer_name ?? '',
  cobuyer_employer_phone_number: tempInfoData.cobuyer_employer_phone_number ?? '',
  cobuyer_job_title: tempInfoData.cobuyer_job_title ?? '',
  cobuyer_years_at_job: tempInfoData.cobuyer_years_at_job ?? undefined,
  cobuyer_months_at_job: tempInfoData.cobuyer_months_at_job ?? undefined,
  cobuyer_salary: tempInfoData.cobuyer_salary ?? undefined,
  cobuyer_pay_frequency: PayFrequency.Annually,
  cobuyer_previous_employer_name: tempInfoData.cobuyer_previous_employer_name ?? '',
  cobuyer_previous_employer_phone_number: tempInfoData.cobuyer_previous_employer_phone_number ?? '',
  cobuyer_previous_job_title: tempInfoData.cobuyer_previous_job_title ?? '',
  cobuyer_previous_years_at_job: tempInfoData.cobuyer_previous_years_at_job ?? undefined,
  cobuyer_previous_months_at_job: tempInfoData.cobuyer_previous_months_at_job ?? undefined,
});

export const cobuyerEmploymentValidationSchema = Yup.object({
  ...cobuyerEmploymentValidation,
  ...cobuyerPreviousEmploymentValidation,
});

export interface CobuyerSsnAndBirthdayValues {
  cobuyer_dob: Date | undefined;
  cobuyer_ssn: string;
  cobuyer_marital_status: MaritalStatusEnum | undefined;
}

export const cobuyerSsnAndBirthdayInitialValues = (tempInfoData: TemporaryData) => ({
  cobuyer_dob: tempInfoData.cobuyer_dob ? new Date(tempInfoData.cobuyer_dob) : undefined,
  cobuyer_ssn: '',
  cobuyer_marital_status: tempInfoData.cobuyer_marital_status ?? undefined,
});

export const cobuyerSsnAndBirthdayValidationSchema = (showMaritalStatusSelector: boolean) =>
  Yup.object({
    cobuyer_dob: dobValidation,
    cobuyer_ssn: ssnFullValidation,
    cobuyer_marital_status: maritalStatusValidation(showMaritalStatusSelector),
  });
