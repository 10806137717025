import { Button } from '@chakra-ui/react';

import { isProd } from '../../../config';
import { LDFlags } from '../../../constants/flags';
import {
  PayoffRequestStatus,
  useCompletedPayoffRequestsJobMutation,
  usePayoffRequestUpdateMutation,
  useTemporaryInfoUpsertMutation,
} from '../../../gql/generated/graphql';
import { CookieKeys, useCookie } from '../../../hooks/useCookie';
import useFlag from '../../../hooks/useFlag';
import { useTempInfo } from '../../../hooks/useTempInfo';

const PayoffRequestHelperButtons = () => {
  const [guid] = useCookie<string>(CookieKeys.GUID_KEY);

  const showShoppingCart = useFlag(LDFlags.FLOW_SHOPPING_CART);
  const [updatePayoffRequest] = usePayoffRequestUpdateMutation();
  const [completedPayoffRequestsJobMutation, { loading }] = useCompletedPayoffRequestsJobMutation();

  const handleUpdate = (success: boolean) => {
    const date = new Date();
    updatePayoffRequest({
      variables: {
        payoffRequest: {
          temporary_info_id: guid,
          ...(success
            ? {
                status: PayoffRequestStatus.Completed,
                vehicle_payoff: 4000,
                sales_tax: 2000,
                completed_date: date,
                good_through_date: new Date(new Date().setMonth(date.getMonth() + 1)),
              }
            : {
                status: PayoffRequestStatus.Failed,
                completed_date: date,
              }),
        },
      },
    });
  };

  const tempInfo = useTempInfo();
  const [upsertTemporaryInfo] = useTemporaryInfoUpsertMutation();

  const clearEstimate = () => {
    upsertTemporaryInfo({
      variables: {
        info: {
          id: tempInfo.info?.id,
          data: {
            shopping_cart: {
              buyout: null,
              credit_score: null,
              down_payment: null,
              loan_term: null,
              payment_estimate_low: null,
              payment_estimate_high: null,
              estimated_fees: null,
            },
          },
        },
      },
    });
  };

  if (isProd) return null;

  return (
    <>
      <Button
        colorScheme="blackAlpha"
        size="sm"
        variant="solid"
        ml={1}
        onClick={() => handleUpdate(true)}
      >
        Success
      </Button>
      <Button
        colorScheme="blackAlpha"
        size="sm"
        variant="solid"
        mx={1}
        onClick={() => handleUpdate(false)}
      >
        Fail
      </Button>
      <Button
        isLoading={loading}
        colorScheme="blackAlpha"
        size="sm"
        variant="solid"
        onClick={() => completedPayoffRequestsJobMutation()}
      >
        Run import
      </Button>
      {showShoppingCart && (
        <Button onClick={clearEstimate} colorScheme="blackAlpha" size="sm" variant="solid" mx={1}>
          clear monthly estimator
        </Button>
      )}
    </>
  );
};

export default PayoffRequestHelperButtons;
