import { date } from 'yup';

import { getAge } from '../helpers';

const MAX_YEARS_OLD = 120;
export const minDate = new Date(new Date().setFullYear(new Date().getFullYear() - MAX_YEARS_OLD));

const isEighteenOrOlder = (birthdate: Date) => getAge(birthdate) >= 18;
const isOlderThanMinDate = (birthdate: Date) => minDate < birthdate;
const dobValidator = (validator: (date: Date) => boolean, value?: Date | null) => {
  const birthdate = value ?? new Date();
  return validator(birthdate);
};

export const dobValidation = date()
  .nullable()
  .required('Please enter a value')
  .test('is-adult', 'Must be 18 years old', (value) => dobValidator(isEighteenOrOlder, value))
  .test('is-not-too-old', `Must be under ${MAX_YEARS_OLD}`, (value) =>
    dobValidator(isOlderThanMinDate, value),
  );

export const birthdateDatePickerFilter = (birthdate: Date) =>
  isEighteenOrOlder(birthdate) && isOlderThanMinDate(birthdate);
