import { Table, Tbody, Td, Tr } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { formatMoney } from '../../utils/helpers';
import { EstimatorFormFields } from './useEstimatorFormik';

const PaymentBreakdown = () => {
  const { values } = useFormikContext<EstimatorFormFields>();

  return (
    <Table size="sm" variant="unstyled" maxW={{ base: '300px', lg: 'unset' }}>
      <Tbody>
        <Tr>
          <Td px={0} py={{ base: '9px', lg: 3 }} fontSize="16px">
            Payoff
          </Td>
          <Td
            px={0}
            py={{ base: '9px', lg: 3 }}
            textAlign="right"
            color={{ lg: 'leaseEndBlue' }}
            fontSize="18px"
          >
            {formatMoney(values.vehicle_payoff)}
          </Td>
        </Tr>
        <Tr>
          <Td px={0} py={{ base: '9px', lg: 3 }} fontSize="16px">
            Down Payment
          </Td>
          <Td
            px={0}
            py={{ base: '9px', lg: 3 }}
            textAlign="right"
            color={{ lg: 'leaseEndBlue' }}
            fontSize="18px"
          >
            ({formatMoney(values.down_payment)})
          </Td>
        </Tr>
        <Tr>
          <Td px={0} pt={{ base: '9px', lg: 3 }} pb={3} fontSize="16px">
            Est. Taxes & Fees
          </Td>
          <Td
            px={0}
            pt={{ base: '9px', lg: 3 }}
            pb={4}
            textAlign="right"
            color={{ lg: 'leaseEndBlue' }}
            fontSize="18px"
          >
            {formatMoney(values.taxes_fees)}
          </Td>
        </Tr>
        <Tr borderTop="1px solid" borderColor="gainsboro">
          <Td px={0} py={4} fontSize="16px">
            Total Amount
          </Td>
          <Td px={0} py={4} textAlign="right" color={{ lg: 'leaseEndBlue' }} fontSize="18px">
            {formatMoney(values.vehicle_payoff + values.taxes_fees - values.down_payment)}
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
};

export default PaymentBreakdown;
