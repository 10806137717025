import { Text, useBreakpointValue } from '@chakra-ui/react';
import { atom, useAtomValue } from 'jotai';
import { useState } from 'react';

import { LEAccordion, LEAccordionItem } from '../../components/LEAccordion';
import LEAccordionHeader from '../../components/LEAccordion/LEAccordionHeader';
import LEAccordionContainer from '../../components/LEAccordion/LeAccordionContainer';
import { ACCORDION_NAME } from '../../components/LEAccordion/utils';
import SafetyMessage from '../../components/SafetyMessage';
import ShoppingCartDesktopSidebar from '../../components/shoppingCart/ShoppingCartDesktopSidebar';
import ShoppingCartMobile from '../../components/shoppingCart/ShoppingCartMobile';
import useEstimatorFormik from '../../components/shoppingCart/useEstimatorFormik';
import { ButtonWrapper } from '../../components/ui/buttons/ButtonWrapper';
import SecondaryButton from '../../components/ui/buttons/SecondaryButton';
import { LDFlags } from '../../constants/flags';
import { MID_FLOW_PAYMENT_CALC_VARIATIONS } from '../../constants/paymentCalculator';
import { useFlag, useTempInfo } from '../../hooks';
import BuyerCoBuyerSwitch from '../LoanApp/components/BuyerCobuyerSwitch';
import LoanAppDevToolbar from '../LoanApp/components/LoanAppDevToolbar';
import CobuyerEmployment from './components/CobuyerEmployment';
import CobuyerPersonalInfo from './components/CobuyerPersonalInfo';
import CobuyerResidence from './components/CobuyerResidence';
import CobuyerSsnAndBirthdate from './components/CobuyerSsnAndBirthdate';

export const cobuyerFirstNameAtom = atom<string | null>(null);

const CobuyerLoanApp = () => {
  const { info } = useTempInfo();
  const tempInfoData = info?.data;

  const cobuyerFirstName =
    useAtomValue(cobuyerFirstNameAtom) ||
    tempInfoData?.cobuyer_first_name?.toUpperCase() ||
    'CO-BUYER';

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { formik, loading: shoppingCartLoading } = useEstimatorFormik();
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const paymentCalcVariation = useFlag(LDFlags.MID_FLOW_PAYMENT_CALC);
  const showPaymentCalc = paymentCalcVariation === MID_FLOW_PAYMENT_CALC_VARIATIONS.showPaymentCalc;

  return (
    <LEAccordionContainer>
      <LEAccordionHeader>Your Application</LEAccordionHeader>
      <Text textAlign="center" fontSize="16px" mb="5px">
        To include a co-buyer, we'll need some personal information about them to complete your
        application.
      </Text>
      <BuyerCoBuyerSwitch justifyContent="center" my={4} />
      <LEAccordion
        name={ACCORDION_NAME.COBUYER_LOAN_APP_ACCORDION}
        minutes={4}
        rightSidebar={
          <>
            {showPaymentCalc && (
              <ShoppingCartDesktopSidebar
                isOpen={isOpen && !isMobile}
                setIsOpen={setIsOpen}
                loading={shoppingCartLoading}
                formik={formik}
              />
            )}
          </>
        }
      >
        <LEAccordionItem
          accordionKey="coBuyerLoanVerifyPersonalInfo"
          title={`${cobuyerFirstName}'S PERSONAL INFO`}
        >
          <CobuyerPersonalInfo />
        </LEAccordionItem>
        <LEAccordionItem
          accordionKey="coBuyerLoanResidence"
          title={`${cobuyerFirstName}'S RESIDENTIAL INFO`}
        >
          <CobuyerResidence />
        </LEAccordionItem>
        <LEAccordionItem
          accordionKey="coBuyerLoanEmployment"
          title={`${cobuyerFirstName}'S EMPLOYMENT INFO`}
        >
          <CobuyerEmployment />
        </LEAccordionItem>
        <LEAccordionItem
          accordionKey="coBuyerLoanBirthdate"
          title={`${cobuyerFirstName}'S DATE OF BIRTH/SSN`}
        >
          <CobuyerSsnAndBirthdate />
        </LEAccordionItem>
      </LEAccordion>
      <SafetyMessage />
      <ButtonWrapper>
        <SecondaryButton />
      </ButtonWrapper>
      <LoanAppDevToolbar />
      {showPaymentCalc && (
        <ShoppingCartMobile
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          loading={shoppingCartLoading}
          formik={formik}
        />
      )}
    </LEAccordionContainer>
  );
};

export default CobuyerLoanApp;
