import * as Yup from 'yup';

import { formatMileage } from '../helpers';

const MAX_MILEAGE = 150_000;

export const mileageValidation = Yup.number()
  .nullable()
  .integer('Please enter a whole number')
  .required('Please enter a value')
  .lessThan(
    MAX_MILEAGE,
    `High mileage alert! We are usually unable to secure financing options for lease cars over ${formatMileage(
      MAX_MILEAGE,
    )} miles. Thank you.`,
  );
