import { Flex, Image, Text, VStack } from '@chakra-ui/react';

import { NoFeeGuarantee } from '../../../../../assets/Images';
import TextWithTooltip from '../../../../../components/TextWithTooltip';
import { LDFlags } from '../../../../../constants/flags';
import { useFlag } from '../../../../../hooks';
import { formatMoney } from '../../../../../utils/helpers';
import { getEstimatedOverageFee } from '../../../../../utils/mileageWarnings';

const OverMileageFeeWarning = ({ mileage }: { mileage: number }) => {
  const estimatedFee = getEstimatedOverageFee(mileage);
  const showSavingsMessage = useFlag(LDFlags.OVER_MILEAGE_SAVINGS);
  return (
    <VStack mt={4}>
      {showSavingsMessage ? (
        <>
          <Text color="leaseEndBlue" fontWeight="bold" fontSize="16px">
            Nice Savings!
          </Text>
          <Text maxW="container.sm" textAlign="center">
            You could save{' '}
            <Text as="b" color="leaseEndGreen">
              {formatMoney(estimatedFee)}
            </Text>{' '}
            by buying out your car with Lease End! That's the amount the dealership could charge you
            in over mileage fees if you return your car to them.
          </Text>
        </>
      ) : (
        <Flex
          gap="20px"
          my="12px"
          alignItems={{ base: 'center', md: 'end' }}
          flexDirection={{ md: 'row' }}
        >
          <Image
            ml="10px"
            src={NoFeeGuarantee}
            alt="No Fee Guarantee"
            display={{ base: 'none', md: 'block' }}
            boxSize="115px"
            borderRadius="50%"
            transform="rotate(-10deg)"
            bgGradient="linear(to-br, white, rgb(0, 0, 0, 0.2))"
            boxShadow="2px 2px 6px rgb(0, 0, 0, 0.2)"
          />
          <Flex flexDir="column" textAlign="left" gap="14px">
            <Text color="leaseEndBlue" fontWeight="bold" fontSize="16px">
              Dealership Fee Alert!
            </Text>
            <Text maxW="container.sm">
              The <b>dealership</b> could charge you{' '}
              <Text as="b" color="leaseEndRed">
                {formatMoney(estimatedFee)}
              </Text>{' '}
              in mileage overage fees if you return your car to them!
            </Text>
            <Flex gap="5px" alignItems="center" justifyContent="left">
              <Text>
                But don't worry, there are <b>no over mileage fees</b> when you buy your car out
                with Lease End.
              </Text>
              <Image
                mr="20px"
                src={NoFeeGuarantee}
                alt="No Fee Guarantee"
                display={{ base: 'block', md: 'none' }}
                boxSize="75px"
                borderRadius="50%"
                transform="rotate(-10deg)"
                bgGradient="linear(to-br, white, rgb(0, 0, 0, 0.2))"
                boxShadow="1px 2px 6px rgb(0, 0, 0, 0.2)"
              />
            </Flex>
          </Flex>
        </Flex>
      )}
      <TextWithTooltip label="This fee is calculated at the average $0.20/per mile that dealerships charge over the standard lease mileage allowance of 36,000 miles.">
        How is this calculated?
      </TextWithTooltip>
    </VStack>
  );
};

export default OverMileageFeeWarning;
