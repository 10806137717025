import { Maybe, useAddressDetailLazyQuery } from '../gql/generated/graphql';

type GoogleResponseField = {
  short_name: string;
  types: string[];
};

const googleAddressDictionary = {
  streetNumber: 'street_number',
  route: 'route',
  city: 'locality',
  county: 'administrative_area_level_2',
  state: 'administrative_area_level_1',
  zipCode: 'postal_code',
};

export const decodeGoogleApiResponse = (response: Array<GoogleResponseField> | undefined) => {
  if (!response) {
    return {};
  }

  const streetNumber = response.find((r) => r.types.includes(googleAddressDictionary.streetNumber));
  const route = response.find((r) => r.types.includes(googleAddressDictionary.route));
  const city = response.find((r) => r.types.includes(googleAddressDictionary.city));
  const county = response.find((r) => r.types.includes(googleAddressDictionary.county));
  const state = response.find((r) => r.types.includes(googleAddressDictionary.state));
  const zipCode = response.find((r) => r.types.includes(googleAddressDictionary.zipCode));

  return {
    streetNumber: streetNumber?.short_name,
    route: route?.short_name,
    city: city?.short_name,
    county: county?.short_name,
    state: state?.short_name,
    zipCode: zipCode?.short_name,
  };
};

export type DecodedAddressType = {
  streetNumber?: string;
  route?: string;
  city?: string;
  county?: string;
  state?: string;
  zipCode?: string;
};

export const fillAddressFieldsWithGoogleAutocomplete = (
  decodedAddress: DecodedAddressType,
  addressLineFieldName: string,
  cityFieldName: string,
  countyFieldName: string,
  stateFieldName: string,
  zipFieldName: string,
  setFieldValue: (field: string, fieldValue: unknown) => void,
) => {
  const { streetNumber, route, zipCode, city, state, county } = decodedAddress;

  if (streetNumber && route) {
    setFieldValue(addressLineFieldName, `${streetNumber} ${route}`);
  }

  if (zipCode) {
    setFieldValue(zipFieldName, zipCode);
  }

  if (city) {
    setFieldValue(cityFieldName, city);
  }

  if (state) {
    setFieldValue(stateFieldName, state);
  }

  if (county) {
    setFieldValue(countyFieldName, county);
  }
};

interface DecodeZipProps {
  zip: string;
  city?: string;
  county?: string;
  state?: string;
  address_line?: string;
  addressDetailLazyQuery: ReturnType<typeof useAddressDetailLazyQuery>[0];
}

export const decodeZip = async (decodeZipProps: DecodeZipProps) => {
  const { addressDetailLazyQuery, zip, address_line } = decodeZipProps;
  let { city, state, county } = decodeZipProps;

  if (!state || !city || !county) {
    const { data: addressDetailData } = await addressDetailLazyQuery({
      variables: { address: `${address_line ? `${address_line} ` : ''}${zip}` },
    });

    state = state || addressDetailData?.addressDetail?.state || '';
    city = city || addressDetailData?.addressDetail?.city || '';
    county = county || addressDetailData?.addressDetail?.county || '';
  }

  return { city, state, county };
};

interface AddressValueParams {
  address_line: Maybe<string>;
  address_line_2: Maybe<string>;
  city: Maybe<string>;
  state: Maybe<string>;
  zip: Maybe<string>;
}

export const getAddressValue = ({
  address_line,
  address_line_2,
  city,
  state,
  zip,
}: AddressValueParams) => {
  const addressValueArr = [];
  if (address_line) {
    addressValueArr.push(address_line);
  }

  if (address_line_2) {
    addressValueArr.push(address_line_2);
  }

  if (city) {
    addressValueArr.push(city);
  }

  if (state) {
    addressValueArr.push(state);
  }

  let addressValue = addressValueArr.join(', ');

  if (zip) {
    addressValue += ` ${zip}`;
  }

  return addressValue;
};
