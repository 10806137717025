/* eslint-disable react/no-unused-prop-types */
import {
  Box,
  Button,
  Flex,
  FormControl,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
} from '@chakra-ui/react';
import { useField } from 'formik';
import React from 'react';

import { Label } from '../../../../../components/formComponents';
import FormErrorMessage from '../../../../../components/formComponents/FormErrorMessage';
import { VEHICLE_COLORS } from '../../../../../constants/vehicleColors';

export const COLORS: { [color in VEHICLE_COLORS]: string } = {
  [VEHICLE_COLORS.Beige]: '#D9D4AA',
  [VEHICLE_COLORS.Black]: '#000000',
  [VEHICLE_COLORS.Blue]: '#005292',
  [VEHICLE_COLORS.Brown]: '#885F37',
  [VEHICLE_COLORS.Gray]: '#808080',
  [VEHICLE_COLORS.Green]: '#017C39',
  [VEHICLE_COLORS.Orange]: '#F7931E',
  [VEHICLE_COLORS.Red]: '#CC1D3A',
  [VEHICLE_COLORS.Silver]: '#CCCCCC',
  [VEHICLE_COLORS.White]: '#FFFFFF',
  [VEHICLE_COLORS.Purple]: '#720CB5',
  [VEHICLE_COLORS.Yellow]: '#FFF200',
};

interface Props {
  name?: string;
}

const NoColor = () => (
  <Box as="span" bgColor="white" h="25px" w="25px" borderRadius="5px">
    <Box
      position="absolute"
      border="1px solid"
      w="35px"
      transform="rotate(-45deg)"
      borderColor="red"
      top="16px"
      left={0}
    />
  </Box>
);

const ColorPicker = ({ name = 'color' }: Props) => {
  const [field, meta, helpers] = useField(name);

  const { value } = field;
  const { error, touched } = meta;
  const { setValue } = helpers;

  const isInvalid = touched && !!error;

  return (
    <Flex flexDir="column" alignItems="flex-start">
      <Label letterSpacing="1px">Color</Label>
      <Box>
        <Popover variant="picker">
          {({ onClose }: { onClose: () => void }) => {
            return (
              <>
                <PopoverTrigger>
                  <Button
                    background={isInvalid ? 'leaseEndRedBG' : 'white'}
                    boxSize="35px"
                    p={0}
                    minWidth="unset"
                    borderRadius="5px"
                    border="1px solid"
                    borderColor="grayDarkBackground"
                    _hover={{ bgColor: isInvalid ? 'leaseEndRedBG' : 'white' }}
                  >
                    {value ? (
                      <Box
                        position="absolute"
                        top="1/2"
                        left="1/2"
                        as="span"
                        bgColor={COLORS[value as VEHICLE_COLORS]}
                        boxSize="25px"
                        borderRadius="5px"
                      />
                    ) : (
                      <NoColor />
                    )}
                  </Button>
                </PopoverTrigger>
                <FormControl isInvalid={isInvalid} w="50px">
                  <FormErrorMessage color="leaseEndRed">{error}</FormErrorMessage>
                </FormControl>
                <PopoverContent w="170px">
                  <PopoverBody h="95px">
                    <SimpleGrid columns={5} spacing={2}>
                      {Object.entries(COLORS).map(([key, c]) => (
                        <Button
                          key={c}
                          background={c}
                          h="22px"
                          w="22px"
                          p={0}
                          minWidth="unset"
                          border="1px solid"
                          borderColor="grayDarkBackground"
                          borderRadius={3}
                          _hover={{ background: c }}
                          onClick={() => {
                            setValue(key as VEHICLE_COLORS);
                            onClose();
                          }}
                        />
                      ))}
                    </SimpleGrid>
                  </PopoverBody>
                </PopoverContent>
              </>
            );
          }}
        </Popover>
      </Box>
    </Flex>
  );
};

export default ColorPicker;
