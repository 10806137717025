import { Grid, GridItem } from '@chakra-ui/react';
import React from 'react';

import DateInput from '../../../../components/formComponents/DateInput';
import Input from '../../../../components/formComponents/Input';
import SSNInput from '../../../../components/formComponents/ssn/SSNInput';
import { CustomerInfo, Option } from '../../../../gql/prs/types';
import { birthdateDatePickerFilter } from '../../../../utils/validation/dob';

// VIN, ODO, AND ZIP don't have content
const CustomerInfoUI = {
  [CustomerInfo.VIN]: null,
  [CustomerInfo.ZIP]: null,
  [CustomerInfo.DOB]: (
    <GridItem alignItems="flex-start">
      <DateInput
        name={CustomerInfo.DOB}
        label="Date of Birth"
        hideCalendar
        filterDate={birthdateDatePickerFilter}
      />
    </GridItem>
  ),
  [CustomerInfo.SSN]: (
    <GridItem alignItems="flex-start">
      <SSNInput name={CustomerInfo.SSN} digits="9" />
    </GridItem>
  ),
  [CustomerInfo.SSN_LAST_6]: (
    <GridItem alignItems="flex-start">
      <SSNInput name={CustomerInfo.SSN_LAST_6} digits="6" />
    </GridItem>
  ),
  [CustomerInfo.SSN_LAST_4]: (
    <GridItem alignItems="flex-start">
      <SSNInput name={CustomerInfo.SSN_LAST_4} digits="4" />
    </GridItem>
  ),
  [CustomerInfo.PHONE_NUMBER]: (
    <GridItem alignItems="flex-start">
      <Input
        label="Your Phone Number"
        name={CustomerInfo.PHONE_NUMBER}
        mask="(999) 999-9999"
        type="tel"
        _container={{ justifyContent: 'flex-end' }}
      />
    </GridItem>
  ),
  [CustomerInfo.ACCOUNT_NUMBER]: (
    <GridItem colSpan={{ lg: 2 }} alignItems="flex-start">
      <Input
        name={CustomerInfo.ACCOUNT_NUMBER}
        placeholder="Account Number"
        label="Account Number"
      />
    </GridItem>
  ),
  [CustomerInfo.CURRENT_ODOMETER]: null,
  [CustomerInfo.FIRST_NAME]: (
    <GridItem alignItems="flex-start">
      <Input name={CustomerInfo.FIRST_NAME} label="First Name" />
    </GridItem>
  ),
  [CustomerInfo.LAST_NAME]: (
    <GridItem alignItems="flex-start">
      <Input name={CustomerInfo.LAST_NAME} label="Last Name" />
    </GridItem>
  ),
};

const PayoffOption = ({ option }: { option: Option }) => {
  return (
    <Grid
      templateColumns={{ base: '1fr', lg: '1fr 1fr' }}
      gap="20px"
      columnGap="75px"
      mx={{ lg: '25px' }}
    >
      {option.map((customerInfo) => (
        <React.Fragment key={customerInfo}>{CustomerInfoUI[customerInfo]}</React.Fragment>
      ))}
    </Grid>
  );
};

export default PayoffOption;
