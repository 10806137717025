import { Image, Spinner, Stack, Text } from '@chakra-ui/react';

interface Props {
  year: string;
  make: string;
  model: string;
  icon: string;
  infoLoading: boolean;
}

const VehicleInfo = ({ year, make, model, icon, infoLoading }: Props) => {
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      w="full"
      p={5}
      borderRadius={5}
      bgColor="brightGray"
      alignItems="center"
    >
      {infoLoading ? (
        <Spinner size="md" />
      ) : (
        <>
          <Image w={20} src={icon} alt="Icon" />
          <Text pl={2} pt={{ base: 3, md: 0 }}>
            <Text as="b">{`${year} ${make}`}</Text> {model}
          </Text>
        </>
      )}
    </Stack>
  );
};

export default VehicleInfo;
