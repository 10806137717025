import { ACCORDION_NAME, ACCORDION_NAME_TO_INDEX } from '../components/LEAccordion/utils';
import { ImportType, TemporaryData, TransformType } from '../gql/generated/graphql';
import { LAST_ACCORDION_ITEM_INDEX } from '../pages/LoanApp';

export const hasCobuyer = (tempInfoData?: TemporaryData) => {
  const filteredObject =
    Object.fromEntries(
      Object.entries(tempInfoData ?? {}).filter(([key]) => key.startsWith('cobuyer_')),
    ) ?? {};

  return Object.values(filteredObject).some((value) => value !== null && value !== '');
};

export const deleteCobuyerData = (tempInfoData?: TemporaryData) => {
  const data = { ...tempInfoData };
  const cobuyerKeys = Object.keys(data).filter((key) => key.startsWith('cobuyer_'));

  const cobuyerValues = {
    ...cobuyerKeys.reduce((o, key) => ({ ...o, [key]: null }), {}),
    current_accordion_panel: `${
      ACCORDION_NAME_TO_INDEX[ACCORDION_NAME.LOAN_APP_ACCORDION]
    }:${LAST_ACCORDION_ITEM_INDEX}`,
  };

  return cobuyerValues;
};

export const getImportType = (
  failedPrequal: boolean,
  hasKbbValues: boolean,
  hasOtherLienholder: boolean,
  frozenOrLockedCredit: boolean,
) => {
  if (hasOtherLienholder) {
    return ImportType.OtherLienholder;
  }
  if (!hasKbbValues) {
    return ImportType.NoKbbValuesFailure;
  }
  if (frozenOrLockedCredit) {
    return ImportType.FrozenOrLockedCredit;
  }
  if (failedPrequal) {
    return ImportType.SoftPullFailure;
  }

  // don't import to dd if we are autoStructuring
  return undefined;
};

export const getTransformType = (
  failedPrequal: boolean,
  hasKbbValues: boolean,
  hasOtherLienholder: boolean,
  frozenOrLockedCredit: boolean,
) => {
  if (hasOtherLienholder) {
    return TransformType.OtherLienholder;
  }
  if (!hasKbbValues) {
    return TransformType.NoKbbFailure;
  }
  if (frozenOrLockedCredit) {
    return TransformType.FrozenOrLockedCredit;
  }
  if (failedPrequal) {
    return TransformType.SoftPullFailure;
  }

  return TransformType.AutoStructureBegin;
};
