import { Customer, EmploymentStatusType, Maybe } from '../gql/generated/graphql';

const sameEmail = (buyer?: Maybe<Customer>, cobuyer?: Maybe<Customer>) => {
  return buyer?.email === cobuyer?.email;
};

const getTimeAtLabel = (status: EmploymentStatusType) => {
  switch (status) {
    case EmploymentStatusType.Employed:
      return 'TIME AT EMPLOYER?';
    case EmploymentStatusType.NotEmployed:
      return 'TIME NOT EMPLOYED?';
    case EmploymentStatusType.Retired:
      return 'TIME RETIRED?';
    default:
      return 'TIME AT EMPLOYER?';
  }
};

export { sameEmail, getTimeAtLabel };
