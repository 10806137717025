import { ButtonWrapper } from '../../components/ui/buttons/ButtonWrapper';
import PrimaryButton from '../../components/ui/buttons/PrimaryButton';
import { Link } from '../../components/ui/links/Link';
import { FAQS } from '../../constants/urls';

const FaqsWrapper = () => (
  <ButtonWrapper
    flexDir={{ base: 'column', md: 'row' }}
    textAlign="center"
    h={{ base: 'unset', md: '50px' }}
  >
    <Link to={FAQS}>
      <PrimaryButton>EXPLORE THE FAQ'S</PrimaryButton>
    </Link>
  </ButtonWrapper>
);

export default FaqsWrapper;
