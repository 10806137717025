import { Box, Center } from '@chakra-ui/react';
import { Form, FormikContext, FormikContextType } from 'formik';

import ButtonedRadioGroup from '../../../components/ButtonedRadioGroup';
import { useLEAccordionItemContext } from '../../../components/LEAccordion/LEAccordionItem/useLEAccordionItem';
import { Label } from '../../../components/formComponents';
import NumberInput from '../../../components/formComponents/NumberInput';
import { EstimatorFormFields } from '../../../components/shoppingCart/useEstimatorFormik';
import PrimaryButton from '../../../components/ui/buttons/PrimaryButton';
import { useTempInfo } from '../../../hooks';
import { termLengths } from '../../../utils/paymentCalculator';

interface Props {
  formik: FormikContextType<EstimatorFormFields>;
}

const OptionalDownPayment = ({ formik }: Props) => {
  const { info } = useTempInfo();
  const tempInfoData = info?.data;
  const vehiclePayoff = tempInfoData?.vehicle_payoff ?? 0;
  const { updateState, index, setOpenAccordionIndex } = useLEAccordionItemContext();

  const { initialValues, setFieldValue, values, submitForm } = formik;
  return (
    <Box>
      <FormikContext.Provider value={formik}>
        <Form>
          <NumberInput
            isMoney
            isWhole
            name="down_payment"
            label="DOWN PAYMENT (OPTIONAL)"
            placeholder="$0"
            min={0}
            mb={2}
            _input={{ inputMode: 'numeric' }}
          />
          <Label mt={5}>TERM LENGTH</Label>
          <ButtonedRadioGroup
            columns={4}
            collapseOnMobile={false}
            defaultValue={initialValues.term}
            mb={8}
            name="term"
            onChange={(value) => setFieldValue('term', Number(value))}
            options={termLengths.map((term) => ({ label: term.label, value: term.value }))}
            value={values.term}
          />

          <Center>
            <PrimaryButton
              mt="30px"
              mb={{
                base: '20px',
                lg: '0',
              }}
              onClick={async () => {
                if (values.down_payment > vehiclePayoff) {
                  setFieldValue('down_payment', Math.floor(vehiclePayoff));
                } else {
                  updateState({ status: 'loading' });
                  await submitForm();
                  setOpenAccordionIndex(index + 1);
                  updateState({ status: 'complete' });
                }
              }}
            />
          </Center>
        </Form>
      </FormikContext.Provider>
    </Box>
  );
};

export default OptionalDownPayment;
