import { Box, Button, Flex, Icon, IconButton, Text, useToast } from '@chakra-ui/react';
import { FaEye } from '@react-icons/all-files/fa/FaEye';
import { FaEyeSlash } from '@react-icons/all-files/fa/FaEyeSlash';
import { Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

import { useResetPasswordAndSendEmailMutation } from '../../gql/generated/graphql';
import { CookieKeys, useCookie } from '../../hooks/useCookie';
import { StrongPasswordTooltipExplanation } from '../../pages/auth/utils';
import { createSuccessToast } from '../../utils/toast';
import { passwordConfirmValidation, passwordValidation } from '../../utils/validation/auth';
import PasswordStrength from '../PasswordStrength';
import TextWithTooltip from '../TextWithTooltip';
import Input from '../formComponents/Input';

interface Props {
  setOpen: (val: boolean) => void;
  loading: boolean;
}

const validationSchema = Yup.object({
  new_password: passwordValidation,
  new_password_confirm: passwordConfirmValidation('new_password'),
});

interface Values {
  new_password: string;
  new_password_confirm: string;
}

const ChangePassword = ({ setOpen, loading }: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toast = useToast();
  const [resetPasswordAndSendEmail] = useResetPasswordAndSendEmailMutation({
    context: {
      isErrorHandled: true,
    },
  });
  const [accessToken] = useCookie<string>(CookieKeys.ACCESS_TOKEN);

  const handleSubmit = async (values: Values, formikHelpers: FormikHelpers<Values>) => {
    try {
      await resetPasswordAndSendEmail({
        variables: {
          access_token: accessToken,
          password: values.new_password,
        },
      });

      toast(
        createSuccessToast({
          title: 'Password Changed Successfully',
        }),
      );

      setOpen(false);
    } catch (e) {
      let errorMsg = (e as Error).message;
      if (errorMsg.includes('PasswordHistoryError')) {
        errorMsg = 'You cannot use your two most recent passwords.';
      }
      formikHelpers.setErrors({ new_password: errorMsg });
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{ new_password: '', new_password_confirm: '' }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ values, isSubmitting }) => {
        return (
          <Form>
            <Box mt={{ base: 2, md: 4 }}>
              <Text
                fontSize="18px"
                fontWeight="semibold"
                textAlign={{ base: 'center', md: 'left' }}
              >
                Change Password
              </Text>
              <Box textAlign={{ base: 'center', md: 'left' }}>
                <TextWithTooltip label={StrongPasswordTooltipExplanation}>
                  Password requirements
                </TextWithTooltip>
              </Box>
              <Flex flexDirection="column">
                <Flex my={6} flexDirection={{ base: 'column', md: 'row' }}>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    label="New Password"
                    name="new_password"
                    placeholder="New Password"
                    _container={{ mr: { base: 0, md: 2 }, my: { base: 2, md: 0 } }}
                    icon={
                      <IconButton
                        variant="unstyled"
                        onClick={() => setShowPassword(!showPassword)}
                        aria-label="Hide/Show password"
                        icon={<Icon as={showPassword ? FaEye : FaEyeSlash} mt={1} />}
                      />
                    }
                  />
                  {values.new_password && (
                    <PasswordStrength
                      display={{ base: 'block', md: 'none' }}
                      h="auto"
                      w="100%"
                      password={values.new_password}
                    />
                  )}
                  <Input
                    type={showConfirmPassword ? 'text' : 'password'}
                    label="Confirm Password"
                    name="new_password_confirm"
                    placeholder="Confirm New Password"
                    _container={{ ml: { base: 0, md: 2 }, my: { base: 2, md: 0 } }}
                    icon={
                      <IconButton
                        variant="unstyled"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        aria-label="Hide/Show password confirm"
                        icon={<Icon as={showConfirmPassword ? FaEye : FaEyeSlash} mt={1} />}
                      />
                    }
                  />
                </Flex>
                {values.new_password && (
                  <PasswordStrength
                    display={{ base: 'none', md: 'block' }}
                    h="auto"
                    w="100%"
                    password={values.new_password}
                  />
                )}
                <Button
                  w="max-content"
                  type="submit"
                  alignSelf="center"
                  my={4}
                  isLoading={isSubmitting}
                  isDisabled={loading}
                >
                  CHANGE MY PASSWORD
                </Button>
              </Flex>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangePassword;
