import { Box, Divider, Flex, HStack, Link, Text, VStack } from '@chakra-ui/react';
import { Formik } from 'formik';
import { useSetAtom } from 'jotai';
import { useContext, useEffect, useMemo } from 'react';
import * as Yup from 'yup';

import { helpAtom, requirementsCompletedAtom } from '../..';
import Loader from '../../../../components/Loader';
import PrimaryButton from '../../../../components/ui/buttons/PrimaryButton';
import { useTemporaryInfoUpsertMutation } from '../../../../gql/generated/graphql';
import { CustomerInfo, CustomerInfoValues } from '../../../../gql/prs/types';
import { LienholderContext, LienholderContextType } from '../../../../hooks/useLienholderInfo';
import { useTempInfo } from '../../../../hooks/useTempInfo';
import {
  checkYupValidation,
  getFormErrorsFromYupValidation,
} from '../../../../utils/validation/yup';
import LienholderLogo from '../LienholderLogo';
import PayoffOption from './PayoffOption';
import { CustomerInfoValidations } from './utils';

const emptyValues: CustomerInfoValues = {
  [CustomerInfo.VIN]: '',
  [CustomerInfo.ZIP]: '',
  [CustomerInfo.DOB]: '',
  [CustomerInfo.SSN]: '',
  [CustomerInfo.SSN_LAST_6]: '',
  [CustomerInfo.SSN_LAST_4]: '',
  [CustomerInfo.PHONE_NUMBER]: '',
  [CustomerInfo.ACCOUNT_NUMBER]: '',
  [CustomerInfo.CURRENT_ODOMETER]: '',
  [CustomerInfo.FIRST_NAME]: '',
  [CustomerInfo.LAST_NAME]: '',
};

interface Props {
  setCustomerInfoValues: (a: CustomerInfoValues) => void;
}

const Content = ({ setCustomerInfoValues }: Props) => {
  const { lienholderRequirements: payoffOptions, lienholderLoading } =
    useContext<LienholderContextType>(LienholderContext);
  const setHelp = useSetAtom(helpAtom);

  const tempInfo = useTempInfo();
  const tempInfoData = tempInfo.info?.data;
  const [upsertTempInfo] = useTemporaryInfoUpsertMutation();
  const setRequirementsCompleted = useSetAtom(requirementsCompletedAtom);

  const initialValues = useMemo(() => {
    const values: CustomerInfoValues = {
      ...emptyValues,
      [CustomerInfo.DOB]: tempInfoData?.dob || '',
      [CustomerInfo.PHONE_NUMBER]: tempInfoData?.phone_number || '',
      [CustomerInfo.ACCOUNT_NUMBER]: tempInfoData?.lh_account_number || '',
      [CustomerInfo.FIRST_NAME]: tempInfoData?.first_name || '',
      [CustomerInfo.LAST_NAME]: tempInfoData?.last_name || '',
    };
    return values;
  }, [tempInfoData]);

  const validationOptions = useMemo(() => {
    const validations = payoffOptions?.map((option) =>
      Yup.object().shape(
        Object.fromEntries(
          option.map((customerInfo) => [customerInfo, CustomerInfoValidations[customerInfo]]),
        ),
      ),
    );

    return validations;
  }, [payoffOptions]);

  useEffect(() => {
    if (
      tempInfoData &&
      payoffOptions.length > 0 &&
      !lienholderLoading &&
      validationOptions.some((validation) => checkYupValidation(emptyValues, validation))
    ) {
      setRequirementsCompleted(true);
    }
  }, [validationOptions, payoffOptions, lienholderLoading, tempInfoData]);

  const validate = (values: CustomerInfoValues) => {
    if (validationOptions.length === 1) {
      return getFormErrorsFromYupValidation(values, validationOptions[0]);
    }
    if (!validationOptions.some((validation) => checkYupValidation(values, validation))) {
      return new Yup.ValidationError('invalid');
    }
    return {};
  };

  const saveData = async (values: CustomerInfoValues) => {
    const validFullSSN = payoffOptions.some(
      (value, i) =>
        value.includes(CustomerInfo.SSN) && checkYupValidation(values, validationOptions[i]),
    );
    const validLast6SSN = payoffOptions.some(
      (value, i) =>
        value.includes(CustomerInfo.SSN_LAST_6) && checkYupValidation(values, validationOptions[i]),
    );
    const validLast4SSN = payoffOptions.some(
      (value, i) =>
        value.includes(CustomerInfo.SSN_LAST_4) && checkYupValidation(values, validationOptions[i]),
    );

    let ssn = null;
    if (validFullSSN) {
      ssn = values.SSN;
    } else if (validLast6SSN) {
      ssn = values.SSN_LAST_6;
    } else if (validLast4SSN) {
      ssn = values.SSN_LAST_4;
    }

    setCustomerInfoValues(values);

    await upsertTempInfo({
      variables: {
        info: {
          id: tempInfo.info?.id,
          data: {
            lh_account_number: values.ACCOUNT_NUMBER,
            ssn,
            dob: values.DOB || null,
            phone_number: values.PHONE_NUMBER,
            first_name: values.FIRST_NAME,
            last_name: values.LAST_NAME,
          },
        },
      },
    });
  };

  if (!tempInfoData) {
    return <Loader />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={async (values) => {
        await saveData(values);
        setRequirementsCompleted(true);
      }}
    >
      {({ isSubmitting, isValid, submitCount, submitForm }) => (
        <Flex direction="column" alignItems="center">
          <LienholderLogo />
          <Text align="center" mt="10px" mb="30px">
            {tempInfoData.lienholder} allows to quickly get your payoff information for you - we
            just need a few pieces of information!
          </Text>
          <VStack w="full" align="flex-start">
            {payoffOptions?.map((payoffOption, i) => (
              <Box key={payoffOption.join(',')} w="full">
                {i !== 0 && (
                  <HStack w="full">
                    <Divider borderColor="grayDarkBackground" />
                    <Text p={5} fontSize="2xl" fontWeight="bold">
                      OR
                    </Text>
                    <Divider borderColor="grayDarkBackground" />
                  </HStack>
                )}
                <PayoffOption option={payoffOption} />
              </Box>
            ))}
          </VStack>

          <Flex flexDir="column" gap={3} mt="20px" align="center" justify="center">
            <PrimaryButton disabled={isSubmitting} onClick={submitForm}>
              NEXT
            </PrimaryButton>
            <Link onClick={() => setHelp(false)}>I'd rather get it myself</Link>
          </Flex>
          {!isValid && submitCount > 0 && validationOptions.length > 1 && (
            <Text color="red" mt={4}>
              At least one section above is required to continue
            </Text>
          )}
        </Flex>
      )}
    </Formik>
  );
};

export default Content;
