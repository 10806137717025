import { HStack, Icon } from '@chakra-ui/react';
import { SetStateAction } from 'jotai';
import { Dispatch, ReactNode } from 'react';
import { FaEye, FaEyeSlash, FaShieldAlt } from 'react-icons/fa';

import Popover from '../../Popover';
import Label from '../Label';

export const LabelWithShield = ({
  label,
  defaultLabel,
}: {
  defaultLabel: string;
  label?: ReactNode;
}) => {
  return (
    <HStack>
      <Label m={0}>{label || defaultLabel}</Label>
      <Popover placement="auto" label="BANK LEVEL AES-256 ENCRYPTION">
        <span>
          <Icon as={FaShieldAlt} color="bostonBlue" />
        </span>
      </Popover>
    </HStack>
  );
};

export const ShowHideIcon = ({
  show,
  setShow,
  isBlank = false,
}: {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  isBlank?: boolean;
}) => {
  if (isBlank && show) {
    return null;
  }

  return (
    <Icon
      cursor="pointer"
      as={show ? FaEye : FaEyeSlash}
      color="grayMedium"
      fontSize="16px"
      onClick={() => setShow(!show)}
    />
  );
};
