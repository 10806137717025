export const BEST_RATE = 6.99;
export const GOOD_RATE = 7.99;
export const BAD_RATE = 10.99;
export const WORST_RATE = 15.99;

export const DEFAULT_DOC_FEE = 799;
export const DEFAULT_BUYOUT = 25000;
export const DEFAULT_INITIAL_SCORE = 740;
export const DEFAULT_DOWN_PAYMENT = 0;
export const DEFAULT_LOAN_TERM = 72;
export const DEFAULT_DAYS_TO_PAYMENT = 45;

// This is used when estimating the monthly payment in order to overestimate the monthly payment.
export const LOAN_OVERESTIMATE_OFFSET = 1.1;
export const DEFAULT_TAXES_FEES_MULTIPLIER = 0.1175;

export const PAYMENT_CALCULATOR_VARIATIONS = {
  emailZipPhone: 0,
  zipEmail: 1,
  zipPhone: 2,
};

export const MID_FLOW_PAYMENT_CALC_VARIATIONS = {
  noPaymentCalcOrDownPaymentAccordion: -1,
  showPaymentCalc: 0,
  showDownPaymentAccordion: 1,
};
