/* eslint-disable react/no-array-index-key */
import { Box } from '@chakra-ui/react';

import { InstructionSet } from '../../../gql/prs/types';
import Description from '../../Description';
import Label from '../../formComponents/Label';
import Instructions from './Instructions';
import { InstructionsDescription, PhoneLabel } from './PayoffStylings';

const PhoneInstructions = ({
  phones,
  hasBoth,
  phoneInstructions,
}: {
  phones: string[];
  hasBoth: boolean;
  phoneInstructions: InstructionSet[];
}) => (
  <>
    {' '}
    <PhoneLabel phones={phones} hasBoth={hasBoth} />
    {phoneInstructions.map((phoneInstruction, i) => (
      <Box key={`phone-${i}`}>
        {phoneInstruction.intro && (
          <InstructionsDescription my={4}>{phoneInstruction.intro}</InstructionsDescription>
        )}
        <Instructions instructions={phoneInstruction.instructions} />
        {phoneInstruction.note && (
          <Description fontSize={{ md: '16px' }}>
            <Label mb={0} mr={1} fontSize={{ md: '16px' }} display="inline-block">
              Note:
            </Label>
            {phoneInstruction.note}
          </Description>
        )}
      </Box>
    ))}
  </>
);

export default PhoneInstructions;
