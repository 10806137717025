import {
  Box,
  Divider,
  Flex,
  Image,
  Link,
  Text,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';

import Loader from '../../../../components/Loader';
import { CONTACT_US } from '../../../../constants/urls';
import { useBankLogoQuery } from '../../../../gql/generated/graphql';
import { formatMoney } from '../../../../utils/helpers';
import { carIconFromCarData } from '../../../../utils/images/carImage';
import { DealWithFarthestState } from '../../utils';

interface Props {
  deal: DealWithFarthestState | undefined;
}
const PaymentOverview = ({ deal }: Props) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { data, loading: bankLoading } = useBankLogoQuery({
    skip: !deal?.financial_info?.bank,
    variables: {
      bank_name: deal?.financial_info?.bank as string,
    },
  });
  if (!deal) return null;
  if (bankLoading) {
    return <Loader padding="20px" />;
  }

  const car = deal?.car;
  return (
    <Flex
      flexDirection="column"
      w="400px"
      maxW="100%"
      align="start"
      mt={{ base: '-40px', md: '0px' }}
    >
      <Text
        fontSize={16}
        fontWeight="light"
        color="leaseEndBlue"
        mb={{ base: 8, md: 4 }}
        align={{ base: 'center', md: 'start' }}
      >
        Just one more step to secure your new financing - make your down payment!
      </Text>
      {!isMobile && (
        <Flex flexDirection="column" align="start" pt={{ base: '20px', md: '0px' }} w="100%">
          <Divider borderColor="lightGray" />
          <Text fontSize={20} fontWeight="semibold" color="leaseEndBlueDark" my={4} align="start">
            Your Lease End Package
          </Text>
          <Text
            fontSize={15}
            fontWeight="semibold"
            color="leaseEndBlueDark"
            ml={4}
          >{`${car?.year} ${car?.make} ${car?.model}`}</Text>
          <Text ml={4} color="gray">{`${car?.kbb_trim_name}`}</Text>
          <Box ml={4} my={8} w="200px">
            <Image src={carIconFromCarData(deal?.car)} />
          </Box>
        </Flex>
      )}
      <Box
        w={{ base: '100%', md: '325px' }}
        mb={{ base: 4, md: 8 }}
        borderWidth={{ base: '2px', md: '1px' }}
        borderRadius="10px"
        borderColor={{ base: 'gray.100', md: 'gray' }}
        pt={3}
        pb={1}
        px={{ base: 10, md: 5 }}
      >
        <Flex
          w="100%"
          align="start"
          justify={{ base: 'space-between', md: 'space-around' }}
          gap={14}
        >
          <VStack alignItems="left" gap={0}>
            <Text fontSize={14} color="gray">
              Payment Due Now:
            </Text>
            <Text fontSize={30} color="black" fontWeight="bold">
              {formatMoney(deal.financial_info?.money_down, { noCents: true })}
            </Text>
          </VStack>
          <Box w="100px">
            <Image
              src={data?.bankLogo ?? ''}
              alt={`${deal?.financial_info?.bank} Logo`}
              my="10px"
            />
          </Box>
        </Flex>
        <Text align="center" color="gray" fontSize={10} py="5px">
          *Payments already made may not be reflected here
        </Text>
      </Box>
      {!isMobile && (
        <>
          <Text align="left" color="gray" mb={5}>
            IMPORTANT: Lease End never stores any of your financial information
          </Text>
          <Link target="_blank" href={CONTACT_US}>
            Questions? Contact us
          </Link>
        </>
      )}
    </Flex>
  );
};

export default PaymentOverview;
