import { Box, Button, Grid, GridItem, Image, Link, Text, VStack } from '@chakra-ui/react';
import { useSetAtom } from 'jotai';
import { useHistory } from 'react-router-dom';

import { helpAtom } from '..';
import { AngryGuy } from '../../../assets/Images';
import { ALL_DONE } from '../../../constants/urls';
import { useSearchPhoneNumbers } from '../../../hooks';

const PayoffFailure = () => {
  const setHelp = useSetAtom(helpAtom);
  const phoneNumber = useSearchPhoneNumbers();
  const history = useHistory();

  return (
    <Grid templateColumns="2fr 7fr">
      <GridItem position="relative">
        <Image src={AngryGuy} alt="Angry Guy" />
      </GridItem>

      <GridItem>
        <VStack align="left" ml="30px" h="full" mt="30px">
          <Text fontSize="22px" fontWeight="semibold">
            DARN.
          </Text>
          <Text fontSize="16px">
            Looks like there was a problem.
            <br />
            <br />
            We ran into issues getting your payoff with the information you provided! Give us a call
            at <Link href={`tel:+1-${phoneNumber}`}>{phoneNumber}</Link> so we can get the problem
            resolved and get you back to ending your lease.
          </Text>
          <Box pt="15px">
            <Button
              onClick={() => {
                setHelp(false);
                history.push({ pathname: ALL_DONE, state: { payoffFailure: true } });
              }}
            >
              OK
            </Button>
          </Box>
        </VStack>
      </GridItem>
    </Grid>
  );
};

export default PayoffFailure;
