import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import {
  LocalStorageKeys,
  getLocalStorageItemOrInitial,
  setLocalStorageItem,
  useLocalStorageEvent,
} from '../../services/localStorage';
import useEventCallback from './useEventCallback';

type SetValue<T> = Dispatch<SetStateAction<T>>;

// Taken from https://usehooks-ts.com/react-hook/use-local-storage
function useLocalStorage<T>(
  key: LocalStorageKeys,
  initialValue: T,
  typeCheck?: (value: T) => boolean,
): [T, SetValue<T>] {
  // Get from local storage then
  // parse stored json or return initialValue
  const readValue = useCallback((): T => {
    // Prevent build error "window is undefined" but keeps working
    if (typeof window === 'undefined') {
      return initialValue;
    }

    return getLocalStorageItemOrInitial(key, initialValue, typeCheck);
  }, [initialValue, key]);

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(readValue);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue: SetValue<T> = useEventCallback((value) => {
    // Prevent build error "window is undefined" but keeps working
    if (typeof window === 'undefined') {
      // eslint-disable-next-line no-console
      console.warn(
        `Tried setting localStorage key “${key}” even though environment is not a client`,
      );
    }

    try {
      // Allow value to be a function so we have the same API as useState
      const newValue = value instanceof Function ? value(readValue()) : value;

      setLocalStorageItem(key, newValue);

      // Save state
      setStoredValue(newValue);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  });

  useEffect(() => {
    setStoredValue(readValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLocalStorageEvent(key, () => {
    setStoredValue(readValue());
  });

  return [storedValue, setValue];
}

export default useLocalStorage;
