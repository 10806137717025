import { Button, Flex } from '@chakra-ui/react';

import { logout } from '../services/auth0';
import Modal from './Modal';
import PrimaryButton from './ui/buttons/PrimaryButton';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const LogoutConfirmModal = ({ isOpen, handleClose }: Props) => {
  return (
    <Modal
      title="Are you sure you want to log out?"
      titleFontSize={{ base: '16px', md: '22px' }}
      onClose={handleClose}
      isOpen={isOpen}
      size="2xl"
    >
      <Flex flexDir="column" alignItems="center" justifyContent="center">
        <PrimaryButton mb={4} onClick={() => logout()}>
          YES
        </PrimaryButton>
        <Button onClick={handleClose} variant="link">
          No, I would like to stay on this page
        </Button>
      </Flex>
    </Modal>
  );
};

export default LogoutConfirmModal;
