import { Box, Button, Flex, Link, Text, useBreakpoint } from '@chakra-ui/react';
import { Form, FormikContext, FormikHelpers, useFormik } from 'formik';
import { useAtomValue } from 'jotai';
// eslint-disable-next-line no-restricted-imports
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { stateAbbreviations, statesArray } from '../../constants/states';
import {
  ERROR_TYPES,
  OLD_CAR_ERROR_MSG,
  UNABLE_TO_ASSIST_ERROR_MSG,
} from '../../constants/tempInfos';
import { ACCOUNT_LOGIN, DASHBOARD } from '../../constants/urls';
import {
  CreateTemporaryInfoMutation,
  useCreateTemporaryInfoMutation,
} from '../../gql/generated/graphql';
import { useSearchPhoneNumbers, useStep } from '../../hooks';
import { CookieKeys, clearCookie, useCookie } from '../../hooks/useCookie';
import { isDevActiveAtom } from '../../pages/dashboard/components/DashboardDevToolbar';
import { FlowParams, PrequalParams, getExtraInfo } from '../../pages/flowEntryPages/utils';
import { RudderEvent, rudderanalytics } from '../../utils/rudderstack';
import { stateValidation } from '../../utils/validation/address';
import validateLicenseNumber, {
  INVALID_LICENSE_PLATE_MSG,
  INVALID_VIN_MSG,
} from '../../utils/validation/licenseNumberValidator';
import validateVin from '../../utils/validation/vinValidator';
import Subtitle from '../Subtitle';
import Switch from '../Switch';
import Input from '../formComponents/Input';
import Select from '../formComponents/Select';
import VehicleManualEntryModal from './VehicleManualEntryModal';

const DECODING_SERVICE_ISSUE_MSG =
  'Our decoding service is experiencing issues. Please enter your info manually';

const licensePlateValidationSchema = Yup.object({
  licensePlate: Yup.string()
    .required('Required field')
    .test('is-valid-license-number', INVALID_LICENSE_PLATE_MSG, async (value) =>
      validateLicenseNumber({ licenseNumber: value }),
    ),
  state: stateValidation,
});

const vinValidationSchema = Yup.object({
  vin: Yup.string()
    .required('Required field')
    .test('is-valid-vin', INVALID_VIN_MSG, (value) => validateVin(value)),
});

const LICENSE_PLATE_NAME = 'PLATE';
const VIN_NAME = 'VIN';

type FormFields = Yup.InferType<typeof licensePlateValidationSchema & typeof vinValidationSchema>;

interface LicensePlateOrVinInputProps {
  autoFocus?: boolean;
  flowParams?: FlowParams;
  onReady?: () => void;
}

const LicensePlateOrVinInput = ({
  autoFocus = false,
  flowParams,
  onReady,
}: LicensePlateOrVinInputProps) => {
  const [, setGuid] = useCookie<string>(CookieKeys.GUID_KEY);
  const [accessToken] = useCookie<string>(CookieKeys.ACCESS_TOKEN);
  const [prequalInfo] = useCookie<PrequalParams>(CookieKeys.PREQUAL_PARAMS);
  const isAuthenticated = !!accessToken;
  const [currentInput, setCurrentInput] = useState(flowParams?.vin ? VIN_NAME : LICENSE_PLATE_NAME);
  const { pathname, search } = useLocation();

  const device = useBreakpoint();
  const step = useStep();
  const ldClient = useLDClient();
  const phoneNumber = useSearchPhoneNumbers();

  const [dealExists, setDealExists] = useState(false);
  const [isManualEntryModalOpen, setIsManualEntryModalOpen] = useState(false);
  const [flowStartError, setFlowStartError] = useState('');
  const isLicensePlate = LICENSE_PLATE_NAME === currentInput;
  const [createTempInfo] = useCreateTemporaryInfoMutation({
    context: {
      isErrorHandled: true,
    },
  });
  const isDevToolsActive = useAtomValue(isDevActiveAtom);

  const handleSubmit = async (values: FormFields, { setFieldError }: FormikHelpers<FormFields>) => {
    const vin = values.vin?.toUpperCase();
    const licensePlate = values.licensePlate?.replace(/\s/g, '')?.toUpperCase();
    const state = values.state?.toUpperCase();

    const isVin = !!vin;

    if (!isVin && (!licensePlate || !stateAbbreviations.includes(state))) {
      return;
    }

    if (isVin && !validateVin(vin)) {
      setFieldError('vin', INVALID_VIN_MSG);
      return;
    }
    if (!isVin && !validateLicenseNumber({ licenseNumber: licensePlate })) {
      setFieldError('licensePlate', INVALID_LICENSE_PLATE_MSG);
      return;
    }

    // workaround to fix apollo-client issue:
    // https://github.com/apollographql/apollo-client/issues/9354#issuecomment-1234952933
    const tempInfoMutation: CreateTemporaryInfoMutation = await new Promise((resolve, reject) =>
      createTempInfo({
        variables: {
          vin,
          license_plate_number: licensePlate,
          license_plate_state: state,
          device_type: device,
          extra_input: getExtraInfo(flowParams, prequalInfo),
          query_params: search,
          ignore_existing_deal: isDevToolsActive,
        },
        onCompleted: resolve,
        onError: (error) => {
          const extensions = error.graphQLErrors[0]?.extensions;
          const dealId = extensions?.dealId;
          const dealState = extensions?.dealState;

          rudderanalytics.track(RudderEvent.Error, {
            error_message: error.message,
            vin,
            license_plate: licensePlate,
            license_plate_state: state,
            pathname,
            date: new Date().toISOString(),
            deal_id: dealId,
            deal_state: dealState,
          });

          if (error.message === ERROR_TYPES.DEAL_EXISTS) {
            setDealExists(true);
          }
          if (error.message === ERROR_TYPES.OLD_CAR) {
            setFlowStartError(OLD_CAR_ERROR_MSG);
          }
          if (error.message === ERROR_TYPES.RESTRICTED_CAR) {
            setFlowStartError(UNABLE_TO_ASSIST_ERROR_MSG);
          }
          if (error.message === ERROR_TYPES.DECODING_SERVICE_ISSUE) {
            setFieldError('vin', DECODING_SERVICE_ISSUE_MSG);
          }
          if (error.message === ERROR_TYPES.INVALID_LICENSE_PLATE) {
            setFieldError('licensePlate', INVALID_LICENSE_PLATE_MSG);
          }
          reject();
        },
      }),
    );

    const id = tempInfoMutation?.createTemporaryInfo?.id as string;
    const tempInfoData = tempInfoMutation?.createTemporaryInfo?.data;

    if (tempInfoData?.vin) {
      ldClient?.track('Flow Entry', {});
      TagManager.dataLayer({
        dataLayer: {
          VIN: tempInfoData.vin,
          event: 'hotjar',
        },
      });
    }

    setGuid(id);
    clearCookie(CookieKeys.PREQUAL_PARAMS);
    rudderanalytics.identify({ guid: id });
    rudderanalytics.track(RudderEvent.VinProvided, {
      year: tempInfoData?.year || undefined,
      make: tempInfoData?.make || undefined,
      model: tempInfoData?.model || undefined,
      vin: tempInfoData?.vin || undefined,
      zip: tempInfoData?.zip || undefined,
      license_plate: tempInfoData?.license_plate_number || undefined,
      license_plate_state: tempInfoData?.license_plate_state || undefined,
      fuel_type: tempInfoData?.fuel_type || undefined,
      vehicle_type: tempInfoData?.vehicle_type || undefined,
    });
    step.moveNext({}, true);
  };

  const formik = useFormik<FormFields>({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      licensePlate: flowParams?.licensePlate || '',
      state: flowParams?.licensePlateState || '',
      vin: flowParams?.vin || '',
    },
    validationSchema: isLicensePlate ? licensePlateValidationSchema : vinValidationSchema,
    onSubmit: handleSubmit,
  });

  const { isSubmitting, setErrors, setValues, errors } = formik;

  const handleWarningsReset = () => {
    setDealExists(false);
    setFlowStartError('');
  };

  const handleOnSwitchChange = (newValue: string): void => {
    setCurrentInput(newValue);
    setValues({ vin: '', licensePlate: '', state: '' });
    setErrors({});
  };

  const isInitialized = useRef(false);
  useEffect(() => {
    const formSubmit = async () => {
      try {
        await formik.submitForm();
      } finally {
        onReady?.();
      }
    };

    if (!isInitialized.current) {
      isInitialized.current = true;
      const hasData =
        !!flowParams?.vin || (!!flowParams?.licensePlate && !!flowParams?.licensePlateState);
      if (hasData) {
        formSubmit();
      } else {
        onReady?.();
      }
    }
  }, [flowParams]);

  if (!isInitialized.current) {
    return null;
  }

  const isDecodingError = errors.vin === DECODING_SERVICE_ISSUE_MSG;

  return (
    <>
      <FormikContext.Provider value={formik}>
        <Form style={{ width: '100%' }}>
          <Flex
            bg={{ base: '#ffffffcc', md: 'white' }}
            border="1px solid"
            borderColor="gray.200"
            shadow="2xl"
            mx="auto"
            maxW="370px"
            rounded="10px"
            p="4"
            flexDirection="column"
          >
            <Flex alignItems="center" flexDirection={{ sm: 'column', md: 'row' }}>
              <Text
                noOfLines={2}
                mr={{ md: '3' }}
                textAlign={{ base: 'center', md: 'left' }}
                mt={1}
                mb={{ sm: '6', md: '0' }}
              >
                GET STARTED WITH YOUR {isLicensePlate ? 'LICENSE PLATE' : `VEHICLE'S VIN`}
              </Text>
              <Flex h="40px" ml={{ md: '1' }} minW="170px">
                <Switch
                  firstOption={LICENSE_PLATE_NAME}
                  secondOption={VIN_NAME}
                  onChange={(val) => {
                    handleOnSwitchChange(val);
                    handleWarningsReset();
                  }}
                  selectedOption={currentInput}
                />
              </Flex>
            </Flex>
            <Flex mt="4" mb={1} minH="50px">
              {isLicensePlate ? (
                <>
                  <Input
                    name="licensePlate"
                    placeholder="License Plate"
                    pr="5px"
                    autoFocus={autoFocus}
                    textTransform="uppercase"
                    onChange={handleWarningsReset}
                    invalid={dealExists || !!flowStartError}
                  />
                  <Flex maxW={{ sm: '110px', md: '100%' }}>
                    <Select
                      name="state"
                      placeholder="State"
                      options={statesArray}
                      onChange={handleWarningsReset}
                      invalid={dealExists}
                    />
                  </Flex>
                </>
              ) : (
                <Input
                  name="vin"
                  placeholder="VIN"
                  textTransform="uppercase"
                  onChange={handleWarningsReset}
                  invalid={dealExists || !!flowStartError}
                />
              )}
            </Flex>
            {dealExists && (
              <Box textAlign="center">
                <Text color="red.500" fontWeight="semibold" wordBreak="break-word">
                  Looks like you already have a lease buyout in progress with us. Please give us a
                  call at{' '}
                  <Link display="inline-block" color="red.500" href={`tel:+1-${phoneNumber}`}>
                    {phoneNumber}
                  </Link>
                  .
                </Text>
                <Text fontWeight="semibold" mt={1}>
                  You can view it's status in{' '}
                  <Link as={ReactRouterLink} to={DASHBOARD}>
                    your dashboard.
                  </Link>
                </Text>
              </Box>
            )}
            {flowStartError && (
              <Text
                as="span"
                color="red.500"
                fontWeight="semibold"
                textAlign="center"
                wordBreak="break-word"
              >
                {flowStartError}
              </Text>
            )}
            {!dealExists && !flowStartError && (
              <Flex direction="column" alignItems="center">
                {isDecodingError ? (
                  <Button
                    w="100%"
                    py="20px"
                    fontWeight="600"
                    onClick={() => setIsManualEntryModalOpen(true)}
                  >
                    ENTER VEHICLE INFO MANUALLY
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    w="100%"
                    py="20px"
                    fontWeight="600"
                    isLoading={isSubmitting}
                  >
                    GET STARTED
                  </Button>
                )}
              </Flex>
            )}
            {!isAuthenticated && !dealExists && !flowStartError && (
              <Subtitle
                textAlign="center"
                lineHeight="25px"
                fontSize="14px"
                maxW={{ base: '350px', lg: '750px' }}
                margin="10px"
                fontWeight="bold"
              >
                Already have an account?{' '}
                <Link
                  as={ReactRouterLink}
                  to={ACCOUNT_LOGIN}
                  color="royalBlue"
                  fontSize="14px"
                  letterSpacing={0}
                  fontWeight="medium"
                >
                  Login now
                </Link>
              </Subtitle>
            )}
          </Flex>
        </Form>
      </FormikContext.Provider>
      <VehicleManualEntryModal
        isOpen={isManualEntryModalOpen}
        setIsOpen={setIsManualEntryModalOpen}
      />
    </>
  );
};

export default LicensePlateOrVinInput;
