import { Flex, Link, Text } from '@chakra-ui/react';

import { PRIVACY_POLICY } from '../constants/urls';

const currentYear = new Date().getFullYear();

const SimpleFooter = () => {
  return (
    <Flex flexDir="column" justifyItems="center" alignItems="center" mt={6}>
      <Text>&#169; {currentYear} Lease End</Text>
      <Link href={PRIVACY_POLICY}>Privacy Policy</Link>
    </Flex>
  );
};

export default SimpleFooter;
