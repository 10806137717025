/* eslint-disable no-undef */
import { useEffect } from 'react';

const useIntersectionObserver = (
  element: HTMLElement | null,
  options: IntersectionObserverInit | undefined,
  observerCallback: IntersectionObserverCallback,
): void => {
  useEffect(() => {
    if (!element || !('IntersectionObserver' in window)) {
      return undefined;
    }

    const observer = new IntersectionObserver(observerCallback, options);

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [element, options, observerCallback]);
};

export default useIntersectionObserver;
