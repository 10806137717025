import {
  Box,
  BoxProps,
  Divider,
  Flex,
  FlexProps,
  Stack,
  StackDivider,
  StackProps,
  Text,
} from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import React from 'react';

import { WebCloserName } from '../../../constants/misc';
import { useDealAdvisorsQuery } from '../../../gql/generated/graphql';
import { NEW_DEAL_VALUE, dashboardDealInfoAtom } from '../Dashboard';
import TeamMember from './TeamMember';

interface Props extends BoxProps {
  isBooted?: boolean;
  textAlign?: FlexProps['textAlign'];
  wrap?: StackProps['wrap'];
}

const YourTeam = ({ isBooted, textAlign, wrap, ...props }: Props) => {
  const dealId = useAtomValue(dashboardDealInfoAtom);
  const { data: dealAdvisors } = useDealAdvisorsQuery({
    variables: {
      dealId: dealId?.id === NEW_DEAL_VALUE.id ? undefined : dealId?.id,
    },
  });

  const closerName = dealAdvisors?.dealAdvisors?.closer?.name;
  const fundingClerkName = dealAdvisors?.dealAdvisors?.funding_clerk?.name;
  const titleClerkName = dealAdvisors?.dealAdvisors?.title_clerk?.name;

  const hasCloser = closerName && closerName !== WebCloserName;
  const hasFundingClerk = fundingClerkName && fundingClerkName !== WebCloserName;
  const hasTitleClerk = titleClerkName && titleClerkName !== WebCloserName;

  const hasAdvisors = hasCloser || hasFundingClerk || hasTitleClerk;

  if (!hasAdvisors) {
    return null;
  }

  return (
    <Box {...props}>
      <Divider borderColor="grayDarkBackground" my="20px" />
      <Flex
        direction="column"
        alignItems={{ base: 'center', lg: isBooted ? 'start' : 'center' }}
        textAlign={textAlign || 'center'}
      >
        <Text>
          <b>Questions?</b> Your Lease End Success Team is ready to help.
        </Text>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          divider={
            <StackDivider
              borderColor="grayDarkBackground"
              display={{ base: 'none', md: 'block' }}
            />
          }
          gap="10px"
          mt="15px"
          wrap={wrap}
        >
          {hasCloser && <TeamMember type="Financial Specialist" name={closerName} />}
          {hasFundingClerk && <TeamMember type="Funding Advisor" name={fundingClerkName} />}
          {hasTitleClerk && <TeamMember type="Title Advisor" name={titleClerkName} />}
        </Stack>
      </Flex>
    </Box>
  );
};

export default YourTeam;
