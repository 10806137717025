import {
  CrossOriginLoginOptions,
  DbSignUpOptions,
  PasswordlessLoginOptions,
  PasswordlessStartOptions,
  WebAuth,
} from 'auth0-js';

import config from '../config';
import {
  ACCOUNT_LOGIN as ACCOUNT_LOGIN_URL,
  GETTING_STARTED,
  MKT_HOME,
  RESET_PASSWORD as RESET_PASSWORD_URL,
} from '../constants/urls';
import { resetLocalStorage } from './localStorage';

export enum AuthType {
  Email = 'email',
  PhoneNumber = 'sms',
  Password = 'Username-Password-Authentication',
}

export enum RedirectType {
  RESET_PASSWORD = 'RESET_PASSWORD',
  ACCOUNT_LOGIN = 'ACCOUNT_LOGIN',
  ACCOUNT_CHECK = 'ACCOUNT_CHECK',
}

export const redirects = {
  [RedirectType.RESET_PASSWORD]: RESET_PASSWORD_URL,
  [RedirectType.ACCOUNT_LOGIN]: ACCOUNT_LOGIN_URL,
  [RedirectType.ACCOUNT_CHECK]: GETTING_STARTED,
};

const getExternalAuthClient = (redirectType?: RedirectType): WebAuth => {
  return new WebAuth({
    clientID: config.auth0.externalClientId,
    domain: config.auth0.externalDomain,
    redirectUri: redirectType && `${config.baseUrl}${redirects[redirectType]}`,
    audience: config.auth0.audience,
    responseType: 'token',
  });
};

type PasswordlessOpts = {
  redirectType?: RedirectType;
  phoneNumber?: PasswordlessStartOptions['phoneNumber'];
  email?: PasswordlessStartOptions['email'];
  callback?: Parameters<WebAuth['passwordlessStart']>[1];
};

export const startPasswordlessEmailCode = ({ email, callback }: PasswordlessOpts) => {
  getExternalAuthClient().passwordlessStart(
    {
      connection: AuthType.Email,
      send: 'code',
      email,
    },
    callback || (() => undefined),
  );
};

export const startPasswordlessEmailLink = ({ redirectType, email, callback }: PasswordlessOpts) => {
  getExternalAuthClient(redirectType).passwordlessStart(
    {
      connection: AuthType.Email,
      send: 'link',
      email,
    },
    callback || (() => undefined),
  );
};

type CompletePasswordlessOpts = {
  code: PasswordlessLoginOptions['verificationCode'];
  redirectType: RedirectType;
  phoneNumber?: PasswordlessLoginOptions['phoneNumber'];
  email?: PasswordlessLoginOptions['phoneNumber'];
  callback: Parameters<WebAuth['passwordlessLogin']>[1];
};

export const completePasswordlessEmail = ({
  redirectType,
  code,
  email,
  callback,
}: CompletePasswordlessOpts) => {
  getExternalAuthClient(redirectType).passwordlessLogin(
    {
      connection: AuthType.Email,
      verificationCode: code,
      email,
    },
    callback,
  );
};

interface ParseTokenFromHashOpts {
  hash: Parameters<WebAuth['parseHash']>[0]['hash'];
  callback: Parameters<WebAuth['parseHash']>[1];
}

// auth client doesn't matter with parseHash nor logout
export const parseTokenFromHash = ({ hash, callback }: ParseTokenFromHashOpts) =>
  getExternalAuthClient(RedirectType.ACCOUNT_LOGIN).parseHash({ hash }, callback);

export interface LogoutOpts {
  redirectType?: RedirectType;
  queryParams?: URLSearchParams;
}

export const logout = ({
  redirectType = RedirectType.ACCOUNT_LOGIN,
  queryParams,
}: LogoutOpts = {}) => {
  resetLocalStorage({ removeGuid: true, removeAccessToken: true });
  const queryParamsString = queryParams?.toString();
  getExternalAuthClient(redirectType).logout({
    returnTo: `${MKT_HOME}${queryParamsString ? `?${queryParamsString}` : ''}`,
  });
};

export const signupAndAuthorize = ({
  email,
  password,
  username,
  userMetadata,
  callback,
}: Omit<DbSignUpOptions, 'connection'> & {
  callback: Parameters<WebAuth['signupAndAuthorize']>[1];
}) => {
  getExternalAuthClient().signupAndAuthorize(
    {
      email,
      password,
      connection: AuthType.Password,
      username,
      userMetadata,
    },
    callback,
  );
};

export const login = ({
  redirectType,
  email,
  password,
  callback,
}: CrossOriginLoginOptions & {
  redirectType?: RedirectType;
  callback: Parameters<WebAuth['login']>[1];
}) => {
  getExternalAuthClient(redirectType || RedirectType.ACCOUNT_LOGIN).login(
    {
      email,
      password,
    },
    callback,
  );
};
