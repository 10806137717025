import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';
import { Button, Image, Text, VStack, useBreakpointValue } from '@chakra-ui/react';
import { useAtom, useAtomValue } from 'jotai';
import React, { useContext, useEffect, useRef } from 'react';

import {
  delayDigitalResponseAtom,
  digitallyFoundAtom,
  helpAtom,
  requirementsCompletedAtom,
} from '../..';
import { CloudSearch } from '../../../../assets/Images';
import { useLEAccordionItemContext } from '../../../../components/LEAccordion/LEAccordionItem/useLEAccordionItem';
import { ACCORDION_NAME, ACCORDION_NAME_TO_INDEX } from '../../../../components/LEAccordion/utils';
import Loader from '../../../../components/Loader';
import PrimaryButton from '../../../../components/ui/buttons/PrimaryButton';
import {
  PayoffRequest,
  PayoffRequestStatus,
  useCancelPayoffRequestMutation,
  useCreatePayoffRequestMutation,
  useGetOperatingHoursAndTimeSlotsQuery,
  useTemporaryInfoUpsertMutation,
} from '../../../../gql/generated/graphql';
import { CustomerInfoValues, InquiryType } from '../../../../gql/prs/types';
import { LienholderContext, LienholderContextType } from '../../../../hooks/useLienholderInfo';
import { useTempInfo } from '../../../../hooks/useTempInfo';
import LienholderLogo from '../LienholderLogo';

interface Props {
  customerInfoValues: CustomerInfoValues;
  digitalFailure: boolean;
  getPayoffInfo: LazyQueryExecFunction<unknown, OperationVariables>;
  payoffRequestRefetch: () => void;
  payoffRequest: PayoffRequest | undefined | null;
  setDigitalGatheringExplanationExpanded: (value: boolean) => void;
}

const PayoffRetrieval = ({
  customerInfoValues,
  digitalFailure,
  getPayoffInfo,
  payoffRequest,
  payoffRequestRefetch,
  setDigitalGatheringExplanationExpanded,
}: Props) => {
  const hasRun = useRef(false);
  const { updateState, index, setOpenAccordionIndex, isExpanded } = useLEAccordionItemContext();
  const { allowsDigital } = useContext<LienholderContextType>(LienholderContext);
  const { data: operatingHoursData } = useGetOperatingHoursAndTimeSlotsQuery();
  const afterHours = !operatingHoursData?.getOperatingHoursAndTimeSlots?.isOpen;

  const tempInfo = useTempInfo();
  const tempInfoData = tempInfo.info?.data;

  const [createPayoff] = useCreatePayoffRequestMutation();
  const [cancelPayoffRequest] = useCancelPayoffRequestMutation();

  const requirementsCompleted = useAtomValue(requirementsCompletedAtom);
  const delayDigitalResponse = useAtomValue(delayDigitalResponseAtom);
  const digitallyFound = useAtomValue(digitallyFoundAtom);
  const [help, setHelp] = useAtom(helpAtom);
  const showLogo = useBreakpointValue({ base: false, md: true });

  useEffect(() => {
    payoffRequestRefetch();
  }, []);

  const [upsertTempInfo] = useTemporaryInfoUpsertMutation();
  const setCurrentAccordionAndPanel = () => {
    upsertTempInfo({
      variables: {
        info: {
          id: tempInfo.info?.id,
          data: {
            current_accordion_panel: `${
              ACCORDION_NAME_TO_INDEX[ACCORDION_NAME.PAYOFF_ACCORDION]
            }:${index}`,
          },
        },
      },
    });
  };

  useEffect(() => {
    const createPayoffRequest = async () => {
      await createPayoff();
      updateState({ status: 'none' });
    };

    if (
      tempInfoData &&
      isExpanded &&
      help &&
      (!allowsDigital || digitalFailure) &&
      requirementsCompleted &&
      // Explicity expecting null. If it's undefined, it means the usePayoffRequestQuery hasn't completed yet
      (payoffRequest === null || payoffRequest?.status === PayoffRequestStatus.Cancelled) &&
      !hasRun.current
    ) {
      setCurrentAccordionAndPanel();
      createPayoffRequest();
      hasRun.current = true;
    }
  }, [requirementsCompleted, payoffRequest, isExpanded, digitalFailure, tempInfoData]);

  useEffect(() => {
    if (!tempInfoData || !allowsDigital || digitalFailure || !isExpanded || digitallyFound) {
      return;
    }

    let inquiryType;
    let inquiryKey;

    if (customerInfoValues?.SSN) {
      inquiryType = InquiryType.SSN;
      inquiryKey = customerInfoValues.SSN;
    } else if (customerInfoValues?.ACCOUNT_NUMBER) {
      inquiryType = InquiryType.ACC;
      inquiryKey = customerInfoValues.ACCOUNT_NUMBER;
    } else {
      inquiryType = InquiryType.VIN;
      inquiryKey = tempInfoData?.vin;
    }

    const variables = {
      state: tempInfoData?.state,
      currentOdometer: tempInfoData.mileage,
      inquiryType,
      inquiryKey,
      slug: tempInfoData.lienholder_slug,
    };

    setCurrentAccordionAndPanel();
    getPayoffInfo({ variables });
  }, [customerInfoValues, tempInfoData, allowsDigital, isExpanded]);

  useEffect(() => {
    // This is used to start the timer to show the cloud search gif for digital payoffs
    if (isExpanded && allowsDigital) setDigitalGatheringExplanationExpanded(true);
  }, [isExpanded, allowsDigital]);

  const handleNext = () => {
    updateState({ status: 'complete' });
    setOpenAccordionIndex(index + 1);
  };

  useEffect(() => {
    if (digitallyFound && isExpanded) {
      handleNext();
    }
  }, [digitallyFound]);

  if (!tempInfoData) {
    return <Loader />;
  }

  const handleGetItMyself = async () => {
    await cancelPayoffRequest();
    setHelp(false);
    updateState({ status: 'none' });
  };

  const getText = () => {
    if ((digitalFailure && !delayDigitalResponse) || !allowsDigital) {
      return (
        <>
          {showLogo && <LienholderLogo />}
          <Text align="center">
            We're on it! We will send you an email with a link to continue your lease buyout process
            after we find your payoff information. In the meantime, feel free to keep this page
            open, or you can close it and go have a cup of coffee!
          </Text>
          <Text align="center" pt={4}>
            If we have any questions we'll give you a call.
          </Text>
          {payoffRequest?.status !== PayoffRequestStatus.Completed && (
            <Button
              variant="link"
              mt="6 !important"
              fontSize={{ base: '12px', md: '14px' }}
              onClick={handleGetItMyself}
            >
              I’d rather get it myself
            </Button>
          )}
        </>
      );
    }

    if ((allowsDigital && !digitallyFound) || delayDigitalResponse) {
      return (
        <>
          <Text align="center">
            We are looking for the best way to find your payoff. This could take up to a minute.
          </Text>
          <Image src={CloudSearch} alt="Cloud Search" h="52" />
        </>
      );
    }

    if (digitallyFound) {
      return (
        <Text align="center">
          WE FOUND YOUR PAYOFF! Click the button below to see your payoff and good through date.
        </Text>
      );
    }

    return null;
  };

  const getNextButton = () => {
    if (delayDigitalResponse) {
      return null;
    }

    if (digitallyFound || payoffRequest?.status === PayoffRequestStatus.Completed) {
      return (
        <PrimaryButton onClick={handleNext} mt="30px !important" mx="auto" type="submit">
          {afterHours && !digitallyFound ? 'I RECEIVED THE EMAIL!' : 'NEXT'}
        </PrimaryButton>
      );
    }

    return null;
  };

  return (
    <VStack>
      {getText()}
      {getNextButton()}
    </VStack>
  );
};

export default PayoffRetrieval;
