import { string } from 'yup';

import { requiredStringMaxCharsValidation } from '../strings';

export const addressLineValidation = requiredStringMaxCharsValidation({ fieldName: 'Address' });
export const addressLine2Validation = string()
  .notRequired()
  .nullable()
  .max(30, 'Must be 30 characters or less');

export const cityValidation = requiredStringMaxCharsValidation({ fieldName: 'City' });
export const stateValidation = string().nullable().required('Please select a value');

const unitedStatesNames = [
  'us',
  'u.s.',
  'usa',
  'u.s.a.',
  'united states',
  'america',
  'the united states',
  'united states of america',
  'the united states of america',
];

export const countyValidation = requiredStringMaxCharsValidation({ fieldName: 'County' }).test(
  'valid-county',
  'Please enter county, not country',
  (value) => {
    if (typeof value === 'string') {
      return !unitedStatesNames.includes(value.trim().toLowerCase());
    }
    return false;
  },
);

export const movedStatesValidation = string().required('Please enter a value');
