import { useEffect, useState } from 'react';

const LOADED_ATTRIBUTE = 'data-loaded';
const SECONDS_TO_WAIT = 5;
const RECHECK_INTERVAL = 200;

const getExistingScript = (src: string) =>
  document.querySelector<HTMLScriptElement>(`script[src="${src}"]`);

const useScript = (scriptUrl: string) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const existingScript = getExistingScript(scriptUrl);

    if (existingScript) {
      const checkLoaded = (count: number) => {
        if (existingScript.getAttribute(LOADED_ATTRIBUTE)) {
          setIsLoaded(true);
        } else if (count < (SECONDS_TO_WAIT * 1000) / RECHECK_INTERVAL) {
          setTimeout(() => checkLoaded(count + 1), RECHECK_INTERVAL);
        } else {
          setIsLoaded(true);
        }
      };
      checkLoaded(0);
    }

    const scriptNode = document.createElement('script');

    scriptNode.src = scriptUrl;
    scriptNode.async = true;

    scriptNode.onload = () => {
      scriptNode.setAttribute(LOADED_ATTRIBUTE, true.toString());
      setIsLoaded(true);
    };

    document.body.appendChild(scriptNode);
  }, [scriptUrl]);

  return isLoaded;
};

export default useScript;
