import { useDisclosure } from '@chakra-ui/react';
import React, { useEffect } from 'react';

import {
  MagnifyingGlassPurpleGuy,
  OdometerPurpleGuy,
  OkGestureGuy,
  PointingGuy,
} from '../../assets/Images';
import { LEAccordion, LEAccordionItem } from '../../components/LEAccordion';
import LEAccordionHeader from '../../components/LEAccordion/LEAccordionHeader';
import LEAccordionContainer from '../../components/LEAccordion/LeAccordionContainer';
import { ACCORDION_NAME } from '../../components/LEAccordion/utils';
import SafetyMessage from '../../components/SafetyMessage';
import { ButtonWrapper } from '../../components/ui/buttons/ButtonWrapper';
import SecondaryButton from '../../components/ui/buttons/SecondaryButton';
import { useStep } from '../../hooks';
import { RudderEvent, rudderanalytics } from '../../utils/rudderstack';
import JustTheBasics from './components/JustTheBasicsItem/Content';
import LooksRightItem from './components/LooksRightItem';
import PersonalInfo from './components/PersonalInfo';
import WhoIsYourLease from './components/WhoIsYourLease';

const GettingStarted: React.FC = () => {
  const { onToggle: refreshToggle } = useDisclosure();
  const step = useStep();

  useEffect(() => {
    rudderanalytics.track(RudderEvent.RStackWebStartFlow);
  }, []);

  return (
    <LEAccordionContainer>
      <LEAccordionHeader>We found your car!</LEAccordionHeader>
      <LEAccordion name={ACCORDION_NAME.VEHICLE_ACCORDION} minutes={1}>
        <LEAccordionItem
          accordionKey="vehicle"
          title="DOES THIS LOOK RIGHT?"
          imgSrc={MagnifyingGlassPurpleGuy}
        >
          <LooksRightItem />
        </LEAccordionItem>
        <LEAccordionItem
          accordionKey="odoZipColor"
          title="JUST THE BASICS"
          imgSrc={OdometerPurpleGuy}
        >
          <JustTheBasics refreshToggle={refreshToggle} />
        </LEAccordionItem>
        <LEAccordionItem
          accordionKey="lienholder"
          title="WHO IS YOUR LEASE THROUGH?"
          imgSrc={PointingGuy}
          contentWrapperProps={{ zIndex: 1 }}
        >
          <WhoIsYourLease />
        </LEAccordionItem>
        <LEAccordionItem
          accordionKey="personalInfo"
          title="HOW CAN WE STAY IN TOUCH?"
          imgSrc={OkGestureGuy}
        >
          <PersonalInfo />
        </LEAccordionItem>
      </LEAccordion>
      <SafetyMessage />
      <ButtonWrapper>
        <SecondaryButton onClick={() => step.movePrevious({ redirect: true })} />
      </ButtonWrapper>
    </LEAccordionContainer>
  );
};

export default GettingStarted;
