import { Box, Button, Container, Flex, Link, Text } from '@chakra-ui/react';
import { Form, Formik, FormikHelpers, FormikState } from 'formik';
import { useState } from 'react';
import { Link as RRLink } from 'react-router-dom';
import * as Yup from 'yup';

import SimpleFooter from '../../components/SimpleFooter';
import CodeInput from '../../components/formComponents/CodeInput';
import Input from '../../components/formComponents/Input';
import { ACCOUNT_LOGIN } from '../../constants/urls';
import { useCheckExistingAccountByEmailLazyQuery } from '../../gql/generated/graphql';
import {
  RedirectType,
  completePasswordlessEmail,
  startPasswordlessEmailCode,
} from '../../services/auth0';
import { emailValidationRequired } from '../../utils/validation/email';
import Subtitle from './components/Subtitle';
import Title from './components/Title';

const STEP_EMAIL = 'email';
const STEP_CODE = 'code';

interface ForgotPasswordForm {
  code: string;
  email: string;
}

interface ContentProps {
  isSubmitting: FormikState<ForgotPasswordForm>['isSubmitting'];
}

const ForgotPassword = () => {
  const [checkExistingAccountByEmail] = useCheckExistingAccountByEmailLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [step, setStep] = useState<keyof typeof STEPS>(STEP_EMAIL);
  const [error, setError] = useState('');

  const STEPS = {
    [STEP_EMAIL]: {
      title: 'Forgot Password?',
      subtitle: 'Enter your email to reset your password',
      validationSchema: Yup.object({
        email: emailValidationRequired,
      }),
      onSubmit: async (
        values: ForgotPasswordForm,
        formikHelpers: FormikHelpers<ForgotPasswordForm>,
      ) => {
        const { data: accountExists } = await checkExistingAccountByEmail({
          variables: {
            email: values.email,
          },
        });

        if (!accountExists?.checkExistingAccountByEmail) {
          setStep(STEP_CODE);
          setError('');
          return;
        }

        startPasswordlessEmailCode({
          email: values.email,
          callback: (err) => {
            if (err) {
              setError(err?.description ?? err?.toString() ?? 'Something went wrong');
              return;
            }

            setStep(STEP_CODE);
            setError('');
            formikHelpers.setSubmitting(false);
          },
        });
      },
      content: (contentProps: ContentProps) => {
        const { isSubmitting } = contentProps;

        return (
          <>
            <Input
              label="Email"
              name="email"
              placeholder="Email"
              autoFocus
              _container={{ textAlign: 'left' }}
            />
            <Button w="100%" mt={8} mb="10px" type="submit" isLoading={isSubmitting}>
              RESET PASSWORD
            </Button>
            <Box textAlign="center">
              <Link as={RRLink} to={ACCOUNT_LOGIN}>
                Back to login
              </Link>
            </Box>
          </>
        );
      },
    },
    [STEP_CODE]: {
      title: 'We’ve Sent You A Code',
      subtitle: null,
      validationSchema: Yup.object({
        code: Yup.string().min(6).max(6).required('Please enter a value'),
      }),
      onSubmit: (values: ForgotPasswordForm, formikHelpers: FormikHelpers<ForgotPasswordForm>) => {
        completePasswordlessEmail({
          redirectType: RedirectType.RESET_PASSWORD,
          code: values.code,
          email: values.email,
          callback: (err) => {
            if (err) {
              setError(err?.description ?? err?.toString() ?? 'Invalid code');
              formikHelpers.setSubmitting(false);
            }
          },
        });
      },
      content: (contentProps: ContentProps) => {
        const { isSubmitting } = contentProps;

        return (
          <>
            <CodeInput
              inputWidth="100%"
              name="code"
              label="If the email address you provide is associated with a Lease End account, you'll receive an email containing a 6-digit code."
            />
            <Link variant="link" mt={2} onClick={() => setStep(STEP_EMAIL)}>
              I didn’t receive my code
            </Link>
            <Button w="100%" mt={8} mb="10px" type="submit" isLoading={isSubmitting}>
              RESET PASSWORD
            </Button>
            <Link as={RRLink} to={ACCOUNT_LOGIN}>
              Back to login
            </Link>
            <Text color="taupeGray" fontSize="sm" textAlign="center" mt={4}>
              If you don’t see the email in your inbox, check your spam folder.
            </Text>
          </>
        );
      },
    },
  };

  return (
    <Container pt={10} textAlign="center">
      <Title>{STEPS[step].title}</Title>
      {STEPS[step].subtitle && <Subtitle>{STEPS[step].subtitle}</Subtitle>}
      <Formik
        enableReinitialize
        initialValues={{ code: '', email: '' }}
        validationSchema={STEPS[step].validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={STEPS[step].onSubmit}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <Flex
                maxW="400px"
                flexDirection="column"
                bg="white"
                p={8}
                rounded="lg"
                alignItems="center"
                mx="auto"
              >
                {error && (
                  <Text fontSize="14px" mb={4} color="leaseEndRed">
                    {error}
                  </Text>
                )}
                {STEPS[step].content({ isSubmitting })}
              </Flex>
              <SimpleFooter />
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default ForgotPassword;
