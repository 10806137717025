import { PropsOf, StackProps } from '@chakra-ui/react';
import { useField } from 'formik';
import { ChangeEvent, useState } from 'react';
import ReactInputMask from 'react-input-mask';

import { Digits, MaskMap, formatSSN, parseSSN } from '../../../utils/ssn';
import Input from '../Input';
import { LabelWithShield, ShowHideIcon } from './BaseSsnComponents';

interface Props extends Omit<PropsOf<typeof Input>, 'placeholder'> {
  digits: Digits;
  _container?: StackProps;
}

const SSNInput = ({ digits, name, label, _container, ...props }: Props) => {
  const [{ value }, , { setValue }] = useField<string>(name);
  const { mask, placeholder, label: defaultLabel } = MaskMap[digits];
  const [showSSN, setShowSSN] = useState(true);

  return (
    <Input
      name={name}
      _input={{
        inputMode: 'numeric',
        value: formatSSN(value, showSSN),
        ...(showSSN
          ? {
              as: ReactInputMask,
              mask,
              maskPlaceholder: '_',
            }
          : {
              maxLength: Number(digits),
            }),
      }}
      type={showSSN ? 'text' : 'password'}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value) {
          setValue(parseSSN(e.target.value, digits));
        }
      }}
      _container={_container}
      {...props}
      placeholder={showSSN ? placeholder : ''}
      icon={<ShowHideIcon show={showSSN} setShow={setShowSSN} isBlank={value === ''} />}
      label={<LabelWithShield label={label} defaultLabel={defaultLabel} />}
    />
  );
};

export default SSNInput;
