import { Box, Button, Center } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { getFinixCardTokenForm, paymentFormOptions } from '../../utils/payments';
import { PaymentFormValues } from './PaymentForm';

const FINIX_FORM_ID = 'finix-card-payment-dashboard-form';

interface Props {
  setFinixForm: Dispatch<SetStateAction<unknown>>;
}

const CardDetails = ({ setFinixForm }: Props) => {
  const { isSubmitting, submitForm } = useFormikContext<PaymentFormValues>();
  const [isProcessing, setIsProcessing] = useState(false);

  const processPayment = async () => {
    setIsProcessing(true);
    await submitForm();
  };

  useEffect(() => {
    const form = getFinixCardTokenForm(FINIX_FORM_ID, paymentFormOptions);
    setFinixForm(form);
  }, []);

  return (
    <Box>
      <Box
        sx={{
          label: {
            fontSize: '1rem',
            marginBottom: '5px',
            textTransform: 'uppercase',
            color: 'leaseEndBlue',
            fontWeight: 600,
            letterSpacing: '1px',
          },
        }}
        textAlign="left"
        id={FINIX_FORM_ID}
      />

      <Center>
        <Button
          px={0}
          mt={8}
          w="200px"
          isLoading={isSubmitting || isProcessing}
          loadingText="PROCESSING"
          onClick={processPayment}
        >
          PROCESS PAYMENT
        </Button>
      </Center>
    </Box>
  );
};

export default CardDetails;
