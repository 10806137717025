import React from 'react';

import { CuriousPurpleGuy } from '../../../../assets/Images';
import { LEAccordionItem } from '../../../../components/LEAccordion';
import Content from './Content';
import Subtitle from './Subtitle';

const WeCanHelpItem = () => {
  return (
    <LEAccordionItem
      accordionKey="weCanHelp"
      title="WE CAN HELP"
      subtitle={<Subtitle />}
      imgSrc={CuriousPurpleGuy}
    >
      <Content />
    </LEAccordionItem>
  );
};

export default WeCanHelpItem;
