import { Cars, SedanWhite, SuvWhite, Suvs, TruckWhite, Trucks } from '../../assets/Images';
import { VEHICLE_COLORS } from '../../constants/vehicleColors';
import { vehicleType } from '../../constants/vehicleType';
import { Car, TemporaryInfo } from '../../gql/generated/graphql';

export const carIconWhite = (info: TemporaryInfo | undefined | null) => {
  if (info?.data?.vehicle_type === vehicleType.MPV) {
    return SuvWhite;
  }
  if (info?.data?.vehicle_type?.trim() === vehicleType.TRUCK) {
    return TruckWhite;
  }
  return SedanWhite;
};

export const carIconFromCarData = (car: Car | undefined | null) => {
  let vehicles = Cars;

  if (car?.vehicle_type === vehicleType.MPV) {
    vehicles = Suvs;
  } else if (car?.vehicle_type?.trim() === vehicleType.TRUCK) {
    vehicles = Trucks;
  }

  const color = car?.color || VEHICLE_COLORS.White;

  const vehicle = vehicles.find((val) => val.value === color);

  return vehicle?.key;
};
