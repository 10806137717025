import { Box, Button, Center, Divider, Flex, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ACCOUNT_LOGIN, DASHBOARD } from '../../constants/urls';
import { useLocalStorage } from '../../hooks';
import { CookieKeys, useCookie } from '../../hooks/useCookie';
import { LocalStorageKeys } from '../../services/localStorage';
import { formatMoney } from '../../utils/helpers';
import { PaymentData, PlaidData } from '../../utils/payments';
import { snakeCaseToHuman } from '../../utils/strings';

interface Props {
  paymentData: PaymentData;
  plaidData?: PlaidData;
}

const Success = ({ paymentData, plaidData }: Props) => {
  const history = useHistory();
  const [accessToken] = useCookie<string>(CookieKeys.ACCESS_TOKEN);
  const [, setFinixIdempotencyId] = useLocalStorage<string>(
    LocalStorageKeys.FINIX_IDEMPOTENCY_ID,
    '',
  );

  const account = plaidData?.accounts?.[0];
  const isAuthenticated = !!accessToken;
  const isBankPayment = !!plaidData;

  useEffect(() => {
    setFinixIdempotencyId('');
  }, []);

  return (
    <Center>
      <Box alignSelf="center">
        <Box
          bgColor="paleJungleGreen"
          borderWidth="1px"
          borderColor="jungleGreen"
          borderRadius="md"
          p={6}
          mb={6}
        >
          Payment successful!
        </Box>
        <Text fontSize={{ base: '18px', md: '22px' }} fontWeight="bold" mb={2}>
          Transaction ID Number:
        </Text>
        <Text fontSize={{ base: '14px', md: '16px' }}>{paymentData?.id}</Text>
        <Divider borderColor="grayDarkBackground" mt={10} mb={6} />
        <Text fontSize={{ base: '18px', md: '22px' }} fontWeight="bold" mb={2}>
          {isBankPayment ? plaidData?.institution?.name : `${paymentData?.cardBrand} Card`}
        </Text>
        <Text fontSize={{ base: '14px', md: '16px' }} mb={1}>
          {isBankPayment
            ? `Account: ******${account?.id.slice(-4).toUpperCase()}`
            : `${paymentData?.maskedNumber}`}
        </Text>
        <Text fontSize={{ base: '14px', md: '16px' }} mb={3} textTransform="capitalize">
          {isBankPayment ? `${account?.subtype}` : `${snakeCaseToHuman(paymentData?.cardType)}`}
        </Text>
        <Flex alignItems="baseline" justifyContent="space-between">
          <Text fontSize={{ base: '14px', md: '16px' }} wordBreak="break-word">
            Amount processed
          </Text>
          <Text
            display={{ base: 'none', sm: 'block' }}
            as="span"
            flex={1}
            borderBottom="2px dotted"
            borderColor="gainsboro"
            mx={1}
          />
          <Text fontSize={{ base: '18px', md: '22px' }} fontWeight="bold" mb={2}>
            {formatMoney(paymentData.amount)}
          </Text>
        </Flex>
        <Divider borderColor="grayDarkBackground" mt={10} mb={6} />
        <Center>
          <Button
            mx="auto"
            onClick={() => history.push(isAuthenticated ? DASHBOARD : ACCOUNT_LOGIN)}
          >
            {isAuthenticated ? 'BACK TO MY DASHBOARD' : 'FINISH'}
          </Button>
        </Center>
      </Box>
    </Center>
  );
};

export default Success;
