import { Box, Divider, HStack, Link, StackDivider, Text, VStack } from '@chakra-ui/react';

import { CONTACT_US, PRIVACY_POLICY } from '../../../constants/urls';

const currentYear = new Date().getFullYear();

const DashboardFooter = () => {
  return (
    <Box py={20}>
      <Divider borderColor="grayDarkBackground" my="10px" />
      <VStack fontSize="12px" alignItems="center">
        <HStack
          fontWeight="semibold"
          divider={<StackDivider borderColor="taupeGray" />}
          justifyContent="center"
        >
          <Link href={PRIVACY_POLICY} target="_blank">
            Privacy
          </Link>
          <Link href={CONTACT_US} target="_blank">
            Contact Us
          </Link>
        </HStack>
        <Text>©{currentYear} Lease End. All rights reserved</Text>
      </VStack>
    </Box>
  );
};

export default DashboardFooter;
