import { Box, Flex, Icon, Spinner, Text, VStack, useBreakpointValue } from '@chakra-ui/react';
import { Form, FormikContext, FormikContextType } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { MdChevronRight } from 'react-icons/md';

import ShoppingCartDesktopModal from './ShoppingCartDesktopModal';
import { EstimatorFormFields } from './useEstimatorFormik';

const ShoppingCartDesktopSidebar = ({
  isOpen,
  setIsOpen,
  loading,
  formik,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  formik: FormikContextType<EstimatorFormFields>;
}) => {
  const showEstimated = useBreakpointValue({ base: false, xl: true });
  const { values } = formik;
  return (
    <FormikContext.Provider value={formik}>
      <Form>
        <VStack align="left">
          <Box
            shadow="sm"
            width={{ lg: '190px', xl: '275px' }}
            bgColor="leaseEndGreen"
            padding={{ base: '15px 5px 15px 15px', xl: '15px 10px 15px 15px' }}
            color="white"
            borderRadius="15px"
            textAlign="left"
            mb={2}
          >
            <Text mb={1} fontSize="14px">
              {showEstimated ? 'Estimated' : 'Est.'} <b>Monthly</b> Payment
            </Text>
            {loading && !isOpen ? (
              <Spinner as="span" />
            ) : (
              <Flex alignItems="center" justifyContent="space-between">
                <Text
                  fontSize={
                    values.estimated_payment.length > 12
                      ? { base: '20px', xl: '28px' }
                      : { base: '24px', xl: '32px' }
                  }
                  fontWeight="bold"
                  onClick={() => setIsOpen(true)}
                  cursor="pointer"
                >
                  {values.estimated_payment}
                </Text>
                <Icon
                  fontSize={
                    values.estimated_payment.length > 12
                      ? { base: '28px', xl: '36px' }
                      : { base: '36px', xl: '40px' }
                  }
                  as={MdChevronRight}
                  color="greenLight"
                  _hover={{ color: 'white', cursor: 'pointer' }}
                  onClick={() => setIsOpen(true)}
                />
              </Flex>
            )}
          </Box>
          <ShoppingCartDesktopModal isOpen={isOpen} setIsOpen={setIsOpen} loading={loading} />
        </VStack>
      </Form>
    </FormikContext.Provider>
  );
};

export default ShoppingCartDesktopSidebar;
